import React, { useMemo } from 'react';

import { TABLE_ID } from 'common/constants';

import Table from 'components/UI/TableConfig/Table';
import {
  ColumnTypesCallback,
  typeToScheme,
} from 'components/UI/TableConfig/column-helper';
import { isColumnConfigClickable } from 'components/UI/TableConfig/types';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { usePageSize } from 'components/hooks/usePageSize';
import { css } from 'emotion';

import { openModal } from 'navigation/utils';
import {
  REV_BI_SPLITS_MODAL_SCHEME,
  useRevBiDrilldownModalWithScheme,
} from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { useManageTableConfigurationColumns } from 'components/hooks/useSettingsForTable';
import {
  getCSVDownloadUrl,
  getDrilldownUrlQueryParams,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import {
  configureDeltaTooltip,
  mergeDeltaAndValue,
  parseCsvColumns,
  parseDrilldownItems,
  useManageDrilldownData,
} from 'components/hooks/useManageDrilldownData';
import { DeltasTag } from 'components/dashboard/ForecastRollUps/styles';
import { OPPORTUNITY_SPLIT } from 'components/dashboard/Metrics/constants';

const modalHeight = css`
  height: 80vh;
`;

const SPLITS_TABLE_SETTINGS = {
  collectionName: OPPORTUNITY_SPLIT,
  tableName: 'RevbiOpportunitySplit',
};

export const OpportunitySplitModal: React.FC = () => {
  const { getProps } = useRevBiDrilldownModalWithScheme(
    REV_BI_SPLITS_MODAL_SCHEME
  );

  const { drilldownParams, metricObject, metricName, changeInterval } =
    getProps();

  const [oppSplitPageSize] = usePageSize('RevbiOpportunitySplit', 15);

  const handleOpportunitiesTableSpecialClick: ColumnTypesCallback = (props) => {
    if (
      isColumnConfigClickable(props.columnConfig) &&
      props.columnConfig.meta.object_id &&
      props.row?.opportunity
    ) {
      const scheme = typeToScheme[props.columnConfig.meta.object_id];
      const opp: IRow = props.row.opportunity as IRow;

      if (scheme) {
        openModal({
          scheme,
          params: { id: opp[props.columnConfig.meta.object_id] as string },
        });
      } else {
        console.warn('Action not supported');
      }
    }
  };

  const { parseColumns, sortColumns } = useManageTableConfigurationColumns();

  const params = {
    ...drilldownParams,
    limit: oppSplitPageSize,
  };

  const {
    items,
    itemsCount,
    columns,
    deltaItems,
    isLoading,
    isSuccess,
    isDeltaLoading,
    isDeltaSuccess,
    pageNumber,
    handlePaginationChange,
    sortOrder,
    handleSort,
  } = useManageDrilldownData({
    tableSettingOrUlr: SPLITS_TABLE_SETTINGS,
    metricObject: OPPORTUNITY_SPLIT,
    params,
    changeInterval,
  });

  const tableColumns =
    columns &&
    sortColumns(parseColumns({
      tableConfigurationColumns: columns,
      hiddenColumns: [],
      oppSplitViewToggleEnabled: false,
      onSpecialClick: handleOpportunitiesTableSpecialClick,
    }).map((column) => configureDeltaTooltip(column, changeInterval)));



  const rows = useMemo(() => {
    const parsedItems = parseDrilldownItems(items);

    if (!isDeltaSuccess) {
      return parsedItems;
    }

    return parsedItems.map((item) =>
      mergeDeltaAndValue(
        item,
        deltaItems.find((deltaItem) => deltaItem.id === item.id),
        tableColumns
      )
    );
  }, [items, deltaItems, tableColumns, isDeltaSuccess]);

  const csvColumns = parseCsvColumns(columns);

  const downloadButton = useMemo(
    () => getCSVDownloadUrl(OPPORTUNITY_SPLIT, drilldownParams, csvColumns),
    [drilldownParams, csvColumns]
  );

  const tableTitle: string = metricName ?? 'Opportunity Split';

  return (
    <div className={modalHeight}>
      <Table
        tableId={TABLE_ID.SPLITS_MODAL}
        renderTitleExtra={() =>
          isSuccess &&
          isDeltaLoading && <DeltasTag>Fetching deltas ... </DeltasTag>
        }
        pinnableColumns
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        showColumnsVisibilityToggle
        totalAmount={0}
        loading={isLoading}
        title={tableTitle}
        totalCount={itemsCount}
        currentPage={pageNumber}
        rowsPerPage={oppSplitPageSize}
        downloadButton={downloadButton}
        data={rows}
        columns={tableColumns}
        sortOrder={sortOrder}
        showTotalAmount={false}
        onSort={handleSort}
        onPaginationChange={handlePaginationChange}
        styleFirstColumn
        firstColumnSize={400}
      />
    </div>
  );
};
