import React from 'react';
import classNames from 'classnames';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { IconProps } from '@tabler/icons-react';

export type IIconProps = {
  name: BoostUpIcons | React.ComponentType<IconProps>;
  className?: string;
  color?: string;
  size?: number;
  stroke?: number;
  forwardedRef?: React.Ref<HTMLElement>;
};

type IIconGroupProps = {
  className?: string;
  forwardedRef?: React.Ref<HTMLElement>;
};

const BuIcon: React.FC<IIconProps> & { Group: React.FC<IIconGroupProps> } = ({
  forwardedRef,
  name,
  className,
  color,
  size = 24,
  stroke = 1.8,
}) => {
  const IconComponent: string | React.ComponentType<IconProps> =
    typeof name === 'string' && name in BoostUpIcons
      ? BoostUpIcons[name as unknown as keyof typeof BoostUpIcons]
      : name;

  if (typeof IconComponent === 'string') {
    return (
      <i
        ref={forwardedRef}
        aria-hidden="true"
        className={classNames(`bu-icon bu-icon-${IconComponent}`, className)}
        style={{ color }}
      />
    );
  }

  return (
    <IconComponent
      className={classNames(
        `bu-icon bu-icon-${IconComponent.displayName}`,
        className
      )}
      color={color}
      size={size}
      stroke={stroke}
      style={{ zIndex: 'inherit' }} // to override the default svg z-index of 9999
    />
  );
};
BuIcon.Group = ({ forwardedRef, children, className }) => (
  <i ref={forwardedRef} className={classNames('bu-icons-group', className)}>
    {children}
  </i>
);

export const BuLogo: React.FC<IIconGroupProps> = ({
  forwardedRef,
  className,
}) => (
  <BuIcon.Group forwardedRef={forwardedRef} className={className}>
    <BuIcon
      name={BoostUpIcons.BoostupLogoPart_1}
      color="var(--bu-color-left-panel-logo-part-2)"
    />
    <BuIcon
      name={BoostUpIcons.BoostupLogoPart_2}
      color="var(--bu-color-left-panel-logo-part-1)"
    />
  </BuIcon.Group>
);

export default BuIcon;
