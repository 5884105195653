import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  BaseEditor,
  createEditor,
  Node,
  Descendant,
  Text,
  BaseText,
  Editor,
  Range,
  Transforms,
} from 'slate';
import classNames from 'classnames';
import {
  ReactEditor,
  Slate,
  Editable,
  withReact,
  RenderLeafProps,
} from 'slate-react';
import { HistoryEditor, withHistory } from 'slate-history';
import { css } from 'emotion';
import ReactDOM from 'react-dom';
import { fontBody } from 'assets/css/fontStyles';

// Custom types for TypeScript
declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: { type: 'paragraph'; children: CustomText[] };
    Text: CustomText;
  }
}

// Define custom text type with hashtag property
type CustomText = BaseText & {
  hashtag?: boolean;
};

// Define props for the SlateEditor component
interface SlateEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  hashtagOptions?: string[]; // Added prop for hashtag options
  disabled?: boolean; // Add disabled prop
  maxLength?: number; // Add maximum character length prop
}

// Export the ref interface for parent components
export interface SlateEditorRef {
  reset: () => void;
  focus: () => void;
}

// Sample hashtags for demo (replace with actual hashtags from your system)
const DEFAULT_HASHTAG_OPTIONS = [
  'meeting',
  'follow-up',
  'important',
  'action-required',
  'bug',
  'feature',
  'documentation',
  'question',
  'tip',
  'note',
];

// Convert a string to Slate value structure
const stringToSlateValue = (text: string): Descendant[] => {
  return [
    {
      type: 'paragraph' as const,
      children: text ? [{ text }] : [{ text: '' }],
    },
  ];
};

// Styles for hashtag dropdown
const hashtagDropdownStyles = css`
  position: absolute;
  min-width: 180px;
  max-width: 300px;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--bu-white);
  border-radius: var(--bu-control-border-radius-medium);
  padding: 8px 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  z-index: 1000;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const hashtagOptionStyles = css`
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: var(--bu-primary-200);
  }
`;

const hashtagOptionSelectedStyles = css`
  background-color: var(--bu-primary-200);
  font-weight: bold;
`;

const editableClassName = css`
  overflow-wrap: anywhere !important;
  overflow-y: auto;
`;

// Add styles for the character counter
const characterCounterStyles = css`
  ${fontBody}
  color: var(--bu-gray-700);
  text-align: right;
  padding-top: 4px;
  padding-right: 4px;
`;

interface HashtagPortalProps {
  target: Range | null;
  options: string[];
  onSelect: (option: string) => void;
  editor: Editor;
}

const HashtagPortal: React.FC<HashtagPortalProps> = ({
  target,
  options,
  onSelect,
  editor,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const selectedOptionRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (target && options.length > 0) {
      try {
        const domRange = ReactEditor.toDOMRange(editor, target);
        const rect = domRange.getBoundingClientRect();

        // Calculate position accounting for scroll
        setPosition({
          top: rect.bottom + window.scrollY + 5,
          left: rect.left + window.scrollX,
        });
      } catch (error) {
        console.error('Error positioning hashtag dropdown:', error);
      }
    }
  }, [editor, target, options]);

  useEffect(() => {
    // Reset selection when options change
    setSelectedIndex(0);
  }, [options]);

  // Ensure the selected option is visible in the scrollable area
  useEffect(() => {
    if (selectedOptionRef.current && ref.current) {
      const dropdownRect = ref.current.getBoundingClientRect();
      const selectedOptionRect =
        selectedOptionRef.current.getBoundingClientRect();

      // Check if the selected option is not fully visible
      if (selectedOptionRect.bottom > dropdownRect.bottom) {
        // Option is below the visible area - scroll down
        selectedOptionRef.current.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        });
      } else if (selectedOptionRect.top < dropdownRect.top) {
        // Option is above the visible area - scroll up
        selectedOptionRef.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!target) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex((prev) => (prev + 1) % options.length);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(
            (prev) => (prev - 1 + options.length) % options.length
          );
          break;
        case 'Enter':
          e.preventDefault();
          if (options[selectedIndex]) {
            onSelect(options[selectedIndex]);
          }
          break;
        case 'Escape':
          e.preventDefault();
          ReactEditor.focus(editor);
          Transforms.select(editor, target);
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [editor, target, options, selectedIndex, onSelect]);

  if (!target || options.length === 0) {
    return null;
  }

  // Render the menu to a portal to ensure it's not clipped by parent containers
  return ReactDOM.createPortal(
    <div
      className={hashtagDropdownStyles}
      ref={ref}
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
    >
      {options.map((option, i) => (
        <div
          key={option}
          ref={i === selectedIndex ? selectedOptionRef : undefined}
          onClick={() => onSelect(option)}
          className={`${hashtagOptionStyles} ${
            i === selectedIndex ? hashtagOptionSelectedStyles : ''
          }`}
        >
          #{option}
        </div>
      ))}
    </div>,
    document.body
  );
};

// Changed to use forwardRef
const SlateEditor = forwardRef<SlateEditorRef, SlateEditorProps>(
  (
    {
      value,
      onChange,
      placeholder,
      className,
      hashtagOptions = DEFAULT_HASHTAG_OPTIONS,
      disabled = false, // Default to false
      maxLength, // No default value - unlimited if not specified
    },
    ref
  ) => {
    // Create a Slate editor object that won't change across renders
    const editor = useMemo(() => {
      const e = withHistory(withReact(createEditor()));

      // Custom plugin to enforce character limit at the editor level
      const withMaxLength = (editor: ReactEditor & HistoryEditor) => {
        const { insertText, insertFragment } = editor;

        // Override insertText to prevent text insertion beyond limit
        editor.insertText = (text) => {
          if (maxLength === undefined) {
            insertText(text);
            return;
          }

          const currentLength = serializeToString(editor.children).length;
          if (currentLength + text.length <= maxLength) {
            insertText(text);
          } else {
            // Only insert what will fit
            const availableSpace = Math.max(0, maxLength - currentLength);
            if (availableSpace > 0) {
              insertText(text.slice(0, availableSpace));
            }
          }
        };

        // Override insertFragment to prevent pasting beyond limit
        editor.insertFragment = (fragment) => {
          if (maxLength === undefined) {
            insertFragment(fragment);
            return;
          }

          const currentLength = serializeToString(editor.children).length;
          const fragmentText = fragment
            .map((node) => Node.string(node))
            .join('\n');

          if (currentLength + fragmentText.length <= maxLength) {
            insertFragment(fragment);
          } else {
            // We can't easily truncate a fragment, so we convert it to text
            const availableSpace = Math.max(0, maxLength - currentLength);
            if (availableSpace > 0) {
              insertText(fragmentText.slice(0, availableSpace));
            }
          }
        };

        return editor;
      };

      return withMaxLength(e);
    }, [maxLength]);

    // Maintain internal state for the editor
    const [editorValue, setEditorValue] = useState(() =>
      stringToSlateValue(value)
    );

    // State for tracking hashtag search
    const [hashtagTarget, setHashtagTarget] = useState<Range | null>(null);
    const [hashtagSearch, setHashtagSearch] = useState<string>('');
    const [filteredHashtags, setFilteredHashtags] = useState<string[]>([]);

    // Add currentLength state to track character count
    const [currentLength, setCurrentLength] = useState(value.length);

    // Expose reset method to parent
    useImperativeHandle(ref, () => ({
      reset: () => {
        const emptyValue = stringToSlateValue('');
        setEditorValue(emptyValue);

        // Reset the editor's content directly
        try {
          editor.children = JSON.parse(JSON.stringify(emptyValue));
          // Place cursor at the beginning
          const point = { path: [0, 0], offset: 0 };
          Transforms.select(editor, { anchor: point, focus: point });
          // Ensure focus is on the editor
          ReactEditor.focus(editor);
        } catch (error) {
          console.error('Error resetting editor:', error);
        }
      },
      focus: () => {
        try {
          // Focus the editor and put cursor at the end of text
          ReactEditor.focus(editor);

          // Get the first child (paragraph) and its first text node safely
          const firstChild = editor.children[0];
          if (firstChild && 'children' in firstChild) {
            const textNode = firstChild.children[0];
            const textLength = Text.isText(textNode) ? textNode.text.length : 0;
            const end = { path: [0, 0], offset: textLength };
            Transforms.select(editor, { anchor: end, focus: end });
          }
        } catch (error) {
          console.error('Error focusing editor:', error);
        }
      },
    }));

    // Sync with external value changes
    useEffect(() => {
      // Reset content if value is empty
      if (value === '') {
        const emptyValue = stringToSlateValue('');
        setEditorValue(emptyValue);

        // Reset the editor's content directly
        try {
          editor.children = JSON.parse(JSON.stringify(emptyValue));
          Transforms.select(editor, {
            anchor: { path: [0, 0], offset: 0 },
            focus: { path: [0, 0], offset: 0 },
          });
        } catch (error) {
          console.error('Error resetting editor:', error);
        }
        return;
      }

      // Handle non-empty values and content updates
      const newSlateValue = stringToSlateValue(value);
      const isValueDifferent =
        JSON.stringify(editorValue) !== JSON.stringify(newSlateValue);

      if (isValueDifferent) {
        setEditorValue(newSlateValue);

        // Update editor content directly for more reliable updates
        try {
          editor.children = JSON.parse(JSON.stringify(newSlateValue));
          // Place cursor at the end of text
          const point = {
            path: [0, 0],
            offset:
              newSlateValue[0] &&
              'children' in newSlateValue[0] &&
              newSlateValue[0].children[0] &&
              typeof newSlateValue[0].children[0].text === 'string'
                ? newSlateValue[0].children[0].text.length
                : 0,
          };
          Transforms.select(editor, { anchor: point, focus: point });
        } catch (error) {
          console.error('Error updating editor with new content:', error);
        }
      }
    }, [value, editor]);

    // Define a rendering function for leaf nodes
    const renderLeaf = useCallback((props: RenderLeafProps) => {
      return <Leaf {...props} />;
    }, []);

    // Define a function to convert Slate's value back to a string
    const serializeToString = useCallback((nodes: Node[]): string => {
      return nodes.map((n) => Node.string(n)).join('\n');
    }, []);

    // Apply hashtag decorations as the user types
    const decorate = useCallback(([node, path]) => {
      const ranges: any[] = [];

      if (!Text.isText(node)) {
        return ranges;
      }

      const text = node.text;
      // Updated regex to only match hashtags with alphanumeric, hyphen, and underscore characters
      const hashtagRegex = /#([a-zA-Z0-9_-]+)/g;
      let match;

      while ((match = hashtagRegex.exec(text)) !== null) {
        ranges.push({
          anchor: { path, offset: match.index },
          focus: { path, offset: match.index + match[0].length },
          hashtag: true,
        });
      }

      return ranges;
    }, []);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent) => {
        // Don't handle keydown if no hashtag target is active
        if (hashtagTarget) {
          // Handle key events for the hashtag dropdown
          switch (event.key) {
            case 'ArrowDown':
            case 'ArrowUp':
            case 'Enter':
            case 'Escape':
              event.preventDefault();
              break;
            default:
              break;
          }
          return;
        }

        // Handle character limit
        if (maxLength !== undefined) {
          // Allow special keys even when at max length
          const isSpecialKey =
            event.key === 'Backspace' ||
            event.key === 'Delete' ||
            event.key === 'ArrowLeft' ||
            event.key === 'ArrowRight' ||
            event.key === 'ArrowUp' ||
            event.key === 'ArrowDown' ||
            event.key === 'Home' ||
            event.key === 'End' ||
            event.key === 'Tab' ||
            event.ctrlKey ||
            event.metaKey;

          const currentLength = serializeToString(editor.children).length;

          // If at max length and trying to add more content
          if (
            currentLength >= maxLength &&
            !isSpecialKey &&
            event.key.length === 1
          ) {
            event.preventDefault();
            return false;
          }
        }
      },
      [hashtagTarget, maxLength, editor, serializeToString]
    );

    // Check if the cursor is after a # character to show hashtag suggestions
    const checkForHashtag = useCallback(() => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);

        // Look at characters before the cursor
        const beforeRange = Editor.range(
          editor,
          Editor.start(editor, []),
          start
        );
        const beforeText = Editor.string(editor, beforeRange);

        // Updated regex to match hashtags with alphanumeric, hyphen, and underscore characters
        const hashtagMatch = beforeText.match(/(^|\s)#([a-zA-Z0-9_-]*)$/);

        if (hashtagMatch) {
          const hashtagText = hashtagMatch[2] || ''; // Empty string if only # is typed
          const startOfHashtag = beforeText.length - (hashtagText.length + 1); // +1 for the # character

          // Create a range that includes just the hashtag part
          const hashtagStart = Editor.before(editor, start, {
            distance: hashtagText.length + 1,
            unit: 'character',
          });

          if (hashtagStart) {
            const hashtagRange = Editor.range(editor, hashtagStart, start);
            // UNCOMMENT THIS TO ENABLE HASHTAG SUGGESTIONS!
            // setHashtagTarget(hashtagRange);
            // setHashtagSearch(hashtagText);

            // If no text after #, show all options. Otherwise, filter.
            if (hashtagText === '') {
              setFilteredHashtags(hashtagOptions);
            } else {
              const filtered = hashtagOptions.filter((tag) =>
                tag.toLowerCase().startsWith(hashtagText.toLowerCase())
              );
              setFilteredHashtags(filtered);
            }
            return;
          }
        }
      }

      setHashtagTarget(null);
      setFilteredHashtags([]);
    }, [editor, hashtagOptions]);

    // Insert the selected hashtag
    const insertHashtag = useCallback(
      (hashtag: string) => {
        if (hashtagTarget) {
          // Delete the current hashtag partial text
          Transforms.select(editor, hashtagTarget);
          Transforms.delete(editor);

          // Insert the full hashtag
          Transforms.insertText(editor, `#${hashtag} `);

          // Reset hashtag search
          setHashtagTarget(null);
          setFilteredHashtags([]);

          // Return focus to the editor
          ReactEditor.focus(editor);
        }
      },
      [hashtagTarget, editor]
    );

    return (
      <div
        className={css`
          position: relative;
        `}
      >
        <Slate
          editor={editor}
          initialValue={editorValue}
          onChange={(newValue) => {
            // Check character limit before updating
            const stringValue = serializeToString(newValue);

            // Update current length
            setCurrentLength(stringValue.length);

            if (maxLength !== undefined && stringValue.length > maxLength) {
              // If the new value exceeds the limit, truncate it
              const truncatedContent = stringToSlateValue(
                stringValue.slice(0, maxLength)
              );

              // We need to update the editor with the truncated content
              try {
                // This prevents the infinite loop by not triggering onChange again
                const point = { path: [0, 0], offset: maxLength };
                editor.children = JSON.parse(JSON.stringify(truncatedContent));

                // Restore selection at the end of the truncated content
                Transforms.select(editor, {
                  anchor: point,
                  focus: point,
                });
              } catch (error) {
                console.error('Error truncating content:', error);
              }

              return;
            }

            setEditorValue(newValue);
            if (stringValue !== value) {
              onChange(stringValue);
            }
            checkForHashtag();
          }}
        >
          <Editable
            className={classNames(editableClassName, className)}
            placeholder={placeholder}
            renderLeaf={renderLeaf}
            decorate={decorate}
            onKeyDown={handleKeyDown}
            readOnly={disabled} // Use readOnly for disabled state
          />
          <HashtagPortal
            target={hashtagTarget}
            options={filteredHashtags}
            onSelect={insertHashtag}
            editor={editor}
          />
        </Slate>
        {maxLength !== undefined && (
          <div className={characterCounterStyles}>
            {currentLength}/{maxLength}
          </div>
        )}
      </div>
    );
  }
);

// Define the Leaf component for rendering decorated text
const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  const typedLeaf = leaf as CustomText;

  if (typedLeaf.hashtag) {
    return <mark {...attributes}>{children}</mark>;
  }

  return <span {...attributes}>{children}</span>;
};

export default SlateEditor;
