import { css } from 'emotion';

import { fontHeading3 } from 'assets/css/fontStyles';

import { errorMessage, textAlignCenter } from '../styles';

export { textAlignCenter };

const videoPlayerStyle = css`
  width: 100%;
  height: 0;
  padding-bottom: 56.75%; /* 16:9 aspect ratio */
  background-color: var(--bu-table-border-color);
`;

export const callVideoPlayerWrapper = css`
  .video-player {
    ${videoPlayerStyle}
    position: relative;

    .video-player-container {
      video {
        width: 100%;
      }

      .video-placeholder,
      .audio-placeholder {
        position: absolute;
        top: 49.9%;
        left: 50.02%;
        transform: translate(-50%, -50%);

        img {
          width: 50%;
          margin-top: 53%;
          margin-left: 6%;
        }
      }

      .audio-placeholder {
        img {
          width: 35%;
        }

        div {
          margin-top: 32px;
        }
      }
    }

    .video-loading {
      ${videoPlayerStyle}
      position: absolute;
      opacity: 0.5;
    }

    .video-placeholder,
    .audio-placeholder {
      ${videoPlayerStyle}
      ${fontHeading3}
      position: relative;
      color: var(--bu-color-call-light-gray);
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }

      .error-message {
        ${errorMessage}

        svg.bu-icon-IconRefreshDot {
          animation: loader 3s linear infinite !important;
          animation-direction: reverse !important;

          path:last-child {
            stroke: var(--bu-red-500);
          }
        }
      }
    }
  }

  .video-control {
    background-color: var(--bu-color-call-video-control);
    height: 32px;
    position: relative;

    .video-control-item {
      display: flex;
      align-items: center;
      position: absolute;
      gap: var(--bu-control-gap-medium);

      button {
        background: transparent;
        padding: 0;

        &.rewind-video {
          transform: rotate(-180deg);
        }
      }

      &.playback-rate {
        left: 10px;

        span {
          min-width: 72px;
        }
      }

      &.play {
        left: calc(50% - 50px);

        .play-button {
          background-color: var(--bu-white);
          box-shadow: var(--bu-shadow-basic);
          border-radius: var(--bu-control-border-radius-extra-large);
          width: 32px;
          height: 32px;
        }
      }

      &.full-screen {
        right: 10px;

        span {
          min-width: 120px;
        }
      }

      &.playback-rate,
      &.full-screen {
        span {
          ${fontHeading3}
          border-radius: var(--bu-control-border-radius);
          width: fit-content;
          padding: 0 16px;
          background-color: var(--bu-white);
          text-align: center;
        }
      }

      i {
        font-size: 16px;
        color: var(--bu-primary-700);
      }
    }
  }

  .video-time {
    margin-top: 10px;
    background-color: var(--bu-color-call-video-time);
    height: 10px;
    border-radius: var(--bu-control-border-radius-large);
    position: relative;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }

    .video-time-progress {
      background-color: var(--bu-primary-500);
      height: 10px;
      border-radius: var(--bu-control-border-radius-large);
      width: 0;
    }

    .video-time-dot {
      position: absolute;
      background-color: var(--bu-white);
      width: 17px;
      height: 17px;
      top: -3px;
      border-radius: var(--bu-control-border-radius-large);
      border: 1px solid var(--bu-gray-600);
      cursor: pointer;
      box-shadow: var(--bu-shadow-basic);
      left: calc(0% - 9px);

      &.disabled {
        cursor: default;
      }
    }
  }
`;
