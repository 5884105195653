import { Feedback } from 'contexts/AIChatContext';

import axiosInstance from './axiosInstance';

// Interface definitions
export interface ChatMessage {
  id?: string;
  sender: 'user' | 'assistant';
  message: string;
  created_at: string;
  follow_up_questions?: string[];
  feedback?: {
    notes?: string;
    sentiment?: Feedback;
  };
}

export interface ChatHistoryResponse {
  history: ChatMessage[];
}

export interface ChatContext {
  object_type: string;
  object_id?: string;
}

// API functions
export const getChatHistory = async (): Promise<ChatHistoryResponse> => {
  const response = await axiosInstance.get('/api/moonraker/query/history');
  return response.data;
};

export const startNewChat = async (): Promise<void> => {
  await axiosInstance.post('/api/moonraker/query/start', {});
};

export const sendChatMessage = async (
  query: string,
  context: ChatContext
): Promise<ChatMessage> => {
  const response = await axiosInstance.post('/api/moonraker/query', {
    query,
    context,
  });
  return response.data;
};

export const sendMessageFeedback = async (
  messageId: string,
  feedback: Feedback
) => {
  const response = await axiosInstance.post('/api/moonraker/query/feedback', {
    id: messageId,
    sentiment: feedback,
  });

  return response.data;
};
