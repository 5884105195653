import className from 'classnames';
import React from 'react';

import BuImpersonationPanel from 'components/UI/BuImpersonationPanel';
import Header from 'components/UI/Wrapper/Header';
import LeftPanel from 'components/UI/Wrapper/LeftPanel';
import * as s from 'components/UI/Wrapper/styles';
import { useAIChat } from 'contexts/AIChatContext';
import AIChat from 'components/UI/AIChat/AIChat';

const Wrapper: React.FC = ({ children }) => {
  const { showChat, openMode } = useAIChat();

  return (
    <div className={className('main-wrapper', s.wrapper)}>
      <div className={s.content}>
        <LeftPanel />

        <Header />

        <BuImpersonationPanel />

        <div id="main_content" className={s.content_box}>
          <div className={s.mainContent}>
            {children}
          </div>

          {showChat && openMode === 'page' && (
            <div className={s.aiChatContainer}>
              <AIChat />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default Wrapper;
