import { useCallback, useRef } from 'react';
import moment from 'moment';
import UserAvatar from 'react-user-avatar';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { Dimmer, Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useCommentsContext } from './comments.context';
import { avatarColors } from 'components/settings/styles';
import BuIcon from 'components/UI/BuIcon';
import BuButton from 'components/UI/BuButton';
import { Comment } from 'components/dashboard/Calls/CallDetails/types';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import { getUser } from 'selectors';

import * as styles from './styles';
import { useVideoContext } from '../../VideoPlayer/videoPlayer.context';
import {
  formatSecondsToFriendlyTimeString,
  formatTimeToFriendlyTimeString,
} from '../../helpers';
import SlateEditor, { SlateEditorRef } from './SlateEditor';

const CommentsTab = () => {
  const user = useSelector(getUser);
  const editorRef = useRef<SlateEditorRef>(null);
  const {
    comments,
    isFetchingComments,
    isSavingComment,
    handlePostComment,
    handleUpdateComment,
    handleDeleteComment,
    comment,
    setComment,
    editingComment,
    setEditingComment,
    isEditingComment,
    setIsEditingComment,
    selectedComment,
    setSelectedComment,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
  } = useCommentsContext();
  const { duration, currentTime, isPlaying, handlePlayPause, setTime } =
    useVideoContext();

  const clearComment = () => {
    setComment('');
    setEditingComment('');
    setIsEditingComment(false);
    setSelectedComment(null);

    // Reset the editor using the ref
    if (editorRef.current) {
      editorRef.current.reset();
    }
  };

  const sanitizeAndHighlightComment = useCallback(
    (comment: Comment) => {
      if (!comments.length) {
        return comment.text;
      }

      const sanitizedComment = DOMPurify.sanitize(comment.text);

      return sanitizedComment.replace(
        new RegExp('#([^\\s]+)', 'gi'),
        (match: string) => `<mark>${match}</mark>`
      );
    },
    [comments]
  );

  const handleClickSendComment = async () => {
    if (isEditingComment && selectedComment) {
      await handleUpdateComment({
        ...selectedComment,
        text: editingComment,
      });
    } else {
      await handlePostComment(comment, currentTime);
    }

    clearComment();
  };

  const handleChangeComment = (value: string) => {
    setComment(value);

    if (value.length === 0) {
      // Don't call clearComment here as it will cause an infinite loop
      // Just reset editing state
      setIsEditingComment(false);
      setSelectedComment(null);
    }

    if (value.length > 0 && isPlaying) {
      handlePlayPause();
    }
  };

  const handleChangeEditingComment = (value: string) => {
    setEditingComment(value);
  };

  const handleClickCommentStartTime = (startTime: number) => {
    if (startTime === 0) return;

    setTime(startTime, true);
  };

  const handleClickEditComment = (comment: Comment) => {
    if (isSavingComment) return;

    if (comment.start_time !== 0) {
      setTime(comment.start_time, true);
    }

    setIsEditingComment(true);
    setSelectedComment(comment);
    setEditingComment(comment.text);

    // Focus the editor after state updates (using setTimeout to ensure React has updated the DOM)
    setTimeout(() => {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    }, 0);
  };

  const handleClickDeleteComment = (comment: Comment) => {
    if (isSavingComment) return;

    setSelectedComment(comment);
    setShowDeleteConfirmation(true);
  };

  const handleClickConfirmDeleteComment = () => {
    handleDeleteComment(selectedComment?.comment_id);
    setShowDeleteConfirmation(false);
  };

  const handleClickCancelEdit = () => {
    setIsEditingComment(false);
    setSelectedComment(null);
    setEditingComment('');
  };

  return (
    <div className={styles.commentsTabWrapper}>
      <div className="comments-tab-input-wrapper">
        <SlateEditor
          ref={editorRef}
          className="comments-tab-input"
          placeholder="Add a comment, use # for tagging"
          value={comment}
          onChange={handleChangeComment}
          disabled={isEditingComment}
          maxLength={500}
        />

        <div className="comments-tab-input-actions">
          <BuButton
            className="comments-tab-send-button"
            disabled={!comment || isSavingComment}
            onClick={handleClickSendComment}
          >
            Add comment{' '}
            {currentTime && currentTime > 0 && comment.length > 0
              ? `at ${formatTimeToFriendlyTimeString(currentTime)}`
              : ''}
          </BuButton>
        </div>
      </div>

      <div className="comments-tab-comments-wrapper">
        {isFetchingComments ? (
          <div className="comments-tab-comments-placeholder">
            <Dimmer inverted active>
              <Loader />
            </Dimmer>
          </div>
        ) : comments.length === 0 ? (
          <div className="comments-tab-comments-placeholder">
            <span className="comments-tab-comments-placeholder-title">
              No comments yet
            </span>
            <span className="comments-tab-comments-placeholder-description">
              You can also add tags by using a #
            </span>
          </div>
        ) : (
          <div className="comments-tab-comments-list">
            {comments.map((comment) => (
              <div
                onClick={() => handleClickCommentStartTime(comment.start_time)}
                className={classNames('comments-tab-comment-item', {
                  'allowed-to-change': comment.author === user?.name,
                  'is-editing-mode': isEditingComment,
                  'not-clickable': comment.start_time === 0,
                })}
                key={comment.comment_id}
              >
                <div className="comments-tab-comment-item-header">
                  <div>
                    <UserAvatar
                      size={32}
                      name={comment.author || 'NA'}
                      colors={avatarColors}
                    />

                    <div className="comments-tab-comment-author-date">
                      <span className="comments-tab-comment-author">
                        {comment.author}
                      </span>
                      <span className="comments-tab-comment-date">
                        {moment(comment.updated_at).format(
                          'MMM DD, YYYY [at] hh.mma'
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="comments-tab-comment-item-actions">
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleClickDeleteComment(comment);
                      }}
                      className={classNames(
                        'comments-tab-comment-item-delete',
                        {
                          disabled: isSavingComment,
                        }
                      )}
                    >
                      <BuIcon name={BoostUpIcons.Trash} />
                    </a>

                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleClickEditComment(comment);
                      }}
                      className={classNames('comments-tab-comment-item-edit', {
                        disabled: isSavingComment,
                      })}
                    >
                      <BuIcon name={BoostUpIcons.Pencil} />
                    </a>

                    <span className="comments-tab-comment-item-start-time-text">
                      {formatSecondsToFriendlyTimeString(
                        comment.start_time,
                        duration
                      )}
                    </span>
                  </div>
                </div>

                {isEditingComment &&
                selectedComment?.comment_id === comment.comment_id ? (
                  <div className="comments-tab-comment-item-editor-wrapper">
                    <SlateEditor
                      ref={editorRef}
                      className="comments-tab-comment-item-editor"
                      value={editingComment}
                      onChange={handleChangeEditingComment}
                      maxLength={500}
                    />

                    <div className="comments-tab-comment-item-editor-actions">
                      <BuButton
                        secondary
                        onClick={handleClickCancelEdit}
                        disabled={isSavingComment}
                        className="comments-tab-comment-item-editor-cancel-button"
                      >
                        Cancel
                      </BuButton>

                      <BuButton
                        onClick={handleClickSendComment}
                        disabled={
                          isSavingComment || editingComment === comment.text
                        }
                        className="comments-tab-comment-item-editor-save-button"
                      >
                        Save
                      </BuButton>
                    </div>
                  </div>
                ) : (
                  <p
                    className="comments-tab-comment-item-text"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeAndHighlightComment(comment),
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <BuConfirmationPopup
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleClickConfirmDeleteComment}
        cancelText="Cancel"
        confirmText="Delete"
        headerText="Delete comment?"
        confirmButtonColor="danger"
      >
        You are about to delete this comment. Do you wish to continue?
      </BuConfirmationPopup>
    </div>
  );
};

export default CommentsTab;
