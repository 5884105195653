import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useIsMutating } from '@tanstack/react-query';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  evaluateWidgetDataIsIncluded,
  getMetricType,
  isBIMetricSimple,
  isObjectIncluded,
} from 'components/dashboard/Metrics/Create/utils';
import { MetricsWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/MetricsWidgetControls';
import {
  RefreshButtonConfiguration,
  WidgetHeader,
} from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import { WidgetVisualization } from 'components/dashboard/Metrics/Widget/Visualization/WidgetVisualization';
import {
  getMetricsNameAndValueUsedInFormula,
  getSyntheticMetricData,
  getWidgetMetricDisplayNameMap,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
  everyMetricsHaveDateField,
} from 'components/dashboard/Metrics/Widget/helper';
import useHierarchicalWidgetFetching from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching';
import { useFormulaMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useFormulaMetricsList';
import {
  createDrillDownParams,
  openDrillDownModal,
  openTableDrillDownModal,
  openTotalDrilldownModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { openTableDrilldownModal as openHistoricalTableDrilldownModal } from 'components/dashboard/Metrics/Widget/Historical/drilldown.helpers';
import { SyntheticMetricDataWidgetState } from 'components/dashboard/Metrics/Widget/types';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  AnalysisType,
  BUSINESS_TYPES_FIELD_NAMES,
  FORECAST_SUBMISSION,
  GENERIC_TIME_INTERVAL_PIVOTS,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  MetricType,
  VisualizationType,
} from 'components/dashboard/Metrics/enums';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIMetricToChartType,
  BIMetricUnion,
  BIMetricsMap,
  BIPivotDescriptor,
  BIWidget,
  DrillDownFilter,
  DrillDownParams,
  ExternalFieldsRequestParams,
} from 'components/dashboard/Metrics/metrics.types';
import { getMetricsList } from 'selectors/revbi/metrics';
import { getFeatureFlag } from 'selectors/settings';
import { fetchApi } from 'utils/network';
import { OnChartDataClick } from './Chart/WidgetChart.types';
import { ConfigClickExtraData } from './Table/helpers/columnsHelpers';
import { useCacheMutation } from './hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  OpenRevBiDrilldownModal,
  useOpenRevBiDrilldownModal,
} from './hooks/useRevBiDrilldownModal';
import { canOpenADrilldown } from '../metrics.helpers';
import { openChartDrilldownModal } from './Historical/drilldown.helpers';
import { HistoricalWidgetControls } from './Controls/Historical/HistoricalWidgetControls';
import { useModal } from 'components/modals/ModalContext/modal.context';
import { WidgetPreviewBanner } from '../Create/WidgetPreviewPlaceholder/WidgetPreviewBanner';

interface Props {
  readonly widget: BIWidget;
  readonly previewWidget: BIWidget;
  readonly isCreateEditMetric: boolean;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const MetricsWidgetPreview: React.FC<Props> = ({
  widget,
  previewWidget,
  isCreateEditMetric = false,
  onChangeWidget,
}) => {
  const dispatch = useDispatch();
  const modal = useModal();

  const { drilldownTableSettings: drilldown_table_settings, hierarchyAlias } =
    useContext(RevBISettingsContext);

  const supportedCustomObjects = Object.keys(drilldown_table_settings);
  const metricDisplayNames = getWidgetMetricDisplayNameMap(widget);
  const [dateTemplateFilterOptions, setDateTemplateFilterOptions] = useState<
    Filters.DateFilterElement[]
  >([]);
  const openRevBiDrilldownModal = useOpenRevBiDrilldownModal();

  const metricsList = useSelector(getMetricsList);
  const { revbiEmailsDrilldownEnabled, revbiEventsDrilldownEnabled } =
    useSelector((state) => {
      return {
        revbiEmailsDrilldownEnabled: getFeatureFlag(
          state,
          'revbi_emails_drilldown_enabled'
        ),
        revbiEventsDrilldownEnabled: getFeatureFlag(
          state,
          'revbi_events_drilldown_enabled'
        ),
      };
    });

  /**
   * something that we need to think if how handle the metric when the user is creating
   * probably we should receive it as a parameter.
   */
  const metricIdList: string[] = useMemo(
    () => widget.metric_list.map((metric: BIMetricCreated) => metric._id),
    [widget.metric_list]
  );

  const metricsInUse: BIMetricsMap = useMemo(
    () =>
      widget.metric_list.reduce(
        (acc: BIMetricsMap, metric: BIMetricCreated) => {
          const key = (metric as BIMetricCreated)._id;
          acc[key] = metric;
          return acc;
        },
        {}
      ),
    [widget.metric_list]
  );

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  const metricsBusinessType: string = useMemo(() => {
    let businessType = '';
    (widget.metric_list as BIMetricCreated[])?.forEach((m) => {
      m.filters?.forEach((f) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    });

    return businessType;
  }, [JSON.stringify(widget.metric_list)]);

  const presentBusinessType = widgetFiltersBusinessType || metricsBusinessType;

  const metricToChartType = useMemo<BIMetricToChartType[]>(() => {
    if (widget?.properties?.metricToChartType?.length) {
      return widget?.properties?.metricToChartType?.filter((mtcItem) =>
        metricIdList.includes(mtcItem.metricId)
      );
    } else {
      return metricIdList.map((el: string) => ({
        chartType: VisualizationType.Column,
        metricId: typeof el === 'string' ? el : (el as BIMetricCreated)?._id,
      }));
    }
  }, [widget?.properties?.metricToChartType]);

  const isHistoricalAnalysis = widget.analysis_type === AnalysisType.HISTORICAL;

  const areHistoryFieldsValid = Boolean(
    widget.time_period && widget.time_interval && widget.point_in_time
  );

  const [availablePivots, setAvailablePivots] = useState<BIPivotDescriptor[]>(
    []
  );

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  const {
    usedFormulaMetricIds,
    objectsSet,
    dateFieldsSet,
  }: {
    usedFormulaMetricIds: string[];
    objectsSet: Set<string>;
    dateFieldsSet: Set<string>;
  } = useMemo(() => {
    const metricsIdsFromWidgetMetrics: string[] = (
      widget.metric_list as BIMetricUnion[]
    )
      .filter((m) => getMetricType(m) === MetricType.Formula)
      .flatMap((m) =>
        parseFormulaToMetricsIdArray((m as BIMetricFormula).synthetic_metric)
      );

    const objectsSet = new Set<string>();
    const dateFieldsSet = new Set<string>();

    widget.metric_list.forEach((metric: BIMetricUnion) => {
      if (isBIMetricSimple(metric)) {
        objectsSet.add(metric.object);
        dateFieldsSet.add(metric.date_field?.name ?? '');
      }
    });

    return {
      usedFormulaMetricIds: [...metricsIdsFromWidgetMetrics],
      objectsSet,
      dateFieldsSet,
    };
  }, [JSON.stringify(widget.metric_list)]);

  const { data: usedFormulaMetricsMap } =
    useFormulaMetricsList(usedFormulaMetricIds);

  /**
   * Hook to fetch available pivot options per selected metrics.
   */
  useEffect(() => {
    const abortController = new AbortController();
    Object.keys(usedFormulaMetricsMap).forEach((element) => {
      const metricSimple = usedFormulaMetricsMap[element];

      if (isBIMetricSimple(metricSimple)) {
        objectsSet.add(metricSimple.object);
      }
    });

    if (objectsSet.size) {
      const queryParams = {
        table_names: [...objectsSet],
      };

      fetchApi<ExternalFieldsRequestParams, BIPivotDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/columns/pivots`,
        queryMethod: 'get',
        queryParams: queryParams,
        setData: (result) => {
          // if some metric doesn't have a date field, the pivot shouldn't be added.
          let cleanedResult = everyMetricsHaveDateField(widget)
            ? [...GENERIC_TIME_INTERVAL_PIVOTS, ...result]
            : [...result];

          if (isPieChart) {
            // if current viz is pie we remove the user.name
            cleanedResult = cleanedResult.filter(
              (e) => !hierarchyAlias.includes(e.name ?? '')
            );
          }

          // checking if all pivot are included in the new available pivots
          // if not should clean the pivot.
          if (widget.group_by.length) {
            const pivotName = widget.time_field?.name.length
              ? widget.time_field?.name
              : widget.group_by[0].name;

            const idx = cleanedResult.findIndex(
              (pivotListed) =>
                pivotListed.available_in_any_pivot &&
                pivotListed.name === pivotName
            );

            if (idx < 0) {
              onChangeWidget({
                ...widget,
                group_by: [],
              });
            }
          }

          setAvailablePivots(cleanedResult);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load second pivot columns: ${error}`);
        },
        signal: abortController.signal,
      });
    }

    return () => {
      setAvailablePivots([]);
    };
  }, [
    JSON.stringify(Array.from(objectsSet)),
    JSON.stringify(Array.from(dateFieldsSet)),
    JSON.stringify(Object.keys(usedFormulaMetricsMap)),
  ]);

  useEffect(() => {
    if (widget.time_interval) {
      const signalAbortTemplate = new AbortController();

      const getType = (name: string): string => {
        if (name === 'all_time') return 'SAME_PERIOD';
        if (name.startsWith('same_')) return 'SAME_PERIOD';
        if (name.startsWith('previous_')) return 'PREVIOUS_PERIOD';
        if (name.startsWith('next_')) return 'NEXT_PERIOD';
        return '';
      };

      fetchApi<void, any[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_date_template_filter_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          setDateTemplateFilterOptions(
            result.map((element) => ({
              label: element.label,
              value: element.name,
              type: getType(element.name) as Filters.DateElementType,
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load date template filter options: ${error}`);
        },
        signal: signalAbortTemplate.signal,
      });
    }
  }, [widget]);

  const {
    treeWidget,
    status: hierarchicalStatus,
    isTableRefetching,
    isTreeWidgetCompatibleWithChartVisualization,
    addSubTreeToFetch,
    refetchLoadedSubtrees,
  } = useHierarchicalWidgetFetching({
    widgetConfiguration: previewWidget,
    urlQuery: { preview: 'preview' },
    widgetType: isTableVisualization ? 'table' : 'chart',
    widgetAction: 'edition',
  });

  const { fetchPivotDelayedBy } = useCacheMutation(widget._id ?? '');

  // checks if any mutation is running to lock the refresh button.
  const isMutatingInProgress = useIsMutating(['updateObjectField']);

  const isTargetMetricOnlyWidget: boolean = useMemo(
    () =>
      Object.keys(metricsInUse).length === 1 &&
      (metricsInUse[Object.keys(metricsInUse)[0]] as BIMetricSimple).object ===
        TARGET,
    [metricsInUse]
  );

  const isPieChart = useMemo(
    () =>
      metricToChartType.some((el) => el.chartType === VisualizationType.Pie),
    [metricsInUse, metricToChartType]
  );

  const firstTargetMetric = useMemo(() => {
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if ((current as BIMetricSimple).object === TARGET) {
        return current;
      }
    }

    return null;
  }, [metricsInUse]);

  // Forecast period is only valid when it is same among other metrics
  const forecastPeriod: string = useMemo(() => {
    const periodsSet = new Set<string>();
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if (isBIMetricSimple(current) && current.object === FORECAST_SUBMISSION) {
        current.filters.forEach((filter) => {
          const { and_condition = [] } = filter;
          const { or_condition = [] } = and_condition.length
            ? and_condition[0]
            : {};
          const { value = [], column = { name: '' } } = or_condition.length
            ? or_condition[0]
            : {};

          if (column.name === 'forecast_submission.period_type') {
            periodsSet.add((value as string[])[0]);
          }
        });
      }
    }

    if (periodsSet.size === 1) {
      const [first] = periodsSet;
      return first;
    } else {
      console.warn(
        'misconfiguration each metric should have the same fs period'
      );
    }

    return '';
  }, [metricsInUse]);

  const cleanCacheAndRefresh = () => {
    if (!isTableRefetching) {
      fetchPivotDelayedBy(widget.group_by.length, refetchLoadedSubtrees);
    }
  };

  const refreshButtonConfiguration = useMemo<RefreshButtonConfiguration>(
    () => ({
      cacheDate: '',
      showRefreshButton: false,
      refreshButtonLoading: isTableRefetching,
      disabled: isMutatingInProgress > 0 || hierarchicalStatus === 'loading',
      onRefreshData: cleanCacheAndRefresh,
    }),
    [
      isTableVisualization,
      isTableRefetching,
      isMutatingInProgress,
      cleanCacheAndRefresh,
      hierarchicalStatus,
    ]
  );

  const openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal = (
    modalConfig
  ) => {
    openRevBiDrilldownModal(modalConfig, () => {});
  };

  const handleTableDataClick = (
    column: IColumn,
    row: IRow,
    extraData: ConfigClickExtraData
  ): void => {
    let metricId = column.metricId ?? column.field.split('|')[0];
    let metricData = metricId in metricsInUse ? metricsInUse[metricId] : null;
    const subValueMetricId = column.config.subValue?.relativeField;

    if (extraData.subValueClicked && subValueMetricId) {
      // Be careful using this metricsList, sometimes it is not up to date
      metricId = subValueMetricId.split('|')[0];
      metricData = metricsList.find(({ _id }) => _id === metricId) || null;
    }

    if (metricData) {
      // Support for v2 and v3
      // onces removed v2 we can use the correct types.
      const columnsData = treeWidget.metricConfigurations;
      const columnData = columnsData.find((c) => c.field_name === metricId);
      const clickedMetric = {
        ...metricData,
        name: columnData?.display_name ?? metricData.name,
      };

      if (isHistoricalAnalysis) {
        openHistoricalTableDrilldownModal(
          clickedMetric,
          column,
          row,
          widget,
          drilldown_table_settings,
          modal.openModal,
          openRevBiDrilldownModalWithOnClose
        );
      } else {
        openTableDrillDownModal(
          clickedMetric as BIMetricCreated,
          column,
          undefined,
          widget?.group_by[0]?.name || '',
          revbiEmailsDrilldownEnabled,
          revbiEventsDrilldownEnabled,
          row,
          widget,
          dispatch,
          openRevBiDrilldownModalWithOnClose,
          drilldown_table_settings
        );
      }
    }
  };

  const handleChartDataClick: OnChartDataClick = (pointEvent) => {
    const metricFieldNameClicked = pointEvent.metricId;

    if (metricFieldNameClicked) {
      const metricClicked = metricsInUse[metricFieldNameClicked];
      if (metricClicked) {
        const hasClickAction = canOpenADrilldown(
          metricClicked,
          supportedCustomObjects
        );
        if (!hasClickAction) {
          return;
        }
        const metricChartType = metricToChartType.find(
          (e) => e.metricId === metricClicked._id
        );

        // Remapping as we're not modifying openDrillDownModal
        const selectedValue = {
          metricId: pointEvent.metricId,
          pivot1Id: pointEvent.pivotValues[0],
          pivot2Id: pointEvent.pivotValues[1],
        };

        if (isHistoricalAnalysis) {
          openChartDrilldownModal(
            metricClicked,
            selectedValue,
            widget,
            // We removed v2 so this is not needed anymore
            // openTableDrillDownModal Should be refactored
            treeWidget,
            drilldown_table_settings,
            modal.openModal,
            openRevBiDrilldownModalWithOnClose
          );
        } else {
          openDrillDownModal(
            widget,
            selectedValue,
            metricClicked,
            widgetFiltersBusinessType,
            revbiEmailsDrilldownEnabled,
            revbiEventsDrilldownEnabled,
            undefined,
            // We removed v2 so this is not needed anymore
            // openTableDrillDownModal Should be refactored
            treeWidget,
            widget?.group_by.length ?? 0,
            widget?.group_by[0],
            widget?.group_by[1],
            metricChartType?.chartType || VisualizationType.Column,
            dispatch,
            openRevBiDrilldownModalWithOnClose,
            hierarchyAlias,
            drilldown_table_settings
          );
        }
      }
    }
  };

  const handleTotalsClick = (metricId: string, chartLabel?: any): void => {
    if (isHistoricalAnalysis) {
      return;
    }

    if (metricId in metricsInUse) {
      const columnsData = treeWidget.metricConfigurations;
      const columnData = columnsData.find((c) => c.field_name === metricId);
      const clickedMetric = {
        ...metricsInUse[metricId],
        name: columnData?.display_name ?? '',
      };

      const drillDownFilters = chartLabel
        ? (widget.group_by ?? []).reduce(
            (accumulator: DrillDownFilter[], column, idx) => {
              if (idx === 0) {
                accumulator.push({
                  column:
                    // this condition should be removed ones we remove the date pivot supports.
                    column.type === 'date' && widget.time_field
                      ? widget.time_field
                      : column.type === 'date' &&
                        isBIMetricSimple(clickedMetric) &&
                        clickedMetric.date_field
                      ? clickedMetric.date_field
                      : column,
                  operator: 'eq',
                  value: chartLabel.levelOptions.custom.pivot1Value,
                });
              }

              if (
                idx === 1 &&
                hierarchyAlias.includes(column.name ?? '') &&
                chartLabel.name
              ) {
                accumulator.push({
                  column:
                    column.type === 'date' && widget.time_field
                      ? widget.time_field
                      : column,
                  operator: 'eq',
                  value:
                    chartLabel.levelOptions.custom.pivot2Value ||
                    chartLabel.name ||
                    '',
                });
              }

              return accumulator;
            },
            []
          )
        : [];

      const drilldownParams: DrillDownParams = createDrillDownParams(
        clickedMetric as BIMetricCreated,
        drillDownFilters,
        widget
      );

      if ((clickedMetric as BIMetricFormula).synthetic_metric) {
        const totals = treeWidget.totals;

        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(
            (clickedMetric as BIMetricFormula).synthetic_metric
          );

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            clickedMetric as BIMetricFormula
          );

        let clickedValue = totals[metricId];

        openSyntheticMetricDetailsModal(
          columnData?.display_name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          {
            y: clickedValue,
            metricsValues: getMetricsNameAndValueUsedInFormula(
              parsedClickedMetricFormulaAsArray,
              totals || {},
              metricDisplayNames
            ),
          },
          openRevBiDrilldownModalWithOnClose,
          drilldown_table_settings
        );
      } else {
        openTotalDrilldownModal(
          clickedMetric,
          drilldownParams,
          dispatch,
          openRevBiDrilldownModalWithOnClose,
          drilldown_table_settings
        );
      }
    }
  };

  const handleTableSortChange = (columnName: string): void => {
    onChangeWidget({
      properties: {
        ...widget?.properties,
        table_view_order_by_column: columnName,
      },
    });
  };

  const isDateFirstPivot =
    widget?.group_by[0]?.type === 'date' ||
    widget?.group_by[0]?.type === 'timePeriod';

  if (!isTreeWidgetCompatibleWithChartVisualization) {
    const newMetricToChartType = widget.properties?.metricToChartType?.map(
      (el) => {
        el.chartType = VisualizationType.Table;
        return el;
      }
    );

    onChangeWidget({
      ...widget,
      properties: {
        ...widget.properties,
        metricToChartType: newMetricToChartType,
      },
    });
    toast.warn(
      'The dataset is too large for chart display. Visualization switched to table mode.'
    );
  }

  const isWidgetDataIncluded = evaluateWidgetDataIsIncluded(
    widget,
    previewWidget
  );

  const historicalWidgetProps = useMemo(() => {
    return {
      relativeDateFilterOptions: dateTemplateFilterOptions,
      timeInterval: widget.time_interval,
      timePeriod: widget.time_period,
    };
  }, [widget]);

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={false}
        isMetricsPreview={isCreateEditMetric}
      >
        <WidgetHeader
          id={widget._id}
          name={widget.name}
          isCreateEditMetric={isCreateEditMetric}
          data-testing="widget-header"
          refreshButtonConfig={refreshButtonConfiguration}
          alternativeVisibility={
            !!widget.advanced_configurations?.user_visibility_name
          }
        />

        {isHistoricalAnalysis && (
          <HistoricalWidgetControls
            widget={widget}
            metricToChartType={metricToChartType}
            onUpdateWidget={onChangeWidget}
            data-testing="metrics-widget-controls"
          />
        )}

        {!isHistoricalAnalysis && (
          <MetricsWidgetControls
            widget={widget}
            isTargetMetricOnlyWidget={isTargetMetricOnlyWidget}
            hasTargetMetric={Boolean(firstTargetMetric)}
            availablePivots={availablePivots}
            metricToChartType={metricToChartType}
            setWidget={onChangeWidget}
            data-testing="metrics-widget-controls"
          />
        )}

        <TemplateFilters
          showControls
          isDateFirstPivot={isDateFirstPivot}
          templateFilters={widget.template_filters}
          widgetFilters={widget.widget_filters}
          dashboardFilters={widget.dashboard_filters ?? []}
          tables={Array.from(objectsSet)}
          targetPeriod={(firstTargetMetric as BIMetricSimple)?.target_period}
          onChangeWidget={onChangeWidget}
          data-testing="metrics-widget-filters"
          {...(isHistoricalAnalysis && historicalWidgetProps)}
        />
        {!isWidgetDataIncluded && (
          <WidgetPreviewBanner analysisType={widget.analysis_type} />
        )}
        {isWidgetDataIncluded && (
          <WidgetVisualization
            showControls
            widgetDataStatus={hierarchicalStatus}
            metricsInUse={metricsInUse}
            metricToChartType={metricToChartType}
            widget={previewWidget}
            widgetData={treeWidget}
            isTableRefetching={isTableRefetching}
            onUpdateWidget={onChangeWidget}
            onChartDataClick={handleChartDataClick}
            onTableDataClick={handleTableDataClick}
            onTotalsClick={handleTotalsClick}
            onTableSortChange={handleTableSortChange}
            addSubTreeToFetch={addSubTreeToFetch}
            data-testing="metrics-widget-visualization"
          />
        )}
      </WidgetContainer>
    </>
  );
};
