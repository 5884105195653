import { Link } from 'react-router-dom';
import classNames from 'classnames';
import UserAvatar from 'react-user-avatar';
import moment from 'moment';

import { Attendee, Organizer } from 'api/Calls';
import { fontBody } from 'assets/css/fontStyles';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import salesloftLogo from 'assets/fonts/boostup-icons/salesloft_logo.svg';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import tableStyles from 'components/new-library/Table/styles';
import BuIcon from 'components/UI/BuIcon';
import { BuTag } from 'components/UI/BuTag';
import BuGroupButton from 'components/UI/BuGroupButton';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { avatarColors } from 'components/settings/styles';
import Tooltip from 'components/UI/common/Tooltip';
import BuButton from 'components/UI/BuButton';
import { openModal } from 'navigation/utils';

import * as styles from './styles';
import { SOURCES_ICONS } from './helpers';
import { SPEAKER_SENTIMENT_ICONS } from '../CallDetails/helpers';
import CallInvite from '../CallDetails/HeaderDetails/CallInvite';
import { Attendee as CallAttendee, CallStatus } from '../CallDetails/types';
import { canDeleteOrSkipCall } from '../ExternalCallDetails/helpers';
import CallActionButtons from '../CallActionButtons';

const NotFound = () => (
  <div className={styles.cell}>
    <div className={styles.notFound}>-</div>
  </div>
);

// Extracted renderer function
const renderCallNameCell = ({
  row,
  clickable = true,
  tooltip = true,
}: {
  row: IRow;
  clickable?: boolean;
  tooltip?: boolean;
}) => {
  const { summary, id, start, attendees, description, source: _source } = row;

  const source = (_source as string)?.toLowerCase();

  const formattedStart = moment(`${start}`)
    .local()
    .format('dd, DD MMM YYYY, H:mm');

  const callInvite = (
    <CallInvite
      attendees={attendees as CallAttendee[]}
      description={description as string}
      callStartDate={formattedStart}
    />
  );

  return (
    <div className={styles.cell}>
      <Tooltip
        mouseEnterDelay={500}
        className={classNames(styles.whiteBgTooltip)}
        tooltip={tooltip ? callInvite : null}
        position="bottom left"
        testingLabel={`call-name-tooltip-${id}`}
      >
        <div className={classNames(styles.cellRow, 'call-name-row')}>
          <div className={styles.cellColumn}>
            <div
              className={
                clickable
                  ? classNames(styles.cellText, styles.clickable)
                  : styles.cellText
              }
            >
              {clickable ? (
                <Link to={`/transcript/${id}`} data-testing={`call-name-${id}`}>
                  {summary}
                </Link>
              ) : (
                summary
              )}
            </div>

            <div className={styles.cellSubtext}>{formattedStart}</div>
          </div>

          <div>
            {source !== 'boostup' && (
              <>
                {source === 'salesloft' ? (
                  <img src={salesloftLogo} alt="Salesloft" className={source} />
                ) : (
                  <BuIcon
                    className={source}
                    name={SOURCES_ICONS[source as keyof typeof SOURCES_ICONS]}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

// Base column configuration
const baseColumnName = {
  id: 'name',
  label: 'Call Name',
  field: 'summary',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  minWidth: 350,
  height: 90,
};

// Clickable column name implementation
const columnName = {
  ...baseColumnName,
  config: {
    renderer(props: { row: IRow }) {
      return renderCallNameCell({ row: props.row, tooltip: false });
    },
  } as CustomCellConfig,
};

// Non-clickable column name implementation
const notClickableColumnName = {
  ...baseColumnName,
  config: {
    renderer(props: { row: IRow }) {
      return renderCallNameCell({ row: props.row, clickable: false });
    },
  } as CustomCellConfig,
};

const columnSentiment = {
  id: 'sentiment',
  label: 'Sentiment',
  field: 'sentiment',
  sort_order: SortOrder.ASCENDING,
  // sortable: true,
  type: ColumnTypes.CUSTOM,
  align: 'center',
  width: 110,
  minWidth: 110,
  maxWidth: 110,
  config: {
    renderer({ row }: { row: IRow }) {
      const sentiment: string = getFieldValue('sentiment', row);
      const sentimentSummary: string = getFieldValue('sentiment_summary', row);

      if (
        !sentiment ||
        !['positive', 'negative', 'neutral'].includes(sentiment)
      ) {
        return <NotFound />;
      }

      const callSentimentTooltip = (sentimentSummary: string) => (
        <div className={styles.callSentimentTooltipWrapper}>
          <div className="call-sentiment-title">Sentiment description</div>

          <div className="call-sentiment-justification">
            <span>{sentimentSummary}</span>
          </div>
        </div>
      );

      return (
        <div className={styles.cell}>
          <TooltipWrapper
            tooltip={
              sentimentSummary ? callSentimentTooltip(sentimentSummary) : null
            }
            hoverable
            position="bottom left"
            popupClassName={styles.removeTooltipBefore}
          >
            <div>
              <BuTag
                uppercase
                label={sentiment}
                sentiment={sentiment}
                prefixIcon={
                  <BuIcon
                    name={
                      SPEAKER_SENTIMENT_ICONS[
                        sentiment as 'positive' | 'negative' | 'neutral'
                      ]
                    }
                  />
                }
                className={styles.sentimentBadge}
              />
            </div>
          </TooltipWrapper>
        </div>
      );
    },
  } as CustomCellConfig,
};

const columnOpportunity = {
  id: 'opportunity',
  label: 'Deal Name',
  field: 'opportunity',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  minWidth: 200,
  width: 200,
  maxWidth: 400,
  height: 90,
  config: {
    renderer({ row }: { row: IRow }) {
      const { opportunity_name, deal_id, deal_owner } = row;

      const handleClick = () => {
        openModal({
          scheme: '/deal/:id',
          params: { id: `${deal_id}` },
        });
      };

      if (!deal_id) {
        return <NotFound />;
      }

      return (
        <div className={styles.cell}>
          <div className={styles.cellColumn}>
            <div
              className={classNames(styles.cellText, styles.clickable)}
              onClick={handleClick}
              data-testing={`deal-name-${deal_id}`}
            >
              <Tooltip
                tooltip={opportunity_name}
                position="top left"
                mouseEnterDelay={500}
                testingLabel={`deal-name-tooltip-${deal_id}`}
              >
                <div className={classNames(tableStyles.deal_name, 'deal_name')}>
                  {opportunity_name}
                </div>
              </Tooltip>
            </div>
            <div className={styles.cellSubtext}>
              {deal_owner && `Owner ${deal_owner}`}
            </div>
          </div>
        </div>
      );
    },
  } as CustomCellConfig,
};

const columnAttendees = {
  id: 'attendees',
  label: 'Attendees',
  field: 'attendees',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  width: 156,
  maxWidth: 156,
  minWidth: 156,
  config: {
    renderer({ row }: { row: IRow }) {
      const { id, attendees, organizer } = row;

      if (!attendees || !Array.isArray(attendees)) {
        return null;
      }

      const maxAttendees = 4;

      const shortAttendeesList =
        attendees.length <= maxAttendees
          ? attendees
          : attendees.slice(0, maxAttendees);

      const internalAttendees = attendees.filter((a) => !a.is_customer);
      const externalAttendees = attendees.filter((a) => a.is_customer);

      // Sort attendees to put organizer at the top of each section
      const sortAttendeesByOrganizer = (attendeeList: Attendee[]) => {
        return [...attendeeList].sort((a, b) => {
          const isAOrganizer =
            organizer && a.email === (organizer as Organizer).email;
          const isBOrganizer =
            organizer && b.email === (organizer as Organizer).email;
          return isAOrganizer ? -1 : isBOrganizer ? 1 : 0;
        });
      };

      const sortedInternalAttendees =
        sortAttendeesByOrganizer(internalAttendees);
      const sortedExternalAttendees =
        sortAttendeesByOrganizer(externalAttendees);

      const attendeeListPopup = (
        <div className={styles.attendeeListPopupWrapper}>
          {externalAttendees.length > 0 && (
            <>
              <div className={styles.attendeeListPopupSectionTitle}>
                Prospect attendees
              </div>
              <div className={styles.attendeeListPopupSection}>
                {sortedExternalAttendees.map((attendee) => (
                  <AttendeeRow
                    attendee={attendee}
                    organizer={organizer as Organizer | undefined}
                  />
                ))}
              </div>
            </>
          )}

          <div className={styles.attendeeListPopupSeparator} />

          {internalAttendees.length > 0 && (
            <>
              <div className={styles.attendeeListPopupSectionTitle}>
                Company attendees
              </div>
              <div className={styles.attendeeListPopupSection}>
                {sortedInternalAttendees.map((attendee) => (
                  <AttendeeRow
                    attendee={attendee}
                    organizer={organizer as Organizer | undefined}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      );

      return (
        <Tooltip
          tooltip={attendeeListPopup}
          className={classNames(
            styles.whiteBgTooltip,
            styles.attendeeListPopup
          )}
          mouseEnterDelay={300}
          position="bottom left"
          testingLabel={`attendees-tooltip-${id}`}
        >
          <div className={styles.cell}>
            <div className={styles.attendeesAvatars}>
              {shortAttendeesList?.map((attendee, index) => (
                <div className="avatar" style={{ zIndex: 100 - index }}>
                  <UserAvatar
                    size={32}
                    name={`${
                      attendee.display_name || attendee.email || 'NA'
                    }`.toUpperCase()}
                    colors={avatarColors}
                  />
                  <div
                    className={classNames(
                      styles.attendeeResponse,
                      attendee.response_status
                    )}
                  >
                    {attendee.response_status == 'accepted' ? (
                      <BuIcon name={BoostUpIcons.Checked} />
                    ) : attendee.response_status == 'declined' ? (
                      <span>X</span>
                    ) : (
                      <span>?</span>
                    )}
                  </div>
                </div>
              ))}
              {attendees.length > maxAttendees && (
                <div className={styles.moreTopicsAndAttendees}>
                  +{attendees.length - maxAttendees}
                </div>
              )}
            </div>
          </div>
        </Tooltip>
      );
    },
  } as CustomCellConfig,
};

const columnTopics = (competitors: boolean) => ({
  id: competitors ? 'competitors' : 'topics',
  label: competitors ? 'Competitors' : 'Topics',
  field: 'topics',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  ...(competitors
    ? {
        width: 120,
        minWidth: 120,
        maxWidth: 320,
      }
    : {
        width: 192,
        minWidth: 192,
        maxWidth: 192,
      }),
  config: {
    renderer({ row }: { row: IRow }) {
      const { topics } = row;
      const maxTopics = 3;

      if (!topics || !Array.isArray(topics)) {
        return <NotFound />;
      }

      const filteredTopics = competitors
        ? topics.filter((topic) => topic.is_competitor)
        : topics.filter((topic) => !topic.is_competitor);

      if (filteredTopics.length === 0) {
        return <NotFound />;
      }

      const fullTopicsList =
        filteredTopics.length > maxTopics ? (
          <div className={styles.topicsContainer}>
            {filteredTopics.slice(maxTopics).map((topic) => (
              <BuTag
                uppercase
                label={topic.topic_name}
                sentiment={topic.sentiment}
              />
            ))}
          </div>
        ) : null;

      return (
        <div className={styles.cell}>
          <div className={styles.topicsContainer}>
            {filteredTopics?.slice(0, maxTopics)?.map((topic, index) => (
              <div>
                <BuTag
                  uppercase
                  label={topic.topic_name}
                  sentiment={topic.sentiment}
                />

                {filteredTopics?.length > maxTopics &&
                  index === maxTopics - 1 && (
                    <Tooltip
                      className={styles.topicsListTooltip}
                      tooltip={fullTopicsList}
                    >
                      <div className={styles.moreTopicsAndAttendees}>
                        +{filteredTopics?.length - maxTopics}
                      </div>
                    </Tooltip>
                  )}
              </div>
            ))}
          </div>
        </div>
      );
    },
  } as CustomCellConfig,
});

const columnCallStatus = {
  id: 'status',
  label: 'Call Status',
  field: 'status',
  sort_order: SortOrder.ASCENDING,
  // sortable: true,
  type: ColumnTypes.CUSTOM,
  align: 'center',
  width: 128,
  minWidth: 128,
  maxWidth: 128,
  config: {
    renderer({ row }: { row: IRow }) {
      const { status: _status } = row;

      const status = _status as CallStatus;

      return status === 'scheduled' ||
        status === 'skipped' ||
        status === 'uninitialized' ? (
        <div>
          <BuTag uppercase label="Not Recorded" sentiment="negative" />
        </div>
      ) : status === 'recorded' ? (
        <div>
          <BuTag uppercase label="Processing" loadingLeft={true} />
        </div>
      ) : status === 'summarized' ? (
        <div>
          <BuTag
            uppercase
            label="Recorded"
            sentiment="positive"
            prefixIcon={<BuIcon name={BoostUpIcons.Checked} />}
          />
        </div>
      ) : status === 'no_show' ? (
        <div>
          <BuTag
            uppercase
            label="No show"
            sentiment="neutral"
            prefixIcon={<BuIcon name={BoostUpIcons.EyeOff} />}
          />
        </div>
      ) : (
        <div>
          <BuTag
            uppercase
            label="No show"
            sentiment="neutral"
            prefixIcon={<BuIcon name={BoostUpIcons.EyeOff} />}
          />
        </div>
      );
    },
  } as CustomCellConfig,
};

const columnUpcomingActions = (
  userRole: string,
  userEmail: string,
  handleClickSkipCall: (id: string, status: string) => void
) => ({
  id: 'upcoming_actions',
  label: 'Recording Status',
  field: 'actions',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  align: 'center' as const,
  width: 62,
  minWidth: 62,
  config: {
    renderer({ row }: { row: IRow }) {
      const { id, status, organizer, creator } = row;

      const selectedOption = status === 'skipped' ? 'disabled' : 'enabled';

      const isDisabled = !canDeleteOrSkipCall(
        userRole,
        userEmail,
        organizer as Organizer,
        creator as Organizer
      );

      const options = [
        {
          id: 'enabled',
          text: 'Enabled',
          disabled: isDisabled,
          backgroundColor: isDisabled ? undefined : 'var(--bu-primary-200)',
          color: isDisabled ? undefined : 'var(--bu-gray-900)',
          borderColor: isDisabled ? undefined : 'var(--bu-primary-500)',
        },
        {
          id: 'disabled',
          text: 'Disabled',
          disabled: isDisabled,
          backgroundColor: isDisabled ? undefined : 'var(--bu-red-100)',
          color: isDisabled ? undefined : 'var(--bu-gray-900)',
          borderColor: isDisabled ? undefined : 'var(--bu-red-500)',
        },
      ];

      return (
        <div className={styles.cell}>
          <BuGroupButton
            options={options}
            className={styles.upcomingActionsButton}
            selectedOption={selectedOption}
            onSelect={() => handleClickSkipCall(id as string, status as string)}
            testingLabel="upcoming-actions-button"
          />
        </div>
      );
    },
  } as CustomCellConfig,
});

const columnActions = (userRole: string, userEmail: string) => ({
  id: 'call_actions',
  label: '',
  field: 'actions',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  align: 'center' as const,
  width: 62,
  minWidth: 62,
  config: {
    renderer({ row }: { row: IRow }) {
      const { id, organizer, creator, status } = row;
      const shareId: string = getFieldValue('share_id', row);
      const externalCallUrl: string = getFieldValue('external_call_url', row);

      return (
        <CallActionButtons
          callId={id as string}
          userRole={userRole}
          userEmail={userEmail}
          organizer={organizer as Organizer}
          creator={creator as Organizer}
          shareId={shareId}
          disableShareLink={status !== 'summarized'}
          disableDownloadTranscript={status !== 'summarized'}
          disableOriginalRecording={status !== 'summarized'}
          externalCallUrl={externalCallUrl}
          currentPage="completed_calls"
          className={styles.cell}
          label={
            <BuButton secondary>
              <BuIcon name={BoostUpIcons.MenuVert} />
            </BuButton>
          }
        />
      );
    },
  } as CustomCellConfig,
});

export const getColumns = (
  upcoming: boolean,
  userRole: string,
  userEmail: string,
  handleClickSkipCall: (id: string, status: string) => void
): IColumn[] => {
  return upcoming
    ? [
        notClickableColumnName,
        columnOpportunity,
        columnAttendees,
        columnUpcomingActions(userRole, userEmail, handleClickSkipCall),
      ]
    : [
        columnName,
        columnOpportunity,
        columnAttendees,
        columnSentiment,
        columnTopics(false),
        columnTopics(true),
        columnCallStatus,
        columnActions(userRole, userEmail),
      ];
};

const AttendeeRow = ({
  attendee,
  organizer,
}: {
  attendee: Attendee;
  organizer: Organizer | undefined;
}) => (
  <div className={styles.attendeeRow}>
    <div>
      <UserAvatar
        size={32}
        name={`${
          attendee.display_name || attendee.email || 'NA'
        }`.toUpperCase()}
        colors={avatarColors}
      />
      <div
        className={classNames(
          styles.attendeeResponse,
          attendee.response_status
        )}
      >
        {attendee.response_status == 'accepted' ? (
          <BuIcon name={BoostUpIcons.Checked} />
        ) : attendee.response_status == 'declined' ? (
          <span>X</span>
        ) : (
          <span>?</span>
        )}
      </div>
    </div>
    <div className={styles.attendeeInfo}>
      <div
        className={classNames(styles.attendeeName, {
          'column-direction': !attendee.display_name,
        })}
        title={attendee.display_name}
      >
        {attendee.display_name || attendee.email}

        {organizer && attendee.email === organizer.email && (
          <BuTag label="Organizer" fontStyle={fontBody} />
        )}
      </div>

      {attendee.display_name && (
        <div className={styles.attendeeEmail} title={attendee.email}>
          {attendee.email}
        </div>
      )}
    </div>
  </div>
);
