import classNames from 'classnames';
import { css } from 'emotion';
import React, { PropsWithChildren } from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';

export const tooltipStyles = css`
  &.ui.inverted.popup {
    background-color: #4a4a4a;
    padding: 2px 10px;
    font-size: 10px;
  }
`;

const Tooltip: React.FC<PopupProps> = ({
  children,
  tooltip,
  position,
  className,
  mouseEnterDelay = 0,
  testingLabel,
}: PropsWithChildren<PopupProps>) =>
  tooltip ? (
    <Popup
      inverted
      basic
      hoverable
      hideOnScroll
      className={classNames(tooltipStyles, className)}
      position={position || 'bottom center'}
      trigger={<span data-testing={testingLabel}>{children}</span>}
      content={tooltip}
      offset={[0, 0]}
      mouseEnterDelay={mouseEnterDelay}
    />
  ) : (
    <>{children}</>
  );

export default Tooltip;
