import {
  RevbiColumn,
  RevbiColumnMeta,
  RevbiColumnType,
} from 'api/RevBiTableDisplay';
import { OnClick } from 'components/hooks/RevbiColumns';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import RevBiSalesProcessCell from 'components/UI/common/TypedTable/renderers/RevBiSalesProcessCell';
import { SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { numberFormatter } from 'components/UI/TableConfig/column-helper';

interface PartiallyParsedRevbiColumn {
  field: string;
  editable: boolean;
  sort_order: SortOrder;
  sortable: boolean;
  label: string;
  type: ColumnTypes;
  meta: RevbiColumnMeta;
  length: number | undefined;
  choices: string[] | undefined;
  /**
   * This is the original type from the API.
   * Needed sometimes to handle special cases
   * when creating the column config.
   * Also useful for debugging.
   *
   * Please restrain from using this property anywhere outside of this file.
   */
  _original_api_type: RevbiColumnType;
  /**
   * This is the original meta.type from the API.
   * Needed sometimes to handle special cases
   * when creating the column config.
   * Also useful for debugging.
   *
   * Please restrain from using this property anywhere outside of this file.
   */
  _original_meta_type?: string;
}

export const mapRevbiColumnToPartialIColumn = (
  revbiColumn: RevbiColumn
): PartiallyParsedRevbiColumn => {
  return {
    field: revbiColumn.object_field,
    editable: revbiColumn.editable,
    sort_order: SortOrder.DESCENDING,
    sortable: revbiColumn.sortable,
    label: revbiColumn.display_name,
    type: getMappedType(revbiColumn),
    meta: revbiColumn.meta,
    length: revbiColumn.length,
    choices: revbiColumn.choices,
    _original_api_type: revbiColumn.type,
    _original_meta_type: revbiColumn.meta.type,
  };
};

export const getAlignmentForColumn = (column: PartiallyParsedRevbiColumn) => {
  switch (column.type) {
    case ColumnTypes.MONEY:
    case ColumnTypes.NUMBER:
    case ColumnTypes.CORPORATE_CURRENCY:
      return 'right';
    case ColumnTypes.SCORE:
      return 'center';
    default:
      return 'left';
  }
};

export const getWidthForColumn = (column: PartiallyParsedRevbiColumn) => {
  const defaultWidth = {
    minWidth: 120,
    maxWidth: 160,
  };

  switch (column.type) {
    case ColumnTypes.NUMBER:
    case ColumnTypes.MONEY:
    case ColumnTypes.CORPORATE_CURRENCY:
      return { ...defaultWidth, width: 160 };

    case ColumnTypes.DATE:
      return { minWidth: 130, maxWidth: 130, width: 130 };

    case ColumnTypes.NOTE:
      return { minWidth: 120, maxWidth: 200, width: 200 };

    case ColumnTypes.CRM_LOOKUP:
      return { minWidth: 120, maxWidth: 200, width: 200 };

    default:
      return defaultWidth;
  }
};

export const getFormatForDateColumn = (column: PartiallyParsedRevbiColumn) => {
  if (column.meta.type === 'relative') return 'ago';
  if (column.meta.type === 'local') return 'local';
  return '';
};

export interface GetColumnConfigOptions {
  showBooleanAsIcons: boolean;
  isMulticurrencyEnabled: boolean;
  companyCurrency: string;

  // Callback that handles specific clicks for a column
  // This is used for cases not related to 'clickable' column type
  onClick: OnClick;
}

export const getConfigForColumn = (
  column: PartiallyParsedRevbiColumn,
  options: GetColumnConfigOptions
) => {
  const multicurrencyFormatter = currencyFormatter(
    options.companyCurrency,
    0,
    options.isMulticurrencyEnabled
  );

  switch (column.type) {
    case ColumnTypes.NUMBER:
      return {
        isNumber: true,
        formatter: numberFormatter,
      };

    case ColumnTypes.CORPORATE_CURRENCY:
      return {
        isMoney: true,
        formatter: multicurrencyFormatter,
      };

    case ColumnTypes.MONEY:
      return {
        isMoney: true,
        formatter: multicurrencyFormatter,
        isMulticurrency: options.isMulticurrencyEnabled,
      };

    case ColumnTypes.DATE:
      const showNotScheduledPlaceholder = [
        'next_event',
        'next_meeting',
      ].includes(column.field)
        ? 'Not Scheduled'
        : undefined;

      const format = getFormatForDateColumn(column);
      return {
        isDate: true,
        format,
        not_available_placeholder: showNotScheduledPlaceholder,
      };

    case ColumnTypes.DROPDOWN:
      const isBoolean = column._original_api_type === 'bool';
      if (isBoolean) {
        return {
          useBoolAsIcon: options.showBooleanAsIcons,
          options: [
            { value: true, text: 'True' },
            { value: false, text: 'False' },
          ],
        };
      }
      return {
        options:
          column?.choices?.map((item) => ({
            key: item ?? '-',
            value: item,
            text: item ?? '-',
          })) ?? [],
      };

    case ColumnTypes.SELECT:
      return {
        multiselect: true,
        secondary: true,
        options:
          column?.choices?.map((item) => ({
            key: item,
            value: item,
            text: item,
          })) ?? [],
      };

    case ColumnTypes.SCORE:
      const label = 'View More';
      return {
        risk_factors_field: column.meta.risk_factors_field,
        positive_indicators_field: column.meta.positive_indicators_field,
        moreButton: {
          label,
          click: options.onClick,
        },
      };

    case ColumnTypes.CUSTOM:
      if (column._original_meta_type === 'sales_process') {
        return {
          renderer: RevBiSalesProcessCell,
        };
      }
      return {};

    default:
      return {};
  }
};

const isValidTableColumnType = (type: string): type is ColumnTypes => {
  return Object.values(ColumnTypes).includes(type as ColumnTypes);
};

const SUB_TYPES_THAT_NEED_REMAPING = ['risk', 'sales_process'];

export const getMappedType = (column: RevbiColumn): ColumnTypes => {
  if (['picklist', 'bool'].includes(column.type)) {
    return ColumnTypes.DROPDOWN;
  }

  if (column.type === 'multipicklist') {
    return ColumnTypes.SELECT;
  }

  const hasSubTypeThatNeedsRemapping = SUB_TYPES_THAT_NEED_REMAPING.includes(
    column.meta.type || ''
  );

  if (hasSubTypeThatNeedsRemapping) {
    const subType = column.meta.type;
    switch (subType) {
      case 'risk':
        return ColumnTypes.SCORE;
      case 'sales_process':
        return ColumnTypes.CUSTOM;
    }
  }

  // No more remappings possible, so it should be a valid table column type
  if (!isValidTableColumnType(column.type)) {
    console.warn(
      `Invalid column type: ${column.type} for column ${column.object_field}. Type not supported. Defaulting to string.`
    );

    return ColumnTypes.STRING;
  }

  return column.type;
};
