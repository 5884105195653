import classNames from 'classnames';
import { css } from 'emotion';
import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontDefault } from 'assets/css/common';
import BuIcon from 'components/UI/BuIcon';
import {
  IDataCellProps,
  TypedTableCellConfig,
  ValueType,
  isDisable,
  isEditable,
} from 'components/UI/common/TypedTable/TypedTable';
import DeltaValueCell from 'components/UI/common/TypedTable/renderers/common/DeltaValueCell';
import { getCellValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import * as s from 'components/UI/common/TypedTable/styles';
import { useHandleCellStatus } from 'components/hooks/useHandleCellStatus';
import { NoteCellModal, NoteCellOnClose } from './NoteCellModal';

export interface NoteCellConfig extends TypedTableCellConfig {}

export const BuCharLengthInfo = ({
  maxLength,
  textLength,
}: {
  maxLength?: number;
  textLength: number;
}) => (
  <>
    {maxLength ? (
      <div
        className={classNames(
          maxLengthInfo,
          'max-length-info',
          'bu-font-caption'
        )}
      >
        <div className="length-info">
          {textLength > maxLength && (
            <span className="error">
              <BuIcon name={BoostUpIcons.BadgeWarningSolid} /> Exceeds character
              limit.
            </span>
          )}
          {textLength === maxLength && (
            <span className="warning">
              <BuIcon name={BoostUpIcons.BadgeWarningSolid} /> Character limit
              reached.
            </span>
          )}
        </div>
        <span className="counter">
          <span className={textLength > maxLength ? 'error' : ''}>
            {textLength}
          </span>{' '}
          / {maxLength}
        </span>
      </div>
    ) : (
      <div className={classNames(maxLengthInfo, 'bu-font-caption', 'right')}>
        <span className="counter">Character entered {textLength}</span>
      </div>
    )}
  </>
);

const editableCellBorder = css`
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: var(--bu-control-border-radius);

  tr:hover & {
    border: 1px solid var(--bu-gray-400);
    box-sizing: border-box;
    background-color: var(--bu-white);

    &:hover {
      border-color: var(--bu-gray-500);
    }
  }

  &.disabled {
    border-color: #edf0f2;
    opacity: 0.45;
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

const padding = css`
  padding-left: 4px;
  padding-right: 4px;
`;

const editingCellBorder = css`
  border-color: var(--bu-primary-500);
`;

const noteTooltip = css`
  max-height: 300px;
  max-width: 250px;
  height: auto;
  padding: 2px 10px 2px 0;
  overflow-y: auto;
`;

const textOverflow = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const maxLengthInfo = css`
  display: flex;
  justify-content: space-between;
  margin-top: -3px;
  margin-bottom: 2px;

  &.right {
    justify-content: flex-end;
  }

  span.warning {
    color: var(--bu-orange-500);
  }

  span.error {
    color: var(--bu-red-400);
  }

  .counter {
    font-size: 10px;
    color: var(--bu-gray-700);
  }
`;

export interface IEditingFormProps {
  onCancel(): void;
  onBlur(): void;
  onChange(tmpValue: string): void;
  onSubmit(value: string): void;
  value?: string | null;
  maxLength?: number;
}

const textArea = css`
  ${fontDefault};

  color: var(--bu-gray-700);
  width: 380px;
  height: 185px;
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  padding: 5px;
  position: relative;
  transition: 0.4s all;

  &.error {
    transition: 0.4s all;
    border: 1px solid var(--bu-red-400);
    background-color: var(--bu-red-100);
  }
`;

const draftLabel = css`
  color: green;
  font-style: italic;
`;

const editableLabel = css`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`;

type LabelProps = {
  disabled?: boolean;
  editable?: boolean;
  onClick?: () => void;
  className?: string;
};
const Label: React.FC<LabelProps> = ({
  onClick = () => {},
  editable = false,
  disabled = false,
  className,
  children,
  ...props
}: React.PropsWithChildren<LabelProps>) => (
  <div
    className={classNames(s.fluid, s.textOverflow, className, padding, {
      [editableCellBorder]: editable || disabled,
      disabled: disabled,
    })}
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
);

type LabelWithTooltipProps = {
  value: string;
  editable?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
  value,
  editable = false,
  disabled = false,
  onClick = () => {},
  children,
  className,
}: React.PropsWithChildren<LabelWithTooltipProps>) => (
  <>
    {value ? (
      <Popup
        style={{ width: 'auto', height: 'auto' }}
        trigger={
          <Label
            editable={editable}
            disabled={disabled}
            onClick={onClick}
            className={className}
          >
            {children}
          </Label>
        }
        hoverable
        mouseEnterDelay={500}
        position="bottom right"
        offset={[0, 0]}
      >
        <div className={classNames('note-tooltip', noteTooltip)}>
          {textToArray(value).map((line: string, i: number) => (
            <p key={i}>{line || '\u00A0'}</p>
          ))}
        </div>
      </Popup>
    ) : (
      <Label
        className={className}
        editable={editable}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Label>
    )}
  </>
);

const textToArray = (value: string): string[] =>
  !Array.isArray(value) ? String(value || '').split('\n') : value ?? [];

const firstLine = (text: string) => {
  return textToArray(text)[0];
};

const NoteCell = ({
  column,
  row,
  draftRow,
  rows,
  onChange = () => {},
  onDraftChange: persistingOnDraftChange,
  status,
}: IDataCellProps) => {
  const savedValue = getCellValue<string>({ column, row });

  /**
   * Draft feature has two modes:
   * 1. Persisted draft: the draft is persisted in the draft row. So draft is "remembered" even after the table is reloaded.
   * 2. Unpersisted draft: the draft is not persisted in the draft row. So draft is "forgotten" after the table is reloaded.
   *
   * If persistingOnDraftChange is not provided, we use the internal draft value.
   */
  const [internalDraftValue, setInternalDraftValue] = useState<
    string | undefined
  >();

  const supportPersistingDraft = !!persistingOnDraftChange;

  const draftValue = supportPersistingDraft
    ? draftRow &&
      getCellValue<string>({
        column,
        row: draftRow,
      })
    : internalDraftValue;

  const valueToShow = draftValue || savedValue;

  const config = (column.config as NoteCellConfig) || {};

  const [isEdit, setEdit] = useState(false);

  const displayValue = (
    <div className={classNames(config.className, textOverflow)}>
      {firstLine(valueToShow as string)}&nbsp;
    </div>
  );

  const { showStatus } = useHandleCellStatus(status);

  const onDraftChange = (newValue: string | undefined) => {
    if (persistingOnDraftChange) {
      persistingOnDraftChange(column, row, newValue);
    } else {
      setInternalDraftValue(newValue);
    }
  };

  const handleSubmit = (newValue: string) => {
    if (newValue !== savedValue) {
      onChange(column, row, newValue as ValueType);
    }
    onDraftChange(undefined);
  };

  if (!isEditable(column, row, rows)) {
    return (
      <DeltaValueCell column={column} row={row} rows={rows}>
        <LabelWithTooltip value={savedValue}>{displayValue}</LabelWithTooltip>
      </DeltaValueCell>
    );
  }

  if (isDisable(column, row, rows)) {
    return (
      <DeltaValueCell column={column} row={row} rows={rows}>
        <LabelWithTooltip value={savedValue} disabled>
          {displayValue}
        </LabelWithTooltip>
      </DeltaValueCell>
    );
  }

  const onNoteModalClose: NoteCellOnClose = (payload) => {
    switch (payload.action) {
      case 'submit':
        handleSubmit(payload.draft);
        break;
      case 'save_draft':
        if (payload.draft === savedValue) {
          onDraftChange(undefined);
        } else {
          onDraftChange(payload.draft);
        }
        break;
    }

    setEdit(false);
  };

  const columnName = column.label;
  const rowName = row['name'] as string;

  const draftDisplayValue = (
    <div className={classNames(config.className, textOverflow, draftLabel)}>
      Draft in progress
    </div>
  );

  return (
    <DeltaValueCell column={column} row={row} rows={rows}>
      {!isEdit && (
        <LabelWithTooltip
          value={valueToShow}
          editable
          onClick={() => setEdit(true)}
          className={classNames(editableLabel, {
            [(status && status.status) || '']: showStatus,
          })}
        >
          {draftValue !== undefined ? draftDisplayValue : displayValue}

          <BuIcon name={BoostUpIcons.Pencil} />
        </LabelWithTooltip>
      )}

      {isEdit && (
        <NoteCellModal
          title={columnName}
          name={rowName}
          onClose={onNoteModalClose}
          note={valueToShow}
          maxLength={column.length}
        />
      )}
    </DeltaValueCell>
  );
};

export default NoteCell;
