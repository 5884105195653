import React, { ReactNode } from 'react';
import { ModalsScheme } from './types';

import {
  CUSTOM_OBJECT_TABLE_MODAL_SCHEME,
  FORMULA_MODAL_SCHEME,
  REV_BI_ACCOUNT_MODAL_SCHEME,
  REV_BI_DEALS_MODAL_SCHEME,
  REV_BI_SPLITS_MODAL_SCHEME,
} from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import AccountsModal from 'components/modals/AccountsModal';
import ActivityModal from 'components/modals/ActivityModal';
import AddBusinessTypeModal from 'components/modals/BusinessTypeModal/AddBusinessTypeModal';
import EditBusinessTypeModal from 'components/modals/BusinessTypeModal/EditBusinessTypeModal';
import CallModal from 'components/modals/CallModal';
import ChildWidgetDealsModal from 'components/modals/ChildWidgetDealsModal';
import {
  CustomObjectTableModal,
  CustomObjectTitleModal,
} from 'components/modals/CustomObjectModal/CustomObjectTableModal';
import { DealsModal, IncludedDealsModal } from 'components/modals/DealsModal';
import EmailDetailModal from 'components/modals/EmailDetailModal';
import EmailsModal from 'components/modals/EmailsModal';
import EventModal from 'components/modals/EventModal';
import EventsModal from 'components/modals/EventsModal';
import FullscreenTrends from 'components/modals/FullscreenTrends';
import { FunnelDrilldownModal } from 'components/modals/FunnelDrilldownModal/FunnelDrilldownModal';
import {
  HistoricalDrilldownModal,
  HistoricalDrilldownModalTitle,
} from 'components/modals/HistoricalDrilldownModal/HistoricalDrilldownModal';
import ImportTargetsModal from 'components/modals/ImportTargetsModal';
import ManageForecastModal from 'components/modals/ManageForecastModal';
import NextStepModal from 'components/modals/NextStepModal';
import ThenaRequestsModal from 'components/modals/ThenaRequestsModal';
import { OpportunitySplitModal } from 'components/modals/OpportunitySplitModal/OpportunitySplitModal';
import PersonalActivity from 'components/modals/PersonalActivity';
import QuickViewAccount from 'components/modals/QuickViewAccount';
import QuickViewDeal from 'components/modals/QuickViewDeal';
import QuickViewUser from 'components/modals/QuickViewUser';
import RevBiAccountsModal from 'components/modals/RevBiAccountsModal/RevBiAccountsModal';
import RevBiDealsModal from 'components/modals/RevBiDealsModal/RevBiDealsModal';
import SyntheticMetricModal from 'components/modals/SyntheticMetricModal';
import { CRMTableFilters } from 'components/settings/CRMSync/CRMTableFiltersModal/CRMTableFilters';
import UsersAddManually from 'components/settings/ManageUsers/UsersAddManually';
import UsersAddView from 'components/settings/ManageUsers/UsersAddView';
import EditThirdPartyIntegrationModal from 'components/settings/ThirdPartyIntegration/EditThirdPartyIntegrationModal/EditThirdPartyIntegrationModal';
import EditProfile from 'components/settings/UserProfiles/EditProfile';
import FollowUpEmailModal, {
  FollowUpEmailModalTitle,
} from 'components/dashboard/Calls/CallDetails/Tabs/ActionItems/FollowUpEmailModal';
import SalesProcessModal, {
  SalesProcessModalTitle,
} from 'components/dashboard/Calls/CallDetails/Tabs/ActionItems/SalesProcessModal';

type Route = {
  size?: 'large' | 'small' | 'tiny' | 'mini';
  type:
    | 'window'
    | 'quickView'
    | 'panel'
    | 'email_window'
    | 'next_window'
    | 'event_window'
    | 'activity_window'
    | 'full_screen'
    | 'thena_requests';
  title?: string | ReactNode;
  component: React.FC<any>;
  fullWidth?: boolean;
  disableScrolling?: boolean;
};

/**
 * The existing modal schemes.
 *
 * @alias modals
 */
const modals: Record<ModalsScheme, Route> = {
  '/account/:id': {
    type: 'quickView',
    component: QuickViewAccount,
  },
  '/accounts': {
    type: 'window',
    title: 'Accounts',
    component: AccountsModal,
  },
  '/activity/:activityId': {
    type: 'activity_window',
    component: ActivityModal,
    size: 'small',
  },
  '/activity/personal/:email': {
    type: 'quickView',
    component: PersonalActivity,
  },
  '/settings/data-integration/edit': {
    type: 'quickView',
    component: EditThirdPartyIntegrationModal,
  },
  '/business-type/add': {
    type: 'quickView',
    component: AddBusinessTypeModal,
    size: 'small',
  },
  '/business-type/edit/:businessType': {
    type: 'quickView',
    component: EditBusinessTypeModal,
    size: 'small',
  },
  '/call/:id': {
    type: 'window',
    title: 'Call',
    component: CallModal,
    size: 'small',
  },
  '/deals-status/:tab': {
    type: 'window',
    title: 'Opportunities',
    component: ManageForecastModal,
    fullWidth: true,
  },
  '/import-targets/:interval/:year/:metric/:businessType': {
    type: 'panel',
    title: 'Import targets',
    component: ImportTargetsModal,
  },
  '/import-targets/:interval/:year/:metric': {
    type: 'panel',
    title: 'Import targets',
    component: ImportTargetsModal,
  },
  '/deal/:id': {
    type: 'quickView',
    component: QuickViewDeal,
  },
  '/deals': {
    type: 'window',
    title: 'Opportunities',
    component: DealsModal,
    fullWidth: true,
  },
  '/deals/:tab': {
    type: 'window',
    title: 'Opportunities',
    component: ChildWidgetDealsModal,
    fullWidth: true,
  },
  '/deals/included/:submissionId': {
    type: 'window',
    component: IncludedDealsModal,
    fullWidth: true,
  },
  '/edit-profile/:profileId': {
    type: 'quickView',
    component: EditProfile,
    size: 'large',
  },
  '/email/:emailId': {
    type: 'email_window',
    component: EmailDetailModal,
    size: 'small',
  },
  '/emails': {
    type: 'window',
    title: 'Emails',
    component: EmailsModal,
  },
  '/event/:eventId': {
    type: 'event_window',
    component: EventModal,
    size: 'small',
  },
  '/events': {
    type: 'window',
    title: 'Meetings',
    component: EventsModal,
  },
  '/next-step/:nextStepId': {
    type: 'next_window',
    component: NextStepModal,
    size: 'small',
  },
  '/thena-requests/:thenaRequestsId': {
    type: 'thena_requests',
    component: ThenaRequestsModal,
    size: 'small',
  },
  '/user/:id': {
    type: 'quickView',
    component: QuickViewUser,
    size: 'small',
  },
  '/users/add': {
    type: 'panel',
    component: UsersAddView,
  },
  '/users/add/manually': {
    type: 'panel',
    component: UsersAddManually,
  },
  '/crm-table/:tableName/filters': {
    type: 'quickView',
    component: CRMTableFilters,
    size: 'small',
  },
  '/historical-drilldown': {
    type: 'window',
    title: <HistoricalDrilldownModalTitle />,
    component: HistoricalDrilldownModal,
  },
  '/funnel-drilldown': {
    type: 'window',
    title: 'Opportunities',
    component: FunnelDrilldownModal,
  },
  '/fullscreen-trends': {
    type: 'full_screen',
    title: 'Trends',
    component: FullscreenTrends,
  },
  '/follow-up-email': {
    type: 'window',
    size: 'large',
    title: <FollowUpEmailModalTitle />,
    component: FollowUpEmailModal,
  },
  '/sales-process': {
    type: 'window',
    size: 'large',
    title: <SalesProcessModalTitle />,
    disableScrolling: true,
    component: SalesProcessModal,
  },
  [CUSTOM_OBJECT_TABLE_MODAL_SCHEME]: {
    type: 'window',
    component: CustomObjectTableModal,
    title: <CustomObjectTitleModal />,
  },
  [REV_BI_DEALS_MODAL_SCHEME]: {
    type: 'window',
    title: 'Opportunities',
    component: RevBiDealsModal,
  },
  [REV_BI_ACCOUNT_MODAL_SCHEME]: {
    type: 'window',
    title: 'Accounts',
    component: RevBiAccountsModal,
  },
  [FORMULA_MODAL_SCHEME]: {
    type: 'window',
    component: SyntheticMetricModal,
    size: 'small',
  },
  [REV_BI_SPLITS_MODAL_SCHEME]: {
    type: 'window',
    title: 'Opportunities',
    component: OpportunitySplitModal,
  },
};

export const MODALS: { [T in ModalsScheme]: Route } = modals;
