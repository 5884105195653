import { isEmpty } from 'ramda';
import React from 'react';

import {
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { SalesProcessDisplay } from './SalesProcessDisplay';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';

export interface RevbiCellProcessValue {
  completed_steps: number;
  total_steps: number;
}

export interface IRevBiSalesProcessCellConfig extends TypedTableCellConfig {
  name: string;
  click(column: IColumn, row: IRow): void;
}

const RevBiSalesProcessCell: React.FC<IDataCellProps> = ({ column, row }) => {
  const field = column.field;

  const salesProcess = getFieldValue(field, row) as
    | RevbiCellProcessValue
    | undefined;

  const { completed_steps, total_steps } = salesProcess || {};

  if (!total_steps) {
    return <SalesProcessDisplay completedSteps={0} totalSteps={0} />;
  }

  return (
    <SalesProcessDisplay
      completedSteps={completed_steps ?? 0}
      totalSteps={total_steps}
    />
  );
};

export default RevBiSalesProcessCell;
