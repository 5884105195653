import { css } from 'emotion';

import { fontBody, fontHeading1, fontHeading2 } from 'assets/css/fontStyles';

export const containerWrapper = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--bu-gray-100);
  ${fontBody}

  .card-disable-recording-join-call {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bu-white);
    border-radius: var(--bu-control-border-radius-medium);
    min-height: 240px;
    width: 564px;
    padding: 24px 0;
  }

  .consent-recording-join-call {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.5em;
    font-family: var(--bu-font-regular);
    font-size: 16px;

    .consent-recording-join-call-title {
      ${fontHeading1}
    }

    .consent-recording-join-call-sub-title {
      ${fontHeading2}
    }

    svg {
      margin-bottom: 24px;
    }

    span {
      text-align: center;
      margin-bottom: 6px;
    }

    button {
      margin-top: 24px;
      min-width: 120px;
      padding: 0 16px;
    }

    a {
      margin-top: 24px;
      color: var(--bu-primary-500);
      cursor: pointer;

      &.disabled {
        color: var(--bu-gray-500);
        cursor: not-allowed;
        pointer-events: none;
      }

      &:hover {
        &:not(.disabled) {
          color: var(--bu-primary-700);
        }
      }
    }

    .disclaimer-text {
      margin-top: 24px;
      ${fontBody}
      font-size: 12px;
      color: var(--bu-gray-700);
    }
  }

  .powered-by-boostup {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--bu-control-gap-small);
    bottom: 24px;
    right: 24px;

    span {
      margin-top: 6px;
      color: var(--bu-text-gray);
    }

    img {
      width: 100px;
      height: 24px;
    }
  }
`;
