import { css } from 'emotion';

import * as s from 'components/UI/Wrapper/LeftPanel/styles';

export const wrapper = css`
  display: flex;
  height: 100vh;
`;

export const content = css`
  width: 100vw;

  .${s.container}:hover + & {
    width: calc(100vw - 200px);
  }
`;

export const content_box = css`
  width: calc(100% - 50px);
  margin-left: 50px;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  display: flex;
  flex-direction: row;

  &.no-header {
    height: 100%;
  }

  &.no-left-panel {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
  }
`;

export const mainContent = css`
  flex: 1;
  overflow-y: auto;
`;

export const aiChatContainer = css`
  height: calc(100vh - 60px);
  min-width: 360px;
  width: 25%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.full-height {
    height: 100%;
  }

  &.position-fixed {
    position: fixed;
    right: 0;
    top: 0;
    width: 25%;
  }
`;
