import { WidgetMetricConfiguration } from 'api/RevBiWidget';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatAmount, shortNumberWithConfig } from 'common/numbers';
import {
  isBIHistoricalWidget,
  isBIMetricFormula,
  isBIMetricSimple,
} from 'components/dashboard/Metrics/Create/utils';
import { SUPPORTED_DRILLDOWN_OBJECTS } from 'components/dashboard/Metrics/Widget/constants';
import {
  FORECAST_SUBMISSION_OBJECTS,
  REVBI_TOTALS_DECIMALS_CONFIG,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import {
  DrilldownTableSettings,
  RevBISettingsContext,
} from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  UnitType,
  VisualizationType,
  VisualizationTypeOneDimension,
  VisualizationTypeTwoDimension,
} from 'components/dashboard/Metrics/enums';
import {
  BIMetricCreated,
  BIMetricToChartType,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { BreadcrumbOptions } from 'highcharts';
import { useContext } from 'react';
import {
  WidgetMetricDisplayNameMap,
  getUnits,
  getWidgetMetricDisplayNameMap,
} from '../../helper';
import { PathAwareHierarchicalWidgetNode } from '../../hooks/useExpandableWidgetTable/useExpandableWidgetTable.helper';
import { HierarchicalWidget } from '../../hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import { VisualizationOptions } from './Dropdown/types';

export const evaluateDrilldownSupported = (
  metricObject: string,
  drilldownTableSettings: Record<string, DrilldownTableSettings> | undefined
) =>
  SUPPORTED_DRILLDOWN_OBJECTS.includes(metricObject) ||
  Object.keys(drilldownTableSettings ?? {}).includes(metricObject);

export type TotalData = {
  id: string;
  total: string | number;
  sufix: string;
  prefix: string;
  errorMessage: string;
  chartType: VisualizationType;
  metricObject: string;
  isDrilldownSupported: boolean;
  totalType: string;
};

const ERROR_CANT_COMPUTE_DIRECT =
  'Cannot compute totals for direct reports top level';

export const getTotalDataValue = (
  metric: BIMetricCreated,
  widgetData: HierarchicalWidget,
  column: WidgetMetricConfiguration,
  hierarchyAlias: string[] = [],
  drilldownTableSettings:
    | Record<string, DrilldownTableSettings>
    | undefined = undefined,
  chartLevels: BreadcrumbOptions[] = [],
  metricToChartType: BIMetricToChartType[] = []
): TotalData => {
  const chartType =
    metricToChartType.find((ct) => ct.metricId === column.field_name)
      ?.chartType || VisualizationType.Column;

  // Error at first level.
  const hasColumnErrorMessage = Object.keys(widgetData.errors).includes(
    column.field_name
  );

  let columnErrorData = hasColumnErrorMessage
    ? widgetData.errors[column.field_name]
    : [];

  // find deeper errors... each pivot could has
  if (!hasColumnErrorMessage) {
    widgetData.pivotConfigurations.forEach((pivot) => {
      const hasPivotErrors = Object.keys(widgetData.errors).includes(
        pivot.field_name
      );
      const deeperColumnErrorData =
        hasPivotErrors &&
        !columnErrorData.length &&
        widgetData.errors[pivot.field_name].find(
          (error) => error.field_name === column.field_name
        );
      columnErrorData = deeperColumnErrorData ? [deeperColumnErrorData] : [];
    });
  }

  const errorData = columnErrorData[0] ?? {};

  // BE sends an error message when the user tries to compute totals for direct reports
  // But is valid to show totals when the user did a drilldown
  const isCannotComputeDirectTotalsError =
    errorData.message === ERROR_CANT_COMPUTE_DIRECT;
  const userDidDrilldown = chartLevels.length > 0;

  const isDirectErrorAndDrilldown =
    isCannotComputeDirectTotalsError && userDidDrilldown;

  const errorMessage = !isDirectErrorAndDrilldown ? errorData.message : '';

  const isCountAgg = isBIMetricSimple(metric)
    ? metric.aggregation_function === 'count'
    : false;

  // counting is always a number,
  // doesn't matter which kind of column type are you counting.
  // and if type is not defined will set as a number.
  let totalType;
  if (isCountAgg) {
    totalType = 'number';
  } else if (isBIMetricSimple(metric)) {
    if (metric.object === TARGET) {
      totalType = 'money';
    } else {
      totalType = metric.column?.type ?? 'number';
    }
  } else {
    totalType = 'number';
  }

  const metricObject = isBIMetricSimple(metric) ? metric.object : '';

  const isDrilldownSupported = isBIMetricSimple(metric)
    ? evaluateDrilldownSupported(metric.object, drilldownTableSettings)
    : isBIMetricFormula(metric);

  const prefix = getUnits(UnitType.Prefix, metric);
  const sufix = getUnits(UnitType.Suffix, metric);
  const total: number | string =
    !hasColumnErrorMessage || isDirectErrorAndDrilldown
      ? getTotal(column, chartLevels, widgetData, hierarchyAlias) ?? '-'
      : '';

  return {
    id: metric._id,
    total,
    sufix,
    prefix,
    errorMessage,
    chartType,
    metricObject,
    isDrilldownSupported,
    totalType,
  };
};

const getTotalValueFormatted = (total: TotalData, currency: string) => {
  const { prefix, sufix, total: value, totalType: type } = total;

  if (typeof value !== 'number') {
    return '-';
  }

  if (isNaN(value)) {
    return '-';
  }

  const formatAsNumber = ['number', undefined].includes(type);

  if (formatAsNumber) {
    const signal = value < 0 ? '-' : '';

    return `${signal}${prefix}${shortNumberWithConfig(
      REVBI_TOTALS_DECIMALS_CONFIG
    )(Math.abs(value))}${sufix}`;
  } else {
    return formatAmount(value, currency);
  }
};

const getVisualizationOptionsForTotal = (
  widgetData: HierarchicalWidget,
  widgetConfig: BIWidget,
  hierarchyAlias: string[],
  totalData: TotalData
) => {
  // FS needs 2 pivot to render something... so makes sense keep this validation.
  //
  const isFSMetric = FORECAST_SUBMISSION_OBJECTS.includes(
    totalData.metricObject
  );

  const isUserFirstPivot = hierarchyAlias.includes(
    widgetConfig.group_by[0]?.name ?? ''
  );

  const disabledByMetricsCountOrPivotsCount =
    widgetData.metricConfigurations.length > 4 ||
    widgetData.pivotConfigurations.length > 2;

  const hasSecondPivot = widgetData.pivotConfigurations.length >= 2;

  const hasMoreThanOneMetric = widgetData.metricConfigurations.length > 1;

  const isHistorical = isBIHistoricalWidget(widgetConfig);

  return {
    pivot1: [
      {
        chartType: VisualizationType.Column,
        icon: BoostUpIcons.ColumnGraph,
        displayName: 'Column chart',
        disabled: disabledByMetricsCountOrPivotsCount || isFSMetric,
      },
      {
        chartType: VisualizationType.Bar,
        icon: BoostUpIcons.BarGraph,
        displayName: 'Bar chart',
        disabled: disabledByMetricsCountOrPivotsCount || isFSMetric,
      },
      {
        chartType: VisualizationType.Line,
        icon: BoostUpIcons.LineGraph,
        displayName: 'Line chart',
        disabled: disabledByMetricsCountOrPivotsCount || isFSMetric,
      },
      {
        chartType: VisualizationType.Pie,
        icon: BoostUpIcons.PieChart,
        displayName: 'Pie chart',
        disabled:
          hasSecondPivot ||
          hasMoreThanOneMetric ||
          // isFSMetric ||
          isHistorical ||
          isUserFirstPivot,
      },
      {
        chartType: VisualizationType.Table,
        icon: BoostUpIcons.Table,
        displayName: 'Table',
        disabled: hasSecondPivot || isFSMetric,
      },
    ],
    pivot2: [
      {
        chartType: VisualizationType.Table,
        icon: BoostUpIcons.Table,
        displayName: 'Table',
        disabled: !hasSecondPivot,
      },
      {
        chartType: VisualizationType.ColumnStacked,
        icon: BoostUpIcons.StackedColumnGraph,
        displayName: 'Stacked column chart',
        disabled:
          disabledByMetricsCountOrPivotsCount || !hasSecondPivot || isFSMetric,
      },
      {
        chartType: VisualizationType.ColumnStacked, //small fix.
        icon: BoostUpIcons.GroupedColumnGraph,
        displayName: 'Grouped column chart',
        disabled:
          disabledByMetricsCountOrPivotsCount || !hasSecondPivot || !isFSMetric,
      },
      {
        chartType: VisualizationType.BarStacked,
        icon: BoostUpIcons.StackedBarGraph,
        displayName: 'Stacked bar chart',
        disabled:
          disabledByMetricsCountOrPivotsCount || !hasSecondPivot || isFSMetric,
      },
      {
        chartType: VisualizationType.BarStacked, //small fix.
        icon: BoostUpIcons.GroupedBarGraph,
        displayName: 'Grouped bar chart',
        disabled:
          disabledByMetricsCountOrPivotsCount || !hasSecondPivot || !isFSMetric,
      },
    ],
  };
};

export interface TotalMetricVisualization {
  options: VisualizationOptions;
  selected: VisualizationType;
}

interface MetricTooltipConfig {
  metric: BIMetricCreated;
  total: number | string;
  prefix: string;
  sufix: string;
  totalType: string;
  widgetMetricsDisplayName: WidgetMetricDisplayNameMap;
  decimals: number;
}

export interface TotalMetric {
  id: string;
  totalTitle: string;
  displayValue: string;
  error: string;
  isClickable: boolean;
  shouldHideValue: boolean;
  visualization?: TotalMetricVisualization;
  tooltipConfig: MetricTooltipConfig;
}

export type TotalSectionLayoutType = 'readonly' | 'actionable';
export interface TotalSectionConfig {
  totals: TotalMetric[];
  layoutType: TotalSectionLayoutType;
  hasChart: boolean;
}

const getRawData = (dic: any[], attr: string, value: string) =>
  dic?.filter((pivotTotal) => pivotTotal[attr] === value) || [];

export const getTotal = (
  column: WidgetMetricConfiguration,
  chartLevels: BreadcrumbOptions[],
  widgetData: HierarchicalWidget,
  hierarchyAlias: string[]
) => {
  // if the chart level is zero or equal to one means we are in top of the chart or is a table
  // so we will use total values.
  if (!chartLevels.length || chartLevels.length === 1) {
    return widgetData.totals[column.field_name] ?? '';
  }
  const pivot1Data = widgetData.pivotConfigurations[0];
  const pivot1FieldName = pivot1Data?.field_name ?? '';

  if (chartLevels.length === 2) {
    const totalData: PathAwareHierarchicalWidgetNode | undefined =
      widgetData.tree.find(
        (pivotTotal) =>
          // @ts-ignore
          pivotTotal[pivot1FieldName] === chartLevels[1].levelOptions.name
      );

    return totalData
      ? totalData[`total_user_value_${column.field_name}`] ??
          totalData[column.field_name]
      : '';
  }

  const pivots2Data = widgetData.pivotConfigurations[1];
  const pivot2FieldName = pivots2Data?.field_name ?? '';
  const sourceData = widgetData.tree;
  const isHierarchyAlias = hierarchyAlias.includes(pivot2FieldName);

  let rawData: any[] = [];
  /**
   * we will find the raw data for the last level, but we need to review
   * all the levels to find the correct data,
   * because it depends of the pivot used.
   */
  for (let i = 1; i < chartLevels.length; i++) {
    const levelLabel = chartLevels[i].levelOptions.name as string;
    const dataToAnalyze = rawData.length
      ? isHierarchyAlias && i === 2
        ? rawData[0].pivots.find(
            (item: any) => item[pivot2FieldName] === levelLabel
          )
        : rawData[0].children
      : sourceData ?? [];

    if (isHierarchyAlias && i > 1 && dataToAnalyze) {
      if (i === 2) {
        rawData = getRawData([dataToAnalyze], pivot2FieldName, levelLabel);
      } else {
        rawData = getRawData(dataToAnalyze, pivot2FieldName, levelLabel);
      }
    } else {
      rawData =
        dataToAnalyze && getRawData(dataToAnalyze, pivot1FieldName, levelLabel);
    }
  }

  let finalTotal;
  if (isHierarchyAlias) {
    rawData?.forEach((item) => {
      const name = chartLevels[chartLevels.length - 1].levelOptions.name;
      if (item[pivot2FieldName] === name) {
        finalTotal =
          item[`total_user_value_${column.field_name}`] ??
          item[column.field_name] ??
          '';
      }
    });
  } else if (rawData && rawData.length) {
    finalTotal =
      rawData[0][`total_user_value_${column.field_name}`] ??
      rawData[0][column.field_name] ??
      '';
  }
  return finalTotal;
};

export const useGetTotalSectionConfig = () => {
  const { hierarchyAlias, drilldownTableSettings, currency } =
    useContext(RevBISettingsContext);

  const getTotalsFromWidget = (
    widgetData: HierarchicalWidget,
    widgetConfig: BIWidget,
    metricToChartType: BIMetricToChartType[],
    chartLevels: BreadcrumbOptions[],
    showControls: boolean
  ): TotalSectionConfig => {
    // the new API is returning the columns ordered.
    const metricsConfig =
      widgetConfig.advanced_configurations?.display?.metrics ?? {};

    // removes totals that should not be displayed based on the configuration.
    const visibleColumns = widgetData.metricConfigurations.filter(
      (metricColumn) =>
        metricsConfig[metricColumn.field_name]?.display_total ?? true
    );

    const isPivoted = widgetData.pivotConfigurations.length > 0;

    const totalItems = visibleColumns
      .map((column) => {
        const metricId: string = column.field_name;
        const metric: BIMetricCreated = widgetConfig.metric_list?.find(
          (metric: BIMetricCreated) => metric?._id === metricId
        );

        const metricWidgetConfiguration = widgetData.metricConfigurations.find(
          (metricColumn) => metricColumn.field_name === metricId
        );

        if (!metric || !metricWidgetConfiguration) {
          return;
        }
        const totalData: TotalData = getTotalDataValue(
          metric,
          widgetData,
          column,
          hierarchyAlias,
          drilldownTableSettings,
          chartLevels,
          metricToChartType
        );

        const isHistoricalWidget = isBIHistoricalWidget(widgetConfig);

        const formattedTotalValue = isHistoricalWidget
          ? '-'
          : getTotalValueFormatted(totalData, currency);

        const showVisualizationDropdown = showControls && isPivoted;

        const visualizationOptions =
          showVisualizationDropdown &&
          getVisualizationOptionsForTotal(
            widgetData,
            widgetConfig,
            hierarchyAlias,
            totalData
          );

        const selectedVisualization = metricToChartType.find(
          (ct) => ct.metricId === metricId
        )?.chartType;

        const visualizationConfig = visualizationOptions
          ? {
              options: visualizationOptions,
              selected: selectedVisualization || VisualizationType.Column,
            }
          : undefined;

        const decimals = metricsConfig[metricId]?.decimals ?? 0;

        const popupConfig: MetricTooltipConfig = {
          metric: { ...metric, name: column.display_name },
          total: totalData.total,
          prefix: totalData.prefix,
          sufix: totalData.sufix,
          totalType: totalData.totalType,
          widgetMetricsDisplayName: getWidgetMetricDisplayNameMap(widgetConfig),
          decimals,
        };

        const totalMetric: TotalMetric = {
          id: metricId,
          totalTitle:
            metricWidgetConfiguration.display_name || column.display_name,
          displayValue: formattedTotalValue,
          error: totalData.errorMessage,
          isClickable: totalData.isDrilldownSupported,
          shouldHideValue: isHistoricalWidget,
          visualization: visualizationConfig,

          // This should be further refactored to only send
          // The display information to the component
          // Instead of the whole metric object, suffix, prefix, etc.
          tooltipConfig: popupConfig,
        };

        return totalMetric;
      })
      .filter((t) => !!t) as TotalMetric[];

    const layoutType = showControls ? 'actionable' : 'readonly';
    const hasChart = isPivoted;

    return {
      totals: totalItems,
      layoutType,
      hasChart,
    };
  };

  const getManagerActionsConfig = (
    widget: BIWidget,
    isDashboardWidget: boolean,
    isReadOnly: boolean
  ): ManagerActionsConfig => {
    const computeUserHierarchyResponse =
      widget.compute_user_hierarchy_response ?? true;

    const removeMyReporteesData =
      widget.advanced_configurations?.remove_reportees_data_from_managers ??
      false;
    const hasUserNamePivot: boolean = widget?.group_by?.some((el) =>
      hierarchyAlias.includes(el?.name)
    );

    const showManagerActions: boolean =
      hasUserNamePivot && !isDashboardWidget && !isReadOnly;

    return {
      enabled: showManagerActions,
      computeUserHierarchyResponse,
      removeMyReporteesData,
    };
  };

  return { getTotalsFromWidget, getManagerActionsConfig };
};

export interface ManagerActionsConfig {
  enabled: boolean;
  computeUserHierarchyResponse: boolean;
  removeMyReporteesData: boolean;
}

export const getManagerActionsConfig = (
  widget: BIWidget
): ManagerActionsConfig => {
  const computeUserHierarchyResponse =
    widget.compute_user_hierarchy_response ?? true;

  const removeMyReporteesData =
    widget.advanced_configurations?.remove_reportees_data_from_managers ??
    false;

  return {
    enabled: computeUserHierarchyResponse || removeMyReporteesData,
    computeUserHierarchyResponse,
    removeMyReporteesData,
  };
};

export const updateMetricToChartTypes = (
  widgetMetricToChartType: BIMetricToChartType[],
  visualizationType: VisualizationType,
  metricId: string
): BIMetricToChartType[] => {
  let result: BIMetricToChartType[] = [...widgetMetricToChartType];

  // When table is selected all other metrics must be tables.
  if (visualizationType === VisualizationType.Table) {
    return widgetMetricToChartType.map((el) => ({
      ...el,
      chartType: VisualizationType.Table,
    }));
  }

  // When Stacked Column is selected all other metrics must be Stacked Column.
  if (visualizationType === VisualizationType.ColumnStacked) {
    return widgetMetricToChartType.map((el) => ({
      ...el,
      chartType: VisualizationType.ColumnStacked,
    }));
  }

  // When Stacked Bar is selected all other metrics must be Stacked Bar.
  if (visualizationType === VisualizationType.BarStacked) {
    return widgetMetricToChartType.map((el) => ({
      ...el,
      chartType: VisualizationType.BarStacked,
    }));
  }

  // When Bar is selected all other metrics must be Bar.
  if (visualizationType === VisualizationType.Bar) {
    return widgetMetricToChartType.map((el) => ({
      ...el,
      chartType: VisualizationType.Bar,
    }));
  }

  // If everything is table then we change them to selected type.
  if (
    (widgetMetricToChartType || []).every(
      (el) => el.chartType === VisualizationType.Table
    )
  ) {
    return widgetMetricToChartType.map((el) => ({
      ...el,
      chartType: visualizationType,
    }));
  }

  const oneDimensionKeys = Object.values(VisualizationTypeOneDimension).map(
    (t) => t.toLocaleLowerCase()
  );
  const twoDimensionKeys = Object.values(VisualizationTypeTwoDimension).map(
    (t) => t.toLocaleLowerCase()
  );

  const type = result.find((el) => {
    return el.metricId === metricId;
  });

  let isSwitchingDimension = true;

  if (!!type) {
    type.chartType = visualizationType;
    result = [...result];
  } else {
    result = [...result, { metricId: metricId, chartType: visualizationType }];
  }

  if (widgetMetricToChartType.length > 1) {
    const otherTypes = result.map((el) => el.chartType);

    if (oneDimensionKeys.includes(visualizationType)) {
      isSwitchingDimension =
        otherTypes.some((t) => twoDimensionKeys.includes(t)) ||
        otherTypes.some((t) => t === VisualizationType.Bar);
    }

    if (twoDimensionKeys.includes(visualizationType)) {
      isSwitchingDimension = otherTypes.some((t) =>
        oneDimensionKeys.includes(t)
      );
    }

    if (isSwitchingDimension) {
      return widgetMetricToChartType.map((el) => {
        // When Bar is selected all other Column metrics must be Bar, and vice versa.
        return {
          ...el,
          chartType: visualizationType,
        };
      });
    }
  }

  return result;
};
