import { css } from 'emotion';

import { fontBody } from 'assets/css/fontStyles';

export const callActionsDropdownWrapper = css`
  .bu-dropdown-content-wrapper .bu-dropdown-content-box {
    border-radius: var(--bu-control-border-radius);
    margin-top: 4px;
    margin-right: 2px;
  }
`;

export const callActionsDropdown = css`
  &.call-actions-dropdown-icon-hidden {
    .dropdown-icon {
      display: none;
    }

    i {
      color: var(--bu-primary-500) !important;
    }
  }

  &.call-actions-dropdown-icon {
    label {
      cursor: pointer !important;
      margin-right: 8px;
    }

    i {
      color: var(--bu-white) !important;
    }
  }
`;

export const callActionsDropdownItemAnchor = css`
  color: var(--bu-gray-900);

  i {
    font-size: 18px;
    color: var(--bu-gray-900);
  }

  &:hover {
    color: var(--bu-gray-900);
  }

  &.disabled {
    color: var(--bu-gray-400);
    cursor: not-allowed;

    i {
      color: var(--bu-gray-400);
    }

    &:hover {
      color: var(--bu-gray-400);
    }
  }

  &.delete-call-option {
    color: var(--bu-red-600);

    i {
      color: var(--bu-red-600);
    }

    &:hover {
      color: var(--bu-red-600);
    }
  }
`;

export const callActionsDropdownItem = css`
  ${fontBody}
  min-width: 200px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--bu-control-gap-medium);
  }

  &:hover {
    background-color: var(--bu-gray-200);
  }
`;

export const callActionsDropdownItemDivider = css`
  border-top: 1px solid var(--bu-gray-300);
  height: 1px;
  width: 100%;
`;
