import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchTaskDone } from 'api/CallInsights';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuButton from 'components/UI/BuButton';
import { useModal } from 'components/modals/ModalContext/modal.context';
import { getFeatureFlags, getUser } from 'selectors';

import * as styles from './styles';
import { useActionItemsContext } from './actionItems.context';
import {
  CallTodoResponse,
  FollowUpEmailModal,
  SalesProcessModal,
  Task,
} from '../../types';
import {
  getIsTaskCompleted,
  getStatusAndDateLabelForTaskList,
} from '../../helpers';

type Props = {
  callId: string;
};

const ActionItemsTab: React.FC<Props> = ({ callId }) => {
  const {
    callTodoData,
    isFetchingCallTodoData,
    regenerateTaskMutation,
    isRegeneratingTask,
  } = useActionItemsContext();
  const queryClient = useQueryClient();

  const { user_id } = useSelector(getUser);
  const { task_completion_enabled } = useSelector((state) =>
    getFeatureFlags(state)
  );

  const {
    openModal: openFollowUpEmailModal,
    updateModalProps: updateFollowUpEmailModalProps,
  } = useModal<FollowUpEmailModal>();
  const {
    openModal: openSalesProcessModal,
    updateModalProps: updateSalesProcessModalProps,
  } = useModal<SalesProcessModal>();

  useEffect(() => {
    updateSalesProcessModalProps('/sales-process', {
      callTodoData,
      isRegeneratingTask,
    });

    updateFollowUpEmailModalProps('/follow-up-email', {
      callTodoData,
      isRegeneratingTask,
    });
  }, [callTodoData, isRegeneratingTask]);

  const salesProcesses = useMemo(
    () =>
      callTodoData.sort(
        (a, b) => Number(getIsTaskCompleted(a)) - Number(getIsTaskCompleted(b))
      ),
    [callTodoData]
  );

  const handleSendFollowUpClick = (salesProcessItem: CallTodoResponse) => {
    openFollowUpEmailModal({
      scheme: '/follow-up-email',
      props: {
        callTodoData,
        salesProcessItem,
        callId,
        user_id,
        completeTaskMutation,
        isRegeneratingTask,
        regenerateTaskMutation,
      },
    });
  };

  const handleUpdateSalesProcessClick = (
    salesProcessItem: CallTodoResponse
  ) => {
    openSalesProcessModal({
      scheme: '/sales-process',
      props: {
        callTodoData,
        salesProcessItem,
        callId,
        user_id,
        completeTaskMutation,
        isRegeneratingTask,
        regenerateTaskMutation,
      },
    });
  };

  const getIsSalesProcessOwner = (salesProcessItem: CallTodoResponse) =>
    salesProcessItem.owner_id === user_id;

  const hasActionItems = salesProcesses.length > 0 || isFetchingCallTodoData;

  const completeTaskMutation = useMutation({
    mutationFn: ({
      task_id,
      isTaskCompleted,
    }: {
      task_id: string;
      isTaskCompleted: boolean;
      noToast?: boolean;
    }) => patchTaskDone({ task_id, isTaskCompleted }),
    onMutate: ({ isTaskCompleted, noToast }) => {
      if (!noToast) {
        toast.warn(
          isTaskCompleted ? 'Uncompleting task...' : 'Completing task...',
          { position: 'bottom-left' }
        );
      }
    },
    onError: (_, { isTaskCompleted, noToast }) => {
      if (!noToast) {
        toast.error(
          isTaskCompleted
            ? 'Error uncompleting task.'
            : 'Error completing task.',
          { position: 'bottom-left' }
        );
      }
    },
    onSuccess: (_, { isTaskCompleted, noToast }) => {
      if (!noToast) {
        toast.success(
          isTaskCompleted
            ? 'Task uncompleted.'
            : 'Task completed successfully.',
          { position: 'bottom-left' }
        );
      }

      queryClient.invalidateQueries(['callTodoData', callId]);
    },
  });

  const { isLoading } = completeTaskMutation;

  const handleCompleteTask = (salesProcessItem: CallTodoResponse) => {
    const isTaskCompleted = getIsTaskCompleted(salesProcessItem);

    // The task can only be completed by the owner of the action item
    if (getIsSalesProcessOwner(salesProcessItem)) {
      completeTaskMutation.mutate({
        task_id: salesProcessItem.id,
        isTaskCompleted,
      });
    }
  };

  return (
    <div className={styles.tabContentWrapper}>
      <div className={styles.actionItemsTabWrapper}>
        {hasActionItems ? (
          <>
            {isFetchingCallTodoData ? (
              <Dimmer inverted active>
                <Loader />
              </Dimmer>
            ) : (
              salesProcesses.map((salesProcessItem) => {
                const isTaskCompleted = getIsTaskCompleted(salesProcessItem);
                const dataLabel =
                  getStatusAndDateLabelForTaskList(salesProcessItem);
                const isSalesProcess =
                  salesProcessItem.type === 'sales-process';

                return (
                  <div
                    className="action-items-card"
                    key={salesProcessItem.created_at}
                  >
                    <div className="content">
                      <div className="content-description">
                        {task_completion_enabled && (
                          <BuButton
                            className={classNames('done-wrapper', {
                              done: getIsTaskCompleted(salesProcessItem),
                            })}
                            secondary
                            disabled={
                              !getIsSalesProcessOwner(salesProcessItem) ||
                              isLoading
                            }
                            onClick={() => handleCompleteTask(salesProcessItem)}
                          >
                            <div
                              className={classNames('done-icon', {
                                done: getIsTaskCompleted(salesProcessItem),
                              })}
                            >
                              <BuIcon name={BoostUpIcons.Checked} />
                            </div>
                          </BuButton>
                        )}

                        {isSalesProcess ? (
                          <BuIcon
                            name={BoostUpIcons.Money}
                            className="sales-process-icon"
                          />
                        ) : (
                          <BuIcon
                            name={BoostUpIcons.Mail}
                            className="follow-up-email-icon"
                          />
                        )}

                        <span>
                          {isSalesProcess
                            ? `Update ${
                                (salesProcessItem.task as unknown as Task).name
                              } of the opportunity
                            based on the meeting discussion and insights`
                            : 'Generate follow up email for this meeting'}
                        </span>
                      </div>

                      <div
                        className={classNames('content-actions', {
                          'margin-left-medium': task_completion_enabled,
                        })}
                      >
                        {!!dataLabel && (
                          <div className="last-updated-gen-ai">
                            {isTaskCompleted
                              ? 'Completed on '
                              : 'Generated on '}
                            {dataLabel}
                          </div>
                        )}

                        <BuButton
                          onClick={() =>
                            isSalesProcess
                              ? handleUpdateSalesProcessClick(salesProcessItem)
                              : handleSendFollowUpClick(salesProcessItem)
                          }
                          secondary
                        >
                          {isSalesProcess
                            ? `Update ${
                                (salesProcessItem.task as unknown as Task).name
                              }`
                            : 'Send Follow Up'}
                        </BuButton>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </>
        ) : (
          <div className={styles.notAvilableContent}>
            <span className="not-available-title">
              No action items available
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionItemsTab;
