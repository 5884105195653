import { css } from 'emotion';

import {
  fontCaption1,
  fontCaption2,
  fontSubheading2,
} from 'assets/css/fontStyles';

export const wrapper = css`
  height: 100%;

  .bu-search {
    width: 300px;
  }

  .deal_name:hover {
    color: var(--bu-primary-500) !important;
  }
`;

export const filters = css`
  padding: 0 20px;
`;

export const loader = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const callsListing = css`
  height: calc(100% - 55px);
`;

export const notFound = css`
  color: var(--bu-gray-400);
  text-align: center;
`;

export const cell = css`
  padding: 10px 0;
`;

export const cellRow = css`
  display: flex;
  flex-direction: row;
  gap: var(--bu-control-gap-medium);
  align-items: center;

  &.call-name-row {
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--bu-control-gap-medium);
    font-size: 18px;

    .gong {
      color: var(--bu-color-gong-logo);
    }

    .chorus {
      color: var(--bu-color-chorus-logo);
    }

    .mindtickle {
      color: var(--bu-color-mindtickle-logo);
    }

    .salesloft {
      color: var(--bu-color-salesloft-logo);
    }
  }
`;

export const cellColumn = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-small);
`;

export const sentimentBadge = css`
  font-size: 10px;
`;

export const clickable = css`
  cursor: pointer;
`;

export const cellText = css`
  ${fontSubheading2}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: var(--bu-clickable-cell) !important;

    &:hover {
      color: var(--bu-primary-500) !important;
    }
  }
`;

export const cellSubtext = css`
  color: var(--bu-gray-900);
  ${fontCaption1}

  &.call-organizer {
    margin-top: 2px;
    height: 22px;
  }
`;

export const dealStage = css`
  background-color: var(--bu-color-call-video-control);
  color: var(--bu-gray-900);
  padding: 2px 4px;
  border-radius: var(--bu-control-border-radius);
  font-size: 12px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

export const attendeeResponse = css`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: -10px;
  margin-left: 20px;
  font-size: 11px;
  text-align: center;
  background-color: var(--bu-gray-400);

  &.accepted {
    color: var(--bu-green-500);
    background-color: var(--bu-green-300);
  }

  &.declined {
    color: var(--bu-red-400);
    background-color: var(--bu-red-300);
  }
`;

export const attendeesAvatars = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
  align-items: center;

  .avatar {
    margin-left: -10px;
    position: relative;
  }

  .UserAvatar--inner {
    border: 2px solid white;
  }
`;

export const topicsContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--bu-control-gap-small);

  > div {
    display: flex;
    align-items: center;
    gap: var(--bu-control-gap-small);
  }
`;

export const topicsListTooltip = css`
  &.ui.inverted.popup {
    background-color: white !important;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
  }
`;

export const moreTopicsAndAttendees = css`
  ${fontCaption1}
  color: var(--bu-gray-900);
`;

export const whiteBgTooltip = css`
  &.ui.inverted.popup {
    background-color: white !important;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    width: 250px;
    padding: 0;
    padding-left: 10px;
  }
`;

export const attendeeListPopup = css`
  &.ui.inverted.popup {
    margin-left: 0;
    margin-top: -5px;
  }
`;

export const attendeeListPopupWrapper = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);
  background-color: white;
  color: var(--bu-gray-900);
  border-radius: var(--bu-control-border-radius);
  padding: 10px 0;
  overflow-y: auto;
  max-height: 300px;
`;

export const attendeeListPopupSectionTitle = css`
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
`;

export const attendeeListPopupSection = css`
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);
`;

export const attendeeRow = css`
  display: flex;
  flex-direction: row;
  gap: var(--bu-control-gap-medium);
`;

export const attendeeInfo = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--bu-control-gap-small);
`;

export const attendeeName = css`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--bu-control-gap-small);

  &.column-direction {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const attendeeEmail = css`
  color: var(--bu-text-gray);
  font-weight: 400;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const attendeeListPopupSeparator = css`
  border-bottom: 1px solid var(--bu-gray-300);
  margin-right: 10px;
`;

export const upcomingActionsButton = css`
  * {
    color: var(--bu-gray-900) !important;
  }
`;

export const callSentimentTooltipWrapper = css`
  ${fontCaption1}
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .call-sentiment-title {
    ${fontCaption2}
    font-size: 14px;
    font-weight: 600;
  }

  .call-sentiment-justification {
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-medium);
  }
`;

export const removeTooltipBefore = css`
  margin: 0.5em 0 0 !important;

  ::before {
    content: none !important;
  }
`;
