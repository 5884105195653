import axios from 'axios';

type CallsPayload = {
  page_number: number;
  page_size: number;
};

type AttendeeResponseStatus = 'accepted' | 'declined' | 'pending';

export type Attendee = {
  id: string;
  display_name: string;
  email: string;
  response_status: AttendeeResponseStatus;
};

export type Organizer = {
  display_name?: string;
  email: string;
  is_self: boolean;
};

export type Call = {
  id: string;
  name: string;
  start: string;
  end: string;
  attendees: Attendee[];
  organizer: Organizer;
};

type CallsResponse = {
  data: {
    calls: Call[];
    start_time: string;
    end_time: string;
    total_count: number;
  };
};

export const getCallsList = async (
  payload: CallsPayload,
  signal: AbortSignal | undefined
) => {
  const response = await axios.post('/api/data/calls/list', payload, {
    signal,
  });

  return response.data as CallsResponse;
};
