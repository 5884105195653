import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuInput from 'components/UI/BuInput';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import BuIcon from 'components/UI/BuIcon';

import * as styles from './styles';
import { useTranscriptContext } from './transcript.context';

type Props = {
  triggerSearch: () => void;
  handleClickSearchedSegment: (direction: 'previous' | 'next') => void;
};

const TranscriptSearch: React.FC<Props> = ({
  triggerSearch,
  handleClickSearchedSegment,
}) => {
  const {
    searchText,
    setSearchText,
    searchedSegments,
    searchedIndexHighlighted,
    resetSearch,
    isSearchActive,
  } = useTranscriptContext();

  const [isFocused, setIsFocused] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(false);
  const [previousSearchText, setPreviousSearchText] = useState('');

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length) {
      setSearchText(e.target.value);
    } else {
      resetSearch();
    }

    setHideTooltip(false);
  };

  const handleSearchTextKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const currentText = e.currentTarget.value;

      setHideTooltip(true);
      setSearchText(currentText);

      if (currentText === previousSearchText) {
        if (e.shiftKey) {
          handleClickSearchedSegment('previous');
        } else {
          handleClickSearchedSegment('next');
        }
      } else {
        triggerSearch();
        setPreviousSearchText(currentText);
      }
    }
  };

  const noSegmentsFound = !searchedSegments.length;

  const getSearchInputPostfix = useMemo(() => {
    if (!isSearchActive) return <></>;

    return (
      <div className="search-input-postfix">
        <span>
          {noSegmentsFound ? 0 : searchedIndexHighlighted + 1}/
          {searchedSegments.length}
        </span>

        <div
          className={classNames(
            styles.divider,
            'minor',
            'search-input-postfix-divider'
          )}
        />

        <div className="search-input-postfix-buttons">
          <BuButton
            size={BuControlSize.SMALL}
            secondary
            borderless
            icon
            onClick={() => handleClickSearchedSegment('previous')}
            disabled={noSegmentsFound}
          >
            <BuIcon name={BoostUpIcons.ChevronUp} />
          </BuButton>

          <BuButton
            size={BuControlSize.SMALL}
            secondary
            borderless
            icon
            onClick={() => handleClickSearchedSegment('next')}
            disabled={noSegmentsFound}
          >
            <BuIcon name={BoostUpIcons.ChevronDown} />
          </BuButton>

          <BuButton
            size={BuControlSize.SMALL}
            secondary
            borderless
            icon
            onClick={() => resetSearch()}
          >
            <BuIcon name={BoostUpIcons.Error} />
          </BuButton>
        </div>
      </div>
    );
  }, [
    searchedSegments,
    searchedIndexHighlighted,
    handleClickSearchedSegment,
    resetSearch,
    noSegmentsFound,
  ]);

  return (
    <TooltipWrapper
      isOpen={isFocused && !hideTooltip}
      tooltip={<div>Type a word or phrase and press enter</div>}
    >
      <BuInput
        type="text"
        value={searchText}
        placeholder="Search in transcript"
        size={BuControlSize.SMALL}
        className="search-transcript-input"
        onKeyUp={handleSearchTextKeyUp}
        onChange={handleSearchTextChange}
        onFocus={() => {
          setIsFocused(true);
          setHideTooltip(false);
        }}
        onBlur={() => {
          setIsFocused(false);
          setHideTooltip(false);
        }}
        prefix={<BuIcon name={BoostUpIcons.Search} />}
        postfix={getSearchInputPostfix}
      />
    </TooltipWrapper>
  );
};

export default TranscriptSearch;
