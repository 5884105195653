import { ACCOUNT, OPPORTUNITY } from 'components/dashboard/Metrics/constants';

export const metricObjectToPlural = (object: string): string => {
  switch (object) {
    case OPPORTUNITY:
      return 'opportunities';

    case ACCOUNT:
      return 'accounts';

    default:
      return object.replace(/_/g, ' ');
  }
};
