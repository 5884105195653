import { WidgetMetricConfiguration } from 'api/RevBiWidget';
import React, { useContext, useEffect, useMemo } from 'react';
import useExpandableTableSorting from '../hooks/useExpandableWidgetTable/useExpandableTableSorting';
import useExpandableWidgetTable from '../hooks/useExpandableWidgetTable/useExpandableWidgetTable';
import { getIsFirstPivotSubheader } from './helpers/helpers';

import { TABLE_ID } from 'common/constants';
import Table from 'components/UI/TableConfig/Table';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  ConfigClickExtraData,
  getDateExtraHeadersForMetrics,
  getMetricColumns,
  getMetricsForExtraHeaders,
  getSortingConfiguration,
} from 'components/dashboard/Metrics/Widget/Table/helpers/columnsHelpers';
import {
  getRowClassName,
  remapDatePivotDelta,
  remapDatePivotedTree,
} from 'components/dashboard/Metrics/Widget/Table/helpers/rowsHelpers';
import * as s from 'components/dashboard/Metrics/Widget/Table/styles';
import { HierarchicalWidget } from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  BIMetricsMap,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { ExpandableRow } from '../hooks/useExpandableWidgetTable/useExpandableWidgetTable.helper';

interface Props {
  sortByColumn?: string;
  treeWidget: HierarchicalWidget;
  widgetConfig: BIWidget;
  metricsInUse: BIMetricsMap;
  isTableRefetching?: boolean;
  onTableDataClick: (
    column: IColumn,
    row: IRow,
    extraData: ConfigClickExtraData
  ) => void;
  onTableSortChange?: (columnName: string) => void;
  // REMOVE CONDITIONAL ?
  addSubTreeToFetch?: (subTree: string) => void;
}

export const WidgetTable: React.FC<Props> = ({
  sortByColumn,
  treeWidget,
  widgetConfig,
  metricsInUse,
  isTableRefetching,
  onTableDataClick,
  onTableSortChange,
  addSubTreeToFetch,
}) => {
  const {
    currency: companyCurrencyCode,
    drilldownTableSettings: drilldown_table_settings,
  } = useContext(RevBISettingsContext);
  const supportedCustomObjects = Object.keys(drilldown_table_settings);

  const pivots = treeWidget.pivotConfigurations;
  const deltasByPath = treeWidget.deltas?.deltasByPath || {};
  const fromChangesSince = treeWidget.deltas?.fromChangesSince;

  const numberOfPivots = pivots.length;

  const [firstPivot] = pivots;
  const isFirstPivotSubheader = getIsFirstPivotSubheader(pivots);

  const sortingConfiguration = getSortingConfiguration(
    numberOfPivots,
    isFirstPivotSubheader
  );
  const metricColumnsConfiguration = treeWidget.metricConfigurations;
  const treeWidgetData = treeWidget.tree;

  const metricsCount = Object.keys(metricsInUse).length;

  const extraHeader = useMemo(() => {
    if (isFirstPivotSubheader) {
      const datePivotNames = treeWidgetData.map((row) =>
        String(row[firstPivot.field_name])
      );
      return getDateExtraHeadersForMetrics(datePivotNames, metricsCount);
    }
  }, [isFirstPivotSubheader, treeWidgetData, firstPivot]);

  const metricColumns = useMemo(() => {
    const thereAreMetricsColumns = metricColumnsConfiguration.length > 0;
    if (!thereAreMetricsColumns && numberOfPivots > 0) {
      return [];
    }

    let metricsToCreateColumns: WidgetMetricConfiguration[] =
      metricColumnsConfiguration;

    if (isFirstPivotSubheader && extraHeader) {
      metricsToCreateColumns = getMetricsForExtraHeaders(
        extraHeader,
        metricColumnsConfiguration
      );
    }

    return getMetricColumns(
      metricsToCreateColumns,
      metricsInUse,
      sortingConfiguration.metricsSortable,
      companyCurrencyCode,
      fromChangesSince,
      supportedCustomObjects,
      onTableDataClick
    );
  }, [
    metricColumnsConfiguration,
    companyCurrencyCode,
    sortingConfiguration.metricsSortable,
    extraHeader,
    fromChangesSince,
    widgetConfig.advanced_configurations?.display?.metrics,
    onTableDataClick,
  ]);

  const remappedData = useMemo(() => {
    if (!treeWidgetData) {
      return [];
    }

    if (isFirstPivotSubheader) {
      return remapDatePivotedTree(treeWidgetData, pivots);
    }

    return treeWidgetData;
  }, [treeWidgetData, pivots]);

  const pivotsToUseForHierarchy = useMemo(() => {
    let finalPivots = [...pivots];
    if (isFirstPivotSubheader) {
      // Removing the first pivot, the date one, as date is an extra header
      finalPivots = finalPivots.slice(1);
    }

    return finalPivots;
  }, [pivots, isFirstPivotSubheader]);

  const remappedDelta = useMemo(() => {
    if (isFirstPivotSubheader) {
      return remapDatePivotDelta(deltasByPath);
    }
    return deltasByPath;
  }, [deltasByPath, isFirstPivotSubheader]);

  const { visibleRows, hierarchyExpandableColumn } = useExpandableWidgetTable({
    tree: remappedData,
    deltasByPath: remappedDelta,
    pivots: pivotsToUseForHierarchy,
    isExpandableColumnSortable: sortingConfiguration.firstColumnSortable,
    onExpand: (node) => addSubTreeToFetch?.(node),
  });

  const { sortedRows, sortByField, setSortByField } = useExpandableTableSorting(
    {
      rows: visibleRows as ExpandableRow[],
      hierarchyExpandableColumn,
      sortingConfiguration,
      initialSortBy: sortByColumn,
    }
  );

  useEffect(() => {
    if (onTableSortChange && sortByField) {
      onTableSortChange(sortByField);
    }
  }, [sortByField]);

  const columns = [hierarchyExpandableColumn, ...metricColumns];

  const tableClassName: string = useMemo(() => {
    if (isFirstPivotSubheader) {
      if (metricsCount === 1) {
        return s.extraHeaderTableCellBordersSingleMetric;
      } else {
        if (numberOfPivots > 1) {
          return s.extraHeaderMultiplePivotsTableCellBorders(metricsCount);
        } else {
          return s.extraHeaderSinglePivotTableCellBorders(metricsCount);
        }
      }
    }

    return s.tableHeaderCellBorders;
  }, [isFirstPivotSubheader, metricsCount, numberOfPivots]);

  return (
    <Table
      tableId={`${TABLE_ID.REVBI_TABLE}-${widgetConfig.id || widgetConfig._id}`}
      fullscreen
      hidePaginationEnd
      hidePaginationStart
      hideSearch
      pinnableColumns
      columns={columns}
      extraHeader={extraHeader}
      data={sortedRows}
      rowsPerPage={10}
      currentPage={0}
      sortOrder={sortByField}
      onSort={setSortByField}
      className={tableClassName}
      rowClassName={getRowClassName}
      loading={isTableRefetching}
    />
  );
};
