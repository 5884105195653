import { TablePrimaryKey } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  BIMetricsFilter,
  BIMetricsQueryFilter,
} from 'components/dashboard/Metrics/metrics.types';
import { DrilldownQueryParams } from 'components/modals/types';
import axiosInstance from './axiosInstance';
import { getDrilldownUrlQueryParamsObject as getMetricObjectQueryParamsObject } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { get } from 'lodash';

/**
 * This type represents an item for a metric object (oppportunity, splits, any custom object), this is
 * used to show a list of items (using a table) that are related to the metric object.
 *
 * Each item has a list of properties that we want to show, according to
 * the columns configured that comes from the BE.
 *
 * Used on Reports and Drilldown Modals
 */
export interface MetricObjectItem {
  id: string;
  [key: string]: any;
}
export interface MetricObjectDataResponseOnRows {
  rows: MetricObjectItem[];
  count: number;
}

export interface MetricObjectDataResponseOnData {
  data: MetricObjectItem[];
  count: number;
}

/**
 * For some reason the response is different when fetching
 * splits, so considering this we have two different types of responses
 */
export type MetricObjectDataResponse =
  | MetricObjectDataResponseOnData
  | MetricObjectDataResponseOnRows;

export interface GetItemsForMetricObjectReturn {
  items: MetricObjectItem[];
  count: number;
}

/**
 * @deprecated This is a temporary solution until drilldown tables are migrated to use RevBi TableDisplay
 */
export const getDeprecatedIdFromItem = (
  item: MetricObjectItem,
  primaryKeyInfo?: TablePrimaryKey
): string => {
  if (primaryKeyInfo) {
    const value = get(item, primaryKeyInfo.keyProperty);

    if (value !== undefined) {
      return String(value);
    }
  }

  // Fallback to the id property if no primary key configuration\ is provided
  // Id can be in different properties depending on the object
  return item._id ?? item.Id ?? item.id;
};

export const getIdFromItem = (
  item: MetricObjectItem,
  primaryKeyInfo?: TablePrimaryKey
): string => {
  if (primaryKeyInfo) {
    const value = get(item, primaryKeyInfo.fullTablePath);

    if (value !== undefined) {
      return String(value);
    }
  }

  // Fallback to the id property if no primary key configuration\ is provided
  // Id can be in different properties depending on the object
  return item._id ?? item.Id ?? item.id;
};

export type GetDrilldownItemsForMetricObjectParams = Omit<
  DrilldownQueryParams,
  'point_in_time' | 'target_time_period' | 'time_interval' | 'time_period'
>;

export interface GetReportItemsForMetricObjectParams {
  columns: string[];
  limit: number;
  offset: number;
  order_by_expression: string;
  secondary_order_by_expression?: string;
  skip_business_validation?: boolean;
  load_extras: boolean;

  // Filters
  dashboard_filters?: BIMetricsFilter[];
  filters?: BIMetricsQueryFilter[];
  template_filters: BIMetricsFilter[];
  total_number_of_records?: number;
}

export type GetItemsForMetricObjectParams =
  | GetDrilldownItemsForMetricObjectParams
  | GetReportItemsForMetricObjectParams;

export interface GetItemsForMetricObjectOptions {
  /**
   * @description Temporary flag to handle the transition between two different table data sources:
   * - When true: Uses the 'drill_down' endpoint with core-configured columns soon to be removed
   * - When false: Uses the new 'report' endpoint with RevBi-configured columns from RevBiTableDisplay.ts
   *
   * This flag is needed because both endpoints return incompatible response structures:
   * - drill_down returns data in { ...data (including crm_metadata: {...} and different nested objects)}
   * - report returns data in { [metricObject]: { ...data flat object} }
   * Display columns are only configured for the report type of structure
   *
   * This flag will be removed in the future when drilldown tables are migrated to use
   * the RevBi TableDisplay configuration and the 'report' endpoint for its data
   *
   * @deprecated This is a temporary solution until drilldown tables are migrated to use RevBi TableDisplay
   */
  IS_FOR_TABLE_USING_REVBI_DISPLAY: boolean;
}

export const getItemsForMetricObject = async (
  metricObject: string,
  params: GetItemsForMetricObjectParams,
  primaryKeyInfo?: TablePrimaryKey,
  options?: GetItemsForMetricObjectOptions
): Promise<GetItemsForMetricObjectReturn> => {
  const drilldownOrReport = options?.IS_FOR_TABLE_USING_REVBI_DISPLAY
    ? 'report'
    : 'drill_down';

  const url = `/rev_bi/${drilldownOrReport}/${metricObject}/data`;
  const response = await axiosInstance.post<MetricObjectDataResponse>(
    url,
    params,
    {
      params: getMetricObjectQueryParamsObject(params),
    }
  );

  const items =
    'data' in response.data ? response.data.data : response.data.rows;

  return {
    items: items.map((item) => ({
      ...item,
      id: options?.IS_FOR_TABLE_USING_REVBI_DISPLAY
        ? getIdFromItem(item, primaryKeyInfo)
        : getDeprecatedIdFromItem(item, primaryKeyInfo),
    })),
    count: response.data.count,
  };
};

export type updateMetricObjectItemFieldPayload = {
  [fieldToUpdate: string]: any;
};

export const updateMetricObjectItemField = async (
  tableName: string,
  objectId: string,
  updateOperation: updateMetricObjectItemFieldPayload
) => {
  const url = `/api/settings/custom_tables/update/${tableName}/${objectId}?origin=revbi`;
  const res = await axiosInstance.post(url, updateOperation);

  return res.data;
};

export type GetItemsTotalsForMetricObjectParams =
  GetItemsForMetricObjectParams & {
    columns: string[];
  };

export type TotalsData = Array<{ value: number; column: string }>;

export interface MetricObjectItemsTotals {
  total_amount: number;
  totals: TotalsData;
}

export interface GetItemsTotalsForMetricObjectResponseData {
  data: MetricObjectItemsTotals;
}

export const getItemsTotalsForMetricObject = async (
  metricObject: string,
  params: GetItemsTotalsForMetricObjectParams,
  options?: GetItemsForMetricObjectOptions
) => {
  const url = options?.IS_FOR_TABLE_USING_REVBI_DISPLAY
    ? `/rev_bi/report/${metricObject}/totals`
    : `/rev_bi/drill_down/${metricObject}/totals`;

  const response =
    await axiosInstance.post<GetItemsTotalsForMetricObjectResponseData>(
      url,
      params,
      {
        params: getMetricObjectQueryParamsObject(params),
      }
    );

  return response.data.data;
};

export interface IdDeltaFilter extends BIMetricsFilter {
  column: {
    name: string;
    label: string;
    type: 'picklist';
  };
  operator: 'in';
  value: string[];
}

export const getIdsFilter = (
  ids: string[],
  primaryKeyInfo: TablePrimaryKey
): IdDeltaFilter => ({
  column: {
    name: primaryKeyInfo.fullTablePath,
    label: 'Id',
    type: 'picklist',
  },
  operator: 'in',
  value: ids,
});

export const getChangesSinceFilter = (
  changeInterval: string
): BIMetricsFilter => ({
  column: {
    name: 'shared.__changes_since',
    label: 'Shared - Changes Since',
    type: 'select',
  },
  operator: 'in',
  value: [changeInterval],
});

export interface DeltaUpdateMetadata {
  'user.name': string;
  'user.email': string;
  created_at: string;
}

export type DeltaUpdateMetadataDic = Record<string, DeltaUpdateMetadata>;

export type GetItemsDeltasForMetricObjectParams =
  GetItemsForMetricObjectParams & {
    change_interval: string;
  };

export interface MetricObjectDeltaItem extends MetricObjectItem {
  updates_metadata: DeltaUpdateMetadataDic;
}

export interface MetricObjectDeltaResponse {
  data: MetricObjectDeltaItem[];
  count: number;
}

export interface GetItemsDeltasForMetricObjectReturn {
  items: MetricObjectDeltaItem[];
  count: number;
}

export const getItemsDeltasForMetricObject = async (
  metricObject: string,
  params: GetItemsDeltasForMetricObjectParams,
  primaryKeyInfo: TablePrimaryKey
): Promise<GetItemsDeltasForMetricObjectReturn> => {
  const url = `/rev_bi/drill_down/${metricObject}/deltas`;
  const response = await axiosInstance.post<MetricObjectDeltaResponse>(
    url,
    params,
    {
      params: getMetricObjectQueryParamsObject(params),
    }
  );

  return {
    items: response.data.data.map((item) => ({
      ...item,
      id: getDeprecatedIdFromItem(item, primaryKeyInfo),
    })),
    count: response.data.count,
  };
};
