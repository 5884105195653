import axios from 'axios';
import { getLocation } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { put, select, call, takeLatest } from 'redux-saga/effects';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import * as callsActions from 'actions/callActions';
import * as selectors from 'selectors';

function* requestCalls(action) {
  const { pathname } = yield select(getLocation);
  if (!pathname.includes('calls')) {
    return;
  }

  yield put(action.loading());

  const activeTab = yield select(selectors.getCallActiveTabPure);
  const filters = yield select((state) =>
    selectors.getFiltersForAPI(state, activeTab.filtersTab)
  );

  const oldFilters = activeTab.filters.reduce(
    (acc, flt) => ({
      ...acc,
      [flt.data.key]: flt.value,
    }),
    {}
  );

  const isUpcoming =
    activeTab.key === 'myUpcomingCalls' || activeTab.key === 'allUpcomingCalls';
  const isLoadingAllowed = !isEmpty(filters);

  const request = {
    ...oldFilters,
    ...filters,
    ...activeTab.request,
    page_number: activeTab.request.page_number - 1,
    time_min: isUpcoming ? new Date() : null,
    time_max: isUpcoming ? null : new Date(),
  };

  if (isLoadingAllowed) {
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/list`,
      request
    );

    yield put(action.success(response.data, activeTab.key));
  }
}

function* requestNewCalls() {
  yield put(callsActions.filterCalls({ key: undefined }));
}

export default function* callsSaga() {
  yield takeLatest(
    [
      actions.ui.filters.loaded.type,
      actions.ui.filters.persist.type,
      actions.ui.filters.clearAll.type,
    ],
    requestNewCalls
  );
}
