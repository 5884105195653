import { css } from 'emotion';

const BORDER_STYLE = '1px solid rgba(34, 36, 38, 0.15)';

export const container = css`
  padding: 0 20px;
  z-index: 10;
`;
export const content = css`
  z-index: 9;
  position: relative;
`;

export const search = css`
  label: dashboard-search;
  flex-shrink: 0;
  height: 38px;
  width: 100%;
  overflow: visible;
  position: relative;
  font-family: var(--bu-font-regular);
  z-index: 10;
`;

export const centerBlockTitle = css`
  margin-bottom: 12px;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
`;

export const centerBlock = css`
  display: flex;
  justify-content: flex-start;
  font-family: var(--bu-font-regular);
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
  &.red {
    color: var(--bu-red-500);
    .bu-label-tag {
      background-color: rgba(255, 114, 113, 0.1);
    }
  }
  &.yellow {
    color: var(--bu-orange-700);
    .bu-label-tag {
      background-color: rgba(255, 200, 56, 0.1);
    }
  }
  &.green {
    color: var(--bu-green-700);
    .bu-label-tag {
      background-color: rgba(4, 218, 178, 0.1);
    }
  }
  .bu-label-tag {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border-radius: 2px;
    padding: 0 2px;
  }
  .count {
    color: var(--bu-gray-900);
    margin-left: 5px;
  }
`;

export const chartContainer = css`
  text {
    color: #666666 !important;
    fill: #666666 !important;
  }
  tspan {
    font-weight: lighter;
  }
`;

export const chartTitle = css`
  font-size: 16px;
  font-family: var(--bu-font-medium);
  color: var(--bu-gray-900);
  width: 100%;
`;

export const filters = css`
  width: auto;
`;

export const banner = css`
  display: flex;
  justify-content: center;
  padding: 15px 30px;
  background: #e6e60c;
  font-family: var(--bu-font-regular);
  font-size: 20px;
  font-weight: 800;
  color: var(--bu-gray-900);
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
  cursor: default;
`;

export const removePadding = css`
  padding: 0 !important;
  margin: 0 !important;
`;

export const removePadding_box = css`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;

export const chartHeader = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

export const dropdown = css`
  padding: 4px 10px !important;
  border-radius: 3px !important;
  border: 1px solid var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  color: var(--bu-gray-900);
  margin-left: 10px;
  width: 130px;

  i.dropdown {
    padding-top: 5px;
    padding-bottom: 15px;
    margin-left: 30px !important;
  }
  .menu {
    width: 100% !important;
  }
`;

export const fullScreen = css`
  width: 18px;
  height: 18px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  margin-left: 20px;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const alignRight = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
