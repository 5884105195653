import { GetItemsForMetricObjectParams } from 'api/MetricObjectItems';
import { UseManageReportDataParams } from 'components/dashboard/Metrics/Widget/Report/ReportTable/hooks/useManageReportData';
import { IColumn } from 'components/UI/common/TypedTable/TypedTable';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';

export const getApiPathAndSearchParam = (
  metricObject: string,
  isMetricObjectEqualToOpportunity: boolean
) => {
  if (metricObject && isMetricObjectEqualToOpportunity) {
    return ['deals', 'opportunity_name'];
  } else if (metricObject) {
    return ['accounts', 'account_name'];
  }

  return [];
};

export const getCSVDownloadUrl = (
  metricObject: string,
  params: UseManageReportDataParams,
  columns: IColumn[]
): DownloadButtonProps => ({
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/report/${metricObject}/download`,
  queryMethod: 'post',
  serializedQueryParams: JSON.stringify({
    drill_down: {
      ...params,
      limit: 100000,
      offset: 0,
    },
    columns: columns.map((column) => {
      return {
        display_name: column.label,
        object_field: column.field,
      };
    }),
  }),
  extendedOptions: true,
});
