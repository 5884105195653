import React from 'react';

import checklistPlaceholder from 'assets/images/checklist_placeholder.png';
import developerPlaceholder from 'assets/images/developer_placeholder.png';
import funnelPlaceholder from 'assets/images/funnel_placeholder.png';
import pieChartPlaceholder from 'assets/images/pie_chart_placeholder.png';
import {
  container,
  titleContainer,
} from 'components/dashboard/Metrics/Create/WidgetPreviewPlaceholder/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';

type Props = {
  analysisType?: AnalysisType;
};

export const WidgetPreviewBanner: React.FC<Props> = ({ analysisType }) => {
  const getPreviewImage = (analysisType?: AnalysisType): string => {
    switch (analysisType) {
      case AnalysisType.FUNNEL:
        return funnelPlaceholder;
      case AnalysisType.HISTORICAL:
        return pieChartPlaceholder;
      case AnalysisType.LIVE:
        return developerPlaceholder;
      case AnalysisType.REPORT:
        return checklistPlaceholder;
      default:
        return developerPlaceholder;
    }
  };

  return (
    <div className={container} data-testing="widget-preview-section">
      <img src={getPreviewImage(analysisType)} width="250px" />
      <div className={titleContainer}>
        Changes pending — Preview to refresh your visualization
      </div>
    </div>
  );
};
