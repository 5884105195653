import { FC } from 'react';
import classNames from 'classnames';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import {
  fontBody,
  fontCaption1,
  fontCaption2,
  fontCaption3,
} from 'assets/css/fontStyles';
import BuIcon from 'components/UI/BuIcon';

import { loadingStyle, tagContainer } from './styles';

interface Props {
  label: string;
  editable?: boolean;
  loading?: boolean;
  uppercase?: boolean;
  fontStyle?:
    | typeof fontBody
    | typeof fontCaption1
    | typeof fontCaption2
    | typeof fontCaption3;
  className?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  sentiment?: string;
  loadingLeft?: boolean;
}

export const BuTag: FC<Props> = ({
  label,
  sentiment,
  editable,
  loading,
  uppercase = false,
  fontStyle = fontCaption2,
  className,
  prefixIcon,
  suffixIcon,
  loadingLeft = false,
}) => {
  const sentimentToClassName = (sentiment?: string) =>
    sentiment?.toLowerCase().replace(/ /g, '-');

  return (
    <div className={tagContainer}>
      <div>
        <span
          className={classNames(
            'label-tag',
            className,
            sentimentToClassName(sentiment),
            {
              editable,
              uppercase: uppercase,
              [fontStyle]: fontStyle,
            }
          )}
        >
          {loadingLeft ? (
            <Loader
              active
              className={classNames(loadingStyle, 'loading-left')}
            />
          ) : (
            prefixIcon
          )}

          {label}

          {editable ? (
            <>
              {loading ? (
                <Loader
                  active
                  className={classNames(loadingStyle, 'loading-right')}
                />
              ) : (
                <BuIcon name={BoostUpIcons.Pencil} />
              )}
            </>
          ) : (
            suffixIcon
          )}
        </span>
      </div>
    </div>
  );
};
