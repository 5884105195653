export enum AggregationFunction {
  Sum = 'sum',
  Average = 'avg',
  Min = 'min',
  Max = 'max',
  Count = 'count',
}

export enum MetricType {
  Formula = 'formula',
  Simple = 'simple',
}

export enum RevbiPages {
  WIDGETS = 'widgets',
  METRICS = 'metrics',
  DASHBOARD = 'dashboard',
  DASHBOARDS = 'dashboards',
}

export enum SidebarType {
  WIDGETS = 'Widget',
  METRICS = 'Metric',
}

export enum UnitType {
  Prefix = 'prefix',
  Suffix = 'suffix',
}

export enum VisualizationType {
  Column = 'column',
  Bar = 'bar',
  ColumnStacked = 'column_stacked',
  BarStacked = 'bar_stacked',
  Line = 'line',
  Pie = 'pie',
  Table = 'table',
}

export enum VisualizationTypeOneDimension {
  Column = 'column',
  Line = 'line',
  Pie = 'pie',
  Bar = 'bar',
}

export enum VisualizationTypeTwoDimension {
  ColumnStacked = 'column_stacked',
  BarStacked = 'bar_stacked',
  Table = 'table',
}
