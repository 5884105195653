import { css } from 'emotion';

import {
  fontHeading2,
  fontBody,
  fontSubheading1,
  fontHeading3,
} from 'assets/css/fontStyles';

export const chatContainer = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: stretch;
  width: 100%;
  height: 100%;
  background: var(--bu-white);
  border-left: 1px solid var(--bu-gray-400);
  z-index: 999;
`;

export const header = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--bu-control-gap-large);
  width: 100%;
  height: 48px;
  background: var(--bu-primary-100);
  border-bottom: 1px solid var(--bu-gray-400);
  padding: 8px 15px;
`;

export const headerTitle = css`
  ${fontHeading2}
  font-size: 20px;
  color: var(--bu-gray-900);
  flex-grow: 1;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const closeButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: var(--bu-control-border-radius);
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const messagesContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: var(--bu-control-gap-medium);
  width: 100%;
  overflow-y: auto;
  border-radius: 0;
  flex: 1 1 auto;
  min-height: 0;
`;

export const messageContainer = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--bu-control-gap-medium);
  width: 100%;
  border-radius: 0;
`;

export const aiMessageContainer = css`
  padding: 10px;
`;

export const userMessageBubble = css`
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: var(--bu-control-gap-medium);
  align-self: stretch;
  border-radius: var(--bu-control-border-radius);
  background: var(--bu-gray-300);
  width: 100%;
`;

export const userMessageIcon = css`
  font-size: 20px;
`;

export const userMessageText = css`
  ${fontBody}
  align-self: center;
  color: var(--bu-gray-900);
`;

export const aiMessageText = css`
  ${fontSubheading1}
  color: var(--bu-gray-900);
  display: flex;

  &.column {
    flex-direction: column;
    gap: var(--bu-control-gap-medium);
  }
`;

export const aiMessageTextBelow = css`
  color: var(--bu-gray-800);
`;

export const suggestionButton = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: var(--bu-control-gap-tiny);
  width: 100%;
  min-height: 52px;
  background: var(--bu-primary-100);
  border-radius: var(--bu-control-border-radius);
  border: none;
  cursor: pointer;

  &:hover {
    background: var(--bu-primary-200);
  }
`;

export const suggestionText = css`
  ${fontSubheading1}
  color: var(--bu-primary-700);
  text-align: left;
  width: 100%;
`;

export const inputContainer = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--bu-control-gap-medium);
  padding: 12px 15px;
  width: 100%;
  background: var(--bu-white);
  border-top: 1px solid var(--bu-gray-400);
  border-radius: 0 0 20px 20px;
`;

export const input = css`
  padding: 8px 0;
  min-height: 24px;
  max-height: 200px;
  overflow-y: auto;
  ${fontHeading3}
  color: var(--bu-gray-900);
  border: none;
  outline: none;
  resize: none;
  flex: 1;

  &::placeholder {
    color: var(--bu-gray-600);
  }
`;

export const sendButton = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: var(--bu-control-gap-medium);
  width: 40px;
  background: var(--bu-primary-500);
  border-radius: var(--bu-control-border-radius);
  border: none;
  cursor: pointer;

  &:hover {
    background: var(--bu-primary-700);
  }
`;

export const sendIcon = css`
  font-size: 20px;
  transform: rotate(-90deg);
`;

export const aiIcon = css`
  font-size: 24px;
`;

export const closeIcon = css`
  font-size: 12px;
`;

export const thinkingIcon = css`
  font-size: 20px;
  margin-right: 10px;
`;

export const followUpQuestionsContainer = css`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);
  width: 100%;
`;

export const followUpButtonsContainer = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);
  width: 100%;
`;

export const feedbackContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--bu-control-gap-medium);
  width: fit-content;
  align-self: flex-end;

  &.disabled {
    opacity: 0.5;
  }

  button {
    margin: 0;
    padding: 0;
    padding: 0 6px;
    background-color: var(--bu-primary-200) !important;
    border-radius: var(--bu-control-border-radius);

    i {
      color: var(--bu-gray-700) !important;
      font-size: 20px;
    }

    &.active {
      &.positive {
        background-color: var(--bu-green-500) !important;

        i {
          color: var(--bu-white) !important;
        }
      }

      &.negative {
        background-color: var(--bu-red-500) !important;

        i {
          color: var(--bu-white) !important;
        }
      }
    }
  }
`;

export const actionsContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--bu-control-gap-extra-large);
  width: 100%;
`;

export const copyButton = css`
  border-radius: var(--bu-control-border-radius);
  border: 1px solid var(--bu-gray-400);
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 32px;
    margin: 0;
    padding: 0 6px;
  }

  i {
    font-size: 18px;
  }
`;

export const loadingIconContainer = css`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  & img:nth-of-type(1) {
    opacity: 1;
    animation: fadeIcon1 15s infinite;
  }

  & img:nth-of-type(2) {
    animation: fadeIcon2 15s infinite;
  }

  & img:nth-of-type(3) {
    animation: fadeIcon3 15s infinite;
  }

  @keyframes fadeIcon1 {
    0%,
    30% {
      opacity: 1;
    }
    33%,
    97% {
      opacity: 0;
    }
    98%,
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIcon2 {
    0%,
    30% {
      opacity: 0;
    }
    33%,
    63% {
      opacity: 1;
    }
    66%,
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIcon3 {
    0%,
    63% {
      opacity: 0;
    }
    66%,
    97% {
      opacity: 1;
    }
    98%,
    100% {
      opacity: 0;
    }
  }
`;

export const thinkingText = css`
  display: flex;
  align-items: center;

  .text {
    &::after {
      content: 'Thinking';
      animation: changeText 15s infinite;
    }
  }

  @keyframes changeText {
    0%,
    32% {
      content: 'Thinking';
    }
    33%,
    65% {
      content: 'Compiling the answer';
    }
    66%,
    100% {
      content: 'Almost there';
    }
  }
`;

export const dots = css`
  &::after {
    content: '';
    animation: ellipsis 1.5s infinite;
    width: 12px;
    display: inline-block;
    text-align: left;
  }

  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`;
