import { css } from 'emotion';

export const defaultStepComponent = css`
  border: 1px solid var(--bu-gray-500);
  color: var(--bu-gray-900);
  min-height: 30px;
  border-radius: 4px;
  line-height: 14px;
  padding: 0 8px;

  &::focus {
    border-color: var(--bu-color-buying_team_email);
  }
`;

export const unsupportedField = css`
  ${defaultStepComponent};
  background-color: var(--bu-gray-200);
`;

export const inputStyle = css`
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${defaultStepComponent};
`;

export const textAreaStyle = css`
  ${defaultStepComponent};
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  font-size: 14px;
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 11px 13px;
  position: relative;
  transition: 0.4s;
  line-height: 21px;

  &:focus {
    border-color: var(--bu-color-buying_team_email);
  }
  &:read-only {
    border-color: var(--bu-read-only-border-color);
    background-color: var(--bu-read-only-primary);
    color: var(--bu-gray-900);

    &::placeholder {
      color: var(--bu-read-only-placeholder-color);
    }
  }

  &:disabled {
    background-color: var(--bu-read-only-primary);
    cursor: not-allowed;
  }

  &.error {
    transition: 0.4s all;
    border: 1px solid var(--bu-red-400);
    background-color: var(--bu-red-100);
  }
`;

export const dateStyle = css`
  ${defaultStepComponent};
  ${inputStyle};
  position: relative;
  display: flex;
  cursor: pointer;
  background-color: var(--bu-white);
  justify-content: flex-end;

  &.disabled {
    background-color: var(--bu-read-only-primary);
    cursor: not-allowed;
  }

  .date-button {
    display: flex;
    align-items: center;
    align-content: space-between;
    line-height: 27px;
    width: 100%;
    justify-content: space-between;

    img {
      margin-right: 5px;
      margin-top: -2px;
      height: 18px;
      width: 18px;

      &.disabled {
        filter: contrast(5%);
      }
    }
  }

  .date-popup {
    position: absolute;
    top: 30px;
    display: block;
    max-width: 280px;
    border: 1px solid #ccc;
    z-index: 2000;
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }

  i {
    font-size: 16px;
  }
`;

export const dropdownStyles = css`
  ${defaultStepComponent};
  box-sizing: border-box;

  padding: 1px;
  width: 100%;

  font-family: var(--bu-font-regular);
  font-size: 13px;
  font-weight: 100;
  height: 28px;

  &.ui.dropdown,
  &.ui.dropdown.active {
    > .text::after {
      content: '\f078';
      display: inline-block;
      font-family: Icons;
      font-size: 9px;
      right: 5px;
      position: absolute;
    }
  }

  &.ui.dropdown {
    & > .text {
      line-height: 14px;

      font-family: var(--bu-font-regular);
      font-size: 13px;
      font-weight: 100;

      padding-left: 5px;
      padding-right: 15px;
      padding-top: 6px;
    }

    &.search {
      &,
      &.active,
      &.visible {
        & > input.search {
          line-height: 25px;
          padding-left: 6px;
        }
      }
    }
  }

  &.dropdown {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.ui.dropdown > .text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--bu-gray-700);
`;
export const content = css`
  flex: 1;
  font-size: 14px;
  overflow-y: auto;
  padding: 0 20px 40px 20px;
`;
export const header = css`
  margin-bottom: 25px;
  color: var(--bu-gray-900);

  .header {
    font-size: 14px;
    font-family: var(--bu-font-medium);
    margin-bottom: 4px;
  }
  .sub-header {
    font-family: var(--bu-font-regular);
    font-size: 14px;
  }
`;
export const actionButtons = css`
  padding: 10px 15px 5px 15px;
  text-align: right;
  border-top: 1px #ccc solid;
  overflow: hidden;

  button {
    min-width: 80px;

    &:first-child {
      border-color: var(--bu-gray-500);
      color: var(--bu-gray-900);
      margin-right: 10px;
    }
  }

  &.ui.button {
    margin: 0 8px;

    &.primary {
      background-color: #0762ec;
    }
  }
`;
export const stepContainer = css`
  position: relative;
  margin-top: 20px;

  .header {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;

    .text {
      flex: 1;
      color: var(--bu-gray-900);
      font-size: 13px;
    }

    .icon-wrapper {
      margin-left: 5px;
      height: 16px;
      padding: 0;
      padding-right: 2px;
    }

    .bu-icon {
      font-size: 14px;
    }
  }

  .control {
    min-height: 30px;
    display: flex;
  }
`;

export const dropDownContainer = css`
  button.bu-dropdown {
    margin: 0;
  }

  &.side-panel-dropdown {
    > div,
    button.bu-dropdown,
    .bu-dropdown-content-wrapper {
      width: 100%;
    }

    .bu-dropdown.bu-dropdown-open,
    .bu-dropdown.bu-dropdown-close {
      color: var(--bu-gray-900);
      border-color: var(--bu-gray-500);
      background-color: var(--bu-white);

      .bu-btn-content {
        .bu-dropdown-label {
          font-family: var(--bu-font-regular);
        }

        i.bu-down,
        i.bu-up {
          border-color: var(--bu-gray-500);
          margin-top: 5px;
        }
      }
    }
  }

  .bu-checkbox-label {
    max-width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
