import {
  BIMetricFormula,
  BIMetricsQueryFilter,
  DecimalPrecisionConfig,
  DrillDownParams,
  FormulaMetricValuesAsChartValue,
  SelectedValue,
} from '../../metrics.types';

import { useModal } from 'components/modals/ModalContext/modal.context';
import {
  ModalCallbacks,
  ModalConfig,
} from 'components/modals/ModalContext/modal.context.types';
import { DrilldownTableSettingsDictionary } from '../../contexts/RevBISettingsContext';
import { AnalysisType } from '../../constants';

interface RevBiDrilldownModalProps {
  shouldReFetchOnClose?: boolean;
  dashboardId?: string;
  widgetId?: string;
}

/** CUSTOM OBJECT MODAL  */

export const CUSTOM_OBJECT_TABLE_MODAL_SCHEME =
  '/revbi-custom-object-table-modal';

export interface CustomObjectModalProps extends RevBiDrilldownModalProps {
  apiUrl: string;
  title: string;
  drilldownParams: DrillDownParams;
  filters: string;
  metricName: string;
  metricObject: string;
  nodePath?: string;
  changeInterval?: string;
  pivotValue?: string;
}

export type CustomObjectModal = ModalConfig<
  CustomObjectModalProps,
  {},
  ModalCallbacks,
  typeof CUSTOM_OBJECT_TABLE_MODAL_SCHEME
>;

/** DEALS MODAL  */

export const REV_BI_DEALS_MODAL_SCHEME = '/revbi-deals-modal';

interface RevBiDealsModalProps extends RevBiDrilldownModalProps {
  title: string;
  metricAnalysisType: AnalysisType;
  drilldownParams: DrillDownParams;
  changeInterval?: string;
}

export type RevBiDealsModalConfig = ModalConfig<
  RevBiDealsModalProps,
  {},
  ModalCallbacks,
  typeof REV_BI_DEALS_MODAL_SCHEME
>;

/**
 * ACCOUNTS MODAL
 */

export const REV_BI_ACCOUNT_MODAL_SCHEME = '/revbi-account-modal';

interface AccountModalProps extends RevBiDrilldownModalProps {
  title: string;
  ids: string[];
}

export type AccountModalConfig = ModalConfig<
  AccountModalProps,
  {},
  ModalCallbacks,
  typeof REV_BI_ACCOUNT_MODAL_SCHEME
>;

/**
 * Formula Modal
 */

export const FORMULA_MODAL_SCHEME = '/revbi-formula-modal';

interface FormulaModalProps extends RevBiDrilldownModalProps {
  title: string;
  formula: string;
  conditions?: BIMetricsQueryFilter[];
  valuesByMetrics: FormulaMetricValuesAsChartValue[];
  drilldownParams: DrillDownParams;
  metric: BIMetricFormula;
  selectedValue: SelectedValue;
  drilldownTableSettings?: DrilldownTableSettingsDictionary;
  changeInterval?: string;
  decimals?: DecimalPrecisionConfig;
}

export type FormulaModalConfig = ModalConfig<
  FormulaModalProps,
  {},
  ModalCallbacks,
  typeof FORMULA_MODAL_SCHEME
>;

/** SPLITS MODAL */

export const REV_BI_SPLITS_MODAL_SCHEME = '/revbi-splits-modal';

interface RevBiSplitsModalProps extends RevBiDrilldownModalProps {
  metricObject: string;
  drilldownParams: DrillDownParams;
  metricName: string;
  changeInterval?: string;
}

export type RevBiSplitsModalConfig = ModalConfig<
  RevBiSplitsModalProps,
  {},
  ModalCallbacks,
  typeof REV_BI_SPLITS_MODAL_SCHEME
>;

/** HISTORICAL MODAL */

export const REV_BI_HISTORICAL_MODAL_SCHEME = '/historical-drilldown';

interface RevBiHistoricalModalProps extends RevBiDrilldownModalProps {
  metric: string;
  pivotValue: string;
  title: string;
  drilldownParams: DrillDownParams;
  showTotalAmount: boolean;
  objectURL: string;
}

export type RevBiHistoricalModalConfig = ModalConfig<
  RevBiHistoricalModalProps,
  {},
  ModalCallbacks,
  typeof REV_BI_HISTORICAL_MODAL_SCHEME
>;

/** MODAL CONFIG */
type RevBIDrilldownModal =
  | CustomObjectModal
  | RevBiDealsModalConfig
  | AccountModalConfig
  | FormulaModalConfig
  | RevBiSplitsModalConfig
  | RevBiHistoricalModalConfig;

/**
 * Maps modal scheme identifiers to their configuration types, enabling type-safe modal operations.
 */
type RevBiDrilldownSchemeToPropsMapping = {
  [CUSTOM_OBJECT_TABLE_MODAL_SCHEME]: CustomObjectModal;
  [REV_BI_DEALS_MODAL_SCHEME]: RevBiDealsModalConfig;
  [REV_BI_ACCOUNT_MODAL_SCHEME]: AccountModalConfig;
  [FORMULA_MODAL_SCHEME]: FormulaModalConfig;
  [REV_BI_SPLITS_MODAL_SCHEME]: RevBiSplitsModalConfig;
  [REV_BI_HISTORICAL_MODAL_SCHEME]: RevBiHistoricalModalConfig;
};

/**
 * Union type of all modal scheme identifiers, derived from `SchemeToPropsMapping`.
 * Used for ensuring type safety in modal operations, limiting schemes to defined configurations.
 */
type RevBiDrilldownModalScheme = keyof RevBiDrilldownSchemeToPropsMapping;

export interface OpenRevBiDrilldownModalConfig<
  T extends RevBiDrilldownModalScheme
> {
  scheme: T;
  props: RevBiDrilldownSchemeToPropsMapping[T]['props'];
}

/**
 * Will make sure that the modal is opened with the correct and required props for the modal scheme.
 */
export type OpenRevBiDrilldownModal = <T extends RevBiDrilldownModalScheme>(
  config: OpenRevBiDrilldownModalConfig<T>
) => void;

/**
 * Provides a type-safe way to open a RevBiDrilldownModal.
 *
 * @example
 * // If you want this hook to support a new modal, you need to add a new interface for the modal props.
 *
 * // Add this const to the navigation/modals file along with your modal component
 * export const EXAMPLE_MODAL_SCHEME = '/example-modal';
 *
 * // The interface should extend `RevBiDrilldownModalProps` to support calling callback on modal edition automatically.
 * interface ExampleModalProps extends RevBiDrilldownModalProps {
 *  foo: string;
 * }
 *
 * export type ExampleModalConfig = ModalConfig<
 *    ExampleModalProps,
 *    {},
 *    ModalCallbacks,
 *    typeof EXAMPLE_MODAL_SCHEME
 * >
 *
 * // Add this to the RevBIDrilldownModal type
 *
 * const RevBIDrilldownModal = ...previous modal types | ExampleModalConfig;
 *
 * // Add this to the RevBiDrilldownSchemeToPropsMapping type
 *
 * type RevBiDrilldownSchemeToPropsMapping = {
 *    ...previous modal mappings,
 *    [EXAMPLE_MODAL_SCHEME]: ExampleModalConfig;
 * }
 *
 *
 * // Component where you want to open the modal
 *
 * const openModal = useOpenRevBiDrilldownModal();
 * openModal({
 *    scheme: EXAMPLE_MODAL_SCHEME,
 *
 *    // This will be correctly typed with the props associated to the scheme
 *    props: {
 *      foo: 'bar',
 *    }},
 *    () => {
 *     // This will be called when the modal is closed if something was edited
 *     // Internally handled by shouldReFetchOnClose
 *    });
 *
 * // Modal Component
 *
 *  const { getProps, markShouldReFetchOnClose } = useRevBiDrilldownModalWithScheme(EXAMPLE_MODAL_SCHEME);
 *
 *  // This will be correctly typed with the props associated to the scheme
 *  const { foo } = getProps();
 *
 *  // When some data is edited and you want to refetch the data
 *  markShouldReFetchOnClose();
 *
 */
export const useOpenRevBiDrilldownModal = () => {
  const modal = useModal<RevBIDrilldownModal>();

  const openRevBiDrilldownModal = <T extends RevBiDrilldownModalScheme>(
    { scheme, props }: OpenRevBiDrilldownModalConfig<T>,
    onClose: () => void
  ) => {
    modal.openModal({
      scheme,
      props,
    });

    modal.setCallbacksForScheme(scheme, {
      onClose: (modalProps: RevBIDrilldownModal['props']) => {
        onClose();
      },
    });
  };

  return openRevBiDrilldownModal;
};

/**
 * Provides type-safe access and manipulation of modal properties based on a given modal scheme.
 * By passing a scheme identifier, the hook returns functions tailored to the modal's configuration,
 * ensuring correct typing of props and other modal interactions.
 *
 * @example
 * // Suppose CUSTOM_OBJECT_TABLE_MODAL_SCHEME is '/revbi-custom-object-table-modal'
 * const { getProps, markShouldReFetchOnClose } = useRevBiDrilldownModalWithScheme(CUSTOM_OBJECT_TABLE_MODAL_SCHEME);
 *
 * // `getProps` now returns `CustomObjectModalProps`, specific to the custom object table modal.
 * const modalProps = getProps();
 *
 * // This is type-safe; TypeScript will enforce `modalProps` to adhere to `CustomObjectModalProps`.
 * console.log(modalProps.apiUrl); // Accesses apiUrl, known to be part of `CustomObjectModalProps`.
 *
 * // `markShouldReFetchOnClose` is also tailored to the specific modal, maintaining type safety.
 * markShouldReFetchOnClose();
 */
export const useRevBiDrilldownModalWithScheme = <
  T extends RevBiDrilldownModalScheme
>(
  scheme: T
) => {
  const modal = useModal<RevBiDrilldownSchemeToPropsMapping[T]>();

  const getProps = (): NonNullable<
    RevBiDrilldownSchemeToPropsMapping[T]['props']
  > => modal.getPropsForScheme(scheme);

  const markShouldReFetchOnClose = () => {
    modal.updateModalProps(scheme, {
      shouldReFetchOnClose: true,
    } as RevBiDrilldownSchemeToPropsMapping[T]['props']);
  };
  return {
    getProps,
    markShouldReFetchOnClose,
  };
};
