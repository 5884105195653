import React, { useEffect, useState } from 'react';
import { Loader, Tab } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getCallInsightsData } from 'api/CallInsights';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { AI_CHAT_ACTIONS } from 'common/constants';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import BuIcon from 'components/UI/BuIcon';
import BuButton from 'components/UI/BuButton';
import TabTitle from 'components/dashboard/Tabs/TabTitle';
import * as selectors from 'selectors';
import { goBackOr, goToCalls } from 'actions/routeActions';

import * as styles from './styles';
import HeaderDetails from './HeaderDetails';
import {
  InsightTab,
  SummaryTab,
  ActionItemsTab,
  TranscriptTab,
  CommentsTab,
} from './Tabs';
import VideoPlayer from './VideoPlayer';
import CallTimeline from './CallTimeline';
import { VideoProvider } from './VideoPlayer/videoPlayer.context';
import { TranscriptProvider } from './Tabs/Transcript/transcript.context';
import { extendCallsInsightDataObject } from './helpers';
import CallInvite from './HeaderDetails/CallInvite';
import { ActionItemsProvider } from './Tabs/ActionItems/actionItems.context';
import { CommentsProvider } from './Tabs/Comments/comments.context';
import { useAIChat } from 'contexts/AIChatContext';
import { getFeatureFlags } from 'selectors';
import { IconTimelineEventX } from '@tabler/icons-react';

type Props = RouteComponentProps<{
  id: string;
}>;

const CallDetails: React.FC<Props> = ({ match }) => {
  const dispatch = useDispatch();
  const { setPartition, clearContext, setRenderExtraTabs } = useHeader();

  const [activeTab, setActiveTab] = useState(0);

  const previousRoute = useSelector(selectors.getPreviousRoute);

  useEffect(() => {
    setPartition('calls');

    return clearContext;
  }, []);

  const { setEnabled, setPageContext } = useAIChat();
  const { chatbot_calls_enabled } = useSelector((state) =>
    getFeatureFlags(state)
  );

  useEffect(() => {
    if (chatbot_calls_enabled) {
      setPageContext({
        object_type: 'call',
        object_id: match.params.id,
        suggestedActions: AI_CHAT_ACTIONS.CALL,
      });

      return () => {
        setEnabled(false);
      };
    }
  }, [match.params.id, setEnabled, chatbot_calls_enabled]);

  const {
    isFetching: isCallInsightsFetching,
    data: callInsightsData,
    error: callInsightsError,
  } = useQuery({
    queryKey: ['get_call_insights_data', match.params.id],
    retry: false,
    queryFn: async () =>
      extendCallsInsightDataObject(
        await getCallInsightsData({ callId: match.params.id })
      ),
  });

  const handleGoBack = () => dispatch(goBackOr(goToCalls, previousRoute));

  useEffect(() => {
    setRenderExtraTabs(
      <TabTitle
        isActive
        scheme="/transcript/:id"
        params={{ id: match.params.id }}
        title="Call Transcript"
        titleLength={120}
      />
    );
  }, [match.params.id]);

  return (
    <VideoProvider>
      <main className={styles.containerWrapper}>
        <header className={styles.header}>
          <BuButton
            className="back-button"
            borderless
            secondary
            onClick={handleGoBack}
          >
            <BuIcon name={BoostUpIcons.ArrowLeft} />
          </BuButton>

          {!isCallInsightsFetching && !!callInsightsData && (
            <>
              <div className={styles.divider} />
              <div className="header-info-wrapper">
                <HeaderDetails
                  attendees={callInsightsData.attendees}
                  participants={callInsightsData.meeting_participants}
                  speakerContactMap={callInsightsData.speaker_contact_map}
                  title={callInsightsData.title}
                  duration={callInsightsData.duration}
                  start={callInsightsData.start}
                  description={callInsightsData.description}
                  speakerPercentages={callInsightsData.speaker_percentages}
                  isCallSummarized={callInsightsData.is_call_summarized}
                  callId={match.params.id}
                  shareId={callInsightsData.share_id}
                  externalCallUrl={callInsightsData.external_call_url}
                />
              </div>
            </>
          )}
        </header>

        {!isCallInsightsFetching && !!callInsightsData && (
          <section
            className={classNames(styles.content, {
              error: callInsightsError && !callInsightsData,
            })}
          >
            <div>
              <VideoPlayer
                isCallSummarized={callInsightsData.is_call_summarized}
                videoUrl={callInsightsData.media_url}
                status={callInsightsData.status}
                start={callInsightsData.start}
              />

              <CallTimeline
                participants={callInsightsData.meeting_participants}
                segments={callInsightsData.segments}
                duration={callInsightsData.duration}
                isCallSummarized={callInsightsData.is_call_summarized}
              />
            </div>

            {callInsightsData.is_call_summarized ? (
              <div className={styles.callSummaryInsightActionItemsTranscript}>
                <TranscriptProvider>
                  <ActionItemsProvider callId={match.params.id}>
                    <CommentsProvider callId={match.params.id}>
                      <Tab
                        menu={{ secondary: true, pointing: true }}
                        activeIndex={activeTab}
                        onTabChange={(e, { activeIndex }) =>
                          setActiveTab(Number(activeIndex) ?? 0)
                        }
                        panes={[
                          {
                            menuItem: 'Summary',
                            render: () => (
                              <SummaryTab
                                accountExecutive={
                                  callInsightsData.account_executive
                                }
                                accountName={callInsightsData.account_name}
                                accountId={callInsightsData.account_id}
                                opportunityName={
                                  callInsightsData.opportunity_name
                                }
                                opportunityId={callInsightsData.opportunity_id}
                                summary={callInsightsData.call_summary_data}
                                segments={callInsightsData.segments}
                                setActiveTab={setActiveTab}
                              />
                            ),
                          },
                          {
                            menuItem: 'Insights',
                            render: () => (
                              <InsightTab
                                accountExecutive={
                                  callInsightsData.account_executive
                                }
                                accountName={callInsightsData.account_name}
                                accountId={callInsightsData.account_id}
                                opportunityName={
                                  callInsightsData.opportunity_name
                                }
                                opportunityId={callInsightsData.opportunity_id}
                                positiveMentions={
                                  callInsightsData.mentions.positive_mentions
                                }
                                negativeMentions={
                                  callInsightsData.mentions.negative_mentions
                                }
                                topics={callInsightsData.topics_discussed}
                                competitors={
                                  callInsightsData.competitors_discussed
                                }
                                segments={callInsightsData.segments}
                              />
                            ),
                          },
                          {
                            menuItem: 'Action Items',
                            render: () => (
                              <ActionItemsTab callId={match.params.id} />
                            ),
                          },
                          {
                            menuItem: 'Transcript',
                            render: () => (
                              <TranscriptTab
                                segments={callInsightsData.segments}
                                participants={
                                  callInsightsData.meeting_participants
                                }
                              />
                            ),
                          },
                          {
                            menuItem: 'Comments',
                            render: () => <CommentsTab />,
                          },
                        ]}
                      />
                    </CommentsProvider>
                  </ActionItemsProvider>
                </TranscriptProvider>
              </div>
            ) : (
              <div className={styles.callInviteWrapper}>
                <div className={styles.tabContentWrapper}>
                  <CallInvite
                    attendees={callInsightsData.attendees}
                    description={callInsightsData.description}
                  />
                </div>
              </div>
            )}
          </section>
        )}

        {isCallInsightsFetching && <Loader active />}

        {callInsightsError && !callInsightsData && (
          <div className={styles.requestErrorWrapper}>
            <div className="error-message">
              <BuIcon
                name={IconTimelineEventX}
                color="var(--bu-red-500)"
                size={48}
              />
              <span className="error-message-title">
                We cannot find the call recording
              </span>
              <span className="error-message-description">
                Please try again in a few minutes
              </span>
            </div>
          </div>
        )}
      </main>
    </VideoProvider>
  );
};

export default CallDetails;
