import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Scheme } from './types';

import WrappedComponent from 'components/UI/WrappedComponent';
import { ROUTES } from 'navigation/routes';
import { IReduxState } from 'reducers/types';
import { getDefaultLanding } from 'selectors';
import { getHiddenPages } from 'selectors/route';
import { Switch } from './Switch';
import { useSchemesForRouting } from './utils';
import PublicWrappedComponent from 'components/UI/PublicWrappedComponent';

const RootRedirect: React.FC = () => {
  const defaultLanding: Scheme | undefined = useSelector(getDefaultLanding);
  if (!defaultLanding) {
    return null;
  }

  return <Redirect to={defaultLanding} />;
};

const RoutesSwitch: React.FC = () => {
  const hiddenPages = useSelector<IReduxState, Scheme[]>(getHiddenPages);

  const schemesForRouting = useSchemesForRouting();

  return (
    <Switch>
      <Route exact path="/" render={() => <RootRedirect />} />

      <Route
        exact
        path="/forecast"
        render={() => <Redirect to="/opportunities" />}
      />
      {hiddenPages
        .filter((path) => ROUTES[path])
        .map((path) => {
          const { exact, strict } = ROUTES[path];

          return (
            <Route
              key={path}
              exact={exact}
              strict={strict}
              path={path}
              render={() => <RootRedirect />}
            />
          );
        })}
      {schemesForRouting.map((path) => {
        const { exact, strict, wrapped, component, publicRoute, noHeader } =
          ROUTES[path];

        const commonProps = {
          key: path,
          path,
          exact,
          strict,
        };

        const WrapperComponent = publicRoute
          ? PublicWrappedComponent
          : WrappedComponent;

        return wrapped ? (
          <Route
            {...commonProps}
            render={(props) => (
              <WrapperComponent
                component={component}
                {...props}
                noHeader={noHeader}
              />
            )}
          />
        ) : (
          <Route {...commonProps} component={component} />
        );
      })}

      {/* Redirect to default landing when unknown url */}
      <Route render={() => <RootRedirect />} />
    </Switch>
  );
};

export default RoutesSwitch;
