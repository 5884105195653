import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCallsActiveTab } from 'selectors/calls';
import CallsListing from './CallsListing';

const Calls = () => {
  const { setPartition, clearContext } = useHeader();
  useEffect(() => {
    setPartition('calls');
    return () => {
      clearContext();
    };
  }, []);

  const activeTab = useSelector(getCallsActiveTab);
  return <CallsListing upcoming={activeTab.key !== 'completedCalls'} />;
};

export default Calls;
