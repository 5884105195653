import { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Call, getCallsList } from 'api/Calls';
import { skipCall } from 'api/CallInsights';
import { TABLE_ID } from 'common/constants';
import Table from 'components/UI/TableConfig/Table';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import useTableSort from 'components/hooks/useTableSort';
import { getCallsActiveTab, getUser } from 'selectors';
import { getSubmissionFilters } from 'selectors/settings';
import { getFiltersForAPI } from 'selectors';
import { IReduxState } from 'reducers/types';

import * as styles from './styles';
import { getColumns } from './utils';

interface Props {
  upcoming: boolean;
}

export const CallsListing = ({ upcoming }: Props) => {
  const queryClient = useQueryClient();
  const [sortByField, setSortByField] = useTableSort<Call>();
  const activeTab = useSelector(getCallsActiveTab);
  const { role, email } = useSelector(getUser);
  const { loaded: filtersLoaded } = useSelector(getSubmissionFilters);
  const history = useHistory();
  const location = useLocation();

  // Use storage hook to persist page number for this specific tab
  const storageKey = `calls_listing_page_${
    upcoming ? 'upcoming' : 'completed'
  }`;
  const [savedPageNumber, setSavedPageNumber] = useLocalStorage(storageKey, 1);

  const [pageNumber, setPageNumber] = useState(savedPageNumber);
  const [pageSize, setPageSize] = useState(50);
  const [searchText, setSearchText] = useState('');
  const [setByUrl, setSetByUrl] = useState(false);
  const [showSkipCallConfirmation, setShowSkipCallConfirmation] =
    useState(false);
  const [selectedCallId, setSelectedCallId] = useState<string | null>(null);
  const [selectedCallStatus, setSelectedCallStatus] = useState<string | null>(
    null
  );
  const previousTotalCountRef = useRef<number | undefined>(undefined);
  const filters = useSelector((state: IReduxState) =>
    getFiltersForAPI(state, activeTab.filtersTab)
  );

  const payload = {
    ...filters,
    page_number: pageNumber - 1,
    page_size: pageSize,
    time_min: upcoming ? new Date() : null,
    time_max: upcoming ? null : new Date(),
    // sort_criterion: sortByField, // should use this once API supports it
    sort_criterion: upcoming ? 'start' : '-start',
    search: searchText.trim() || undefined,
  };

  const key = upcoming ? 'upcoming_calls' : 'completed_calls';

  const {
    data: callsData,
    isFetching: callsLoading,
    refetch: refetchCalls,
  } = useQuery({
    queryKey: ['calls-listing', key],
    retry: false,
    queryFn: ({ signal }) => getCallsList(payload, signal),
    enabled: filtersLoaded && setByUrl,
  });

  const handleClickSkipCall = (id: string, status: string) => {
    setShowSkipCallConfirmation(true);
    setSelectedCallId(id);
    setSelectedCallStatus(status);
  };

  const isEnablingCallRecording = selectedCallStatus === 'skipped';

  const skipCallMutation = useMutation({
    mutationFn: () =>
      skipCall({
        callId: selectedCallId as string,
        shouldRecord: isEnablingCallRecording,
      }),
    onMutate: () => {
      setShowSkipCallConfirmation(false);
      toast.warn(
        `${
          isEnablingCallRecording ? 'Enabling' : 'Disabling'
        } call recording...`,
        {
          position: 'bottom-left',
        }
      );
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.status === 401) {
        toast.error(
          `You are not authorized to ${
            isEnablingCallRecording ? 'enable' : 'disable'
          } call recording`,
          {
            position: 'bottom-left',
          }
        );
      } else {
        toast.error(
          `Error ${
            isEnablingCallRecording ? 'enabling' : 'disabling'
          } call recording`,
          {
            position: 'bottom-left',
          }
        );
      }
    },
    onSuccess: () => {
      queryClient.setQueryData(['calls-listing', key], (prevData: any) => {
        return {
          ...prevData,
          data: {
            ...prevData.data,
            calls: prevData.data.calls.map((call: Call) =>
              call.id === selectedCallId
                ? {
                    ...call,
                    status: isEnablingCallRecording ? 'scheduled' : 'skipped',
                  }
                : call
            ),
          },
        };
      });

      toast.success(
        `Call recording ${
          isEnablingCallRecording ? 'enabled' : 'disabled'
        } successfully`,
        { position: 'bottom-left' }
      );
    },
  });

  // Removed the refetching from the 'queryKey' because it was failing to use 'queryClient.setQueryData'
  useEffect(() => {
    refetchCalls();
  }, [key, JSON.stringify({ ...payload, time_min: null, time_max: null })]);

  // Use the previous total count if we're loading and don't have a current total count
  const totalCount = useMemo(() => {
    return callsLoading && callsData?.data?.total_count === undefined
      ? previousTotalCountRef.current
      : callsData?.data?.total_count;
  }, [callsLoading, callsData?.data?.total_count]);

  // Store the previous total count when we have valid data
  useEffect(() => {
    if (callsData?.data?.total_count !== undefined) {
      previousTotalCountRef.current = callsData.data.total_count;
    }
  }, [callsData?.data?.total_count]);

  // Applying search and page number from url
  useEffect(() => {
    if (!setByUrl) {
      const params = new URLSearchParams(location.search);
      const urlPageNumber =
        Number(params.get('page_number')) || savedPageNumber;
      const urlSearchText = params.get('search') || '';

      setSearchText(urlSearchText);
      setPageNumber(urlPageNumber);
      setSetByUrl(true);
    }
  }, [location.search, setByUrl, savedPageNumber]);

  // Update the saved page number whenever it changes
  useEffect(() => {
    if (setByUrl) {
      setSavedPageNumber(pageNumber);
    }
  }, [pageNumber, setSavedPageNumber, setByUrl]);

  useEffect(() => {
    if (totalCount && totalCount / pageSize < pageNumber - 1) {
      setPageNumber(1);
      handleUrlChange(1);
    }
  }, [totalCount]);

  const handleUrlChange = (pageNumberParam?: number) => {
    const _pageNumber = pageNumberParam || pageNumber;
    const searchStr = searchText ? `&search=${searchText}` : '';
    const currentPath = `${location.pathname}?page_number=${_pageNumber}${searchStr}`;

    // Only update if the URL would actually change
    if (location.search !== `?page_number=${_pageNumber}${searchStr}`) {
      history.push(currentPath);
    }
  };

  // Storing search and page number in url - only when they change by user action, not during initial URL parsing
  useEffect(() => {
    // Only update URL when values have been initialized from the URL first
    if (setByUrl) {
      handleUrlChange();
    }
  }, [
    pageNumber,
    searchText,
    setByUrl,
    history,
    location.pathname,
    location.search,
  ]);

  if (!filtersLoaded) {
    return (
      <div className={styles.loader}>
        <Loader active inline="centered" />
      </div>
    );
  }

  const onPaginationChange = (pageNumber: number, pageSize: number) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    handleUrlChange(pageNumber);
    setSavedPageNumber(pageNumber);
  };

  const onSearchChange = (searchText: string) => {
    setSearchText(searchText);
    setPageNumber(1);
  };

  const onChangeFilter = () => {
    setPageNumber(1);
  };

  const columns = getColumns(
    upcoming,
    role as string,
    email as string,
    handleClickSkipCall
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <OpenFiltersPanel
          tab={activeTab.filtersTab}
          onChangeFilter={onChangeFilter}
        />
      </div>

      <div className={styles.callsListing}>
        <Table
          tableId={TABLE_ID.CALLS}
          title={upcoming ? 'Calls' : 'Completed Calls'}
          data={callsData?.data?.calls as Call[]}
          columns={columns}
          loading={callsLoading}
          rowsPerPage={pageSize}
          currentPage={pageNumber}
          totalCount={totalCount}
          onPaginationChange={onPaginationChange}
          onSearchChange={onSearchChange}
          onSort={setSortByField}
          sortOrder={sortByField}
          searchPlaceholder="Search by name, opportunity or organizer"
          defaultSearchText={searchText}
          dataType="calls"
          fullscreen
          hidePaginationEnd
        />
      </div>

      <BuConfirmationPopup
        isOpen={showSkipCallConfirmation}
        onClose={() => setShowSkipCallConfirmation(false)}
        onConfirm={() => skipCallMutation.mutate()}
        cancelText="Cancel"
        confirmText={`${
          selectedCallStatus === 'skipped' ? 'Enable' : 'Disable'
        }`}
        headerText={`${
          selectedCallStatus === 'skipped'
            ? 'Enable call recording?'
            : 'Disable call recording?'
        }`}
      >
        {selectedCallStatus === 'skipped' ? (
          <>
            When the call recording is enabled, BoostUp will record the call. Do
            you wish to continue?
          </>
        ) : (
          <>
            When the call recording is disabled, BoostUp will not record the
            call. Do you wish to continue?
          </>
        )}
      </BuConfirmationPopup>
    </div>
  );
};

export default CallsListing;
