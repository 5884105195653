import FILTERS from 'common/filters';

export default {
  count: 0,
  totalAmount: 0,
  tabs: {
    upcomingCalls: {
      key: 'upcomingCalls',
      title: 'Upcoming Calls',
      active: false,
      filters: [
        {
          data: FILTERS.UPCOMING_DATES,
          value: ['__all__'],
        },
        {
          data: FILTERS.USERS,
          value: ['__all__'],
        },
        {
          data: FILTERS.OPPORTUNITY_STAGES,
          value: ['__all__'],
        },
        {
          data: FILTERS.FORECAST_CATEGORY_NAMES,
          value: ['__all__'],
        },
      ],
      request: {
        sort_criterion: 'start',
        page_number: 1,
        page_size: 50,
      },
      calls: [],
      count: 0,
    },
    completedCalls: {
      key: 'completedCalls',
      title: 'Completed Calls',
      active: true,
      filters: [
        {
          data: FILTERS.USERS,
          value: ['__all__'],
        },
        {
          data: FILTERS.TOPICS,
          value: ['__all__'],
        },
        {
          data: FILTERS.CALL_DURATION,
          value: ['__all__'],
        },
        {
          data: FILTERS.COMPLETED_DATES,
          value: ['__all__'],
        },
        {
          data: FILTERS.OPPORTUNITY_STAGES,
          value: ['__all__'],
        },
        {
          data: FILTERS.NO_SHOW,
          value: ['__all__'],
        },
        {
          data: FILTERS.FORECAST_CATEGORY_NAMES,
          value: ['__all__'],
        },
      ],
      request: {
        sort_criterion: '-start',
        page_number: 1,
        page_size: 50,
      },
      calls: [],
      count: 0,
      scroll: 0,
    },
    upcomingEvents: {
      key: 'allUpcomingEvents',
      title: 'All Upcoming Events',
      active: false,
      adminOnly: true,
      filters: [
        {
          data: FILTERS.UPCOMING_DATES,
          value: ['__all__'],
        },
        {
          data: FILTERS.USERS,
          value: ['__all__'],
        },
      ],
      request: {
        sort_criterion: 'start',
        page_number: 1,
        page_size: 50,
      },
      calls: [],
      count: 0,
    },
    pastEvents: {
      key: 'allEvents',
      title: 'All Past Events',
      active: false,
      adminOnly: true,
      filters: [
        {
          data: FILTERS.USERS,
          value: ['__all__'],
        },
      ],
      request: {
        sort_criterion: '-start',
      },
      calls: [],
      count: 0,
    },
  },
  getCallsStatus: 'notAsked',
  paginationStatus: 'noAsked',
  modal: null,
};
