import React, { useMemo } from 'react';

import checklistPlaceholder from 'assets/images/checklist_placeholder.png';
import developerPlaceholder from 'assets/images/developer_placeholder.png';
import funnelPlaceholder from 'assets/images/funnel_placeholder.png';
import pieChartPlaceholder from 'assets/images/pie_chart_placeholder.png';
import {
  containerBordered,
  titleContainer,
} from 'components/dashboard/Metrics/Create/WidgetPreviewPlaceholder/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';

type Props = {
  analysisType?: AnalysisType;
};

export const WidgetPreviewPlaceholder: React.FC<Props> = ({ analysisType }) => {
  const getPreviewImage = (analysisType?: AnalysisType): string => {
    switch (analysisType) {
      case AnalysisType.FUNNEL:
        return funnelPlaceholder;
      case AnalysisType.HISTORICAL:
        return pieChartPlaceholder;
      case AnalysisType.LIVE:
        return developerPlaceholder;
      case AnalysisType.REPORT:
        return checklistPlaceholder;
      default:
        return developerPlaceholder;
    }
  };

  const getTitle = (analysisType?: AnalysisType): string => {
    switch (analysisType) {
      case AnalysisType.FUNNEL:
        return 'Funnel definition';
      case AnalysisType.HISTORICAL:
        return 'Create a historical analysis';
      case AnalysisType.LIVE:
        return 'Metrics with live data';
      case AnalysisType.REPORT:
        return 'Create a custom report';
      default:
        return 'What metric do you want to create?';
    }
  };

  const getMessage = (analysisType?: AnalysisType): string => {
    switch (analysisType) {
      case AnalysisType.FUNNEL:
        return 'Define the stages of your funnel that you want to analyze and their order';
      case AnalysisType.HISTORICAL:
        return 'Setup a metric with a point-in-time snapshot to compare changes over time';
      case AnalysisType.LIVE:
        return 'Analyze metrics over pivots of any field using live data';
      case AnalysisType.REPORT:
        return 'Choose the object, conditions and settings to filter and order the desired list of items';
      default:
        return 'Build your metric and check it out in the widget preview';
    }
  };

  const [previewImage, title, message] = useMemo(() => {
    const image = getPreviewImage(analysisType);

    const title = getTitle(analysisType);

    const message = getMessage(analysisType);

    return [image, title, message];
  }, [analysisType]);

  return (
    <div className={containerBordered} data-testing="widget-preview-section">
      <img src={previewImage} width="250px" />
      <div className={titleContainer}>{title}</div>
      {message}
    </div>
  );
};
