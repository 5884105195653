import { css } from 'emotion';

import { fontBody, fontCaption2 } from 'assets/css/fontStyles';

export const container = css`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
`;

export const filterBarElement = css`
  > i {
    font-size: 20px;
  }
`;

export const pivotFiltersContainer = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;

  > .secondary-background {
    margin-right: 8px;
  }

  .bu-dropdown {
    max-width: calc((100vw - 150px) / 6);
    min-width: auto;
  }

  .bu-btn-content {
    div {
      max-width: fit-content;
    }
  }
`;

const BASE_Z_INDEX = 999;
export const pivotZIndex = (pivotPosition: number) => css`
  z-index: ${BASE_Z_INDEX - pivotPosition};
`;

export const pivotWidget = css`
  border: 1px solid var(--bu-table-border-color);
  height: 32px;
  margin-right: 8px;
  border-radius: 4px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-width: 80px !important;
  width: unset !important;
  z-index: 989;
  text-transform: capitalize;
`;

export const pivotWidgetMenu = css`
  display: flex;
  flex-direction: column;
  max-height: 380px;
  overflow-y: scroll;
  margin-top: 4px !important;
`;

export const pivotWidgetSearch = css`
  display: flex;
  flex-direction: row;
  margin: 8px;
  background-color: var(--bu-gray-200);
  padding: 6px;
  border-radius: 4px;
  input {
    border: none;
    outline: none;
    background-color: var(--bu-gray-200);
  }
`;

export const pivotWidgetSearchOption = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 20px;
  width: 100%;
  height: 32px;
  ${fontBody}
  text-transform: capitalize;
  &:hover {
    background-color: var(--bu-primary-200);
    cursor: pointer;
  }
  &.selected {
    background-color: var(--bu-primary-200);
  }
`;

export const pivot2WidgetSeparator = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  padding: 6px 12px;
  width: 100%;
  height: 32px;
  border-top: 1px solid var(--bu-gray-300);
  ${fontCaption2}
`;

export const pivotTitleSeparator = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${fontCaption2}
  text-transform: capitalize;
`;

export const pivotTitleWrapper = css`
  background-color: var(--bu-gray-200);
  border-top: 1px solid var(--bu-gray-300);
  border-bottom: 1px solid var(--bu-gray-300);
  display: flex;
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 32px;
  margin-top: 4px;
  padding: 6px 12px;
  pointer-events: none;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
`;
