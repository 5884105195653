import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { BuTag } from 'components/UI/BuTag';

import * as styles from './styles';
import { Participant, Segment } from '../types';
import RoundedAvatarIcon from '../RoundedAvatarIcon';
import { useVideoContext } from '../VideoPlayer/videoPlayer.context';
import {
  getSpeakingTimeRanges,
  getVideoTimeAsPercentage,
  SPEAKER_SENTIMENT_ICONS,
} from '../helpers';

type Props = {
  participants: Participant[];
  segments: Segment[];
  duration: number;
  isCallSummarized: boolean;
};

const MINIMUM_PARTICIPANTS_TO_SHOW = 4;

const CallTimeline: React.FC<Props> = ({
  participants,
  segments,
  duration: dataDuration,
  isCallSummarized,
}) => {
  const {
    currentTime,
    duration: videoDuration,
    highlightedSections,
    videoError,
    isVideoErrorOrLoading,
    setTime,
  } = useVideoContext();

  const [
    isShowingMoreParticipantsOnTimeline,
    setIsShowingMoreParticipantsOnTimeline,
  ] = useState(false);

  const handleShowMoreParticipantsOnTimeline = () =>
    setIsShowingMoreParticipantsOnTimeline((prev) => !prev);

  const participantsSortedBySpeakerPercentage = useMemo(
    () =>
      participants
        .slice()
        .sort((a, b) => b.speaker_percentage - a.speaker_percentage),
    [participants]
  );

  const participantsToShow = useMemo(
    () =>
      isShowingMoreParticipantsOnTimeline ||
      participantsSortedBySpeakerPercentage.length <
        MINIMUM_PARTICIPANTS_TO_SHOW
        ? participantsSortedBySpeakerPercentage
        : participantsSortedBySpeakerPercentage.slice(
            0,
            MINIMUM_PARTICIPANTS_TO_SHOW
          ),
    [isShowingMoreParticipantsOnTimeline, participantsSortedBySpeakerPercentage]
  );

  // TODO: Will have this in the future
  // const renderRoleCompany = (role: string, company: string) => {
  //   if (!role && !company) return null;

  //   const roleText = role ? role : '';
  //   const companyText = company ? company : '';

  //   const separator = role && company ? ', ' : '';

  //   return (
  //     <span className="participant-role-company">
  //       {roleText}
  //       {separator}
  //       {companyText}
  //     </span>
  //   );
  // };

  const handleClickSpeakingTimeRange = (startTime: number) => {
    if (isVideoErrorOrLoading) return;
    setTime(startTime, true);
  };

  const speakingTimeRanges = useMemo(
    () => getSpeakingTimeRanges(segments),
    [segments]
  );

  const getSpeakerTimeRanges = useCallback(
    (participant: Participant) =>
      speakingTimeRanges.find(({ speaker }) => participant.name === speaker)
        ?.time_ranges,
    [speakingTimeRanges]
  );

  const duration = useMemo(
    () => videoDuration || dataDuration,
    [videoDuration]
  );

  const speakerSentimentTooltip = (sentimentJustification: string) => (
    <div className={styles.participantSentimentTooltipWrapper}>
      <div className="speaker-sentiment-title">Sentiment description</div>

      <div className="speaker-sentiment-justification">
        <span>{sentimentJustification}</span>
      </div>
    </div>
  );

  if (!isCallSummarized) return null;

  return (
    <div className={styles.callTimelineWrapper}>
      <div className="call-timeline">
        {!videoError && (
          <div
            className="call-timeline-progress"
            style={{
              width: `${getVideoTimeAsPercentage(currentTime, duration)}%`,
            }}
          />
        )}

        {highlightedSections.map((highlightedSection) => (
          <div
            className={classNames('call-timeline-highlight-section', {
              disabled: isVideoErrorOrLoading,
            })}
            style={{
              left: `${getVideoTimeAsPercentage(
                highlightedSection[0],
                duration
              )}%`,
              width: `${getVideoTimeAsPercentage(
                highlightedSection[1] - highlightedSection[0],
                duration
              )}%`,
              minWidth: '0.5%',
            }}
            key={highlightedSection[0]}
            onClick={() => handleClickSpeakingTimeRange(highlightedSection[0])}
          />
        ))}

        {participantsToShow.map((participant) => (
          <div className="call-timeline-participant" key={participant.name}>
            <div className="participant">
              <RoundedAvatarIcon color={participant.color} />

              <div className="participant-info">
                <div className="participant-info-left">
                  <span className="participant-name">{participant.name}</span>

                  {/* TODO: Will have this in the future */}
                  {/* {renderRoleCompany(participant.role, participant.company)} */}

                  {!!participant.sentiment && (
                    <TooltipWrapper
                      tooltip={speakerSentimentTooltip(
                        participant.sentiment.sentiment_justification
                      )}
                      hoverable
                      position="bottom left"
                      popupClassName={styles.removeTooltipBefore}
                    >
                      <div>
                        <BuTag
                          className="speaker-sentiment-tag"
                          label={participant.sentiment.sentiment}
                          sentiment={participant.sentiment.sentiment}
                          prefixIcon={
                            <BuIcon
                              name={
                                SPEAKER_SENTIMENT_ICONS[
                                  participant.sentiment.sentiment
                                ]
                              }
                            />
                          }
                        />
                      </div>
                    </TooltipWrapper>
                  )}
                </div>

                <div>
                  <span className="participant-percentage">
                    {participant.speaker_percentage
                      ? Math.round(participant.speaker_percentage)
                      : '0'}
                    %
                  </span>
                </div>
              </div>
            </div>

            <div className="video-time">
              {getSpeakerTimeRanges(participant)?.map((timeRange) => (
                <div
                  className={classNames('video-participant-speaking', {
                    disabled: isVideoErrorOrLoading,
                  })}
                  style={{
                    left: `calc(${getVideoTimeAsPercentage(
                      timeRange[0],
                      duration
                    )}% - 0px)`,
                    width: `${getVideoTimeAsPercentage(
                      timeRange[1] - timeRange[0],
                      duration
                    )}%`,
                    backgroundColor: participant.color,
                  }}
                  key={timeRange[0]}
                  onClick={() => handleClickSpeakingTimeRange(timeRange[0])}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {participantsSortedBySpeakerPercentage.length >
        MINIMUM_PARTICIPANTS_TO_SHOW && (
        <BuButton
          onClick={handleShowMoreParticipantsOnTimeline}
          size={BuControlSize.REGULAR}
          borderless
          className="call-timeline-show-more"
          dropdown={isShowingMoreParticipantsOnTimeline ? 'open' : 'close'}
        >
          <span>
            Show {isShowingMoreParticipantsOnTimeline ? 'less' : 'more'}
          </span>
        </BuButton>
      )}
    </div>
  );
};

export default CallTimeline;
