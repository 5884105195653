import { css } from 'emotion';

import {
  fontBody,
  fontCaption1,
  fontHeading3,
  fontSubheading2,
} from 'assets/css/fontStyles';

import { cardStyle } from '../../styles';

const markStyle = css`
  font-family: var(--bu-font-medium);
  background-color: var(--bu-yellow-200);
  color: var(--bu-gray-900);
  padding: 2px 4px;
  border-radius: var(--bu-control-border-radius);
`;

const saveActionStyle = css`
  margin: 0;
  padding: 0 16px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:disabled:not(.bu-secondary) {
    background-color: var(--bu-primary-500);
  }
`;

const editorStyle = css`
  min-height: 72px !important;
  max-height: 144px !important;
  overflow-y: auto;
  border-radius: var(--bu-control-border-radius);
  border-width: 1px;
  padding: 12px 16px;
  border: 1px solid var(--bu-gray-400);
  background-color: var(--bu-white);

  &:focus {
    border: 1px solid var(--bu-color-buying_team_email);
    outline: none;
  }

  mark {
    ${markStyle}
  }
`;

export const commentsTabWrapper = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);
  height: 100%;
  padding: 12px 0;
  position: relative;

  .comments-tab-input-wrapper {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-medium);

    .comments-tab-input {
      ${editorStyle}

      &[data-slate-editor="true"][contenteditable="false"] {
        background-color: var(--bu-gray-100);
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    .comments-tab-input-actions {
      display: flex;
      justify-content: flex-end;

      .comments-tab-send-button {
        ${saveActionStyle}
        min-width: 120px;
      }
    }
  }

  .comments-tab-comments-wrapper {
    padding: 0 16px 10px;
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-small);
    margin-top: 16px;
    overflow-y: auto;

    .comments-tab-comments-placeholder {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-medium);
      align-items: center;
      text-align: center;

      .comments-tab-comments-placeholder-title {
        ${fontHeading3}
      }

      .comments-tab-comments-placeholder-description {
        ${fontBody}
        color: var(--bu-gray-700);
      }
    }

    .comments-tab-comments-list {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-medium);

      .comments-tab-comment-item {
        ${cardStyle}
        display: flex;
        border-radius: var(--bu-control-border-radius);
        gap: var(--bu-control-gap-medium);
        position: relative;
        flex-direction: column;

        &:not(.not-clickable):hover {
          background-color: var(--bu-gray-100);
          cursor: pointer;
        }

        &.allowed-to-change:not(.is-editing-mode):hover {
          .comments-tab-comment-item-delete,
          .comments-tab-comment-item-edit {
            display: block !important;
          }
        }

        .comments-tab-comment-item-header {
          display: flex;
          gap: var(--bu-control-gap-medium);
          justify-content: space-between;

          > div {
            display: flex;
            gap: var(--bu-control-gap-medium);
          }

          .UserAvatar--inner {
            color: var(--bu-white);
          }

          .comments-tab-comment-author-date {
            display: flex;
            flex-direction: column;

            .comments-tab-comment-author {
              ${fontHeading3}
            }

            .comments-tab-comment-date {
              ${fontCaption1}
              color: var(--bu-gray-700);
            }
          }

          .comments-tab-comment-item-actions {
            display: flex;
            gap: var(--bu-control-gap-large);

            a,
            span {
              height: min-content;
            }

            .comments-tab-comment-item-delete,
            .comments-tab-comment-item-edit {
              cursor: pointer;
              display: none;

              &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
                pointer-events: none;
              }

              i {
                font-size: 16px;
                color: var(--bu-gray-800);
              }
            }

            .comments-tab-comment-item-delete {
              i {
                color: var(--bu-red-600);
              }
            }

            .comments-tab-comment-item-start-time-text {
              margin-top: 2px;
              color: var(--bu-gray-700);
            }
          }
        }

        .comments-tab-comment-item-text {
          ${fontBody}
          padding-left: 40px;

          mark {
            ${markStyle}
          }
        }

        .comments-tab-comment-item-editor-wrapper {
          display: flex;
          gap: var(--bu-control-gap-medium);
          flex-direction: column;
          justify-content: flex-end;

          .comments-tab-comment-item-editor {
            ${editorStyle}
          }

          .comments-tab-comment-item-editor-actions {
            display: flex;
            gap: var(--bu-control-gap-medium);
            justify-content: flex-end;

            .comments-tab-comment-item-editor-save-button,
            .comments-tab-comment-item-editor-cancel-button {
              ${saveActionStyle}
            }
          }
        }
      }
    }
  }
`;
