import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps,
  Size,
} from 'react-virtualized';

import BuToggle from 'components/UI/BuToggle';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

import * as styles from './styles';
import SegmentCard from './SegmentCard';
import TranscriptSearch from './TranscriptSearch';
import { useTranscriptContext } from './transcript.context';
import { Segment, Participant } from '../../types';
import {
  findNearestLowerStartTimeIndex,
  getTranscriptSearchText,
} from '../../helpers';
import { useVideoContext } from '../../VideoPlayer/videoPlayer.context';

type Props = {
  segments: Segment[];
  participants: Participant[];
};

const TranscriptTab: React.FC<Props> = ({ segments, participants }) => {
  const {
    scrollToIndex,
    isAutoscrollActive,
    setScrollToIndex,
    setIsAutoscrollActive,
    searchText,
    searchedIndexHighlighted,
    setSearchedIndexHighlighted,
    searchedSegments,
    setSearchedSegments,
    setIsSearchActive,
  } = useTranscriptContext();

  const {
    setTime,
    isVideoErrorOrLoading,
    currentTime,
    userIteractionWithVideoTime,
  } = useVideoContext();

  const [showTurnedAutoscrollOffMessage, setShowTurnedAutoscrollOffMessage] =
    useState(false);

  const isAutoscrollActiveRef = useRef(isAutoscrollActive);
  const listRef = useRef<List>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const segmentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const cellMeasurerCache = useRef(
    new CellMeasurerCache({
      defaultHeight: 100,
      fixedWidth: true,
    })
  );

  const segmentsStartTime = useMemo(
    () => segments.map((segment) => segment.start_time),
    [segments]
  );

  const handleClickSegmentCard = (index: number) => {
    if (isVideoErrorOrLoading) return;
    setTime(segmentsStartTime[index], true);
  };

  const participantsColors = useMemo(
    () =>
      participants.reduce((acc, participant) => {
        acc[participant.name] = participant.color;
        return acc;
      }, {} as Record<string, string>),
    [participants]
  );

  const scrollIntoSegmentCard = useCallback(
    (
      elementId: number,
      shouldHighlight?: boolean,
      forceScrollToIndex?: boolean
    ) => {
      const segmentCardElem = segmentRefs.current[elementId];
      const container = containerRef.current;
      const transcriptListContainer = container?.querySelectorAll(
        '.ReactVirtualized__List'
      )[0];

      if (shouldHighlight) {
        container
          ?.querySelectorAll('.segment-card.active')[0]
          ?.classList.remove('active');
      }

      if (segmentCardElem && container) {
        if (shouldHighlight) {
          segmentCardElem.classList.add('active');
        }

        if (
          (isAutoscrollActive || forceScrollToIndex) &&
          transcriptListContainer
        ) {
          const containerTop =
            transcriptListContainer.getBoundingClientRect().top;
          const elementTop = segmentCardElem.getBoundingClientRect().top;
          const { scrollTop } = transcriptListContainer;
          const offset = elementTop - containerTop + scrollTop;

          transcriptListContainer.scrollTo({
            top: offset,
          });
        }
      }
    },
    [segmentRefs.current, containerRef.current, isAutoscrollActive]
  );

  const highlighSegment = (
    forceScrollToIndex?: boolean,
    shouldHighlight: boolean = true,
    time?: number
  ) => {
    const segmentCardIndex = findNearestLowerStartTimeIndex(
      segmentsStartTime,
      time || currentTime
    );

    if (!!segmentCardIndex) {
      // Immediately scrolling to have the elements rendered on the screen;
      if (isAutoscrollActive || forceScrollToIndex) {
        setScrollToIndex(segmentCardIndex);
      }

      // Deferring to ensure this will be called after the scroll to index;
      setTimeout(
        () =>
          scrollIntoSegmentCard(
            segmentCardIndex,
            shouldHighlight,
            forceScrollToIndex
          ),
        0
      );
    }
  };

  // Update selection upon access the tab;
  useEffect(() => highlighSegment(true), []);

  // Update selection upon user changes the video time;
  useEffect(() => highlighSegment(true), [userIteractionWithVideoTime]);

  // Update selection upon video time or autoscroll changes;
  useEffect(
    () => highlighSegment(),
    [currentTime, segmentsStartTime, isAutoscrollActive]
  );

  const triggerSearch = () => {
    if (!searchText.length) {
      setSearchedSegments([]);
      setIsSearchActive(false);
      return;
    }

    const _searchedSegments = getTranscriptSearchText(searchText, segments);

    setSearchedSegments(_searchedSegments);
    setIsSearchActive(true);

    if (_searchedSegments.length) {
      setSearchedIndexHighlighted(0);
      highlighSegment(
        true,
        false,
        _searchedSegments[0]?.start_time || currentTime
      );
      turnAutoscrollOffAndShowMessage();
    }
  };

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const segment = segments[index];

    return (
      <CellMeasurer
        key={key}
        cache={cellMeasurerCache.current}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
      >
        {({ measure }) => (
          <SegmentCard
            segment={segment}
            color={participantsColors[segment.speaker_name]}
            index={index}
            style={style}
            segmentRefs={segmentRefs}
            handleClickSegmentCard={handleClickSegmentCard}
            onLoad={measure}
          />
        )}
      </CellMeasurer>
    );
  };

  const handleAutoscrollChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoscrollActive(e.target.checked);
    setShowTurnedAutoscrollOffMessage(false);
  };

  const turnAutoscrollOffAndShowMessage = () => {
    if (isAutoscrollActiveRef.current) {
      setIsAutoscrollActive(false);
      setShowTurnedAutoscrollOffMessage(true);

      // Hide the message after 3 seconds
      setTimeout(() => {
        setShowTurnedAutoscrollOffMessage(false);
      }, 3000);
    }
  };

  useEffect(() => {
    isAutoscrollActiveRef.current = isAutoscrollActive;
  }, [isAutoscrollActive]);

  useEffect(() => {
    const scrollableElement =
      // @ts-ignore - Grid has _scrollingContainer
      listRef.current?.Grid?._scrollingContainer || null;

    if (scrollableElement) {
      const handleUserScrollByWheel = () => {
        turnAutoscrollOffAndShowMessage();
      };

      const handleUserScrollByClick = (
        event: React.MouseEvent<HTMLDivElement>
      ) => {
        const target = event.target as HTMLDivElement;
        const targetRect = target.getBoundingClientRect();
        const targetX = event.clientX - targetRect.left;
        const targetWidth = targetRect.width;
        const scrollbarWidth = target.offsetWidth - target.clientWidth;
        const isInsideVerticalScrollbar =
          targetX > targetWidth - scrollbarWidth;

        if (isInsideVerticalScrollbar) {
          turnAutoscrollOffAndShowMessage();
        }
      };

      scrollableElement.addEventListener('wheel', handleUserScrollByWheel);
      scrollableElement.addEventListener('mouseup', handleUserScrollByClick);

      return () => {
        scrollableElement.removeEventListener('wheel', handleUserScrollByWheel);
        scrollableElement.removeEventListener(
          'mouseup',
          handleUserScrollByClick
        );
      };
    }
  }, []);

  const handleClickSearchedSegment = (direction: 'next' | 'previous') => {
    if (searchedIndexHighlighted === 0 && direction === 'previous') return;
    if (
      searchedIndexHighlighted === searchedSegments.length - 1 &&
      direction === 'next'
    )
      return;
    if (isAutoscrollActive) turnAutoscrollOffAndShowMessage();

    const segmentIndex =
      searchedIndexHighlighted + (direction === 'next' ? 1 : -1);

    setSearchedIndexHighlighted(segmentIndex);
    highlighSegment(
      true,
      false,
      searchedSegments[segmentIndex]?.start_time || currentTime
    );
  };

  return (
    <div
      className={classNames(
        styles.tabContentWrapper,
        'transcript-content-wrapper',
        'no-scroll',
        {
          'no-padding-top': segments.length,
          'no-padding-right': segments.length,
        }
      )}
      ref={containerRef}
    >
      {segments.length ? (
        <>
          <div className={styles.transcriptTabHeader}>
            <div className="transcript-tab-header-actions">
              <TranscriptSearch
                triggerSearch={triggerSearch}
                handleClickSearchedSegment={handleClickSearchedSegment}
              />

              <BuToggle
                leftLabel
                checked={isAutoscrollActive}
                onChange={handleAutoscrollChange}
              >
                <TooltipWrapper
                  isOpen
                  tooltip="Autoscroll has been turned off"
                  position="bottom right"
                  popupClassName={classNames(
                    styles.autoscrollMessagePopupClass,
                    {
                      'fade-in': showTurnedAutoscrollOffMessage,
                      'fade-out': !showTurnedAutoscrollOffMessage,
                    }
                  )}
                >
                  <div>Autoscroll</div>
                </TooltipWrapper>
              </BuToggle>
            </div>
          </div>

          <div
            className={classNames(styles.transcriptTabWrapper, {
              disabled: isVideoErrorOrLoading,
            })}
          >
            <AutoSizer>
              {({ height, width }: Size) => (
                <List
                  ref={listRef}
                  width={width}
                  height={height}
                  deferredMeasurementCache={cellMeasurerCache.current}
                  rowCount={segments.length}
                  rowHeight={cellMeasurerCache.current.rowHeight}
                  scrollToIndex={scrollToIndex}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          </div>
        </>
      ) : (
        <div className={styles.noTranscriptAvailable}>
          <span className="not-available-title">No transcript available</span>
        </div>
      )}
    </div>
  );
};

export default TranscriptTab;
