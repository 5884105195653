import { Organizer } from 'api/Calls';
import { USER_ROLES } from 'common/constants';

export const canDeleteOrSkipCall = (
  userRole: string,
  userEmail: string,
  organizer?: Organizer,
  creator?: Organizer
): boolean =>
  userRole === USER_ROLES.SALES_OPERATIONS ||
  organizer?.email === userEmail ||
  creator?.email === userEmail;
