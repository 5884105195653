import { css } from 'emotion';

import { fontBody, fontCaption1, fontHeading3 } from 'assets/css/fontStyles';

import { divider, ellipsis, removeTooltipBefore } from '../styles';

export { divider, removeTooltipBefore };

export const headerDetailsWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .call-info-wrapper {
    display: flex;
    align-items: center;
    gap: var(--bu-control-gap-xxx-large);

    @media (max-width: 1450px) {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--bu-control-gap-medium);

      > span {
        margin-left: 0;
      }
    }

    > div {
      display: flex;
      align-items: center;
    }

    > span {
      flex: none;
      max-width: 300px;
      width: unset;

      @media (min-width: 1850px) {
        max-width: 500px;
      }

      @media (min-width: 1650px) {
        max-width: 400px;
      }

      @media (max-width: 1280px) {
        max-width: 400px;
      }
    }

    .call-info-details {
      @media (max-width: 1450px) {
        font-size: 12px;
      }
    }

    > span,
    .call-name {
      ${ellipsis}
      ${fontHeading3}

      @media (max-width: 1450px) {
        font-size: 14px;
      }
    }
  }

  .call-actions-wrapper {
    display: flex;
    align-items: center;
    gap: var(--bu-control-gap-medium);
  }

  > div {
    display: flex;
  }

  i {
    font-size: 16px;
    color: var(--bu-primary-700);
  }

  span {
    margin-left: 8px;
  }

  .detail {
    display: flex;
    align-items: center;

    span {
      ${ellipsis}
      max-width: 200px;
    }

    &.organizer {
      font-family: var(--bu-font-medium);
    }
  }

  .share-dropdown {
    :disabled {
      background-color: var(--bu-gray-300);
    }

    .bu-btn-content {
      gap: var(--bu-control-gap-medium);
    }

    .dropdown-icon {
      i {
        color: var(--bu-white);
      }
    }
  }
`;

export const dropdownOption = css`
  height: 48px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  padding: 6px 12px;
  ${fontBody}

  i {
    color: var(--bu-color-call-light-gray) !important;
  }

  :hover {
    cursor: pointer;
    background-color: var(--bu-primary-100);
  }
`;

export const callInviteTooltipWrapper = css`
  max-height: 600px;
  overflow-x: auto;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 400px;
`;

export const callInviteWrapper = css`
  padding: 10px;
  min-width: 300px;

  > span,
  .call-name {
    ${fontHeading3}
    color: var(--bu-gray-900);
  }

  .call-start-date {
    margin-top: 8px;
    font-size: 13px;
    color: var(--bu-gray-700);
  }

  .call-details-wrapper {
    margin-top: 20px;
    font-size: 13px;
    min-width: 450px;
    overflow-x: auto;

    .call-creator,
    .call-attendees,
    .call-description {
      display: grid;
      grid-template-columns: 20% 75%;
      grid-gap: var(--bu-control-gap-medium);
      margin-bottom: 8px;
      color: var(--bu-gray-900);

      .item-name,
      .item-value {
        font-family: var(--bu-font-medium);
      }

      .item-name {
        justify-content: flex-end;
        text-align: right;
        color: var(--bu-gray-700);
      }

      .item-value {
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
        color: var(--bu-gray-900);
        gap: var(--bu-control-gap-medium);
      }
    }

    .call-description {
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;

      a {
        word-break: break-all;
      }
    }

    .call-attendees .item-value {
      span {
        margin-left: 4px;
        font-size: 12px;
      }

      .accepted {
        color: var(--bu-green-500);
      }

      .declined {
        color: var(--bu-red-400);
      }

      .tentative {
        color: var(--bu-orange-500);
      }

      .attendee-item {
        display: flex;
        align-items: center;

        .attendee-name {
          ${ellipsis}
        }

        .attendee-email {
          font-size: 12px;
          color: var(--bu-gray-700);
        }
      }
    }
  }
`;

export const durationTooltipWrapper = css`
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);

  .duration-total {
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-small);
    font-family: var(--bu-font-medium);

    span {
      ${fontCaption1}
      color: var(--bu-gray-700);
    }
  }

  /* TODO: Will have this in the future */
  /* .duration-splitted {
    display: flex;
    align-items: center;
    gap: var(--bu-control-gap-medium);

    ::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: var(--bu-control-border-radius-medium);
    }

    &.customer {
      ::before {
        background-color: var(--bu-purple-500);
      }
    }

    &.company {
      ::before {
        background-color: var(--bu-lime-500);
      }
    }

    .duration-label {
      color: var(--bu-color-call-light-gray);
    }

    .duration-value,
    .duration-percentage {
      font-family: var(--bu-font-medium);
    }

    .duration-percentage {
      color: var(--bu-orange-500);
    }
  } */
`;

export const attendeesListTooltipWrapper = css`
  max-height: 600px;
  overflow-x: auto;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);

  .attendee {
    display: flex;
    gap: var(--bu-control-gap-medium);

    .attendee-info {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-small);
      font-family: var(--bu-font-medium);

      .attendee-type {
        text-transform: capitalize;
      }

      span + span {
        ${fontCaption1}
        color: var(--bu-gray-700);
      }
    }
  }
`;
