import { FC, useEffect, useMemo, useState } from 'react';

import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { HistoricalWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/Historical/HistoricalWidgetControls';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { WidgetVisualization } from 'components/dashboard/Metrics/Widget/Visualization/WidgetVisualization';
import { HierarchicalWidget } from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  MetricType,
  VisualizationType,
} from 'components/dashboard/Metrics/enums';
import {
  BIMetricFormula,
  BIMetricSimple,
  BIMetricToChartType,
  BIMetricUnion,
  BIMetricsMap,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus } from 'utils/network';
import { OnChartDataClick } from 'components/dashboard/Metrics/Widget/Chart/WidgetChart.types';
import { WidgetPreviewBanner } from 'components/dashboard/Metrics/Create/WidgetPreviewPlaceholder/WidgetPreviewBanner';
import { AnalysisType, TARGET } from 'components/dashboard/Metrics/constants';
import {
  evaluateWidgetDataIsIncluded,
  getMetricType,
  isBIMetricSimple,
} from 'components/dashboard/Metrics/Create/utils';
import { TemplateFilters } from '../../TemplateFilters/TemplateFilters';
import { parseFormulaToMetricsIdArray } from '../../helper';

interface Props {
  readonly isControlsShown: boolean;
  readonly isDashboardModal?: boolean;
  readonly isDashboardWidget?: boolean;
  readonly isMetricPreview?: boolean;
  readonly isReadOnly?: boolean;
  readonly dashboardName?: string;
  readonly metricsInUse: BIMetricsMap;
  readonly widget: BIWidget;
  readonly previewWidget: BIWidget;
  readonly widgetData: HierarchicalWidget;
  readonly widgetDataStatus: QueryStatus;
  readonly isTableRefetching?: boolean;
  onCloneWidget?: (metricId: string) => void;
  onEditWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
  onChartDataClick: OnChartDataClick;
  onTableDataClick: (column: IColumn, row: IRow) => void;
  onUpdateWidget: (changes: Partial<BIWidget>) => void;
  addSubTreeToFetch?: (subTree: string) => void;
}

export const BaseHistoricalWidget: FC<Props> = ({
  isControlsShown = false,
  isDashboardModal = false,
  isDashboardWidget = false,
  isMetricPreview = false,
  isReadOnly = false,
  dashboardName = '',
  metricsInUse,
  widget,
  previewWidget,
  widgetData,
  widgetDataStatus,
  isTableRefetching,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
  onChartDataClick,
  onTableDataClick,
  onUpdateWidget,
  addSubTreeToFetch,
}) => {
  const [showMetrics, setShowMetrics] = useState(!isDashboardWidget);

  const metricToChartType = useMemo<BIMetricToChartType[]>(() => {
    if (widget?.properties?.metricToChartType) {
      return widget?.properties?.metricToChartType;
    }

    // Case for metric creation
    if (isMetricPreview && Object.keys(metricsInUse).length === 1) {
      return [
        {
          chartType:
            widget.chart_type === VisualizationType.Table
              ? VisualizationType.Table
              : VisualizationType.Column,
          metricId: Object.keys(metricsInUse)[0],
        },
      ];
    }

    return [];
  }, [JSON.stringify(widget?.properties?.metricToChartType)]);

  const {
    usedFormulaMetricIds,
    objectsSet,
    dateFieldsSet,
  }: {
    usedFormulaMetricIds: string[];
    objectsSet: Set<string>;
    dateFieldsSet: Set<string>;
  } = useMemo(() => {
    const metricsIdsFromWidgetMetrics: string[] = (
      widget.metric_list as BIMetricUnion[]
    )
      .filter((m) => getMetricType(m) === MetricType.Formula)
      .flatMap((m) =>
        parseFormulaToMetricsIdArray((m as BIMetricFormula).synthetic_metric)
      );

    const objectsSet = new Set<string>();
    const dateFieldsSet = new Set<string>();

    widget.metric_list.forEach((metric: BIMetricUnion) => {
      if (isBIMetricSimple(metric)) {
        objectsSet.add(metric.object);
        dateFieldsSet.add(metric.date_field?.name ?? '');
      }
    });

    return {
      usedFormulaMetricIds: [...metricsIdsFromWidgetMetrics],
      objectsSet,
      dateFieldsSet,
    };
  }, [JSON.stringify(widget.metric_list)]);

  const firstTargetMetric = useMemo(() => {
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if ((current as BIMetricSimple).object === TARGET) {
        return current;
      }
    }

    return null;
  }, [metricsInUse]);

  // triggered when metric is added to widget and updates visualization types
  useEffect(() => {
    const metricToChartTypes = Object.keys(metricsInUse).map(
      (metricId: string) => {
        const current = metricToChartType.find(
          (mtct) => mtct.metricId === metricId
        );

        if (current && current.chartType !== VisualizationType.Pie) {
          return current;
        }

        return {
          chartType:
            widget.chart_type === VisualizationType.Table
              ? VisualizationType.Table
              : VisualizationType.Column,
          metricId,
        };
      }
    );

    onUpdateWidget({
      properties: {
        ...widget?.properties,
        metricToChartType: metricToChartTypes,
      },
    });
  }, [JSON.stringify(metricsInUse)]);

  const handleTableSortChange = (columnName: string): void => {
    onUpdateWidget({
      properties: {
        ...widget?.properties,
        table_view_order_by_column: columnName,
      },
    });
  };

  const isWidgetDataIncluded = evaluateWidgetDataIsIncluded(
    widget,
    previewWidget
  );

  const isDateFirstPivot =
    widget?.group_by[0]?.type === 'date' ||
    widget?.group_by[0]?.type === 'timePeriod';

  return (
    <WidgetContainer
      isDashboard={isDashboardWidget}
      isMetricsPreview={isMetricPreview}
      data-testing="widget-container"
    >
      <WidgetHeader
        id={widget.id}
        name={widget.name}
        dashboardName={dashboardName}
        isCreateEditMetric={isMetricPreview}
        isDashboardWidget={isDashboardWidget}
        isDashboardModal={isDashboardModal}
        showMetrics={showMetrics}
        optionalMetrics={isDashboardWidget}
        onEditWidget={onEditWidget}
        onCloneWidget={onCloneWidget}
        onRemoveWidget={onRemoveWidget}
        onTitleClicked={onTitleClicked}
        onCloseWidgetModal={onCloseWidgetModal}
        setShowMetrics={setShowMetrics}
        data-testing="widget-header"
        alternativeVisibility={false}
      />

      {isControlsShown && (
        <HistoricalWidgetControls
          widget={widget}
          metricToChartType={metricToChartType}
          onUpdateWidget={onUpdateWidget}
          data-testing="metrics-widget-controls"
        />
      )}

      {isControlsShown && (
        <TemplateFilters
          showControls
          isDateFirstPivot={isDateFirstPivot}
          templateFilters={widget.template_filters}
          widgetFilters={widget.widget_filters}
          dashboardFilters={widget.dashboard_filters ?? []}
          tables={Array.from(objectsSet)}
          targetPeriod={(firstTargetMetric as BIMetricSimple)?.target_period}
          onChangeWidget={onUpdateWidget}
          data-testing="metrics-widget-filters"
        />
      )}

      {!isWidgetDataIncluded && (
        <WidgetPreviewBanner analysisType={AnalysisType.HISTORICAL} />
      )}

      {isWidgetDataIncluded && (
        <WidgetVisualization
          widgetDataStatus={widgetDataStatus}
          isTableRefetching={isTableRefetching}
          isDashboardWidget={isDashboardWidget && !isDashboardModal}
          isReadOnly={isReadOnly}
          metricsInUse={metricsInUse}
          metricToChartType={metricToChartType}
          showControls={isControlsShown}
          widget={previewWidget}
          widgetData={widgetData}
          showMetrics={showMetrics}
          onUpdateWidget={onUpdateWidget}
          onChartDataClick={onChartDataClick}
          onTableDataClick={onTableDataClick}
          onTableSortChange={handleTableSortChange}
          onTotalsClick={() => {}}
          addSubTreeToFetch={addSubTreeToFetch}
          data-testing="widget-visualization"
        />
      )}
    </WidgetContainer>
  );
};
