import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  formatTo,
  selectTimeRange,
} from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import setElementInViewport from 'components/UI/OpenFiltersPanel/Dropdowns/setElementInViewport';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { Props } from 'components/UI/OpenFiltersPanel/types';
import { BetweenDate } from 'components/UI/TimeFilter/TabsForFilter/types';
import { TimeFilterContent } from 'components/UI/TimeFilter/TimeFilter';
import { LocalTab } from 'components/UI/TimeFilter/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { valuesConfigDate } from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/helpers';
import { getFiscalYearStartDate } from 'selectors';

export const DropdownCalendar: React.FC<Props> = ({
  config,
  isProfileSettings,
  name,
  onChange,
  onClose,
  tab,
  className,
  align = 'left',
  forceDown = false,
}) => {
  const fiscalYearStartDate = useSelector(getFiscalYearStartDate);

  const { isOpen, setIsOpen, refElement } = useClickOutside();
  const [localTab, setLocalTab] = useState<LocalTab>('Predefined');
  const [betweenDate, setBetweenDate] = useState<BetweenDate>({
    startDate: '',
    endDate: '',
  });

  const { isChanged, isLocked, title, values } = config;
  const currValue = values.find((item) => item.checked);
  const value = currValue?.value || [];

  const panelRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  /*
   * When the drop down appears, it will verify if should display on the top of the label
   * or normally on the bottom, and applies if will center or align on left or on the right
   */
  useLayoutEffect(() => {
    if (labelRef && panelRef) {
      const { current: labelCurrent } = labelRef;
      const { current: panelCurrent } = panelRef;
      const { innerHeight: height } = window;
      const panelBouding = panelCurrent?.getBoundingClientRect();
      const labelBouding = labelCurrent?.getBoundingClientRect();

      if (forceDown) {
        // This effect tries to move it up when there's no space down so we
        // don't need to do anything if its forced to be open downwards
        return;
      }

      if (labelBouding && panelBouding && panelCurrent) {
        const fitY = height - (panelBouding.y + panelBouding.height + 10) > 0;
        // On profile settings, the dropdown should always be on the bottom because
        // it clips the content when it's on the top
        if (!fitY && !isProfileSettings) {
          const moveTo = -(panelBouding.height + labelBouding.height + 10);
          panelCurrent.style.top = `${moveTo}px`;
        }

        if (align === 'center') {
          const { width: labelWidth } = labelBouding;
          const { width: panelWidth } = panelBouding;
          const moveLeftTo = -(
            Math.ceil(panelWidth / 2) - Math.floor(labelWidth / 2)
          );
          panelCurrent.style.left = `${moveLeftTo}px`;
        }

        setElementInViewport(panelRef);
      }
    }

    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen]);

  useEffect(() => {
    const format = (date: string) => formatTo(date, 'YYYY-MM-DD', 'MM/DD/YYYY');

    if (isString(value) && value.length === 21) {
      const dates = value.split(',');

      setLocalTab('Custom Range');
      setBetweenDate({
        startDate: format(dates[0]),
        endDate: format(dates[1]),
      });
    } else if (value && value[0] && value[0].length >= 10) {
      const dates = value[0].split(',');

      setBetweenDate({
        startDate: format(dates[0]),
        endDate: format(dates[1]),
      });
    } else {
      const date = selectTimeRange(
        isArray(value) ? value : [value],
        fiscalYearStartDate,
        false
      );

      setBetweenDate({
        startDate: date ? format(Object.keys(date[0])[0]) : '',
        endDate: date ? format(date[0][Object.keys(date[0])[0]]) : '',
      });
    }
  }, [JSON.stringify(value)]);

  const customConfig = {
    ...config,
    values:
      config.group === 'custom_filters'
        ? config.values.map((el) => {
            const findEl = valuesConfigDate.find((i) => i.value === el.value);
            return { ...el, type: findEl?.type };
          })
        : config.values,
  };

  return (
    <div
      id={`${name}_dropdown`}
      data-testing={`${name}_dropdown`}
      ref={refElement}
      className={classNames(s.panel_wrapper, className)}
    >
      <div
        ref={labelRef}
        className={classNames(s.panel_dropdown_button, {
          isChanged,
          isLocked: !isProfileSettings && isLocked,
          isOpen,
          revbi: tab === 'revbi',
        })}
        onClick={() => !isLocked && setIsOpen(!isOpen)}
      >
        <span className={s.panel_dropdown_name}>{title}:</span>
        <span className={s.panel_dropdown_value}>{currValue?.label || ''}</span>

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      {isOpen && (
        <div
          ref={panelRef}
          className={classNames(s.panel_dropdown, 'isCalendar')}
        >
          <TimeFilterContent
            config={customConfig}
            betweenDate={betweenDate}
            localTab={localTab}
            onChange={(id) => onChange([{ id, checked: true }], true)}
            onChangeLocalTab={setLocalTab}
            setBetweenDate={setBetweenDate}
            tab={tab}
            value={value}
            onClose={setIsOpen}
          />
        </div>
      )}
    </div>
  );
};
