import React, { useEffect, useMemo } from 'react';
import { Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import {
  IconDeviceComputerCameraOff,
  IconCalendarClock,
  IconTimelineEventX,
  IconRefreshDot,
} from '@tabler/icons-react';

import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';

import * as styles from './styles';
import { useVideoContext } from './videoPlayer.context';
import {
  formatTimeToFriendlyTimeString,
  formatDateToFriendlyTimeString,
  getVideoTimeAsPercentage,
} from '../helpers';
import boostupLogo from '../../../../../assets/images/new_icon/boostup_logo.svg';
import { CallStatus } from '../types';

type Props = {
  isCallSummarized: boolean;
  videoUrl: string;
  status: CallStatus;
  start: string;
};

const VideoPlayer: React.FC<Props> = ({
  isCallSummarized,
  videoUrl,
  status,
  start,
}) => {
  const {
    playbackRate,
    videoError,
    isLoading,
    isPlaying,
    currentTime,
    duration,
    videoElementRef,
    progressBarRef,
    progressDotRef,
    handlePlayPause,
    handleAdvance,
    handleRewind,
    handleFullScreen,
    handleProgressBarClick,
    handleMouseDown,
    handleError,
    handleCanPlay,
    handleDestroyComponent,
    handleClickIncreaseVideoPlaybackRate,
    handleClickDecreaseVideoPlaybackRate,
  } = useVideoContext();

  useEffect(() => handleDestroyComponent, []);

  const handleDoubleClickVideoPlayer = () => handleFullScreen();

  const videoErrorOrNotSummarized = useMemo(
    () => videoError || !isCallSummarized,
    [videoError, isCallSummarized]
  );

  const isAudio = useMemo(
    () =>
      ['.mp3', '.wav', '.ogg', '.aac'].some((ext) => videoUrl?.includes(ext)),
    [videoUrl]
  );

  const processingVideo = useMemo(
    () =>
      (!videoUrl || status === 'recorded') &&
      !['scheduled', 'skipped', 'uninitialized', 'no_show'].includes(status),
    [videoUrl, status]
  );

  const getVideoErrorMessage = () => {
    if (processingVideo) {
      return (
        <div className="error-message">
          <BuIcon name={IconRefreshDot} color="var(--bu-gray-900)" size={48} />
          <span className="error-message-title">
            Processing the call recording
          </span>
          <span className="error-message-description">
            Please come back in a few minutes
          </span>
        </div>
      );
    }

    if (videoError) {
      return (
        <div className="error-message">
          <BuIcon
            name={IconTimelineEventX}
            color="var(--bu-red-500)"
            size={48}
          />
          <span className="error-message-title">
            We cannot find the call recording
          </span>
          <span className="error-message-description">
            Please try again in a few minutes
          </span>
        </div>
      );
    }

    switch (status) {
      case 'skipped':
      case 'uninitialized':
      case 'no_show':
      default:
        return (
          <div className="error-message">
            <BuIcon
              name={IconDeviceComputerCameraOff}
              color="var(--bu-gray-900)"
              size={48}
            />
            <span>This call was not recorded</span>
          </div>
        );

      case 'scheduled':
        return (
          <div className="error-message">
            <BuIcon
              name={IconCalendarClock}
              color="var(--bu-gray-900)"
              size={48}
            />
            <span className="error-message-title">
              Call scheduled to happen on{' '}
              {formatDateToFriendlyTimeString(start)}
            </span>
            <span className="error-message-description">
              If the call is recorded you will see the recording here
            </span>
          </div>
        );
    }
  };

  return (
    <div className={styles.callVideoPlayerWrapper}>
      <div
        className="video-player"
        onClick={handlePlayPause}
        onDoubleClick={handleDoubleClickVideoPlayer}
      >
        {isLoading && (
          <div className="video-loading">
            <Loader active={isLoading} />
          </div>
        )}

        {videoErrorOrNotSummarized ? (
          <div className="video-placeholder">{getVideoErrorMessage()}</div>
        ) : (
          <div className="video-player-container">
            <video
              preload="auto"
              ref={videoElementRef}
              onCanPlay={handleCanPlay}
              onError={handleError}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {currentTime === 0 && !isPlaying && (
              <div className="video-placeholder">
                <img src={boostupLogo} alt="Boostup Logo" />
              </div>
            )}

            {isAudio && (currentTime !== 0 || isPlaying) && (
              <div className="audio-placeholder">
                <img src={boostupLogo} alt="Boostup Logo" />
                <div>Video is unavailable. Playing audio instead.</div>
              </div>
            )}
          </div>
        )}
      </div>

      {!videoErrorOrNotSummarized && (
        <>
          <div className="video-control">
            <div className="video-control-item playback-rate">
              <BuButton
                outline
                borderless
                onClick={handleClickDecreaseVideoPlaybackRate}
              >
                <BuIcon name={BoostUpIcons.ChevronLeft} />
              </BuButton>

              <span>{playbackRate}x</span>

              <BuButton
                outline
                borderless
                onClick={handleClickIncreaseVideoPlaybackRate}
              >
                <BuIcon name={BoostUpIcons.ChevronRight} />
              </BuButton>
            </div>

            <div className="video-control-item play">
              <BuButton
                outline
                borderless
                disabled={isLoading}
                onClick={handleRewind}
                className="rewind-video"
              >
                <BuIcon name={BoostUpIcons.RefreshIcon} />
              </BuButton>

              <BuButton
                outline
                borderless
                onClick={handlePlayPause}
                className="play-button"
              >
                <BuIcon
                  name={
                    isPlaying ? BoostUpIcons.MediaPause : BoostUpIcons.MediaPlay
                  }
                />
              </BuButton>

              <BuButton
                outline
                borderless
                disabled={isLoading}
                onClick={handleAdvance}
              >
                <BuIcon name={BoostUpIcons.RefreshIcon} />
              </BuButton>
            </div>

            <div className="video-control-item full-screen">
              <span>
                {formatTimeToFriendlyTimeString(currentTime)} /{' '}
                {formatTimeToFriendlyTimeString(duration)}
              </span>

              <BuButton
                outline
                borderless
                disabled={isLoading}
                onClick={handleFullScreen}
              >
                <BuIcon name={BoostUpIcons.FullScreen} />
              </BuButton>
            </div>
          </div>

          <div
            className={classNames('video-time', {
              disabled: isLoading,
            })}
            ref={progressBarRef}
            onClick={handleProgressBarClick}
          >
            <div
              className="video-time-progress"
              style={{
                width: `${getVideoTimeAsPercentage(currentTime, duration)}%`,
              }}
            />

            <TooltipWrapper
              tooltip={
                <div className={styles.textAlignCenter}>
                  {formatTimeToFriendlyTimeString(currentTime)}
                </div>
              }
              position="bottom center"
              mouseEnterDelay={0}
            >
              <div
                className={classNames('video-time-dot', {
                  disabled: isLoading,
                })}
                style={{
                  left: `calc(${getVideoTimeAsPercentage(
                    currentTime,
                    duration
                  )}% - 9px)`,
                }}
                ref={progressDotRef}
                onMouseDown={handleMouseDown}
              />
            </TooltipWrapper>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
