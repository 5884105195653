import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState, useEffect, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getIsTotalsRowEnabled } from 'selectors';
import { getTableDisplayConfiguration } from 'api/RevBiTableDisplay';
import {
  useGetItemsForMetricObject,
  useUpdateFieldMutation,
} from 'components/hooks/useManageDrilldownData';

import {
  getIdFromItem,
  getItemsTotalsForMetricObject,
  GetReportItemsForMetricObjectParams,
} from 'api/MetricObjectItems';
import { getTotalsRow } from 'components/UI/common/TypedTable/helper';
import { updateDealMutation } from 'components/modals/RevBiDealsModal/RevBiDealsModal.helper';
import { getSalesProcessItems } from 'api/SalesProcess';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';

const STALE_TIME = 5 * 60 * 1000;
const CACHE_TIME = 5 * 60 * 1000;

export type UseManageReportDataParams = Omit<
  GetReportItemsForMetricObjectParams,
  'columns' | 'offset'
>;

export interface UseManageReportData {
  metricObject: string;
  tableConfigurationUrl: string;
  params: UseManageReportDataParams;
}

export const useManageReportData = ({
  metricObject,
  tableConfigurationUrl,
  params,
}: UseManageReportData) => {
  const queryClient = useQueryClient();
  const isTotalsRowEnabled = useSelector(getIsTotalsRowEnabled);
  const [pageNumber, setPageNumber] = useState(1);
  const [userSortOrder, setUserSortOrder] = useState<string>();
  const { primaryKeysPerTable } = useContext(RevBISettingsContext);
  const primaryKeyInfo = primaryKeysPerTable[metricObject];

  const handlePaginationChange = (page: number) => {
    setPageNumber(page);
  };

  const handleSort = (sort?: string) => {
    setUserSortOrder(sort);
  };

  /**
   *
   * Table Columns and Table Settings
   *
   */

  const {
    data: tableConfig,
    isLoading: isTableSettingsLoading,
    isSuccess: isTableSettingsSuccess,
  } = useQuery({
    queryKey: ['getTableConfiguration', tableConfigurationUrl],
    queryFn: () => getTableDisplayConfiguration(tableConfigurationUrl),
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    enabled: !!tableConfigurationUrl,
  });

  /**
   *
   * Table data
   *
   */

  const columns = tableConfig?.columns || [];
  const columnsFields = columns.map((c) => c.object_field) || [];

  const defaultSortOrder = tableConfig?.order?.column
    ? `${tableConfig?.order.direction === -1 ? '-' : ''}${
        tableConfig?.order.column
      }`
    : '';

  const order_by_expression = params.order_by_expression;
  const secondary_order_by_expression = userSortOrder || defaultSortOrder;

  const tableDataParams: GetReportItemsForMetricObjectParams = {
    ...params,
    columns: columnsFields,
    order_by_expression,
    secondary_order_by_expression,
    offset: pageNumber - 1,
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
  }, [JSON.stringify(params)]);

  const {
    metricObjectItems,
    metricObjectItemsLoading,
    metricObjectItemsSuccess,
    invalidateData: invalidateMetricObjectItems,
  } = useGetItemsForMetricObject(
    metricObject,
    tableDataParams,
    isTableSettingsSuccess,
    {
      IS_FOR_TABLE_USING_REVBI_DISPLAY: true,
    }
  );

  /**
   * Core dependand data
   */

  const salesProcessColumns = tableConfig?.sales_process_columns || [];
  const itemsIds =
    metricObjectItems?.items
      ?.map((item) => getIdFromItem(item, primaryKeyInfo))
      .filter(Boolean) || [];

  const thereAreItemsIds = itemsIds.length > 0;

  const salesProcessCallNeeded =
    salesProcessColumns.length > 0 && thereAreItemsIds;

  const { data: salesProcessItems, isLoading: isSalesProcessLoading } =
    useQuery({
      queryKey: ['getSalesProcessItems', salesProcessColumns, itemsIds],
      queryFn: () =>
        getSalesProcessItems({
          sales_process_columns: salesProcessColumns,
          ids: itemsIds,
        }),
      enabled: salesProcessCallNeeded,
    });

  /**
   *
   * Totals Row Data
   *
   */

  const totalParams = {
    ...tableDataParams,
    columns: columnsFields,
  };

  const { data: tableTotals, isLoading: isTableTotalsLoading } = useQuery({
    queryKey: ['reportTotals', tableDataParams],
    queryFn: () =>
      getItemsTotalsForMetricObject(metricObject, totalParams, {
        IS_FOR_TABLE_USING_REVBI_DISPLAY: true,
      }),
    enabled: !isTableSettingsLoading && isTotalsRowEnabled,
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
  });

  const totalRow =
    !!tableConfig && isTotalsRowEnabled && tableConfig.columns
      ? getTotalsRow(tableTotals, isTableTotalsLoading)
      : undefined;

  /**
   *
   * Object Mutation
   *
   */
  const customMutation =
    metricObject === 'opportunity' ? updateDealMutation : undefined;

  const updateFieldMutation = useUpdateFieldMutation(
    metricObject,
    tableDataParams,
    {
      customMutation,
      IS_FOR_TABLE_USING_REVBI_DISPLAY: true,
      doNotInvalidateOnSettled: true,
    }
  );

  /**
   * Items parsing
   */

  const parsedItems = useMemo(() => {
    if (!metricObjectItems) {
      return [];
    }

    if (salesProcessCallNeeded && !salesProcessItems) {
      return metricObjectItems.items;
    }

    return metricObjectItems.items.map((item) => {
      const salesProcessData = salesProcessItems?.[item.id]?.opportunity;
      return salesProcessData
        ? {
            ...item,
            opportunity: {
              ...salesProcessData,
              ...item.opportunity,
            },
          }
        : item;
    });
  }, [metricObjectItems, salesProcessItems, salesProcessCallNeeded]);

  /**
   * Loading Logic
   */

  const isSalessProcessNeededAndLoading =
    salesProcessCallNeeded && isSalesProcessLoading;

  const isLoading =
    isTableSettingsLoading ||
    metricObjectItemsLoading ||
    isSalessProcessNeededAndLoading;

  const invalidateData = () => {
    queryClient.invalidateQueries(['reportTotals']);
    queryClient.invalidateQueries(['getSalesProcessItems']);
    invalidateMetricObjectItems();
  };

  return {
    columns,
    items: parsedItems || [],
    isLoading,
    totalRow,
    itemsCount: metricObjectItems?.count || 0,
    isSuccess: metricObjectItemsSuccess,
    updateObjectField: updateFieldMutation.mutateAsync,
    handlePaginationChange,
    pageNumber,
    handleSort,
    sortOrder: secondary_order_by_expression,
    invalidateData,
  };
};
