import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React, { createContext, useContext, ReactNode } from 'react';
import { toast } from 'react-toastify';

import { getCallTodoData, patchTaskRegenerate } from 'api/CallInsights';

import { CallTodoResponse } from '../../types';

export interface ActionItemsContextType {
  callTodoData: CallTodoResponse[];
  isFetchingCallTodoData: boolean;
  regenerateTaskMutation: UseMutationResult<
    { status: string },
    unknown,
    { task_id: string },
    void
  >;
  isRegeneratingTask: boolean;
}

const ActionItemsContext = createContext<ActionItemsContextType | undefined>(
  undefined
);

export const ActionItemsProvider: React.FC<{
  children: ReactNode;
  callId: string;
}> = ({ children, callId }) => {
  const queryClient = useQueryClient();

  const { data: callTodoData = [], isFetching: isFetchingCallTodoData } =
    useQuery({
      queryKey: ['callTodoData', callId],
      queryFn: () => getCallTodoData({ callId }),
      enabled: callId !== undefined,
    });

  const regenerateTaskMutation = useMutation({
    mutationFn: ({ task_id }: { task_id: string }) =>
      patchTaskRegenerate({
        task_id,
      }),
    onMutate: () => {
      toast.warn('Regenerating task...', { position: 'bottom-left' });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['callTodoData', callId],
        (prevState: CallTodoResponse[] | undefined) => {
          if (!prevState) return [];

          const updatedTasks = prevState.map((task: CallTodoResponse) =>
            task.id === data.id ? { ...task, ...data } : task
          );

          return [...updatedTasks];
        }
      );

      toast.success('Task regenerated successfully.', {
        position: 'bottom-left',
      });
    },
    onError: () => {
      toast.error('Error regenerating task.', {
        position: 'bottom-left',
      });
    },
  });

  const { isLoading: isRegeneratingTask } = regenerateTaskMutation;

  return (
    <ActionItemsContext.Provider
      value={{
        callTodoData,
        isFetchingCallTodoData,
        regenerateTaskMutation,
        isRegeneratingTask,
      }}
    >
      {children}
    </ActionItemsContext.Provider>
  );
};

export const useActionItemsContext = (): ActionItemsContextType => {
  const context = useContext(ActionItemsContext);
  if (!context) {
    throw new Error(
      'useActionItemsContext must be used within a ActionItemsProvider'
    );
  }
  return context;
};
