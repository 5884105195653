import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

type Highlighted = {
  name: string;
  type: '' | 'topic' | 'competitor';
};

interface VideoContextType {
  currentTime: number;
  duration: number;
  playbackRate: number;
  isPlaying: boolean;
  isFullScreen: boolean;
  highlightedSections: [number, number][];
  highlighted: Highlighted;
  userIteractionWithVideoTime: number;
  videoError: boolean;
  isLoading: boolean;
  isVideoErrorOrLoading: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
  setHighlightedSections: React.Dispatch<
    React.SetStateAction<[number, number][]>
  >;
  setHighlighted: React.Dispatch<React.SetStateAction<Highlighted>>;
  videoElementRef: React.MutableRefObject<HTMLVideoElement | null>;
  progressBarRef: React.MutableRefObject<HTMLDivElement | null>;
  progressDotRef: React.MutableRefObject<HTMLDivElement | null>;
  setTime: (time: number, userIteraction?: boolean) => void;
  updateVideoPlaybackRate: (newPlaybackRate: number) => void;
  handlePlayPause: () => void;
  handleAdvance: () => void;
  handleRewind: () => void;
  handleFullScreen: () => void;
  handleProgressBarClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleMouseDown: () => void;
  handleMouseMove: (event: MouseEventInit) => void;
  handleMouseUp: () => void;
  handleError: () => void;
  handleCanPlay: () => void;
  handleDestroyComponent: () => void;
  handleClickIncreaseVideoPlaybackRate: () => void;
  handleClickDecreaseVideoPlaybackRate: () => void;
}

const VideoContext = createContext<VideoContextType | undefined>(undefined);

export const VIDEO_PLAYBACK_RATES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const VideoProvider: React.FC = ({ children }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(VIDEO_PLAYBACK_RATES[3]); // 1
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [highlightedSections, setHighlightedSections] = useState<
    [number, number][]
  >([]);
  const [highlighted, setHighlighted] = useState<Highlighted>({
    name: '',
    type: '',
  });
  const [userIteractionWithVideoTime, setUserIteractionWithVideoTime] =
    useState(Date.now());
  const [draggedTime, setDraggedTime] = useState<number | null>(null);
  const wasVideoBeforeDragTime = useRef(isPlaying);
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const progressDotRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);

  const isVideoErrorOrLoading = useMemo(
    () => videoError || isLoading,
    [videoError, isLoading]
  );

  const updateCurrentVideoTime = (
    time: number,
    userIteraction: boolean = false
  ) => {
    if (videoElementRef.current) {
      setCurrentTime(time);
      videoElementRef.current.currentTime = time;
    }

    if (userIteraction) {
      setUserIteractionWithVideoTime(Date.now());
    }
  };

  const handleWaiting = () => setIsLoading(true);

  const handlePlaying = () => {
    setIsPlaying(true);
    setIsLoading(false);
  };

  const handlePlay = () => setIsPlaying(true);

  const handlePause = () => setIsPlaying(false);

  const handleFullScreenChange = () =>
    setIsFullScreen(!!document.fullscreenElement);

  const handleTimeUpdate = () => {
    if (videoElementRef.current) {
      setCurrentTime(videoElementRef.current.currentTime);
      setDuration(videoElementRef.current.duration);
    }
  };

  const handleLoadedData = () => {
    if (videoElementRef.current && videoElementRef.current.duration) {
      setIsLoading(false);
      setDuration(videoElementRef.current.duration);
    }
  };

  const handleRatechange = () => {
    if (videoElementRef.current) {
      setPlaybackRate(videoElementRef.current.playbackRate);
    }
  };

  const handleCanPlay = () => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      handleLoadedData();

      videoElement.addEventListener('waiting', handleWaiting);
      videoElement.addEventListener('playing', handlePlaying);
      videoElement.addEventListener('ratechange', handleRatechange);
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
      videoElement.addEventListener('fullscreenchange', handleFullScreenChange);
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('loadedmetadata', handleLoadedData);
      videoElement.addEventListener('loadeddata', handleLoadedData);
    }
  };

  const handleError = () => {
    setIsLoading(false);
    setVideoError(true);
  };

  const handleDestroyComponent = () => {
    const videoElement = videoElementRef.current;

    if (videoElement) {
      videoElement.removeEventListener('waiting', handleWaiting);
      videoElement.removeEventListener('playing', handlePlaying);
      videoElement.removeEventListener('ratechange', handleRatechange);
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener(
        'fullscreenchange',
        handleFullScreenChange
      );
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      videoElement.removeEventListener('loadedmetadata', handleLoadedData);
      videoElement.removeEventListener('loadeddata', handleLoadedData);
    }
  };

  const updateVideoPlaybackRate = (newPlaybackRate: number) => {
    if (videoElementRef.current) {
      videoElementRef.current.playbackRate = newPlaybackRate;
    }
  };

  const handlePlayPause = () => {
    if (isFullScreen) return;

    if (videoElementRef.current) {
      if (isPlaying) {
        videoElementRef.current.pause();
      } else {
        videoElementRef.current.play();
      }
    }
  };

  const setTime = (time: number, userIteraction?: boolean) => {
    if (videoElementRef.current) {
      updateCurrentVideoTime(time, userIteraction);

      if (isPlaying) {
        videoElementRef.current.play();
      }
    }
  };

  const handleAdvance = () => {
    if (videoElementRef.current && !isLoading) {
      updateCurrentVideoTime(videoElementRef.current.currentTime + 10, true);
    }
  };

  const handleRewind = () => {
    if (videoElementRef.current && !isLoading) {
      updateCurrentVideoTime(videoElementRef.current.currentTime - 10, true);
    }
  };

  const handleFullScreen = () => {
    if (videoElementRef.current && !isLoading) {
      videoElementRef.current.requestFullscreen();
    }
  };

  const handleProgressBarClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (progressBarRef.current && videoElementRef.current && !isLoading) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const newTime = (clickX / rect.width) * duration;

      updateCurrentVideoTime(newTime, true);
    }
  };

  const handleMouseMove = useCallback(
    (event: MouseEventInit) => {
      if (isDragging.current && progressBarRef.current) {
        requestAnimationFrame(() => {
          if (progressBarRef.current) {
            const rect = progressBarRef.current.getBoundingClientRect();
            let newTime =
              ((event.clientX! - rect.left) / rect.width) * duration;
            newTime = Math.max(0, Math.min(newTime, duration));

            // Store the new time but do not update the video time yet
            setDraggedTime(newTime);
            setCurrentTime(newTime);
          }
        });
      }
    },
    [duration]
  );

  const handleMouseDown = () => {
    wasVideoBeforeDragTime.current = isPlaying;

    if (videoElementRef.current && isPlaying) {
      videoElementRef.current.pause();
    }

    if (!isLoading) {
      isDragging.current = true;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  const handleMouseUp = () => {
    if (videoElementRef.current && wasVideoBeforeDragTime.current) {
      videoElementRef.current.play();
    }

    if (!isLoading && draggedTime !== null) {
      updateCurrentVideoTime(draggedTime, true);
      setDraggedTime(null);
    }

    isDragging.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleChangeVideoPlaybackRate = (direction: number) =>
    setPlaybackRate((prevPlaybackRate) => {
      const currentIndex = VIDEO_PLAYBACK_RATES.indexOf(prevPlaybackRate);
      const newIndex = currentIndex + direction;

      if (newIndex >= 0 && newIndex < VIDEO_PLAYBACK_RATES.length) {
        const newPlaybackRate = VIDEO_PLAYBACK_RATES[newIndex];

        updateVideoPlaybackRate(newPlaybackRate);

        return newPlaybackRate;
      }

      return prevPlaybackRate;
    });

  const handleClickIncreaseVideoPlaybackRate = () =>
    handleChangeVideoPlaybackRate(1);

  const handleClickDecreaseVideoPlaybackRate = () =>
    handleChangeVideoPlaybackRate(-1);

  const value: VideoContextType = {
    currentTime,
    duration,
    playbackRate,
    isPlaying,
    isFullScreen,
    highlightedSections,
    highlighted,
    userIteractionWithVideoTime,
    videoError,
    isLoading,
    isVideoErrorOrLoading,
    setIsPlaying,
    setCurrentTime,
    setDuration,
    setPlaybackRate,
    setHighlightedSections,
    setHighlighted,
    videoElementRef,
    progressBarRef,
    progressDotRef,
    setTime,
    updateVideoPlaybackRate,
    handlePlayPause,
    handleAdvance,
    handleRewind,
    handleFullScreen,
    handleProgressBarClick,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleError,
    handleCanPlay,
    handleDestroyComponent,
    handleClickIncreaseVideoPlaybackRate,
    handleClickDecreaseVideoPlaybackRate,
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export const useVideoContext = () => {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error('useVideoContext must be used within a VideoProvider');
  }
  return context;
};
