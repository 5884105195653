import React, { Dispatch, FC, SetStateAction } from 'react';

import {
  MetricPreviewColumn,
  MetricPreviewContainer,
  PreviewNoteParagraph,
} from 'components/dashboard/Metrics/Create/MetricCreate/MetricPreview/styles';
import { WidgetPreviewPlaceholder } from 'components/dashboard/Metrics/Create/WidgetPreviewPlaceholder/WidgetPreviewPlaceholder';
import { MetricsWidget } from 'components/dashboard/Metrics/Widget/MetricsWidget';
import {
  BIMetricUnion,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isWidgetReadyForPreview: boolean;
  metric: BIMetricUnion;
  previewWidget: BIWidget;
  newPreviewWidget: BIWidget;
  setPreviewWidget: Dispatch<SetStateAction<BIWidget>>;
}

export const MetricPreview: FC<Props> = ({
  isWidgetReadyForPreview,
  metric,
  previewWidget,
  newPreviewWidget,
  setPreviewWidget,
}) => (
  <MetricPreviewColumn>
    {!isWidgetReadyForPreview && <WidgetPreviewPlaceholder />}

    {isWidgetReadyForPreview && (
      <MetricPreviewContainer data-testing="widget-preview-section">
        <MetricsWidget
          metric={metric}
          widget={previewWidget}
          previewWidget={newPreviewWidget}
          setWidget={setPreviewWidget}
        />
      </MetricPreviewContainer>
    )}

    <PreviewNoteParagraph>
      The widget and all it includes (pivot, chart type) is only used for
      previewing your metric and it will not be saved with the metric.
    </PreviewNoteParagraph>
  </MetricPreviewColumn>
);
