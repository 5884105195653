import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { TABLE_ID } from 'common/constants';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Table from 'components/UI/TableConfig/Table';
import {
  ColumnTypesCallback,
  columnTypes,
  handleColumnClick,
} from 'components/UI/TableConfig/column-helper';
import { TableConfigurationData } from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { getDrilldownUrlQueryParams } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  CsvColumns,
  DrilldownDataResponse,
  DrilldownQueryParams,
} from 'components/modals/types';
import { QueryStatus, fetchApi } from 'utils/network';
import { usePageSize } from 'components/hooks/usePageSize';
import { useModal } from '../ModalContext/modal.context';
import { isMulticurrencyEnabled } from 'selectors';

export const HistoricalDrilldownModalTitle: React.FC = () => {
  const { getPropsForScheme } = useModal<any>();
  const params = getPropsForScheme('/historical-drilldown');
  return (
    <div>
      <span>{params.title ?? 'Historical Drilldown'}</span>
    </div>
  );
};

export const HistoricalDrilldownModal: React.FC = () => {
  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);
  const isMultiCurrencyEnabled = useSelector(isMulticurrencyEnabled);

  const { getPropsForScheme } = useModal<any>();

  const params = getPropsForScheme('/historical-drilldown');

  const [pageSize] = usePageSize('RevbiOpportunityHistory', 15);

  const [tableColumns, setTableColumns] = useState<IColumn[]>([]);
  const [csvColumns, setCsvColumns] = useState<CsvColumns[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);
  const [count, setCount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [offsetIndex, setOffsetIndex] = useState<number>(0);
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [orderByExpression, setOrderByExpression] = useState<
    string | undefined
  >('id');
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const handleOpportunitiesTableSpecialClick: ColumnTypesCallback = (props) => {
    if (props.column.type !== ColumnTypes.SALES_PROCESS) {
      handleColumnClick(props);
    }
  };

  const handlePageChange = (pageNumber: number, _: number) => {
    setOffsetIndex(pageNumber - 1);
    setPaginationIndex(pageNumber);
  };

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrencyCode,
        0,
        isMultiCurrencyEnabled
      ),
      sequence: [],
    },
    handleOpportunitiesTableSpecialClick,
    '',
    isMultiCurrencyEnabled
  );

  useEffect(() => {
    if (params.objectURL) {
      const objectURL = params.objectURL;
      fetchApi<void, { data: TableConfigurationData }>({
        url: objectURL,
        queryMethod: 'get',
        setData: (result) => {
          const data = result?.data ?? {};
          const columns = data.columns;
          const order = data.order;
          setOrderByExpression(order.object_field);
          setCsvColumns(
            columns.map((c) => ({
              display_name: c.display_name,
              object_field:
                c.object_field === 'crm_metadata.opportunity_name'
                  ? 'opportunity_name'
                  : c.object_field,
            }))
          );
          setTableColumns(
            columns
              ?.map<IColumn>((column, index) => ({
                field: column.object_field,
                sort_order: SortOrder.DESCENDING,
                sortable: column.sortable,
                id: `${column.field_name}-${index}`,
                label: column.display_name,
                ...getColumnType(column, columns),
                editable: column.editable,
              }))
              .map((column) => ({
                ...column,
                showTooltip: ![
                  ColumnTypes.NUMBER,
                  ColumnTypes.PERCENT,
                  ColumnTypes.MONEY,
                ].includes(column.type),
              })) || []
          );
        },
        setError: () => {
          toast.error('Failed to load drilldown columns');
        },
      });
    }
  }, [params]);

  const drilldownUrlQueryParams = getDrilldownUrlQueryParams(
    params.drilldownParams
  );
  useEffect(() => {
    if (params?.metric?.object && tableColumns.length) {
      const columns = tableColumns.map(({ field }) => field);
      const queryParams: DrilldownQueryParams = {
        ...params.drilldownParams,
        columns: columns,
        limit: pageSize,
        offset: offsetIndex,
        order_by_expression: orderByExpression || '',
      };

      fetchApi<DrilldownQueryParams, DrilldownDataResponse>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${params.metric.object}/data${drilldownUrlQueryParams}`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          const data = result ?? [];

          data.count && setCount(data.count);
          data.total_amount && setTotalAmount(data.total_amount);

          const parsedDeals: IRow[] = data?.data.map((deal: Deals.Deal) => ({
            ...deal,
            id: `${deal._id}`,
            crm_metadata:
              deal.crm_metadata && deal.crm_metadata.next_step === null
                ? { ...deal.crm_metadata, next_step: '' }
                : deal.crm_metadata,
          }));

          setRows(parsedDeals);
        },
        setStatus: setDataStatus,
        setError: () => {
          toast.error('Failed to load drilldown data');
          setRows([]);
        },
      });
    }
  }, [
    params?.metric?.object,
    params.drilldownParams,
    tableColumns,
    offsetIndex,
    orderByExpression,
  ]);

  const downloadButton: DownloadButtonProps = useMemo(
    () => ({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${params?.metric?.object}/download${drilldownUrlQueryParams}`,
      queryMethod: 'post',
      serializedQueryParams: JSON.stringify({
        drill_down: {
          ...params?.drilldownParams,
          limit: 100000,
          offset: 0,
          order_by_expression: orderByExpression || '',
        },
        columns: csvColumns,
      }),
    }),
    [params, csvColumns, orderByExpression]
  );

  const tableTitle: string = useMemo(() => {
    if (params) {
      const name = params?.metric?.name || params?.metric?.metricName;
      if (params?.pivotValue) {
        return `${name}: ${params?.drilldownParams?.target_time_period} - ${params?.pivotValue}`;
      } else {
        return `${name}: ${params?.drilldownParams?.target_time_period}`;
      }
    }

    return 'Historical';
  }, [params]);

  // If you modify the layout remember to leave the data-testing attribute for automation
  return (
    <div style={{ minHeight: '150px' }} data-testing="deals-modal">
      <Table
        tableId={TABLE_ID.MODAL_HISTORICAL}
        pinnableColumns
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        totalAmount={totalAmount}
        loading={dataStatus !== 'success' && dataStatus !== 'error'}
        title={tableTitle}
        totalCount={count}
        currentPage={paginationIndex}
        rowsPerPage={pageSize}
        downloadButton={downloadButton}
        data={rows}
        columns={tableColumns}
        sortOrder={orderByExpression}
        showTotalAmount={params.showTotalAmount}
        onSort={setOrderByExpression}
        onPaginationChange={handlePageChange}
      />
    </div>
  );
};
