import { ChatContext as APIChatContext } from '../api/AIChat';
import { PageContext } from './AIChatContext';

export const OBJECT_TYPE_OPEN_MODE = {
  call: 'page',
  deal: 'quickview',
} as const;

export const getContext = (pageContext: PageContext): APIChatContext => {
  switch (pageContext.object_type) {
    case 'deal':
      return {
        object_type: pageContext.object_type,
        object_id: pageContext.object_id,
      };
    case 'call':
      return {
        object_type: pageContext.object_type,
        object_id: pageContext.object_id,
      };
    case 'none':
    default:
      return {
        object_type: pageContext.object_type,
      };
  }
};
