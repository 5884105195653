import { css } from 'emotion';

export const singleTotalContainer = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  padding: 8px 8px 8px 16px;
`;

export const singleTotalItemContainer = ({
  isClickable,
}: {
  isClickable: boolean;
}) => css`
  background-color: green;
  width: 100%;
  height: 100%;
  ${isClickable ? 'cursor: pointer !important;' : 'cursor: auto;'}
`;

export const readOnlyTotalContainer = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  flex: 1;
  padding: 8px;
`;

export const moreMargin = css`
  margin-bottom: 12px;
`;

export const readOnlyTotalCardItem = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  width: 105px;
  min-width: 105px;
  max-width: 105px;

  &.clickable {
    cursor: pointer !important;
  }
`;

export const totalCardHalfSpace = css`
  flex: 1 1 min(50%, 200px);
`;

export const totalCardAThirdOfSpace = css`
  flex: 1 1 min(33%, 200px);
`;

export const icon = css`
  margin-right: 8px;
  font-size: 20px;
`;

export const actionableTotalsContainer = css`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 8px;
  justify-content: flex-end;
`;

export const actionableTotalsInnerContainer = css`
  display: flex;
  align-items: center;
  padding: 8px 0;
  width: 100%;
`;

export const actionableTotalsValueContainer = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  margin-left: 8px;
  align-items: stretch;
`;

export const actionableTotalsItemContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2px;
  border: solid var(--bu-gray-400) 1px;
  border-radius: 4px;
  padding: 4px 8px;

  &.clickable {
    cursor: pointer !important;
  }
`;
