import { BoostUpIcons } from 'assets/css/boostup-icons';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';
import BuIcon from '../BuIcon';

export enum BuControlSize {
  SMALL = 'bu-small',
  REGULAR = 'bu-regular',
  BIG = 'bu-big',
}

const triangle = css`
  border-style: solid;
  height: 0;
  width: 0;
  border-width: 4px;
  border-color: var(--bu-gray-900);
  display: inline-block;
  margin: 0 2px;

  &.bu-up {
    border-top-width: 0;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
  }

  &.bu-down {
    border-bottom-width: 0;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
  }
`;

export const buttonStyle = css`
  height: var(--bu-control-height-regular);
  font-family: var(--bu-font-medium);
  min-width: fit-content;
  padding: 0 8px;
  margin: 0 2px;

  background-color: var(--bu-primary-500);
  color: var(--bu-white);
  border: 1px solid transparent;
  border-radius: 4px;
  line-height: 0;

  .bu-btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 4px;
  }

  &.bu-secondary {
    .bu-btn-content {
      .bu-icon {
        color: var(--bu-primary-500);
      }
    }
  }

  &.bu-basic {
    background-color: var(--bu-white);
    color: var(--bu-gray-800);
    border-color: var(--bu-gray-400);
  }

  .bu-icon {
    color: var(--bu-white);

    &.bu-icon-triangleDown,
    &.bu-icon-triangleUp {
      font-size: 6px;
      color: var(--bu-gray-800);
    }

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:not(:first-child) {
      margin-left: 5px;
    }
  }

  &.bu-borderless {
    border: none;
  }

  &.bu-button-icon {
    .bu-icon {
      color: var(--bu-primary-500);
    }

    &:disabled {
      .bu-icon {
        color: var(--bu-gray-500);
      }
    }
  }

  &.bu-dropdown {
    position: relative;
    padding-right: 8px;
    padding-left: 8px;
    margin-right: 0;
    margin-left: 0;

    &.bu-button-icon {
      width: calc(var(--bu-control-height-regular) + 12px);
    }

    .${triangle} {
      position: absolute;
      right: 5px;
      border-color: var(--bu-white);
    }

    &.bu-secondary {
      .bu-icon {
        color: var(--bu-gray-800);
      }
    }

    &.bu-secondary,
    &.bu-borderless {
      .${triangle} {
        border-color: var(--bu-primary-500);
      }

      &.bu-dropdown-open {
        border-color: var(--bu-primary-500);

        .${triangle} {
          border-color: var(--bu-primary-500);
        }
      }
    }

    .bu-btn-content {
      justify-content: left;
    }

    &:disabled {
      color: var(--bu-gray-500);
      background-color: var(--bu-gray-200);
    }
  }

  &.bu-small {
    height: var(--bu-control-height-small);
    font-size: var(--bu-font-size-small);
  }

  &.bu-big {
    height: var(--bu-control-height-big);
    font-size: var(--bu-font-size-big);
  }

  &.bu-secondary,
  &.bu-button-icon {
    background-color: transparent;
    color: var(--bu-primary-500);
    border-color: var(--bu-primary-500);
  }

  &.bu-read-only {
    &:disabled {
      border-color: var(--bu-read-only-border-color) !important;
      background-color: var(--bu-read-only-primary) !important;
      color: var(--bu-gray-900) !important;
      cursor: not-allowed;
    }
  }

  &.bu-borderless {
    background-color: transparent;
    color: var(--bu-gray-900);
    border-color: transparent;
  }

  &.bu-outline {
    background-color: var(--bu-white);
    color: var(--bu-primary-500);
    border-color: var(--bu-primary-500);

    &:active {
      background-color: var(--bu-primary-200) !important;
    }
  }

  &.bu-button-icon {
    width: var(--bu-control-height-regular);

    .bu-icon {
      font-size: 1.2em;
    }

    &.bu-small {
      width: var(--bu-control-height-small);
    }

    &.bu-big {
      width: var(--bu-control-height-big);
    }

    min-width: initial;
    padding-left: 0;
    padding-right: 0;
  }

  &,
  &:focus {
    outline: none;
  }

  &:enabled {
    cursor: pointer;

    i {
      cursor: pointer;
    }

    &:hover {
      background-color: var(--bu-primary-700);

      &.bu-secondary,
      &.bu-basic,
      &.bu-button-icon {
        background-color: var(--bu-gray-300);

        .bu-dropdown-label {
          color: var(--bu-gray-900);
        }
      }

      &.bu-borderless {
        background-color: transparent;
        &:not(:focus) {
          border-color: var(--bu-gray-500);
        }
      }
      &.bu-outline {
        background-color: var(--bu-gray-300);
      }
    }
  }

  &:disabled {
    color: white;
    background-color: var(--bu-gray-500);
    border-color: var(--bu-read-only-border-color);
    cursor: not-allowed;

    &.bu-dropdown .${triangle} {
      border-color: white;
    }

    &.bu-read-only .${triangle} {
      border-color: var(--bu-read-only-secondary);
    }

    &.bu-borderless {
      background-color: transparent;
      color: var(--bu-gray-700);

      &.bu-dropdown .${triangle} {
        border-color: var(--bu-gray-700);
      }
    }

    &.bu-outline {
      color: var(--bu-primary-500);
      background-color: var(--bu-white);
      opacity: 0.4;
    }
  }
`;

type ButtonDropdownState = 'open' | 'close';

export type IButtonProps = {
  icon?: boolean;
  secondary?: boolean;
  basic?: boolean;
  borderless?: boolean;
  outline?: boolean;
  tooltip?: string;
  size?: BuControlSize;
  dropdown?: ButtonDropdownState;
  innerRef?: React.Ref<HTMLButtonElement>;
  noDropdownIcon?: true;
  readOnly?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  Common.DataTestingType;
const BuButton: React.FC<IButtonProps> = ({
  icon,
  secondary,
  basic,
  borderless,
  outline,
  tooltip,
  className,
  size = BuControlSize.REGULAR,
  dropdown,
  children,
  innerRef,
  noDropdownIcon = false,
  readOnly = false,
  disabled = false,
  testingLabel = '',
  ...rest
}) => {
  let innerTestingLabel = testingLabel ? `${testingLabel}-Btn` : 'Btn';

  if (typeof children === 'string') {
    innerTestingLabel = `${innerTestingLabel}-${children.replaceAll(' ', '_')}`;
  }

  return (
    <button
      {...rest}
      data-testing={innerTestingLabel}
      disabled={disabled || readOnly}
      ref={innerRef}
      data-bu-tooltip={tooltip}
      data-bu-tooltip-position="bottom center"
      className={classNames('bu-font-default', className, buttonStyle, size, {
        'bu-button-icon': icon,
        'bu-secondary': secondary,
        'bu-basic': basic,
        'bu-borderless': borderless,
        'bu-outline': outline,
        'bu-dropdown': !noDropdownIcon && dropdown,
        'bu-dropdown-open': dropdown === 'open',
        'bu-dropdown-close': dropdown === 'close',
        'bu-read-only': readOnly,
      })}
    >
      <div className="bu-btn-content">
        {children}

        {!noDropdownIcon && dropdown && (
          <div className="dropdown-icon">
            {dropdown === 'open' ? (
              <BuIcon name={BoostUpIcons.TriangleUp} />
            ) : (
              <BuIcon name={BoostUpIcons.TriangleDown} />
            )}
          </div>
        )}
      </div>
    </button>
  );
};

export default BuButton;
