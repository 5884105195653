import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import {
  ActionDropdownText,
  HeaderContainer,
  NameInputContainer,
  NameNotEditing,
} from 'components/dashboard/Metrics/Create/Header/styles';
import { CreationType } from 'components/dashboard/Metrics/Create/constants';

interface Props {
  id?: string;
  name?: string;
  type: CreationType;
  isSaveDisabled: boolean;
  canPreview?: boolean;
  /**
   * Should be remove and add preview support for report widgets
   */
  hidePreviewButton?: boolean;
  onNameChange: (newName: string) => void;
  onClone: () => void;
  onDelete: () => void;
  onSave: () => void;
  onPreview?: () => void;
}

export const Header: React.FC<Props> = ({
  id,
  name,
  type,
  isSaveDisabled,
  canPreview = false,
  hidePreviewButton = false,
  onNameChange,
  onClone,
  onDelete,
  onSave,
  onPreview,
}) => {
  const history = useHistory();

  const [inputName, setInputName] = useState<string>(name || '');
  const [isEditingName, setIsEditingName] = useState<boolean>(true);

  const triggerChange = useCallback(
    debounce((value: string) => onNameChange(value), 750),
    []
  );

  const handleChangeName = (name: string): void => {
    setInputName(name);
    triggerChange(name);
  };

  return (
    <HeaderContainer>
      <BuButton
        icon
        borderless
        onClick={() => {
          history.push(`/revbi/${type}s/list`);
        }}
        size={BuControlSize.SMALL}
      >
        <BuIcon name={BoostUpIcons.ArrowLeft} />
      </BuButton>

      <NameInputContainer onClick={() => setIsEditingName(true)}>
        {isEditingName || !name ? (
          <BuInput
            autoFocus
            placeholder={`Enter a ${type} name`}
            type="text"
            value={inputName}
            onChange={(e) => {
              handleChangeName(e.target.value);
            }}
            onBlur={() => setIsEditingName(false)}
            onFocus={() => setIsEditingName(true)}
            testingLabel={`${type}-name`}
          />
        ) : (
          <NameNotEditing data-testing={`${type}-name-to-edit`}>
            <p>{name}</p>
            <div className="icon-button">
              <BuIcon name={BoostUpIcons.Pencil} />
            </div>
          </NameNotEditing>
        )}
      </NameInputContainer>

      {id && (
        <BuDropdown
          label={<BuIcon name={BoostUpIcons.MenuHoriz} />}
          icon
          noDropdownIcon
          borderless
          placement="bottom-end"
          testingLabel="existing-metric-actions"
        >
          {(hide) => (
            <BuDropdownItemContainer>
              <BuDropdownItem
                onClick={() => {
                  onClone();
                  hide();
                }}
              >
                <ActionDropdownText>Clone</ActionDropdownText>
              </BuDropdownItem>
              <BuDropdownItem
                onClick={() => {
                  onDelete();
                  hide();
                }}
              >
                <ActionDropdownText>Delete</ActionDropdownText>
              </BuDropdownItem>
            </BuDropdownItemContainer>
          )}
        </BuDropdown>
      )}

      <section>
        {!hidePreviewButton && (
          <BuButton
            secondary
            onClick={() => {
              if (onPreview) {
                onPreview();
              }
            }}
            disabled={!canPreview}
          >
            Preview
          </BuButton>
        )}
        <BuButton onClick={onSave} disabled={isSaveDisabled}>
          Save and Exit
        </BuButton>
      </section>
    </HeaderContainer>
  );
};
