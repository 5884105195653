import classNames from 'classnames';
import moment from 'moment';
import { isEmpty } from 'ramda';
import React, {
  useCallback,
  useState,
  createRef,
  useEffect,
  RefObject,
} from 'react';

import IconTime from 'assets/images/icons/time_small.svg';
import IconArrow from 'assets/images/new_icon/next_arrow.svg';
import BuButton from 'components/UI/BuButton';
import {
  HOURS,
  MINUTES,
  defaultTimeFormats,
} from 'components/UI/BuTimePicker/constants';
import {
  TimePickerPopup,
  RollItem,
  TimeRoll,
  TimePicker,
  PickerContainer,
} from 'components/UI/BuTimePicker/styles';

type IBuTimePicker = {
  value: string;
  onChange(value: string | null): void;
  disabled: boolean;
  formats?: {
    inputFormat: string;
    displayFormat: string;
  };
  className?: string;
};

type IBuWheel = {
  items: string[];
  onSelect: (item: string) => void;
  selectedItem: string;
  className?: string;
};

const createItemRef = (acc: Record<string, RefObject<any>>, value: string) => ({
  ...acc,
  [value]: createRef<any>(),
});

export const BuWheel = ({
  items,
  onSelect,
  selectedItem,
  className,
}: IBuWheel) => {
  const refs = items.reduce(createItemRef, {});

  useEffect(() => {
    if (refs[selectedItem] && refs[selectedItem].current) {
      refs[selectedItem].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedItem]);

  /* @param {-1 | 1} dx - go up|down in the array */
  const handleSelectItem = (dx: -1 | 1) => {
    if (dx === -1 && items.indexOf(selectedItem) === 0) {
      /* from the first element to the last one */
      onSelect(items[items.length - 1]);
    } else if (dx === 1 && items.indexOf(selectedItem) === items.length - 1) {
      /* from the last element to the first one */
      onSelect(items[dx - 1]);
    } else {
      onSelect(items[items.indexOf(selectedItem) + dx]);
    }
  };

  return (
    <div className={classNames('bu-wheel', className)}>
      <img
        src={IconArrow}
        alt="time icon"
        onClick={() => handleSelectItem(-1)}
        className={classNames('arrow-up', 'arrow')}
      />
      <TimeRoll>
        {items.map((item) => (
          <RollItem
            key={item}
            ref={refs[item]}
            onClick={() => onSelect(item)}
            className={classNames({
              selected: selectedItem === item,
            })}
          >
            {item}
          </RollItem>
        ))}
      </TimeRoll>
      <img
        src={IconArrow}
        alt="time icon"
        onClick={() => handleSelectItem(1)}
        className={classNames('arrow-down', 'arrow')}
      />
    </div>
  );
};

const BuTimePicker = ({
  value,
  onChange,
  disabled,
  formats = defaultTimeFormats,
  className,
}: IBuTimePicker) => {
  const [date, setDate] = useState(
    moment(value, formats.inputFormat).format(formats.displayFormat)
  );

  const [isSelectorVisible, setSelectorVisible] = useState(false);
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');

  const handleSaveTime = useCallback(() => {
    const newDateMoment = moment(`${hour}:${minute}`, 'hhA:mm');

    onChange(newDateMoment.format(formats.inputFormat));
    setDate(newDateMoment.format(formats.displayFormat));
    setSelectorVisible(false);
  }, [hour, minute]);

  return (
    <TimePicker className={classNames(className, { disabled })}>
      <div className="time-picker-content-wrapper">
        <div
          className={classNames('date-button', { disabled })}
          onClick={() => !disabled && setSelectorVisible(!isSelectorVisible)}
        >
          <img
            src={IconTime}
            alt="time icon"
            className={classNames({ disabled })}
          />
          {date}
        </div>
        {!disabled && isSelectorVisible && (
          <TimePickerPopup>
            <PickerContainer>
              <BuWheel items={HOURS} onSelect={setHour} selectedItem={hour} />
              <BuWheel
                items={MINUTES}
                onSelect={setMinute}
                selectedItem={minute}
              />
            </PickerContainer>
            <div>
              <BuButton secondary onClick={() => setSelectorVisible(false)}>
                Cancel
              </BuButton>
              <BuButton
                disabled={isEmpty(hour) || isEmpty(minute)}
                onClick={handleSaveTime}
              >
                OK
              </BuButton>
            </div>
          </TimePickerPopup>
        )}
      </div>
    </TimePicker>
  );
};

export default BuTimePicker;
