import axiosInstance from './axiosInstance';

export interface SalesProcessStep {
  completed_steps: number;
  total_steps: number;
}

export interface SalesProcessData {
  [processName: string]: SalesProcessStep;
}

export interface SalesProcessItem {
  id: string;
  opportunity: SalesProcessData;
}

export interface SalesProcessResponse {
  [opportunityId: string]: SalesProcessItem;
}

export interface GetSalesProcessItemsParams {
  sales_process_columns: string[];
  ids: string[];
}

interface SalesProcessResponseData {
  data: SalesProcessResponse;
}

export const getSalesProcessItems = async ({
  sales_process_columns,
  ids,
}: GetSalesProcessItemsParams): Promise<SalesProcessResponse> => {
  const url = '/api/data/deals/sales_process/';
  const params = {
    sales_process_columns,
    ids,
    include_sales_process_detail: false,
  };

  const response = await axiosInstance.post<SalesProcessResponseData>(
    url,
    params
  );
  return response.data.data;
};
