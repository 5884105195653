import { css } from 'emotion';
import React from 'react';

const pile = css`
  color: var(--bu-primary-700);
  background-color: var(--bu-primary-100);
  border-radius: var(--bu-control-border-radius);
  padding: 2px 6px 2px 6px;
`;

interface SalesProcessDisplayProps {
  completedSteps: number;
  totalSteps: number;
  onClick?: () => void;
}

export const SalesProcessDisplay: React.FC<SalesProcessDisplayProps> = ({
  completedSteps,
  totalSteps,
  onClick,
}) => {
  if (!totalSteps) {
    return <div style={{ textAlign: 'center' }}> - </div>;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <span
        className={pile}
        onClick={onClick}
        role="button"
        tabIndex={0}
        aria-pressed={false}
      >
        {completedSteps} / {totalSteps}
      </span>
    </div>
  );
};
