import classNames from 'classnames';
import {
  pivotTitleSeparator,
  pivotTitleWrapper,
  pivotWidgetSearchOption,
} from 'components/dashboard/Metrics/Widget/Controls/Live/styles';
import { MONTH, QUARTER, WEEK } from 'components/dashboard/Metrics/constants';
import {
  DataDescriptor,
  BIPivotDescriptor,
} from 'components/dashboard/Metrics/metrics.types';
import { getIconByEntityName } from 'common/icons';
import { formatGroup } from 'components/dashboard/Metrics/Widget/TemplateFilters/helpers';

const buildTimeGroupName = (
  group: DataDescriptor,
  timeField: DataDescriptor | undefined
) => {
  const groupName = (timeField as BIPivotDescriptor)?.table_name
    ? `${formatGroup((timeField as BIPivotDescriptor).table_name!)} - `
    : '';

  switch (group.name) {
    case WEEK:
      return {
        name: `${timeField?.name}_WoW`,
        label: `${groupName}${timeField?.label}-WoW`,
        type: 'timePeriod',
      };
    case MONTH:
      return {
        name: `${timeField?.name}_MoM`,
        label: `${groupName}${timeField?.label}-MoM`,
        type: 'timePeriod',
      };
    case QUARTER:
      return {
        name: `${timeField?.name}_QoQ`,
        label: `${groupName}${timeField?.label}-QoQ`,
        type: 'timePeriod',
      };
    default:
      return {
        name: group?.name.replace('$', '.'),
        label: `${groupName}${group?.label}`,
        type: group?.type,
      };
  }
};

// this should be removed when we remove timeField property
export const selectedPivotLabel = (
  pivot: BIPivotDescriptor,
  timeField?: DataDescriptor | null
): string => {
  if (
    [QUARTER, MONTH, WEEK].includes(pivot?.name) &&
    timeField &&
    timeField.name
  ) {
    const groupReNamed = buildTimeGroupName(pivot, timeField);

    return groupReNamed.label;
  }

  // when the pivot comes from the response it doesn't have the table_name
  // so we need to extract it from the name
  const tableName = pivot?.table_name ?? pivot?.name.split('.')[0] ?? '';

  const groupName = tableName ? `${formatGroup(tableName)} - ` : '';

  return pivot?.label && pivot?.label !== 'None'
    ? `${groupName}${pivot.label}`
    : 'Add pivot';
};

const optionBuilder = (
  el: BIPivotDescriptor,
  idx: number,
  isNewGroup: boolean,
  title: string,
  itemText: string,
  onChange: (values: string[], pivotNro: number) => void,
  dataTestingId: string,
  pivotNro: number,
  groups: BIPivotDescriptor[]
) => {
  const IconPath = getIconByEntityName(el.table_icon);
  const groupName = title.replace('__c', ' C').replace('_', ' ');
  return (
    <>
      {isNewGroup ? (
        <div className={pivotTitleWrapper} key={groupName}>
          <img src={IconPath} />
          <div className={pivotTitleSeparator}>{groupName}</div>
        </div>
      ) : null}
      <div key={`${el.name}-${idx}`}>
        <div
          key={el.name}
          className={classNames(pivotWidgetSearchOption, {
            selected: groups[pivotNro]?.name === el.label,
          })}
          onClick={() => onChange([el.name], pivotNro)}
          data-testing={`${dataTestingId}-${idx}`}
          data-testing-value={`${title} - ${itemText}`}
        >
          {itemText}
        </div>
      </div>
    </>
  );
};

export const pivotOptionsBuilder = (
  options: BIPivotDescriptor[],
  onChange: (values: string[], pivotNro: number) => void,
  dataTestingId: string,
  searchingValue: string = '',
  pivotNro: number = 0,
  groups: BIPivotDescriptor[]
) => {
  let titleGroup = '';
  let isNewGroup = false;

  return [
    ...options
      .filter((el) =>
        el.label.toLowerCase().includes(searchingValue.toLowerCase())
      )
      .map((el, idx) => {
        const group = el.table_name ?? '';
        const optionLabel = el.label;
        isNewGroup = group !== titleGroup;
        titleGroup = group;

        return optionBuilder(
          el,
          idx,
          isNewGroup,
          group,
          optionLabel,
          onChange,
          dataTestingId,
          pivotNro,
          groups
        );
      }),
  ];
};

export const removePivotBuilder = (
  dataTestingId: string,
  index = 0,
  onChange: (values: string[], pivotNro: number) => void
): JSX.Element => (
  <div
    key="none"
    className={classNames(pivotWidgetSearchOption)}
    onClick={() => onChange(['none'], index)}
    data-testing={`${dataTestingId}-none`}
  >
    Remove pivot
  </div>
);
