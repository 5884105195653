import { css } from 'emotion';

export const tagContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-around;

  .label-tag {
    font-size: 10px;
    width: fit-content;
    height: 20px;
    border-radius: var(--bu-control-border-radius);
    padding: 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--bu-control-gap-small);
    color: var(--bu-gray-900);
    background-color: var(--bu-gray-300);

    &.uppercase {
      text-transform: uppercase;
    }

    i {
      font-size: 14px;
    }

    &.neutral {
      background-color: var(--bu-gray-400);
    }

    &.positive {
      background-color: var(--bu-green-300);
    }

    &.negative {
      background-color: var(--bu-red-300);
    }
  }
`;

// Could not avoid using !important here because of the Loader component
export const loadingStyle = css`
  position: relative !important;
  height: 12px !important;
  width: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
  left: 0 !important;
  font-size: 12px !important;
  z-index: 1 !important;

  &:before,
  &:after {
    width: 12px !important;
    height: 12px !important;
    font-size: 12px !important;
    margin: 0 !important;
    margin-top: 4px !important;
    animation: loader 3s linear infinite !important;
  }

  &.loading-left {
    margin-right: var(--bu-control-gap-small) !important;
  }

  &.loading-right {
    margin-left: var(--bu-control-gap-small) !important;
  }
`;
