import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';

import { Call, Organizer } from 'api/Calls';
import { deleteCall, postShareCall } from 'api/CallInsights';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuDropdown from 'components/UI/BuDropdown';
import { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { getActionTestingId } from 'common/helpers';
import { useDownloadFile } from 'utils/network/downloadFile';

import * as styles from './styles';
import { canDeleteOrSkipCall } from '../ExternalCallDetails/helpers';

interface CallActionButtonsProps {
  callId: string;
  currentPage: 'upcoming_calls' | 'completed_calls' | 'call_details';
  userRole: string;
  userEmail: string;
  organizer: Organizer;
  creator: Organizer;
  className?: string;
  shareId?: string;
  label: React.ReactNode | string;
  disableShareLink?: boolean;
  disableDownloadTranscript?: boolean;
  disableOriginalRecording?: boolean;
  externalCallUrl?: string;
}

const CallActionButtons: FC<CallActionButtonsProps> = ({
  callId,
  currentPage,
  userRole,
  userEmail,
  organizer,
  creator,
  className,
  shareId: savedShareId = '',
  label,
  disableShareLink = false,
  disableDownloadTranscript = false,
  disableOriginalRecording = false,
  externalCallUrl,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [shareId, setShareId] = useState<string | undefined>(savedShareId);

  const handleClickDeleteCall = (hide: () => void) => {
    setShowDeleteConfirmation(true);
    hide();
  };

  const handleClickDownloadTranscript = (hide: () => void) => {
    triggerTranscriptDownload();
    hide();
  };

  const handleClickCopyLink = (hide: () => void) => {
    copyLinkToClipboard();
    hide();
  };

  const handleClickOriginalRecording = (
    hide: () => void,
    sourceUrl: string
  ) => {
    window.open(sourceUrl, '_blank');
    hide();
  };

  const copyLinkToClipboard = async () => {
    let _shareId: string | undefined = shareId;

    if (!_shareId) {
      const { data: refetchedShareId } = await refetchPostShareCall();
      _shareId = refetchedShareId;
    }

    setShareId(_shareId);

    if (_shareId) {
      navigator.clipboard
        .writeText(`${window.location.origin}/external/call/${_shareId}`)
        .then(() => {
          toast.success('Link copied to the clipboard.', {
            position: 'bottom-left',
          });
        })
        .catch(() => {
          toast.error('Failed to copy link to the clipboard.', {
            position: 'bottom-left',
          });
        });
    } else {
      toast.error('Failed to copy link to the clipboard.', {
        position: 'bottom-left',
      });
    }
  };

  const {
    isPending: isDownloadingTranscript,
    error: transcriptDownloadError,
    triggerDownload: triggerTranscriptDownload,
  } = useDownloadFile({
    queryMethod: 'get',
    queryParams: {},
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/transcript/csv`,
  });

  useEffect(() => {
    if (transcriptDownloadError) {
      toast.error('Error downloading transcript', { position: 'bottom-left' });
    }
  }, [transcriptDownloadError]);

  useEffect(() => {
    if (isDownloadingTranscript) {
      toast.warn('Downloading transcript...', { position: 'bottom-left' });
    }
  }, [isDownloadingTranscript]);

  const deleteCallMutation = useMutation({
    mutationFn: () => deleteCall({ callId }),
    onMutate: () => {
      setShowDeleteConfirmation(false);
      toast.warn('Deleting call...', { position: 'bottom-left' });
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.status === 401) {
        toast.error('You are not authorized to delete this call', {
          position: 'bottom-left',
        });
      } else {
        toast.error('Error deleting call', { position: 'bottom-left' });
      }
    },
    onSuccess: () => {
      if (currentPage === 'call_details') {
        history.goBack();
      } else {
        queryClient.setQueryData(
          ['calls-listing', currentPage],
          (prevData: any) => {
            return {
              ...prevData,
              data: {
                ...prevData.data,
                calls: prevData.data.calls.filter(
                  (call: Call) => call.id !== callId
                ),
              },
            };
          }
        );
      }

      toast.success('Call deleted successfully', { position: 'bottom-left' });
    },
  });

  const { isFetching: isFetchingShareId, refetch: refetchPostShareCall } =
    useQuery({
      queryKey: ['post_share_call', callId],
      queryFn: () => postShareCall({ callId }),
      enabled: false,
    });

  useEffect(() => {
    if (isFetchingShareId) {
      toast.warn('Generating share link...', { position: 'bottom-left' });
    }
  }, [isFetchingShareId]);

  const dropdownOptions: {
    text: string;
    icon: BoostUpIcons;
    onClick: (hide: () => void) => void;
    disabled?: boolean;
    dividerTop?: boolean;
    className?: string;
  }[] = [
    {
      text: 'Share call link',
      icon: BoostUpIcons.Share,
      onClick: (hide: () => void) => handleClickCopyLink(hide),
      disabled: disableShareLink,
    },
    {
      text: 'Download transcript (csv)',
      icon: BoostUpIcons.Download,
      onClick: (hide: () => void) => handleClickDownloadTranscript(hide),
      disabled: disableDownloadTranscript,
    },
    {
      dividerTop: true,
      disabled: !canDeleteOrSkipCall(
        userRole,
        userEmail,
        organizer as Organizer,
        creator as Organizer
      ),
      text: 'Delete call',
      icon: BoostUpIcons.Trash,
      className: 'delete-call-option',
      onClick: (hide: () => void) => handleClickDeleteCall(hide),
    },
  ];

  if (externalCallUrl) {
    dropdownOptions.unshift({
      text: 'Original recording',
      icon: BoostUpIcons.ExternalLink,
      onClick: (hide: () => void) =>
        handleClickOriginalRecording(hide, externalCallUrl),
      disabled: disableOriginalRecording,
    });
  }

  const isIconDropdown = typeof label === 'string';

  return (
    <>
      <div className={classNames(className, styles.callActionsDropdownWrapper)}>
        <BuDropdown
          label={label}
          className={classNames(styles.callActionsDropdown, {
            'call-actions-dropdown-icon': isIconDropdown,
            'call-actions-dropdown-icon-hidden': !isIconDropdown,
          })}
          secondary={!isIconDropdown}
          size={!isIconDropdown ? BuControlSize.SMALL : undefined}
          iconDropdown={!isIconDropdown}
          placement="bottom-end"
          data-testing="call-actions-dropdown"
        >
          {(hide) =>
            dropdownOptions.map((option) => {
              const actionTestingId = `call-action-${getActionTestingId(
                option.text
              )}`;

              return (
                <a
                  className={classNames(
                    styles.callActionsDropdownItemAnchor,
                    option.className,
                    {
                      disabled: option.disabled,
                    }
                  )}
                  onClick={() =>
                    option.disabled ? null : option.onClick?.(hide)
                  }
                  data-testing={actionTestingId}
                >
                  {option.dividerTop && (
                    <div className={styles.callActionsDropdownItemDivider} />
                  )}

                  <div className={styles.callActionsDropdownItem}>
                    <div className="content">
                      <BuIcon name={option.icon} />
                      {option.text}
                    </div>
                  </div>
                </a>
              );
            })
          }
        </BuDropdown>
      </div>

      <BuConfirmationPopup
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => deleteCallMutation.mutate()}
        cancelText="Cancel"
        confirmText="Delete"
        headerText="Delete call recording?"
        confirmButtonColor="danger"
      >
        You are about to delete the metadata and recording for this call. Do you
        wish to continue?
      </BuConfirmationPopup>
    </>
  );
};

export default CallActionButtons;
