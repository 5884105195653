import { RevbiColumn, RevbiColumnMeta } from 'api/RevBiTableDisplay';
import {
  getWidthForColumn,
  getConfigForColumn,
  getAlignmentForColumn,
  mapRevbiColumnToPartialIColumn,
} from 'components/hooks/RevbiColumns.helper';
import { getSalesforceLink } from 'components/hooks/useManageDrilldownData';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';

import {
  IColumn,
  IRow,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { CallbackProps } from 'components/UI/TableConfig/column-helper';
import { get } from 'lodash';
import { openModal } from 'navigation/utils';
import { useSelector } from 'react-redux';
import {
  getUserLocalCurrency,
  isMulticurrencyEnabled as isMulticurrencyEnabledSelector,
  getSfBaseUrl,
  getFeatureFlags,
  getSalesforceUrl,
} from 'selectors';

import { getShowOrHideColumns } from 'selectors';

export type OnClick = (
  column: IColumn,
  row: IRow,
  extraData?: CallbackProps['extraData']
) => void;

export interface parseColumnsOptions {
  onClick: OnClick;
}

export const useManageRevbiTableConfigurationColumns = () => {
  const companyCurrency = useSelector(getUserLocalCurrency);
  const isMulticurrencyEnabled = useSelector(isMulticurrencyEnabledSelector);
  const sfBaseUrl = useSelector(getSfBaseUrl);
  const { show_booleans_as_icons_enabled } = useSelector(getFeatureFlags);

  const parseColumns = (
    tableConfigColumns: RevbiColumn[],
    options: parseColumnsOptions
  ): IColumn[] =>
    tableConfigColumns.map((nonParsedColumn, idx) => {
      const partiallyParsed = mapRevbiColumnToPartialIColumn(nonParsedColumn);

      const align = getAlignmentForColumn(partiallyParsed);

      const widths = getWidthForColumn(partiallyParsed);

      const config = getConfigForColumn(partiallyParsed, {
        showBooleanAsIcons: show_booleans_as_icons_enabled,
        isMulticurrencyEnabled,
        companyCurrency,
        onClick: options.onClick,
      });

      const onCellClick =
        partiallyParsed.meta.type === 'clickable' ? options.onClick : null;

      return {
        ...partiallyParsed,
        id: `${partiallyParsed.field}-${idx}`,
        align,
        ...widths,
        config: {
          ...config,
          ...(onCellClick ? { click: onCellClick } : {}),
        } as unknown as TypedTableCellConfig,
      } as IColumn;
    });

  return {
    parseColumns,
  };
};

export const useSortColumns = () => {
  const showOrHideColumns = useSelector(getShowOrHideColumns);
  const sortColumns = (columns: IColumn[]): IColumn[] => {
    if (showOrHideColumns && showOrHideColumns.length) {
      const allColumns = [...columns];
      // create orderedColumns base on the ordering of showOrHideColumns
      const newOrderedColumns = showOrHideColumns.reduce(
        (pre: IColumn[], showOrHideCol) => {
          const idx = allColumns.findIndex(
            (c) => c.label === showOrHideCol.label
          );
          if (idx !== -1) {
            pre = pre.concat(allColumns.splice(idx, 1));
          }
          return pre;
        },
        []
      );
      // in case new column is added, there might be left over items in allColumns
      // those column will be added to the end
      return newOrderedColumns.concat(allColumns);
    } else {
      // no order specified. do nothing
      return columns;
    }
  };

  return {
    sortColumns,
  };
};

export const useCommonClickHandler = () => {
  const salesforceUrl = useSelector(getSalesforceUrl);

  const onClick = (column: IColumn, row: IRow): boolean => {
    const isScoreClicked = column.type === ColumnTypes.SCORE;
    if (isScoreClicked) {
      // TODO this should come from the column configuration
      // ideally re-using clickable_action on column meta,
      // with the path to the correct id and the table
      // for now, we are using a hack to get the account id from the row
      const isAccountScore = column.field.includes('account_risk');

      const scheme = isAccountScore ? '/account/:id' : '/deal/:id';

      const id = isAccountScore
        ? (row.opportunity as unknown as any).account?.id
        : row.id;

      openModal({
        scheme,
        params: { id },
      });
      return true;
    }

    const isClickable = column.meta.type === 'clickable';
    const clickableAction = column.meta.clickable_action as
      | RevbiColumnMeta['clickable_action']
      | undefined;

    const table = clickableAction?.table;
    const path = clickableAction?.path;
    const objectId = path && get(row, path);
    if (isClickable && clickableAction && typeof objectId === 'string') {
      switch (table) {
        case 'account':
          openModal({
            scheme: '/account/:id',
            params: { id: objectId },
          });
          break;
        case 'opportunity':
          openModal({
            scheme: '/deal/:id',
            params: { id: objectId },
          });
          break;
        default:
          if (salesforceUrl) {
            window.open(getSalesforceLink(salesforceUrl, objectId), '_blank');
          }
      }
      return true;
    }

    return false;
  };

  return {
    /**
     * Returns true if the click was handled, false otherwise.
     */
    onClick,
  };
};
