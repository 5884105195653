import axiosInstance from 'api/axiosInstance';
import {
  CallInsightsResponse,
  CallTodoResponse,
  Step,
  Comment,
  ExternalJoinCallData,
} from 'components/dashboard/Calls/CallDetails/types';
import { DealInsights } from 'components/deal/types';

type GetCallInsightsDataMethodParams = {
  callId: string;
};

type GetCallInsightsExternalDataMethodParams = {
  sharedCallId: string;
};

export const getCallInsightsData = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<CallInsightsResponse> => {
  const res = await axiosInstance.get<{ data: CallInsightsResponse }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${callId}/`
  );

  return res.data.data;
};

export const getCallTodoData = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<CallTodoResponse[]> => {
  const res = await axiosInstance.get<CallTodoResponse[]>(
    `${process.env.REACT_APP_BACKEND_URL}/api/todo?call_id=${callId}`
  );

  return res.data;
};

export const getCallInsightsExternalData = async ({
  sharedCallId,
}: GetCallInsightsExternalDataMethodParams): Promise<CallInsightsResponse> => {
  const res = await axiosInstance.get<{ data: CallInsightsResponse }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/shared/${sharedCallId}/`
  );

  return res.data.data;
};

export const getCallInsightsExternalJoinCallData = async ({
  sharedCallId,
}: GetCallInsightsExternalDataMethodParams): Promise<ExternalJoinCallData> => {
  const res = await axiosInstance.get<{ data: ExternalJoinCallData }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${sharedCallId}/join-call`
  );

  return res.data.data;
};

export const disableRecordingCall = async ({
  sharedCallId,
}: GetCallInsightsExternalDataMethodParams): Promise<void> => {
  await axiosInstance.put(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${sharedCallId}/disable-recording`
  );
};

export const postShareCall = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<string> => {
  const res = await axiosInstance.post<{ share_id: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${callId}/share/`
  );

  return res.data.share_id;
};

export const deleteCall = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<void> => {
  await axiosInstance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}`
  );
};

export const skipCall = async ({
  callId,
  shouldRecord,
}: GetCallInsightsDataMethodParams & {
  shouldRecord: boolean;
}): Promise<void> => {
  await axiosInstance.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/recording-status`,
    {
      should_record: shouldRecord,
    }
  );
};

export const putSalesProcess = async ({
  opportunity_id,
  sales_process,
  steps,
}: {
  opportunity_id: string;
  sales_process: string;
  steps: Record<string, Step['new_value']> | null;
}): Promise<DealInsights> => {
  const res = await axiosInstance.put<DealInsights>(
    `${process.env.REACT_APP_BACKEND_URL}/api/sales_process/update_fields`,
    {
      opportunity_id,
      sales_process,
      steps,
    }
  );

  return res.data;
};

export const patchTaskDone = async ({
  task_id,
  isTaskCompleted,
}: {
  task_id: string;
  isTaskCompleted: boolean;
}): Promise<{ status: string }> => {
  const res = await axiosInstance.patch<{ status: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/todo/${task_id}/${
      isTaskCompleted ? 'open' : 'complete'
    }`
  );

  return res.data;
};

export const patchTaskRegenerate = async ({
  task_id,
}: {
  task_id: string;
}): Promise<CallTodoResponse> => {
  const res = await axiosInstance.patch<CallTodoResponse>(
    `${process.env.REACT_APP_BACKEND_URL}/api/todo/${task_id}/regenerate`
  );

  return res.data;
};

export const getComments = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<Comment[]> => {
  const res = await axiosInstance.get<{ data: { comments: Comment[] } }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/comments`
  );

  return res.data.data.comments;
};

export const postComment = async ({
  callId,
  comment,
  startTime,
}: {
  callId: string;
  comment: string;
  startTime: number;
}): Promise<Comment> => {
  const res = await axiosInstance.post<{ data: Comment }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/comments`,
    {
      text: comment,
      start_time: startTime,
    }
  );

  return res.data.data;
};

export const updateComment = async ({
  callId,
  comment,
}: {
  callId: string;
  comment: Comment;
}): Promise<Comment> => {
  const res = await axiosInstance.put<{ data: Comment }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/comments/${comment.comment_id}`,
    {
      text: comment.text,
    }
  );

  return res.data.data;
};

export const deleteComment = async ({
  callId,
  commentId,
}: {
  callId: string;
  commentId: string;
}): Promise<void> => {
  await axiosInstance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/comments/${commentId}`
  );
};
