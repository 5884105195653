import React, { createContext, useContext, ReactNode, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  deleteComment,
  getComments,
  postComment,
  updateComment,
} from 'api/CallInsights';
import { Comment } from 'components/dashboard/Calls/CallDetails/types';
import { toast } from 'react-toastify';

export interface CommentsContextType {
  comments: Comment[];
  isFetchingComments: boolean;
  handlePostComment: (comment: string, startTime: number) => void;
  handleUpdateComment: (comment: Comment) => void;
  handleDeleteComment: (commentId?: string) => void;
  isSavingComment: boolean;
  comment: string;
  setComment: (comment: string) => void;
  editingComment: string;
  setEditingComment: (comment: string) => void;
  isEditingComment: boolean;
  setIsEditingComment: (isEditing: boolean) => void;
  selectedComment: Comment | null;
  setSelectedComment: (comment: Comment | null) => void;
  showDeleteConfirmation: boolean;
  setShowDeleteConfirmation: (show: boolean) => void;
}

const CommentsContext = createContext<CommentsContextType | undefined>(
  undefined
);

export const CommentsProvider: React.FC<{
  children: ReactNode;
  callId: string;
}> = ({ children, callId }) => {
  const queryClient = useQueryClient();

  const [comment, setComment] = useState('');
  const [editingComment, setEditingComment] = useState('');
  const [isEditingComment, setIsEditingComment] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<Comment | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const { data: comments = [], isFetching: isFetchingComments } = useQuery({
    queryKey: ['comments', callId],
    queryFn: () => getComments({ callId }),
    enabled: callId !== undefined,
  });

  const { mutateAsync: postCommentMutation, isLoading: isPostingComment } =
    useMutation({
      mutationFn: ({
        comment,
        startTime,
      }: {
        comment: string;
        startTime: number;
      }) => postComment({ callId, comment, startTime }),
      onSuccess: (comment: Comment) => {
        queryClient.setQueryData(['comments', callId], (prevComments: any) => {
          if (!prevComments) return [];

          const newComments = [...prevComments, comment].sort(
            (a, b) => a.start_time - b.start_time
          );

          return newComments;
        });
      },
      onError: () => {
        toast.error('Failed to add comment', {
          position: 'bottom-left',
        });
      },
    });

  const { mutateAsync: updateCommentMutation, isLoading: isUpdatingComment } =
    useMutation({
      mutationFn: (comment: Comment) => updateComment({ callId, comment }),
      onSuccess: (comment: Comment) => {
        queryClient.setQueryData(['comments', callId], (prevComments: any) => {
          if (!prevComments) return [];

          const newComments = prevComments.map((c: Comment) =>
            c.comment_id === comment.comment_id ? comment : c
          );

          return newComments;
        });
      },
      onError: () => {
        toast.error('Failed to update comment', {
          position: 'bottom-left',
        });
      },
    });

  const { mutateAsync: deleteCommentMutation, isLoading: isDeletingComment } =
    useMutation({
      mutationFn: (commentId: string) => deleteComment({ callId, commentId }),
      onSuccess: (_, commentId: string) => {
        queryClient.setQueryData(['comments', callId], (prevComments: any) => {
          if (!prevComments) return [];

          return prevComments.filter(
            (c: Comment) => c.comment_id !== commentId
          );
        });
      },
      onError: () => {
        toast.error('Failed to delete comment', {
          position: 'bottom-left',
        });
      },
    });

  const handlePostComment = async (comment: string, startTime: number = 0) => {
    await postCommentMutation({ comment, startTime });
  };

  const handleUpdateComment = async (comment: Comment) => {
    await updateCommentMutation(comment);
  };

  const handleDeleteComment = async (commentId?: string) => {
    if (!commentId) return;

    await deleteCommentMutation(commentId);
  };

  return (
    <CommentsContext.Provider
      value={{
        comments,
        isFetchingComments,
        handlePostComment,
        handleUpdateComment,
        handleDeleteComment,
        isSavingComment:
          isPostingComment || isUpdatingComment || isDeletingComment,
        comment,
        setComment,
        editingComment,
        setEditingComment,
        isEditingComment,
        setIsEditingComment,
        selectedComment,
        setSelectedComment,
        showDeleteConfirmation,
        setShowDeleteConfirmation,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

export const useCommentsContext = (): CommentsContextType => {
  const context = useContext(CommentsContext);
  if (!context) {
    throw new Error(
      'useCommentsContext must be used within a CommentsProvider'
    );
  }
  return context;
};
