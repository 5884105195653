import classNames from 'classnames';
import { isEmpty, isNil, last } from 'ramda';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Popup } from 'semantic-ui-react';

import * as dealsActions from 'actions/dealsActions';
import TickAlertCircle from 'assets/images/icons/tick_alert_circle.svg';
import { formatMoney } from 'common/numbers';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import BuTimePicker from 'components/UI/BuTimePicker';
import styles from 'components/UI/DealsFlatTableTS/Table/styles';
import { Deal } from 'components/UI/DealsFlatTableTS/Table/types';
import {
  ValueProp,
  ValueType,
} from 'components/UI/common/TypedTable/TypedTable';
import { BuCharLengthInfo } from 'components/UI/common/TypedTable/renderers/NoteCell';
import { Step } from 'components/UI/common/TypedTable/renderers/SalesProcessCell';
import DatePicker from 'components/dashboard/Forecast/MeddicSidePanel/DatePicker';
import {
  actionButtons,
  container,
  content,
  header,
  stepContainer,
  textAreaStyle,
  unsupportedField,
  dropDownContainer,
} from 'components/dashboard/Forecast/MeddicSidePanel/styles';
import { getUserLocalCurrency } from 'selectors';
import * as selectors from 'selectors';
import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

export type StepWithValue = Step & {
  value: ValueProp;
  user_changed?: boolean;
  hasAiNewValue?: boolean;
};

type IMeddicFieldProps = {
  step: StepWithValue;
  origValue?: ValueProp;
  onChange?(value: ValueType): void;
  companyCurrency: string;
  readOnly: boolean;
  handleUndoClicked?: () => void;
};

export const MeddicField: React.FC<IMeddicFieldProps> = ({
  step,
  origValue,
  onChange = () => {},
  companyCurrency,
  readOnly,
  handleUndoClicked = () => {},
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const renderField = (type: Step['type']) => {
    const isDisabled = readOnly || !step.editable;

    switch (type) {
      case 'bool':
        return (
          <BuDropdown
            className="meddic-field meddic-field-bool"
            label={!isNil(step.value) ? (step.value ? 'True' : 'False') : ''}
            secondary
            disabled={isDisabled}
            readOnly={!step.editable}
            fullWidth
            onOpen={() => setIsFocused(true)}
            onClose={() => setIsFocused(false)}
          >
            {(hide) => (
              <BuDropdownItemContainer>
                <BuDropdownItem
                  key="true"
                  value={step.value as string}
                  active={step.value === true}
                  onClick={() => {
                    hide();
                    onChange(true);
                  }}
                >
                  True
                </BuDropdownItem>
                <BuDropdownItem
                  key="false"
                  value={step.value as string}
                  active={step.value === false}
                  onClick={() => {
                    hide();
                    onChange(false);
                  }}
                >
                  False
                </BuDropdownItem>
              </BuDropdownItemContainer>
            )}
          </BuDropdown>
        );
      case 'time':
        return (
          <BuTimePicker
            className="meddic-field meddic-field-time"
            value={(step.value as string) || ''}
            onChange={(value) => onChange(value as string)}
            formats={{
              inputFormat: 'HH:mm',
              displayFormat: 'hh:mm A',
            }}
            disabled={isDisabled}
          />
        );
      case 'phone':
        return (
          <BuInput
            className="meddic-field meddic-field-phone"
            type="text"
            value={(step.value as string) || ''}
            disabled={isDisabled}
            readOnly={!step.editable}
            onChange={(e) => {
              const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
              if (e.target.value.length <= 1) {
                onChange(e.target.value.replace(/[^0-9+]/g, ''));
              } else if (e.target.value.match(regex)) {
                onChange(e.target.value);
              }
            }}
            style={{ width: '100%' }}
            placeholder="Type"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        );
      case 'text':
      case 'CRM Lookup':
        return (
          <BuInput
            type="text"
            value={(step.value as string) || ''}
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.value)}
            className="meddic-field meddic-field-text"
            style={{ width: '100%' }}
            placeholder="Type"
            readOnly={!step.editable}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        );
      case 'money':
        return (
          <div className="meddic-field meddic-field-money">
            <BuInput
              type="currency"
              code={companyCurrency}
              disabled={isDisabled}
              readOnly={!step.editable}
              value={(step.value as number) || 0}
              onChange={(e) => onChange(e.currentTarget.valueAsNumber)}
              style={{ width: '100%' }}
              placeholder="Type"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
        );
      case 'number':
        return (
          <BuInput
            className="meddic-field meddic-field-number"
            type="number"
            disabled={isDisabled}
            readOnly={!step.editable}
            value={(step.value as number) || 0}
            onChange={(e) => onChange(e.currentTarget.valueAsNumber)}
            style={{ width: '100%' }}
            placeholder="Type"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        );
      case 'percentage':
        return (
          <div className="meddic-field meddic-field-percentage">
            <BuInput
              type="number"
              postfix="%"
              disabled={isDisabled}
              readOnly={!step.editable}
              value={(step.value as number) || 0}
              onChange={(e) => onChange(e.currentTarget.valueAsNumber)}
              style={{ width: '100%' }}
              placeholder="Type"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
        );
      case 'note':
        const text = (step.value as string) || '';

        return (
          <div className="meddic-field-note-wrapper">
            <div
              className="meddic-field meddic-field-note"
              style={{ width: '100%' }}
            >
              <div className="content-wrapper">
                <textarea
                  className={classNames(textAreaStyle, {
                    error: step.length && text.length > step.length,
                  })}
                  onChange={(e) => onChange(e.target.value)}
                  value={(step.value as string) || ''}
                  disabled={isDisabled}
                  placeholder="Type"
                  readOnly={!step.editable}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  data-testing="txt_field"
                />
              </div>
            </div>
            <BuCharLengthInfo
              textLength={text.length}
              maxLength={step.length}
            />
          </div>
        );
      case 'date':
        return (
          <DatePicker
            className="meddic-field meddic-field-date"
            value={step.value as string}
            onChange={(newVal) => onChange(newVal as string)}
            disabled={isDisabled}
            onOpen={() => setIsFocused(true)}
            onClose={() => setIsFocused(false)}
          />
        );
      case 'picklist':
        return (
          <BuDropdown
            className="meddic-field meddic-field-picklist"
            label={
              `${step.value}`.length > 45 ? (
                <Popup
                  hoverable
                  content={step.value}
                  trigger={
                    <div className={classNames(styles.dropdownLabel)}>
                      {step.value}
                    </div>
                  }
                />
              ) : (
                <div className={classNames(styles.dropdownLabel)}>
                  {step.value}
                </div>
              )
            }
            secondary
            disabled={isDisabled}
            readOnly={!step.editable}
            fullWidth
          >
            {(hide) => (
              <BuDropdownItemContainer style={{ maxHeight: 300 }}>
                {step?.choices?.map((item) => (
                  <BuDropdownItem
                    key={item}
                    value={item}
                    active={step.value === item}
                    onClick={() => {
                      hide();
                      onChange(item);
                    }}
                  >
                    <div
                      title={`${item}`}
                      className={classNames(styles.dropdownLabel)}
                    >
                      {item}
                    </div>
                  </BuDropdownItem>
                ))}
              </BuDropdownItemContainer>
            )}
          </BuDropdown>
        );
      case 'multipicklist':
        const def =
          typeof step.value === 'string' && !isEmpty(step.value)
            ? step.value.split(';')
            : (step.value as string[]);
        return (
          <BuSelect
            className="meddic-field meddic-field-multipicklist"
            secondary
            isLargePlaceholder
            disabled={isDisabled}
            readOnly={!step.editable}
            multiselect
            placeholder="Not Available"
            defaults={def}
            returnFullList
            options={
              step?.choices?.map((item, i) => ({
                key: i,
                value: item,
                text: item,
              })) || []
            }
            onChange={(selection: any) => {
              onChange(selection.join(';'));
            }}
          />
        );
      default:
        return (
          <div className={unsupportedField}>
            Unsupported field type ({type})
          </div>
        );
    }
  };

  return (
    <div className={stepContainer}>
      <div className="header">
        <div className="text">{step.step_name}</div>

        {step.hasAiNewValue && !step.user_changed && (
          <TooltipWrapper position="top center" tooltip={step.explanation}>
            <div className="icon-wrapper">
              <BuIcon name={BoostUpIcons.AiIcon} />
            </div>
          </TooltipWrapper>
        )}

        {step.hasAiNewValue && step.user_changed && (
          <TooltipWrapper position="top center" tooltip="Reset value">
            <BuButton
              borderless
              secondary
              className="icon-wrapper"
              onClick={handleUndoClicked}
            >
              <BuIcon
                name={BoostUpIcons.AiUndo}
                color="var(--bu-primary-700)"
              />
            </BuButton>
          </TooltipWrapper>
        )}

        {step.editable && (isNil(step.value) || isEmpty(step.value)) && (
          <div className="icon-wrapper">
            <BuIcon
              name={BoostUpIcons.BadgeWarningSolid}
              color="var(--bu-color-negative)"
            />
          </div>
        )}
      </div>

      <div
        className={classNames(
          'control',
          'side-panel-dropdown',
          dropDownContainer,
          {
            'meddic-field-with-ai': !!step.hasAiNewValue,
          }
        )}
      >
        {renderField(step.type)}
      </div>
    </div>
  );
};

export type ChangeObject = {
  [key: string]: ValueProp;
};

type IProps = {
  data: StepWithValue[];
  onCancel(): void;
  salesProcessVersion: number;
  sidePanelDeal: Deal | undefined;
  salesProcessName: string;
  isRealTime?: boolean;
};

const MeddicSidePanel: React.FC<IProps> = ({
  data,
  salesProcessVersion,
  sidePanelDeal,
  salesProcessName,
  onCancel,
  isRealTime = false,
}) => {
  const [steps, setSteps] = useState<StepWithValue[]>([]);
  const dispatch = useDispatch();

  const isReadOnlyUser = useSelector(selectors.isReadOnlyUser);
  const [companyCurrency, isAdmin, isAdminSFDCUpdateAllowed] = useSelector(
    (state) => [
      getUserLocalCurrency(state),
      selectors.isAdmin(state),
      selectors.getIsAdminSFDCUpdateAllowed(state),
    ],
    shallowEqual
  );

  const currencyValueFormatter = (value: number) =>
    formatMoney(companyCurrency as string, value, 0);

  const amount = currencyValueFormatter(
    (sidePanelDeal?.amount.value || 0) / (sidePanelDeal?.exchange_rate ?? 1)
  );

  useEffect(() => {
    setSteps(data.map((step) => ({ ...step })));
  }, [JSON.stringify(data)]);

  const handleFieldChange = (step: StepWithValue) => (value: ValueType) => {
    setSteps(
      steps.map((item) =>
        item.object_field === step.object_field ? { ...step, value } : item
      )
    );
  };

  const changeSalesProcess = (id: string, steps: Record<string, any>) => {
    if (isAdmin && !isAdminSFDCUpdateAllowed) {
      toast.error('Admin not allowed.', { position: 'bottom-left' });
      dispatch(dealsActions.refreshDeals());
    } else {
      const stepsPayload = steps.reduce(
        (acc: Record<string, StepWithValue['value']>, step: StepWithValue) => {
          acc[step.step_name] = step.value;
          return acc;
        },
        {} as ChangeObject
      );

      const changes = steps.reduce(
        (acc: Record<string, StepWithValue['value']>, step: StepWithValue) => {
          acc[last(step.object_field.split('.')) as string] = step.value;
          return acc;
        },
        {} as ChangeObject
      );

      dispatch(
        dealsActions.updateSalesProcess(
          id,
          salesProcessName,
          stepsPayload,
          changes,
          isRealTime
        )
      );
    }
  };

  const handleSave = () => {
    // the API only allow string values.
    // here we evaluate if the value is an Array and transform it to string value.
    const newSteps = steps.map((step: StepWithValue) => {
      const v = Array.isArray(step.value) ? step.value.join(';') : step.value;
      return { ...step, value: v ?? null };
    });

    const changes = newSteps.reduce((acc, step) => {
      acc[step.step_name] = step.value;
      return acc;
    }, {} as ChangeObject);

    if (salesProcessVersion === 2) {
      changeSalesProcess(sidePanelDeal!._id, newSteps);
    } else {
      dispatch(
        dealsActions.changeDeal(sidePanelDeal!._id, changes, isRealTime)
      );
    }
  };

  const fields = steps.map((step, index) => (
    <MeddicField
      key={step.object_field}
      readOnly={isReadOnlyUser}
      step={step}
      origValue={data[index]?.value}
      onChange={handleFieldChange(step)}
      companyCurrency={companyCurrency as string}
    />
  ));

  return (
    <div className={container}>
      <div className={content}>
        <div className={header}>
          <div className="header" data-testing={`header_company`}>
            {sidePanelDeal?.opportunity_name}
          </div>
          <span className="sub-header" data-testing={`header_amount`}>
            Amount: {amount}
          </span>
        </div>

        {fields}
      </div>

      <div className={actionButtons}>
        <BuButton
          secondary
          size={BuControlSize.REGULAR}
          onClick={onCancel}
          data-testing={`side_panel_cancel`}
        >
          Cancel
        </BuButton>
        <BuButton
          disabled={isReadOnlyUser}
          size={BuControlSize.REGULAR}
          onClick={handleSave}
          data-testing={`side_panel_save`}
        >
          Save
        </BuButton>
      </div>
    </div>
  );
};

export default MeddicSidePanel;
