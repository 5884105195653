import { css } from 'emotion';

import { fontHeading3, fontSubheading1, fontBody } from 'assets/css/fontStyles';

export const cardStyle = css`
  padding: 12px 20px;
  box-shadow: var(--bu-shadow-basic);
  background-color: var(--bu-white);
`;

export const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const textAlignCenter = css`
  text-align: center;
`;

export const showMore = css`
  ${fontSubheading1}
  color: var(--bu-primary-700);
  padding: 0;

  i {
    color: var(--bu-primary-700) !important;
  }
`;

export const notAvilableContent = css`
  ${fontHeading3}
  padding-top: 24px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--bu-control-gap-medium);

  .not-available-title {
    ${fontSubheading1}
  }

  .not-available-description {
    ${fontBody}
  }

  a {
    font-family: var(--bu-font-medium);
    text-decoration: underline;
    color: var(--bu-primary-700);
    cursor: pointer;
  }
`;

export const removeTooltipBefore = css`
  margin: 0.5em 0 0 !important;

  ::before {
    content: none !important;
  }
`;

export const errorMessage = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--bu-control-gap-medium);
  color: var(--bu-gray-900);

  .error-message-description {
    ${fontBody}
    color: var(--bu-gray-700);
  }
`;

export const containerWrapper = css`
  height: 100%;
  width: 100%;
  background-color: var(--bu-white);
  overflow: auto;
  color: var(--bu-gray-900);
`;

export const header = css`
  position: sticky;
  top: 0;
  z-index: 999;
  height: 48px;
  background-color: var(--bu-gray-200);
  border-bottom: 1px solid var(--bu-table-border-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;

  @media (max-width: 1450px) {
    height: 68px;
  }

  .back-button {
    cursor: pointer;
    padding: 0 6px;
    margin: 0;

    i {
      font-size: 16px;
      color: var(--bu-primary-700);
    }
  }

  .header-info-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: var(--bu-control-gap-xxx-large);
  }
`;

export const content = css`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: var(--bu-control-gap-extra-large);
  padding: 16px 24px;

  &.error {
    grid-template-columns: 1fr;
  }
`;

export const divider = css`
  border-right: 1px solid var(--bu-gray-400);
  height: 28px;
  margin: 0 18px;

  &.minor {
    height: 16px;
    margin: 0 12px;
  }

  @media (max-width: 1280px) {
    height: 38px;
    margin: 0 12px;
  }
`;

export const callSummaryInsightActionItemsTranscript = css`
  background-color: var(--bu-primary-100);
  max-height: 80vh;
  position: sticky;
  top: 60px;

  > div {
    height: calc(100% - 40px);
  }

  .ui.secondary.pointing.menu {
    border-bottom: 1px solid var(--bu-gray-400);
    margin-bottom: 0;
    overflow-x: auto;
    overflow-y: hidden;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      &.active {
        position: relative;
        border-color: transparent !important;
        box-shadow: 0 -2px 0 var(--bu-primary-700) inset;
      }
    }
  }
`;

export const callInviteWrapper = css`
  background-color: var(--bu-primary-100);
  max-height: 80vh;

  .call-invite-wrapper {
    ${cardStyle}
  }
`;

export const tabContentWrapper = css`
  padding: 12px 16px;
  overflow-y: auto;
  position: relative;
  height: 100%;

  &.no-padding-top {
    padding-top: 0;
  }

  &.no-padding-right {
    padding-right: 0;
  }

  &.no-scroll {
    overflow: hidden;
  }
`;

export const requestErrorWrapper = css`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .error-message {
    ${errorMessage}
    margin-top: 48px;
  }
`;
