import axiosInstance from './axiosInstance';
import {
  DefaultDateColumnPerTable,
  DrilldownTableSettingsDictionary,
  RevBISettings,
  REV_BI_SETTINGS,
  TablePrimaryKey,
} from 'components/dashboard/Metrics/contexts/RevBISettingsContext';

const transformPrimaryKeys = (
  rawPrimaryKeys: Record<string, string | null> | undefined
): Record<string, TablePrimaryKey> => {
  const transformedKeys: Record<string, TablePrimaryKey> = {};

  if (!rawPrimaryKeys) {
    return {};
  }

  Object.entries(rawPrimaryKeys).forEach(([table, fullPath]) => {
    // Value in some cases can be null
    // In that case we discard that value
    // In real scenarios it shouldn't be possible
    // To define a drilldown (where this config is used) if the object has no id
    // So it shouldn't be a problem
    if (fullPath) {
      transformedKeys[table] = {
        keyProperty: fullPath.split('.').pop() || '',
        fullTablePath: fullPath,
      };
    }
  });

  return transformedKeys;
};

export type RevBISettingsResponse = {
  primary_keys_per_table: Record<string, string>;
  manager_hierarchy_column_names: string[];
  max_number_of_pivots: number;
  drilldown_table_settings: DrilldownTableSettingsDictionary;
  table_aliases_configs: Record<string, string>;
  hide_widget_exploratory_view: boolean;
  warning_elapsed_update_time_seconds: number;
  waiting_time_to_refresh_data_seconds: number;
  default_date_column_per_table: DefaultDateColumnPerTable;
  changes_since_to_core: Record<string, string>;
  table_settings: Record<string, string>;
};

export const getRevBISettings = async (): Promise<RevBISettings> => {
  const rawData = await axiosInstance.get<
    string,
    { data: RevBISettingsResponse }
  >(`/rev_bi/external/settings`);

  const {
    primary_keys_per_table,
    manager_hierarchy_column_names,
    max_number_of_pivots,
    drilldown_table_settings,
    table_aliases_configs,
    hide_widget_exploratory_view,
    warning_elapsed_update_time_seconds,
    waiting_time_to_refresh_data_seconds,
    default_date_column_per_table,
    changes_since_to_core,
    table_settings,
    ...rest
  } = rawData.data;

  const newRevBISettings: RevBISettings = {
    ...REV_BI_SETTINGS,
    ...rest,
    primaryKeysPerTable: transformPrimaryKeys(primary_keys_per_table),
    maxAvailablePivots: max_number_of_pivots,
    // for some reason we still using this alias
    // and be is not returning it as an option.
    hierarchyAlias: ['user$name', ...manager_hierarchy_column_names],
    drilldownTableSettings: drilldown_table_settings,
    tableAliasesConfigs: table_aliases_configs,
    hideWidgetExploratoryView: hide_widget_exploratory_view,
    warningElapsedUpdateTimeSeconds:
      warning_elapsed_update_time_seconds ?? 3600,
    waitingTimeToRefreshWidgetSeconds:
      waiting_time_to_refresh_data_seconds ?? 3,
    defaultDateColumnPerTable: default_date_column_per_table ?? {},
    changeIntervalMapToCore: changes_since_to_core ?? {},
    tableSettings: table_settings ?? {},
  };

  return newRevBISettings;
};
