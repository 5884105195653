import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutateAsyncFunction,
} from '@tanstack/react-query';
import { Feedback } from 'contexts/AIChatContext';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { getUser } from 'selectors';

import {
  getChatHistory,
  startNewChat,
  sendChatMessage,
  ChatContext,
  ChatMessage,
  sendMessageFeedback,
  ChatHistoryResponse,
} from '../api/AIChat';

interface UseAIChatService {
  chatEnabled: boolean;
}

interface SendFeedback {
  messageId: string;
  feedback: Feedback;
}

export type SendFeedbackMutation = UseMutateAsyncFunction<
  SendFeedback,
  unknown,
  SendFeedback,
  unknown
>;

type ChatHistoryMutate = ChatHistoryResponse | undefined;

export const useAIChatService = ({ chatEnabled }: UseAIChatService) => {
  const queryClient = useQueryClient();

  const user = useSelector(getUser);

  // Because of impersonation when the user is on admin page admin/dashboard
  // The call should not happen as it will be done using the token of the original user
  // So we need to disable the query until the user is not on the admin page
  // WHere we can be sure that the token is the one of the impersonated user
  // This is a workaround to avoid the issue
  const location = useLocation();
  const isAdminPage = location.pathname.includes('/admin/dashboard');

  const {
    data: chatHistory,
    isLoading: isLoadingHistory,
    isError: isHistoryError,
  } = useQuery({
    queryKey: ['chatHistory', user?.login_time],
    queryFn: getChatHistory,
    enabled: !isAdminPage && chatEnabled,
    cacheTime: 0,
    staleTime: 0,
  });

  const { mutateAsync: initializeChat, isLoading: isInitializingChat } =
    useMutation(startNewChat, {
      onMutate: () => {
        queryClient.setQueryData(['chatHistory', user?.login_time], () => ({
          history: [],
        }));
      },
    });

  const {
    mutate: sendMessage,
    isLoading: isSendingMessage,
    isError: isSendMessageError,
    data: messageResponse,
  } = useMutation(
    ({ query, context }: { query: string; context: ChatContext }) =>
      sendChatMessage(query, context),
    {
      onMutate: ({ query }) => {
        const userMessage: ChatMessage = {
          sender: 'user',
          message: query,
          created_at: new Date().toISOString(),
        };

        queryClient.setQueryData(
          ['chatHistory', user?.login_time],
          (oldData: ChatHistoryMutate) => {
            const currentHistory = oldData?.history || [];
            return {
              history: [...currentHistory, userMessage],
            };
          }
        );
      },
      onSuccess: (response: ChatMessage) => {
        queryClient.setQueryData(
          ['chatHistory', user?.login_time],
          (oldData: ChatHistoryMutate) => {
            const currentHistory = oldData?.history || [];
            return {
              history: [...currentHistory, response],
            };
          }
        );
      },
      onError: () => {
        queryClient.setQueryData(
          ['chatHistory', user?.login_time],
          (oldData: ChatHistoryMutate) => {
            const currentHistory = oldData?.history || [];
            return {
              history: [
                ...currentHistory,
                {
                  sender: 'assistant',
                  message:
                    'Ops, something went wrong. Please try again in a few seconds.',
                  created_at: new Date().toISOString(),
                } as ChatMessage,
              ],
            };
          }
        );
      },
    }
  );

  const { mutateAsync: sendFeedback, isLoading: isSendingFeedback } =
    useMutation(
      ({ messageId, feedback }: SendFeedback) =>
        sendMessageFeedback(messageId, feedback),
      {
        onSuccess: (_, { messageId, feedback }: SendFeedback) => {
          queryClient.setQueryData(
            ['chatHistory', user?.login_time],
            (oldData: ChatHistoryMutate) => {
              const currentHistory = oldData?.history || [];
              return {
                history: currentHistory.map((message: ChatMessage) =>
                  message.id === messageId
                    ? {
                        ...message,
                        feedback: {
                          ...message.feedback,
                          sentiment: feedback,
                        },
                      }
                    : message
                ),
              };
            }
          );
        },
      }
    );

  return {
    chatHistory: chatHistory?.history || [],
    isLoadingHistory,
    isHistoryError,
    initializeChat,
    isInitializingChat,
    sendMessage,
    isSendingMessage,
    isSendMessageError,
    messageResponse,
    sendFeedback,
    isSendingFeedback,
  };
};
