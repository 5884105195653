import React from 'react';
import classNames from 'classnames';

import { h2Markdown, ulMarkdown, bigFont } from './styles';

interface H2Props {
  children: React.ReactNode;
  big?: boolean;
}

export const H2 = ({ children, big = false, ...props }: H2Props) => {
  return (
    <h2
      {...props}
      className={classNames(h2Markdown, {
        [bigFont]: big,
      })}
    >
      {children}
    </h2>
  );
};

interface UlProps {
  children: React.ReactNode;
  big?: boolean;
}

export const Ul = ({ children, big = false, ...props }: UlProps) => {
  return (
    <ul
      {...props}
      className={classNames(ulMarkdown, {
        [bigFont]: big,
      })}
    >
      {children}
    </ul>
  );
};

interface OlProps {
  children: React.ReactNode;
  big?: boolean;
}

export const Ol = ({ children, big = false, ...props }: OlProps) => {
  return (
    <ol
      {...props}
      className={classNames(ulMarkdown, {
        [bigFont]: big,
      })}
    >
      {children}
    </ol>
  );
};
