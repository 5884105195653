import { isEmpty } from 'ramda';
import React from 'react';

import { RecognizedTableConfigurationTypes } from 'components/UI/TableConfig/types';
import {
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import { SalesProcessDisplay } from './SalesProcessDisplay';

export type Step = {
  step_name: string;
  field_name: string;
  display_name: string;
  crm_field: string;
  type: RecognizedTableConfigurationTypes['type'];
  editable: boolean;
  sortable: boolean;
  object_field: string;
  protected: boolean;
  length?: number;
  choices?: string[];
  meta?: {
    delta: boolean;
  };
  /**
   * Indicates if it's AI automatically generated
   */
  explanation?: string | null;
};

export interface ISalesProcessCellConfig extends TypedTableCellConfig {
  steps: Step[];
  version?: number;
  name: string;
  click(column: IColumn, row: IRow): void;
}

const SalesProcessCell: React.FC<IDataCellProps> = ({ column, row }) => {
  const {
    steps,
    click: callback,
    version,
    name,
  } = column.config as ISalesProcessCellConfig;

  const stepValues = steps.map((step) => getFieldValue(step.object_field, row));
  const salesProcess = (row?.sales_process as any)?.[name] || {};

  if (version === 2 && isEmpty(salesProcess)) {
    return <SalesProcessDisplay completedSteps={0} totalSteps={0} />;
  }

  const completedSteps = stepValues.filter((v) => !!v && !isEmpty(v)).length;

  return (
    <SalesProcessDisplay
      completedSteps={completedSteps}
      totalSteps={steps.length}
      onClick={() => callback(column, row)}
    />
  );
};

export default SalesProcessCell;
