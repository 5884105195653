import { css } from 'emotion';

const styles = {
  body_column: css`
    display: flex;
    flex-direction: column;
    padding: 13px 0;
    overflow: hidden;
  `,
  body_column_row: css`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 5px 0;
    height: fit-content;
  `,
  body_column_column: css`
    display: flex;
    flex-direction: column;
  `,
  deal_name: css`
    color: var(--bu-clickable-cell);
    font-size: 1em !important;
    line-height: 19px;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  deal_ae: css`
    color: var(--bu-gray-700);
    font-size: 0.85em;
    line-height: 19px;
    font-weight: 300;
  `,
  attendees_field: css`
    display: flex;
    flex-direction: row;
    padding: 3px 0;
  `,
  attendees_name: css`
    height: 19px;
    width: fit-content;
    max-width: 250px;
    margin-right: 3px;
    color: var(--bu-gray-900);
    font-size: 1em;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  btn_show_Propspect: css`
    display: flex;
    align-items: flex-end;
    color: var(--bu-primary-500);
    padding: 3px 0;
    font-size: 1em;
    line-height: 19px;
    cursor: pointer;
  `,
  red_text: css`
    color: rgb(238, 114, 78);
    font-size: 1em;
    font-weight: bold;
  `,
  table_body_spanText: css`
    font-size: 1em;
    color: var(--bu-gray-700);
  `,
  call_audio_column: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 14px;
    width: 75px;
    height: 24px;
    box-sizing: border-box;
    background: #f7f7f7;
    border-radius: 15px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #4a4a4a;
    position: relative;
    transition: 0.4s;
    margin-right: 25px;
    font-family: var(--bu-font-regular);

    &:focus {
      outline: none;
      text-decoration: none;
    }

    img {
      position: absolute;
      width: 24px;
      height: 24px;
      top: -1px;
      right: -1px;
    }

    &.margin {
      margin: 5px 5px 5px 0;
    }
  `,
};

export default styles;
