import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import classNames from 'classnames';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IconBrowserX, IconCalendarCheck } from '@tabler/icons-react';

import {
  disableRecordingCall,
  getCallInsightsExternalJoinCallData,
} from 'api/CallInsights';
import boostupLogo from 'assets/images/new_icon/boostup_logo.svg';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';

import * as styles from './styles';

type Props = RouteComponentProps<{
  id: string;
}>;

const ExternalJoinCall: React.FC<Props> = ({ match }) => {
  const sharedCallId = match.params.id;

  const [currentTime, setCurrentTime] = useState(new Date());

  const {
    isFetching: isCallInsightsExternalJoinCallFetching,
    data: callInsightsExternalJoinCallData,
    error: callInsightsExternalJoinCallError,
  } = useQuery({
    queryKey: ['call_insights_external_join_call', sharedCallId],
    queryFn: async () => getCallInsightsExternalJoinCallData({ sharedCallId }),
    retry: false,
  });

  const {
    mutateAsync: disableRecordingCallMutation,
    isLoading: isDisableRecordingCallPending,
  } = useMutation({
    mutationFn: async ({ sharedCallId }: { sharedCallId: string }) =>
      disableRecordingCall({ sharedCallId }),
  });

  // Update the status more frequently for better responsiveness
  const updateCurrentTime = useCallback(() => {
    setCurrentTime(new Date());
  }, []);

  useEffect(() => {
    // Initial update when component mounts
    updateCurrentTime();

    // Set interval for regular updates (every 10 seconds for more responsive UI)
    const intervalId = setInterval(updateCurrentTime, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [updateCurrentTime]);

  const callHasEnded = useMemo(() => {
    if (!callInsightsExternalJoinCallData) return false;

    const endTime = new Date(callInsightsExternalJoinCallData.end);

    return endTime < currentTime;
  }, [callInsightsExternalJoinCallData, currentTime]);

  const callIsHappening = useMemo(() => {
    if (!callInsightsExternalJoinCallData) return false;

    const startTime = new Date(callInsightsExternalJoinCallData.start);
    const endTime = new Date(callInsightsExternalJoinCallData.end);

    return startTime < currentTime && endTime > currentTime;
  }, [callInsightsExternalJoinCallData, currentTime]);

  const redirectToCall = () => {
    if (callInsightsExternalJoinCallData?.meeting_url) {
      window.location.href = callInsightsExternalJoinCallData?.meeting_url;
    }
  };

  const handleClickDisableRecordingAndJoin = async () => {
    if (!callInsightsExternalJoinCallData?.id || isDisableRecordingCallPending)
      return;

    await disableRecordingCallMutation({
      sharedCallId: callInsightsExternalJoinCallData?.id,
    });

    redirectToCall();
  };

  const handleClickJoinCall = () => {
    redirectToCall();
  };

  return (
    <main className={styles.containerWrapper}>
      {isCallInsightsExternalJoinCallFetching && (
        <Dimmer inverted active={isCallInsightsExternalJoinCallFetching}>
          <Loader />
        </Dimmer>
      )}

      <div className="card-disable-recording-join-call">
        {callInsightsExternalJoinCallError && (
          <div className="consent-recording-join-call">
            <BuIcon name={IconBrowserX} color="var(--bu-red-500)" size={48} />

            <span className="consent-recording-join-call-sub-title">
              We cannot find the call you are trying to join
            </span>
          </div>
        )}

        {callInsightsExternalJoinCallData &&
          !callInsightsExternalJoinCallError && (
            <>
              {callIsHappening && (
                <div className="consent-recording-join-call">
                  <span className="consent-recording-join-call-title">
                    {callInsightsExternalJoinCallData.summary}
                  </span>

                  <span>
                    {moment(callInsightsExternalJoinCallData.start).format(
                      'Do MMMM [at] h:mma'
                    )}
                  </span>

                  <span>
                    {callInsightsExternalJoinCallData.attendees
                      .slice(0, 3)
                      .join(', ')}
                    {callInsightsExternalJoinCallData.attendees.length > 3 &&
                      `, +${
                        callInsightsExternalJoinCallData.attendees.length - 3
                      } more`}
                  </span>

                  <BuButton onClick={handleClickJoinCall}>Join call</BuButton>

                  {callInsightsExternalJoinCallData.status === 'scheduled' && (
                    <span className="disclaimer-text">
                      The call will be recorded. <br />
                      You can request the host to disable the recording after
                      joining the call.
                    </span>
                  )}
                </div>
              )}

              {callHasEnded && (
                <div className="consent-recording-join-call">
                  <BuIcon
                    name={IconCalendarCheck}
                    color="var(--bu-gray-900)"
                    size={48}
                  />

                  <span className="consent-recording-join-call-title">
                    {callInsightsExternalJoinCallData.summary}
                  </span>

                  <span>
                    {moment(callInsightsExternalJoinCallData.start).format(
                      'Do MMMM [at] h:mma'
                    )}
                  </span>

                  <span>
                    {callInsightsExternalJoinCallData.attendees
                      .slice(0, 3)
                      .join(', ')}
                    {callInsightsExternalJoinCallData.attendees.length > 3 &&
                      `, +${
                        callInsightsExternalJoinCallData.attendees.length - 3
                      } more`}
                  </span>

                  <br />

                  <span className="consent-recording-join-call-sub-title">
                    The call has already ended.
                  </span>
                </div>
              )}

              {!callIsHappening && !callHasEnded && (
                <div className="consent-recording-join-call">
                  <span className="consent-recording-join-call-title">
                    {callInsightsExternalJoinCallData.summary}
                  </span>

                  <span>
                    {moment(callInsightsExternalJoinCallData.start).format(
                      'Do MMMM [at] h:mma'
                    )}
                  </span>

                  <span>
                    {callInsightsExternalJoinCallData.attendees
                      .slice(0, 3)
                      .join(', ')}
                    {callInsightsExternalJoinCallData.attendees.length > 3 &&
                      `, +${
                        callInsightsExternalJoinCallData.attendees.length - 3
                      } more`}
                  </span>

                  <BuButton onClick={handleClickJoinCall}>Join call</BuButton>

                  {callInsightsExternalJoinCallData.status === 'scheduled' && (
                    <a
                      onClick={handleClickDisableRecordingAndJoin}
                      className={classNames({
                        disabled: isDisableRecordingCallPending,
                      })}
                    >
                      Disable recording and join call
                    </a>
                  )}
                </div>
              )}
            </>
          )}
      </div>

      <div className="powered-by-boostup">
        <span>Powered by</span>
        <img src={boostupLogo} alt="Boostup Logo" />
      </div>
    </main>
  );
};

export default ExternalJoinCall;
