import React, { useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Organizer } from 'api/Calls';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { AI_CHAT_ACTIONS } from 'common/constants';
import BuIcon from 'components/UI/BuIcon';
import BuButton from 'components/UI/BuButton';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import Tooltip from 'components/UI/common/Tooltip';
import { useAIChat } from 'contexts/AIChatContext';
import { getFeatureFlags, getUser } from 'selectors';

import * as styles from './styles';
import CallInvite from './CallInvite';
import { Attendee, CallInsightsResponse, Participant } from '../types';
import RoundedAvatarIcon from '../RoundedAvatarIcon';
import {
  formatDurationToFriendlyDurationString,
  formatDateToFriendlyTimeString,
  getUniqueNameOrEmail,
} from '../helpers';
import CallActionButtons from '../../CallActionButtons';

type Props = {
  attendees: Attendee[];
  participants: Participant[];
  speakerContactMap: CallInsightsResponse['speaker_contact_map'];
  title: string;
  duration: number;
  start: string;
  description: string;
  speakerPercentages: CallInsightsResponse['speaker_percentages'];
  isCallSummarized: boolean;
  hideInviteTooltip?: boolean;
  hideAttendeesTooltip?: boolean;
  callId?: string;
  shareId?: string;
  externalCallUrl?: string;
};

const HeaderDetails: React.FC<Props> = ({
  attendees,
  participants,
  speakerContactMap,
  title,
  duration,
  start,
  description,
  speakerPercentages,
  isCallSummarized,
  hideInviteTooltip = false,
  hideAttendeesTooltip = false,
  callId = '',
  shareId = '',
  externalCallUrl,
}) => {
  const { company_vs_prospect_enabled, chatbot_calls_enabled } = useSelector(
    (state) => getFeatureFlags(state)
  );
  const { role, email } = useSelector(getUser);
  const { showChat, setHideChat } = useAIChat();

  const organizer = useMemo(
    () => attendees.find((attendee) => attendee.organizer),
    [attendees]
  );

  const callStartDate = useMemo(
    () => formatDateToFriendlyTimeString(start),
    [start]
  );

  // If there is no participants, use attendees then
  const attendeesListForHeader = useMemo(
    () =>
      !!participants.length
        ? participants
        : attendees.map(
            (attendee) =>
              ({
                name: getUniqueNameOrEmail(attendee),
                speaker_percentage: 0,
                organizer: attendee.organizer,
                color: '',
                type: speakerContactMap
                  ?.find((contact) => contact.contact_email === attendee.email)
                  ?.contact_type?.toLowerCase() as Participant['type'],
              } as Participant)
          ),
    [attendees, participants]
  );

  const attendeesListForTooltip = useMemo(
    () =>
      attendeesListForHeader.slice().sort((a, b) => {
        if (a.organizer !== b.organizer) {
          return a.organizer ? -1 : 1;
        }

        return a.name.localeCompare(b.name);
      }),
    [attendeesListForHeader]
  );

  const callDateTooltip = (
    <div className={styles.callInviteTooltipWrapper}>
      <CallInvite
        attendees={attendees}
        title={title}
        description={description}
        callStartDate={callStartDate}
      />
    </div>
  );

  const durationTooltip = (
    <div className={styles.durationTooltipWrapper}>
      <div className="duration-total">
        Total Duration: {formatDurationToFriendlyDurationString(duration)}
        {!!speakerPercentages && (
          <span>
            Speaking time:{' '}
            {formatDurationToFriendlyDurationString(
              speakerPercentages.speak.duration
            )}
          </span>
        )}
      </div>

      {
        // TODO: Will have this in the future
        // <div className="duration-splitted customer">
        //   <span className="duration-label">Customer:</span>
        //   <span className="duration-value">
        //     {formatDurationToFriendlyDurationString(
        //       speakerTypePercentages.customer.duration
        //     )}
        //   </span>
        //   <span className="duration-percentage">
        //     ({speakerTypePercentages.customer.percentage.toFixed(2)}%)
        //   </span>
        // </div>
        // <div className="duration-splitted company">
        //   <span className="duration-label">Company:</span>
        //   <span className="duration-value">
        //     {formatDurationToFriendlyDurationString(
        //       speakerTypePercentages.company.duration
        //     )}
        //   </span>
        //   <span className="duration-percentage">
        //     ({speakerTypePercentages.company.percentage.toFixed(2)}%)
        //   </span>
        // </div>
      }
    </div>
  );

  const attendeesTooltip = (
    <div className={styles.attendeesListTooltipWrapper}>
      {attendeesListForTooltip.map((attendee) => (
        <div className="attendee" key={attendee.name}>
          <RoundedAvatarIcon color={attendee.color} />

          <div className="attendee-info">
            {!!attendee.name && <span>{attendee.name}</span>}
            {attendee.organizer && <span>Organizer</span>}
            {!!attendee.type && company_vs_prospect_enabled && (
              <span className="attendee-type">
                {attendee.type === 'customer' ? 'Prospect' : attendee.type}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const handleClickEnableChatbot = () => {
    setHideChat(false);
  };

  return (
    <div className={styles.headerDetailsWrapper}>
      <div className="call-info-wrapper">
        <Tooltip tooltip={title} className="call-name" position="bottom left">
          <>{title}</>
        </Tooltip>

        <div className="call-info-details">
          <TooltipWrapper
            tooltip={isCallSummarized && !hideInviteTooltip && callDateTooltip}
            testingLabel="call-date-tooltip"
            hoverable
            position="bottom left"
            popupClassName={styles.removeTooltipBefore}
          >
            <div className="detail">
              <BuIcon name={BoostUpIcons.Calendar} />
              <span>{callStartDate}</span>
            </div>
          </TooltipWrapper>

          <div className={classNames(styles.divider, 'minor')} />
          <TooltipWrapper
            tooltip={duration > 0 ? durationTooltip : null}
            testingLabel="duration-tooltip"
            hoverable
            position="bottom left"
            popupClassName={styles.removeTooltipBefore}
          >
            <div className="detail">
              <BuIcon name={BoostUpIcons.Clock} />
              <span>
                {formatDurationToFriendlyDurationString(duration, true)}
              </span>
            </div>
          </TooltipWrapper>

          <div className={classNames(styles.divider, 'minor')} />
          <TooltipWrapper
            tooltip={
              !hideAttendeesTooltip &&
              attendeesListForTooltip.length &&
              attendeesTooltip
            }
            testingLabel="attendees-tooltip"
            hoverable
            position="bottom left"
            popupClassName={styles.removeTooltipBefore}
          >
            <div className="detail">
              <BuIcon name={BoostUpIcons.UserGroup} />

              <span>
                {attendeesListForHeader.length} Attendee
                {attendeesListForHeader.length > 1 ? 's' : ''}
              </span>
            </div>
          </TooltipWrapper>

          {!!organizer && (
            <>
              <div className={classNames(styles.divider, 'minor')} />

              <div className="detail organizer">
                <BuIcon name={BoostUpIcons.User} />
                <span>{getUniqueNameOrEmail(organizer)}</span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="call-actions-wrapper">
        {!!callId && (
          <>
            <CallActionButtons
              callId={callId}
              currentPage="call_details"
              userRole={role}
              userEmail={email}
              organizer={organizer as Organizer}
              creator={organizer as Organizer}
              shareId={shareId}
              disableShareLink={!isCallSummarized}
              disableDownloadTranscript={!isCallSummarized}
              disableOriginalRecording={!isCallSummarized}
              externalCallUrl={externalCallUrl}
              label="Options"
            />

            {!showChat && chatbot_calls_enabled && (
              <BuButton secondary onClick={handleClickEnableChatbot}>
                <BuIcon name={BoostUpIcons.AiIcon} />
                Chatbot
              </BuButton>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderDetails;
