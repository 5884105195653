import { lazy } from 'react';
import { Scheme } from './types';

import ErrorPage from 'components/ErrorPage';
import FullScreenAccount from 'components/UI/FullScreenComponents/Account';
import FullScreenOpportunities from 'components/UI/FullScreenComponents/Opportunities';
import Dashboards from 'components/chart-dashboards';
import Dashboard from 'components/chart-dashboards/Dashboard';
import { DashboardPage } from 'components/dashboard/DashboardPage/DashboardPage';
import NewForecast from 'components/dashboard/Forecast/NewForecast';
import ForecastAnalytics from 'components/dashboard/ForecastAnalytics';
import ForecastDashboard from 'components/dashboard/ForecastDashboard';
import ForecastRollUps from 'components/dashboard/ForecastRollUps';
import CompletedMeetings from 'components/dashboard/Meetings/CompletedMeetings';
import UpcomingMeetings from 'components/dashboard/Meetings/UpcomingMeetings';
import { MetricCreate } from 'components/dashboard/Metrics/Create/MetricCreate/MetricCreate';
import { WidgetCreate } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreate';
import { RevBIContainer } from 'components/dashboard/Metrics/RevBiContainer';
import { RevBiDashboardContainer } from 'components/dashboard/Metrics/RevBiDashboardContainer';
import PipelineDashboard from 'components/dashboard/Pipeline/PipelineDashboard';
import PipelineDealsProgression from 'components/dashboard/Pipeline/PipelineDealsProgression';
import PipelineAnalytics from 'components/dashboard/PipelineAnalytics';
import Progression from 'components/dashboard/Progression';
import ProspectAccountDashboard from 'components/dashboard/ProspectAccountDashboard';
import RepsRecommendations from 'components/dashboard/RepsRecommendations';
import Scorecard from 'components/dashboard/Scorecard';
import SellerTargets from 'components/dashboard/SellerTargets/SellerTargets';
import AddThirdPartyIntegrationModal from 'components/settings/ThirdPartyIntegration/AddThirdPartyIntegrationModal';
import {
  CallDetails,
  ExternalCallDetails,
  ExternalJoinCall,
} from 'components/dashboard/Calls';
import AccountInsights from 'screens/Account';
import Accounts from 'screens/Accounts';
import Calls from 'screens/Calls';
import CallListing from 'components/dashboard/Calls/CallListing/Calls';
import Deal from 'screens/DealDetailed';
import Event from 'screens/Event';

type Route = {
  lazy?: boolean;
  exact?: boolean;
  strict?: boolean;
  wrapped?: boolean;
  component: any;
  apiKey?: string;
  publicRoute?: boolean;
  noHeader?: boolean;
};

const routes: Record<Scheme, Route> = {
  '/login': {
    component: lazy(() => import('components/login')),
  },
  '/sign-up': {
    component: lazy(() => import('components/login')),
  },
  '/privacy': {
    component: lazy(() => import('components/settings/PrivacyPolicy')),
  },
  '/tos': {
    component: lazy(() => import('components/settings/TermsOfService')),
  },
  '/error': {
    component: ErrorPage,
  },
  '/sfobject/opportunity/:dealId': {
    component: FullScreenOpportunities,
  },
  '/admin/dashboard': {
    component: lazy(() => import('components/admin')),
  },
  '/meetings': {
    component: UpcomingMeetings,
    exact: true,
    strict: true,
    wrapped: true,
    apiKey: 'meetings__upcoming',
  },
  '/meetings/completed': {
    component: CompletedMeetings,
    exact: true,
    strict: true,
    wrapped: true,
    apiKey: 'meetings__completed',
  },
  '/event/:eventId': {
    component: Event,
  },
  '/search': {
    component: lazy(() => import('components/dashboard/Search')),
    wrapped: true,
  },
  '/settings': {
    component: lazy(() => import('components/settings/Settings')),
    strict: true,
    wrapped: true,
  },
  '/pipeline/analytics': {
    component: PipelineAnalytics,
    strict: true,
    wrapped: true,
    apiKey: 'pipeline__risk_analytics',
  },
  '/pipeline/dashboard': {
    component: PipelineDashboard,
    strict: true,
    wrapped: true,
    apiKey: 'pipeline__dashboard',
  },
  '/pipeline/deals-progression': {
    component: PipelineDealsProgression,
    strict: true,
    wrapped: true,
    apiKey: 'pipeline__deals_progression',
  },
  '/dashboards': {
    component: Dashboards,
    exact: true,
    strict: true,
    wrapped: true,
  },
  '/dashboards/0': {
    component: Dashboard,
    wrapped: true,
    apiKey: 'activity',
  },
  '/dashboards/1': {
    component: Dashboard,
    wrapped: true,
  },
  '/dashboards/2': {
    component: Dashboard,
    wrapped: true,
    apiKey: 'sellers',
  },
  '/opportunities/preview/:dealId': {
    component: Deal,
    strict: true,
    wrapped: true,
  },
  '/accounts/all': {
    component: Accounts,
    strict: true,
    wrapped: true,
    apiKey: 'accounts',
  },
  '/accounts/recommendations': {
    component: Accounts,
    wrapped: true,
  },
  '/accounts/analytics': {
    component: Accounts,
    wrapped: true,
  },
  '/accounts/preview/:id': {
    component: AccountInsights,
    wrapped: true,
  },
  '/sfobject/account/:id': {
    component: FullScreenAccount,
  },
  '/opportunities': {
    component: NewForecast,
    exact: true,
    strict: true,
    wrapped: true,
    apiKey: 'opportunities',
  },
  '/forecast/analytics': {
    component: ForecastAnalytics,
    strict: true,
    wrapped: true,
    apiKey: 'forecast__trends',
  },
  '/calls': {
    component: CallListing,
    exact: true,
    strict: true,
    wrapped: true,
    apiKey: 'calls__completed_calls',
  },
  '/calls/upcoming': {
    component: CallListing,
    exact: true,
    strict: true,
    wrapped: true,
    apiKey: 'calls__upcoming_calls',
  },
  '/transcript/:id': {
    component: CallDetails,
    exact: true,
    strict: true,
    wrapped: true,
  },
  '/external/call/:id': {
    component: ExternalCallDetails,
    publicRoute: true,
    wrapped: true,
  },
  '/external/join-call/:id': {
    component: ExternalJoinCall,
    publicRoute: true,
    noHeader: true,
    wrapped: true,
  },
  '/targets/forecast': {
    component: SellerTargets,
    strict: true,
    wrapped: true,
    apiKey: 'targets__forecast',
  },
  '/targets/pipeline-creation': {
    component: SellerTargets,
    strict: true,
    wrapped: true,
    apiKey: 'targets__pipeline_creation',
  },
  '/targets/pipeline-coverage': {
    component: SellerTargets,
    strict: true,
    wrapped: true,
    apiKey: 'targets__pipeline_coverage',
  },
  '/forecast/pacing': {
    component: Progression,
    strict: true,
    wrapped: true,
    apiKey: 'forecast__pacing',
  },
  '/forecast/dashboard': {
    component: ForecastDashboard,
    strict: true,
    wrapped: true,
    apiKey: 'forecast__dashboard',
  },
  '/forecast/roll-ups/preview/:tableId': {
    component: ForecastRollUps,
    strict: true,
    wrapped: true,
    apiKey: 'forecast__rollups',
  },
  '/forecast/roll-ups': {
    component: ForecastRollUps,
    strict: true,
    wrapped: true,
    apiKey: 'forecast__rollups',
  },
  '/prospect-account-dashboard': {
    component: ProspectAccountDashboard,
    wrapped: true,
    apiKey: 'prospect_account',
  },
  '/reps-recommendations/:tableName?': {
    component: RepsRecommendations,
    wrapped: true,
    apiKey: 'todos',
  },
  '/scorecard': {
    component: Scorecard,
    wrapped: true,
  },
  '/dashboard': {
    component: DashboardPage,
    exact: true,
    strict: true,
    wrapped: true,
  },
  '/dashboard/forecast': {
    component: ForecastDashboard,
    wrapped: true,
    apiKey: 'dashboards__forecast',
  },
  '/dashboard/trends': {
    component: ForecastAnalytics,
    wrapped: true,
    apiKey: 'dashboards__trends',
  },
  '/dashboard/deals-progression': {
    component: PipelineDealsProgression,
    wrapped: true,
    apiKey: 'dashboards__deals_progression',
  },
  '/dashboard/pacing': {
    component: Progression,
    wrapped: true,
    apiKey: 'dashboards__pacing',
  },
  '/dashboard/pipeline': {
    component: PipelineDashboard,
    wrapped: true,
    apiKey: 'dashboards__pipeline',
  },
  '/dashboard/risk_analytics': {
    component: PipelineAnalytics,
    wrapped: true,
    apiKey: 'dashboards__risk_analytics',
  },
  '/dashboard/leaderboard': {
    component: Dashboard,
    wrapped: true,
    apiKey: 'dashboards__leaderboard',
  },
  '/dashboard/scorecard': {
    component: Scorecard,
    wrapped: true,
    apiKey: 'dashboards__scorecard',
  },
  '/dashboard/seller': {
    component: Dashboard,
    wrapped: true,
    apiKey: 'dashboards__seller',
  },
  '/third-party-integration/create': {
    component: AddThirdPartyIntegrationModal,
    wrapped: true,
  },
  '/revbi': {
    component: RevBIContainer,
    wrapped: true,
    exact: true,
    apiKey: 'RevBI',
  },
  '/revbi/dashboard/:dashboardId': {
    component: RevBIContainer,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/revbi/dashboards/list': {
    component: RevBIContainer,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/revbi/metrics/list': {
    component: RevBIContainer,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/revbi/widgets/list': {
    component: RevBIContainer,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/revbi/metrics/create': {
    component: MetricCreate,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/revbi/metrics/edit/:metricId': {
    component: MetricCreate,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/revbi/widgets/edit/:widgetId': {
    component: WidgetCreate,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/revbi/widgets/create': {
    component: WidgetCreate,
    wrapped: true,
    apiKey: 'RevBI',
  },
  '/:tab_name/rev-bi/dashboard/:id': {
    component: RevBiDashboardContainer,
    wrapped: true,
    apiKey: 'revbi_dashboard',
  },
};

export const SCHEMES: Scheme[] = Object.keys(routes) as any[];
export const ROUTES: { [T in Scheme]: Route } = routes;

export const indexToDashboard: {
  [key: string]: { scheme: Scheme; tab: string };
} = {
  '0': { scheme: '/dashboards/0', tab: 'team_dashboard' },
  '1': { scheme: '/dashboards/1', tab: 'accounts_analytics' },
  '2': { scheme: '/dashboards/2', tab: 'seller_dashboard' },
};

export const dashboardToIndex = Object.keys(indexToDashboard).reduce<{
  [key: string]: string;
}>((acc, item) => ({ ...acc, [indexToDashboard[item].scheme]: item }), {
  '/dashboard/leaderboard': '0',
  '/dashboard/seller': '2',
});

export const apiKeyToScheme = SCHEMES.reduce<{ [apikey: string]: Scheme }>(
  (acc, scheme: Scheme) => {
    const currentRoute = routes[scheme] as Route;

    return currentRoute.apiKey
      ? { ...acc, [currentRoute.apiKey]: scheme }
      : acc;
  },
  {}
);
