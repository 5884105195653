import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import classNames from 'classnames';

import * as styles from './styles';
import {
  ATTENDEE_RESPONSE_STATUS,
  getUniqueNameOrEmail,
  replaceLinksWithAnchorTags,
} from '../helpers';
import { Attendee } from '../types';

type Props = {
  attendees: Attendee[];
  title?: string;
  callStartDate?: string;
  description: string;
};

const CallInvite: React.FC<Props> = ({
  attendees,
  title = '',
  callStartDate = '',
  description,
}) => {
  const cleanHtmlDescription = replaceLinksWithAnchorTags(
    DOMPurify.sanitize(description)
  );

  const attendeesCustomersFirst = useMemo(
    () =>
      attendees.slice().sort((a, b) => {
        if (a.is_customer && !b.is_customer) return -1;
        if (!a.is_customer && b.is_customer) return 1;
        return 0;
      }),
    [attendees]
  );

  const organizer = useMemo(
    () => attendees.find((attendee) => attendee.organizer),
    [attendees]
  );

  return (
    <div
      className={classNames(styles.callInviteWrapper, 'call-invite-wrapper')}
    >
      <div className="call-name">
        {title ? title : 'Meeting invite details'}
      </div>

      <div className="call-start-date">{callStartDate}</div>

      <div className="call-details-wrapper">
        <div className="call-creator">
          <div className="item-name">Creator:</div>

          <div className="item-value">{getUniqueNameOrEmail(organizer)}</div>
        </div>

        <div className="call-attendees">
          <div className="item-name">Attendees:</div>

          <div className="item-value">
            {attendeesCustomersFirst.map((attendee) => (
              <div
                className="attendee-item"
                key={getUniqueNameOrEmail(attendee)}
              >
                <span className="attendee-name">
                  {getUniqueNameOrEmail(attendee)}
                </span>

                {/* Show email only if unique_name is present to avoid showing email again */}
                {/* TODO: Will have this in the future */}
                {/* {!!attendee.unique_name && (
                  <span className="attendee-email">{`<${attendee.email}>`}</span>
                )} */}

                <span className={attendee.response_status}>
                  (
                  {
                    ATTENDEE_RESPONSE_STATUS[
                      attendee.response_status || 'needsAction'
                    ]
                  }
                  )
                </span>
              </div>
            ))}
          </div>
        </div>

        {!!description && (
          <div className="call-description">
            <div className="item-name">Description:</div>
            <div
              dangerouslySetInnerHTML={{
                __html: cleanHtmlDescription,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CallInvite;
