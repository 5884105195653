import { css } from 'emotion';

const boolDropdown = `
  label: dropdown-overrides;
  font-family: 'Roboto regular', sans-serif;
  font-size: 14px;
  font-weight: 100;
  @media (max-width: 1370px) {
    font-size: 10px;
  }
  &.ui.top.right.pointing.dropdown > .menu {
    margin-top: 0;
  }
  &.ui.dropdown {
    display: flex;
    align-items: center;
    padding: 0 9px;
    i {
      display: flex;
      align-items: center;
    }
  }
`;

export const styles = {
  wrapper: css`
    flex: 1;
    width: 100%;
  `,
  prospectEngagementContainer: css`
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
  `,
  table: css`
    border: 1px solid #dddddd;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 20px;
  `,
  table_header: css`
    border-top: 1px solid #dddddd;
    background: #f4f7f8;
    padding: 10px 20px;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    font-family: var(--bu-font-regular);
    color: #777;
    border-bottom: 1px solid #f1a525;
    text-transform: uppercase;
  `,
  attendees: css`
    color: #4a4a4a;
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
    font-family: var(--bu-font-regular);
    padding-right: 20px;
  `,
  title: css`
    color: var(--bu-gray-900);
    font-family: var(--bu-font-semi-bold);
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  `,
  attendee: css`
    line-height: 1.2rem;

    & + & {
      margin-top: 0.5rem;
    }
  `,
  subject: css`
    color: #4a4a4a;
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
    font-family: var(--bu-font-regular);
    padding-right: 20px;
    cursor: pointer;

    :hover {
      color: #327cff;
    }
  `,
  email_subject: css`
    font-size: 24px;
  `,
  no_show: css`
    display: block;
    color: #f74544;
    font-size: 15px;
    font-family: var(--bu-font-regular);
  `,
  position: css`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    font-family: var(--bu-font-regular);
    color: #4a4a4a;

    &.red {
      color: #f74544;
    }

    &.green {
      color: #0ba5a3;
    }
  `,
  graph: css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `,
  fullScreenIcon: css`
    font-size: 20px;
    margin-left: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  `,
  chatButton: css`
    margin-left: 10px;
  `,
  next_steps: css`
    display: inline-block;
    margin-bottom: 20px;
    background: var(--bu-white);
    border-radius: 5px;
    min-width: 261px;
    width: 100%;
    color: var(--bu-gray-900);
    align-items: center;
    border: 1px solid var(--bu-gray-500);
  `,
  next_steps_header: css`
    border-bottom: 1px solid var(--bu-gray-500);
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: var(--bu-gray-700);
    height: 55px;
  `,
  next_steps_header_title: css`
    color: var(--bu-gray-900);
  `,
  next_steps_header_icon: css`
    font-size: 30px;
    margin-right: 8px;
  `,
  description: css`
    padding: 10px;
    &:hover {
      background-color: var(--bu-gray-200);
    }
  `,
  boolDropdown: css`
    ${boolDropdown}

    &.dropdown,
    & .dropdown {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  `,
  disablePointerEvents: css`
    pointer-events: none;
  `,
  nonEditableField: css`
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  amountInlineStyles: css`
    width: 100%;
    display: flex;
    font-weight: 500;
    min-height: 33px;
    align-items: center;
    font-family: var(--bu-font-medium);

    & .allDeals-table-body-column-other {
      & div {
        height: auto;
        position: relative;
        width: auto;
        top: 0;
      }
      & div.opp-split-icon {
        width: 19px;
        height: 19px;
      }

      &,
      & div,
      & input {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }
  `,
  modal_container: css`
    border: 1px solid var(--bu-gray-300);
    border-radius: 2px;
  `,
  modal_header: css`
    padding: 0 21px;
    border-bottom: 1px solid var(--bu-gray-300);
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .dropdown {
      padding: 0;
    }
  `,
  modal_title: css`
    font-size: 16px;
    font-family: var(--bu-font-medium);
    color: var(--bu-gray-900);
    padding: 14px 0;
  `,
  syncButton: css`
    &.bu-button-icon {
      border-radius: 100%;
      font-size: 9px;
      width: 24px;
      height: 24px;
      min-width: 24px;
      margin: 0 10px 0 0;
      padding: 0;

      .bu-icon {
        color: var(--bu-green-500);
      }
    }
  `,
  pencilIcon: css`
    width: 12px;
    height: 12px;
    margin: 0 0 5px 10px;
    color: var(--bu-gray-900);
  `,
  SyncDealContainer: css`
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    color: var(--bu-gray-700);
  `,
};

export default styles;
