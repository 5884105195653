import classNames from 'classnames';
import React, { useState } from 'react';
import { Dimmer, Modal as SemanticModal } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import * as styles from 'components/UI/Modal/Modal/styles';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { useModal } from 'components/modals/ModalContext/modal.context';
import { history } from 'store/configureStore';

type Props = {
  size: 'fullscreen' | 'large' | 'small' | 'tiny' | 'mini';
  onClose: () => void;
  title: string | JSX.Element | null;
  controls?: JSX.Element;
  fullHeight?: boolean;
  fullWidth?: boolean;
  disableScrolling?: boolean;
};

const Modal: React.FC<Props> = ({
  size,
  onClose,
  children,
  title,
  controls,
  fullHeight = false,
  fullWidth = false,
  disableScrolling: disableScrollingProp = false,
}) => {
  const [exit, setExit] = useState(false);
  const { closeTooltip, isCloseEnabled } = useModal();

  const disableScrolling =
    disableScrollingProp || history.location.pathname.includes('~/deals');

  const closeHandler = () => {
    if (isCloseEnabled) {
      setExit(true);
      onClose();
    }
  };

  if (exit) {
    return null;
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { closeModal: closeHandler });
    }
    return child;
  });

  return (
    <Dimmer
      active
      onClickOutside={closeHandler}
      className={classNames('page', 'modals', styles.dimmer)}
    >
      <div
        className={classNames(
          'ui',
          size,
          'modal',
          'transition',
          'visible',
          'active',
          styles.modal,
          { [styles.fullHeight]: fullHeight }
        )}
        style={{
          position: 'relative',
        }}
      >
        <div className={styles.header}>
          <div className={classNames(styles.header_title, 'bu-font-heading')}>
            {title}
          </div>
          <div className={styles.controllers_box}>{controls}</div>

          <TooltipWrapper
            tooltip={<p className={styles.closeTooltip}>{closeTooltip}</p>}
            disable={!closeTooltip}
          >
            <div className={styles.closeButton_box}>
              <span className={styles.closeButton} onClick={closeHandler}>
                <BuIcon name={BoostUpIcons.ClosePopup} />
              </span>
            </div>
          </TooltipWrapper>
        </div>

        <SemanticModal.Content
          scrolling={!disableScrolling}
          className={classNames(styles.modalContent, {
            [styles.modalContentFullHeight]: fullHeight,
            'no-padding': fullWidth,
          })}
        >
          {childrenWithProps}
        </SemanticModal.Content>
      </div>
    </Dimmer>
  );
};

export default Modal;
