import { css } from 'emotion';

import { fontHeading2 } from 'assets/css/fontStyles';

export const h2Markdown = css`
  ${fontHeading2}
  font-size: 16px;
  text-align: left;
  color: var(--bu-text-primary);
  margin: 0 0 8px;
`;

export const ulMarkdown = css`
  margin: 0 0 16px 0px;
  padding-left: 20px;
  text-align: left;
  color: var(--bu-text-primary);
`;

export const olMarkdown = css`
  margin: 0 0 16px 0px;
  padding-left: 20px;
  text-align: left;
  color: var(--bu-text-primary);
`;

export const bigFont = css`
  font-size: 16px;
  line-height: 22.4px;
`;
