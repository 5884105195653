import axiosInstance from 'api/axiosInstance';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { GetTableDataApiParams } from 'components/dashboard/Metrics/Widget/Report/ReportTable/DeprecatedReportTable';

type GetTableDataMethodParams = {
  getTableDataParams: GetTableDataApiParams;
  tableDataUrlApiPath: string;
};

type DealsApiResponse = {
  data: {
    count: number;
    deals: Deals.Deal[];
    total_amount: number;
  };
  accounts: IRow[];
  total_count: number;
};

export const getTableData = async ({
  getTableDataParams,
  tableDataUrlApiPath,
}: GetTableDataMethodParams): Promise<DealsApiResponse> => {
  const res = await axiosInstance.post<DealsApiResponse>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/${tableDataUrlApiPath}/`,
    getTableDataParams
  );

  const data = res.data ?? {};

  return data;
};

////////// NEW API //////////
export interface OrderBy {
  column: string;
  direction: number;
}

export type RevbiColumnMetaType =
  | 'clickable'
  | 'local'
  | 'relative'
  | 'risk'
  | 'sales_process';

export interface RevbiColumnMeta {
  type?: RevbiColumnMetaType;
  delta: boolean;
  clickable_action: {
    table: string;
    path: string;
  };
  positive_indicators_field?: string;
  risk_factors_field?: string;
}

export type RevbiColumnType =
  | 'text'
  | 'bool'
  | 'number'
  | 'money'
  | 'corporate_currency'
  | 'percentage'
  | 'note'
  | 'date'
  | 'picklist'
  | 'multipicklist'
  | 'custom'
  | 'CRM Lookup'
  | 'sales_process'
  | 'risk';

export interface RevbiColumn {
  object_field: string;
  display_name: string;
  type: RevbiColumnType;
  sortable: boolean;
  editable: boolean;
  meta: RevbiColumnMeta;
  length?: number;
  choices?: string[];
}

export interface TableDisplayConfiguration {
  _id?: string;
  company_id: string;
  name: string;
  created_at?: string;
  updated_at?: string;
  columns: RevbiColumn[];
  order: OrderBy;
  table_name: string;
  is_table_default: boolean;
  sales_process_columns?: string[];
}

export const getTableDisplayConfiguration = async (
  talbeid: string
): Promise<TableDisplayConfiguration> => {
  const url = `/rev_bi/table_displays/${talbeid}`;
  const res = await axiosInstance.get<TableDisplayConfiguration>(url);
  return res.data;
};
