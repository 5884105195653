import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';

import Header from 'components/UI/Wrapper/Public/Header';

import * as s from '../Wrapper/styles';

interface Props {
  component: React.ComponentType;
  noHeader?: boolean;
}

const PublicWrappedComponent: React.FC<Props> = (props) => (
  <div
    id="main_content"
    className={classNames(s.content_box, 'no-left-panel', {
      'no-header': props.noHeader,
    })}
  >
    {!props.noHeader && <Header />}

    <div style={{ height: '100%', width: '100%' }}>
      <props.component {...props} />
    </div>
  </div>
);

export default PublicWrappedComponent;
