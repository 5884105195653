import classNames from 'classnames';
import { css } from 'emotion';
import debounce from 'lodash/debounce';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import {
  DEFAULT_ACCOUNTS_ORDER_BY,
  NEW_METRIC,
  SUPPORTED_REPORT_VIEW_OBJECTS,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/ReportDefinition/constants';
import {
  InputContainer,
  MessageSpan,
  RadiosContainer,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/ReportDefinition/styles';
import { DEFAULT_OPPORTUNITIES_ORDER_BY } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/constants';
import {
  ACCOUNT,
  AnalysisType,
  OPPORTUNITY,
} from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  DataDescriptor,
  BIReportMetric,
  BIMetricSimple,
  BIMetricsQueryFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { getObjectsListFormattedByAnalysisType } from 'selectors/revbi/metrics';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  getTableDisplayConfiguration,
  TableDisplayConfiguration,
} from 'api/RevBiTableDisplay';
import { useQuery } from '@tanstack/react-query';
import { IReduxState } from 'reducers/types';
interface Props {
  readonly widget: BIWidget;
  readonly columnList: DataDescriptor[];
  setWidget: (widget: Partial<BIWidget>) => void;
}

enum Direction {
  Descending = -1,
  Ascending = 1,
}

export const ReportDefinition: React.FC<Props> = ({
  widget,
  columnList,
  setWidget,
}) => {
  const { tableSettings } = useContext(RevBISettingsContext);
  const [localMetric, setLocalMetric] = useState<Partial<BIReportMetric>>(
    widget.metric_list[0] || NEW_METRIC
  );
  const [numberOfRecords, setNumberOfRecords] = useState<number>(
    widget.limit ?? 0
  );
  const [isValidNumberOfRecords, setValidNumberOfRecords] =
    useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<string>(
    () => widget?.order_by_column?.name || ''
  );
  const [direction, setDirection] = useState<Direction>(() =>
    widget?.order_by?.[0] === 'ascending'
      ? Direction.Ascending
      : Direction.Descending
  );

  const objectList = useSelector((state: IReduxState) =>
    getObjectsListFormattedByAnalysisType(state, AnalysisType.LIVE)
  );

  const tableConfigurationUrl = widget.table_display_id;

  const isAccount = widget.metric_list[0]?.object === ACCOUNT;

  const changeOrderByConfig = (tableConfig: TableDisplayConfiguration) => {
    // Check if widget has a valid order by column
    const hasValidOrderBy =
      widget.order_by_column &&
      tableConfig.columns.some(
        (column) => column.object_field === widget.order_by_column?.name
      );

    if (hasValidOrderBy) {
      return tableConfig;
    }

    const orderByColumn = tableConfig.order.column;
    const orderByColumnData = tableConfig.columns.find(
      (column) => column.object_field === orderByColumn
    );

    if (orderByColumnData) {
      setOrderBy(orderByColumn);
      setDirection(tableConfig.order.direction);
      setWidget({
        order_by_column: {
          name: orderByColumnData.object_field,
          label: orderByColumnData.display_name,
          type: orderByColumnData.type,
        },
        order_by: [
          tableConfig.order.direction === Direction.Ascending
            ? 'ascending'
            : 'descending',
        ],
      });
    } else {
      setOrderBy(tableConfig.columns[0].object_field);
      setDirection(Direction.Descending);
      setWidget({
        order_by_column: {
          name: tableConfig.columns[0].object_field,
          label: tableConfig.columns[0].display_name,
          type: tableConfig.columns[0].type,
        },
        order_by: ['descending'],
      });
    }
  };

  const { data: tableConfig, isLoading: isTableSettingsLoading } = useQuery({
    queryKey: ['getTableConfiguration', tableConfigurationUrl],
    queryFn: async () => {
      const tableConfig = await getTableDisplayConfiguration(
        tableConfigurationUrl!
      );
      changeOrderByConfig(tableConfig);
      return tableConfig;
    },
    enabled: !!tableConfigurationUrl && !isAccount,
  });

  useEffect(() => {
    setOrderBy(() => widget?.order_by_column?.name || '');
    setDirection(() =>
      widget?.order_by?.[0] === 'ascending'
        ? Direction.Ascending
        : Direction.Descending
    );
  }, [widget]);

  useEffect(() => {
    // First time we need to set the table display id
    if (!widget.table_display_id) {
      const object = widget.metric_list[0]?.object;
      const tableDisplayId = tableSettings[object];
      setWidget({
        table_display_id: tableDisplayId,
      });
    }
  }, [widget.table_display_id, widget.metric_list[0]?.object]);

  const handleNumberOfRecordsChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      const numOfRecords = !isNaN(e.currentTarget.valueAsNumber)
        ? e.currentTarget.valueAsNumber
        : 0;
      let isValidNumber = true;

      if (numOfRecords < 0) {
        isValidNumber = false;
      }

      setValidNumberOfRecords(isValidNumber);
      setNumberOfRecords(numOfRecords);

      if (numberOfRecords !== numOfRecords && isValidNumber) {
        setWidget({ limit: numOfRecords });
      }
    }, 400),
    [widget]
  );

  const handleObjectChange = useCallback(
    (values: string[]) => {
      const object = values[0];
      const tableDisplayId = tableSettings[object];

      if (object === localMetric.object) {
        return;
      }

      setLocalMetric((prev) => ({
        ...prev,
        object: object,
        filters: [],
      }));
      setWidget({
        metric_list: [{ ...localMetric, object: object, filters: [] }],
        order_by_column:
          object === ACCOUNT ? DEFAULT_ACCOUNTS_ORDER_BY : undefined,
        table_display_id: tableDisplayId,
      });
    },
    [localMetric]
  );

  const handleOrderBy = (columnName: string): void => {
    setOrderBy(columnName);

    // Currently accounts use columnList while other objects use table configuration
    // TODO: Accounts will be migrated to use table configuration as well
    if (isAccount) {
      const column = columnList.find(
        (c: DataDescriptor) => c.name === columnName
      );
      if (column) {
        setWidget({
          order_by_column: column,
        });
      }
    } else {
      const column = tableConfig?.columns.find(
        (c) => c.object_field === columnName
      );
      if (column) {
        setWidget({
          order_by_column: {
            name: column.object_field,
            label: column.display_name,
            type: column.type,
          },
        });
      }
    }
  };

  const handleOrderByDirection = (direction: Direction): void => {
    setDirection(direction);
    setWidget({
      order_by: [
        direction === Direction.Ascending ? 'ascending' : 'descending',
      ],
    });
  };

  // this funtion makes me thinking we have to improve it...
  // probably wasn't a good idea to move it here...
  const handleChangeFilters = (
    complete: boolean,
    filters: BIMetricsQueryFilter[]
  ) => {
    const newMetric = { ...localMetric, filters };
    setLocalMetric(newMetric);
    setWidget({ metric_list: [newMetric] });
  };

  const objectSelectorOptions = useMemo(() => {
    const tableNamesInSettings = Object.keys(tableSettings);
    return objectList
      .filter((object: ISelectOption) =>
        [...tableNamesInSettings, 'account'].includes(object.value)
      )
      .map((object: ISelectOption) => ({
        text: (
          object.text[0].toUpperCase() + object.text.substring(1)
        ).replaceAll('_', ' '),
        value: object.value,
      }));
  }, [objectList, tableSettings]);

  const accountReportOrderBySelectorOptions = useMemo(
    () =>
      columnList
        ? columnList
            .map((column: DataDescriptor) => ({
              text: column.label,
              value: column.name,
            }))
            // To remove duplicates which are causing issues
            .filter(
              (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
            )
        : [],
    [columnList]
  );

  const reportOrderBySelectorOptions = useMemo(() => {
    return tableConfig?.columns.map((column) => ({
      text: column.display_name,
      value: column.object_field,
    }));
  }, [tableConfig]);

  const orderByOptions = isAccount
    ? accountReportOrderBySelectorOptions
    : reportOrderBySelectorOptions;

  const sortOrderByOptions =
    orderByOptions?.sort((a: ISelectOption, b: ISelectOption) =>
      a.text.localeCompare(b.text, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    ) || [];

  const getOrderByValue: string[] = useMemo(() => {
    if (orderBy) return [orderBy];
    if (localMetric?.object === ACCOUNT) return ['account.open_opportunities'];
    if (tableConfig?.order?.column) return [tableConfig.order.column];
    if (localMetric.object === OPPORTUNITY)
      return ['opportunity.opportunity_name'];
    if (sortOrderByOptions.length > 0) return [sortOrderByOptions[0].value];

    return [''];
  }, [orderBy, localMetric.object, tableConfig?.order, sortOrderByOptions]);

  return (
    <>
      <InputContainer>
        <MetricCreateSubTitle>Object</MetricCreateSubTitle>
        <BuSelect
          fullWidth
          secondary
          isLargePlaceholder
          options={objectSelectorOptions as ISelectOption[]}
          defaults={[localMetric.object ?? '']}
          placeholder="Select a table"
          testingLabel="object"
          onChange={handleObjectChange}
        />
      </InputContainer>
      <InputContainer>
        <Conditions
          metric={localMetric as BIMetricSimple}
          columnFields={[localMetric.object ?? '']}
          onCompleteConditions={handleChangeFilters}
        />
      </InputContainer>
      <InputContainer>
        <MetricCreateSubTitle>Number of records</MetricCreateSubTitle>
        <BuInput
          isAlignLeftText={false}
          type="number"
          value={numberOfRecords}
          testingLabel="number-of-records"
          onChange={(e) => handleNumberOfRecordsChange(e)}
          className={classNames(
            css`
              width: 100%;
              margin-bottom: 4px;
              input {
                text-align: left !important;
              }
            `,
            !isValidNumberOfRecords &&
              css`
                border-color: var(--bu-red-400) !important;
                input {
                  background-color: var(--bu-red-100);
                  color: var(--bu-red-600);
                  box-shadow: none !important;
                }
              `
          )}
        />

        {isValidNumberOfRecords ? (
          <>
            <BuIcon
              name={BoostUpIcons.BadgeInfoSolid}
              className={css`
                font-size: 18px;
              `}
              color="var(--bu-primary-500)"
            />
            <MessageSpan isValid>
              Enter the maximum number of records to return. Leave empty or
              enter 0 for no limit.
            </MessageSpan>
          </>
        ) : (
          <>
            <BuIcon
              name={BoostUpIcons.BadgeWarningSolid}
              className={css`
                font-size: 18px;
              `}
              color="var(--bu-red-400)"
            />
            <MessageSpan isValid={false}>
              Please choose a value that is greater or equal to 0
            </MessageSpan>
          </>
        )}
      </InputContainer>
      <InputContainer>
        <MetricCreateSubTitle>Order by</MetricCreateSubTitle>
        <BuSelect
          secondary
          fullWidth
          searchable
          isLargePlaceholder
          options={sortOrderByOptions}
          onChange={(values: string[]) => handleOrderBy(values[0])}
          defaults={getOrderByValue}
          placeholder={
            !isAccount && isTableSettingsLoading
              ? 'Loading...'
              : 'Select a option'
          }
          testingLabel="order-by"
          disabled={!isAccount && isTableSettingsLoading}
        />
        <RadiosContainer>
          <BuRadio
            onChange={(_, { value }) => {
              setDirection(value as Direction);
              handleOrderByDirection(value as Direction);
            }}
            value={Direction.Descending}
            checked={direction === Direction.Descending}
            label="Descending"
            testingLabel="Descending"
          />
          <BuRadio
            onChange={(_, { value }) => {
              setDirection(value as Direction);
              handleOrderByDirection(value as Direction);
            }}
            value={Direction.Ascending}
            checked={direction !== Direction.Descending}
            label="Ascending"
            testingLabel="Ascending"
          />
        </RadiosContainer>
      </InputContainer>
    </>
  );
};
