import BuConfirmationModal from '../BuConfirmationPopup';
import BuConfirmationPopup from '../BuConfirmationPopup';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import {
  createCustomView,
  updateCustomView as updateCustomViewAction,
} from 'actions/customViews';
import * as forecastActions from 'actions/forecastActions';
import {
  tabsWithAnalytics,
  allTabs,
  BusinessTypeTab,
  USER_ROLES,
  Role,
} from 'common/constants';
import FILTERS from 'common/filters';
import CreateNewViewModal from 'components/UI/OpenFiltersPanel/CreateNewViewModal/index';
import CustomViewsDropdown from 'components/UI/OpenFiltersPanel/CustomViewsDropdown';
import { DropdownCalendar } from 'components/UI/OpenFiltersPanel/Dropdowns/Calendar';
import { DropdownMoneySlider } from 'components/UI/OpenFiltersPanel/Dropdowns/MoneySlider';
import { DropdownNormal } from 'components/UI/OpenFiltersPanel/Dropdowns/Normal';
import { DropdownText } from 'components/UI/OpenFiltersPanel/Dropdowns/Text';
import SaveViewDropdown from 'components/UI/OpenFiltersPanel/SaveViewDropdown';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { Props as HandlerProps } from 'components/UI/OpenFiltersPanel/types';
import { PinnedView } from 'components/UI/OpenFiltersPanel/types';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { USER_MANAGEMENT_ROLES } from 'components/settings/Settings/types';
import { IReduxState } from 'reducers/types';
import {
  getActiveFiltersProfile,
  getFiltersForCustomViews,
  getViewNames,
  getForecastActiveBusinessType,
  getPersistName,
  getSelectedBusinessTypeForPanel,
  getShowOrHideColumns,
  getSelectedItem,
  getFiltersState,
  getShowCustomViews,
  isReadOnlyUser,
  getUserRole,
  isUserRole,
} from 'selectors';

type OwnProps = {
  tab: string;
  activeTab?: string;
  isModal?: boolean;
  hideFilters?: string[];
  onChangeFilter?: (
    tab: string,
    name: string,
    values: Filters.PersistValue[],
    withReset: boolean,
    emptyNotAllowed?: boolean
  ) => void;
};
type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = typeof dispatchToProps;

type Props = ReduxStateProps & ReduxDispatchProps & OwnProps;

type HandlerType = Record<Filters.Config['type'], React.FC<HandlerProps>>;

const Handlers: HandlerType = {
  date: DropdownCalendar,
  datepicker: DropdownCalendar,
  radio: DropdownNormal,
  checkbox: DropdownNormal,
  text_field: DropdownNormal,
  search_bar: DropdownText,
  slider: DropdownMoneySlider,
};

const isShowCustomViewPage: Record<string, boolean> = {
  accounts: true,
  forecast_opportunities: true,
  forecast_roll_ups: true,
  forecast_analytics: true,
  forecast_analytics_change: true,
  pipeline_dashboard: true,
  pipeline_analytics: true,
  seller_dashboard: true,
  pipeline_deals_progression: true,
};

const OpenFiltersPanel: React.FC<Props> = ({
  clearFilters,
  filterPersist,
  onChangeFilter = () => {},
  filters,
  tab,
  activeTab,
  isModal = false,
  isShowCustomViews,
  isReadOnlyUser,
  selectedMetric,
  selectMetric,
  hideFilters = [],
}) => {
  const dispatch = useDispatch();

  const {
    persistName,
    properties,
    showOrHideColumns,
    selectedBusinessType,
    selectedProfile,
    filterProfilesNames,
    userRole,
  } = useSelector((state: IReduxState) => {
    return {
      persistName: getPersistName(state, false),
      properties: getFiltersForCustomViews(state, tab),
      showOrHideColumns: getShowOrHideColumns(state, false),
      selectedBusinessType: allTabs.includes(tab as BusinessTypeTab)
        ? getSelectedBusinessTypeForPanel(state, tab as BusinessTypeTab)
        : getForecastActiveBusinessType(state),
      selectedProfile: getActiveFiltersProfile(state, tab),
      filterProfilesNames: getViewNames(state, tab),
      userRole: getUserRole(state) as Role,
    };
  });

  const isSalesOps = useSelector((state) =>
    isUserRole(state, USER_MANAGEMENT_ROLES)
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [pinnedView, setPinnedView] = useState<PinnedView>({
    id: '',
    name: '',
  });

  if (!filters) {
    return null;
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitNewView = (
    name: string,
    isPinned: boolean,
    shouldShareForAllProfiles: boolean
  ) => {
    if (name && properties) {
      const data: Filters.CustomViewCreationPayload = {
        view_type: 'PERSONAL',
        view_section: tab,
        pinned: isPinned,
        global: shouldShareForAllProfiles,
        properties: {
          ...properties,
          tablePersistName: persistName,
          columnToggleState: showOrHideColumns,
          display_name: name,
          selectedBusinessType,
        },
      };

      dispatch(createCustomView(data));
      setModalOpen(false);
      handleViewAnalytics(data, name, true);
    }
  };

  const updateCustomView = () => {
    if (selectedProfile?.name && selectedProfile?.profile?.id && properties) {
      const data: Filters.CustomView = {
        view_type: 'PERSONAL',
        view_section: tab,
        pinned: Boolean(selectedProfile.profile.pinned),
        properties: {
          ...properties,
          tablePersistName: persistName,
          columnToggleState: showOrHideColumns,
          display_name: selectedProfile.name,
          selectedBusinessType,
        },
      };

      dispatch(
        updateCustomViewAction({ id: selectedProfile.profile.id, data })
      );
      handleViewAnalytics(data, selectedProfile?.name, false);
    }
    setShowConfirmationModal(false);
  };

  const handleUpdateCustomView = () => {
    if (isSalesOps) {
      setShowConfirmationModal(true);
    } else {
      updateCustomView();
    }
  };

  const handleViewAnalytics = (
    data: Filters.CustomView,
    name: string,
    isNew?: boolean
  ) => {
    if (tabsWithAnalytics.includes(tab)) {
      AnalyticsTracker.event(
        { tab, data },
        {
          category: isNew ? 'Save view' : 'Update view',
          action: isNew ? 'Create new view' : 'Update view',
          label: name,
        }
      );
    }
  };

  const handleAnalytics = (
    action: string,
    label: string = '',
    values?: Filters.PersistValue[]
  ): void => {
    if (tabsWithAnalytics.includes(tab)) {
      AnalyticsTracker.event(
        { tab, values },
        {
          category: 'Filters Panel',
          action,
          label,
        }
      );
    }
  };

  const filtersList = Object.keys(filters).filter(
    (filter) =>
      filter !== 'users_seller' &&
      (activeTab !== 'scorecard' ||
        filter === 'activity_period' ||
        filter === 'sales_managers') &&
      !hideFilters.includes(filter)
  );

  const canViewBeUpdated =
    selectedProfile?.profile.view_type === 'DEFAULT' ||
    (selectedProfile?.profile.view_type === 'GLOBAL' && !isSalesOps);

  const onChangeView = (name: string): void => {
    clearFilters({ tab });
    selectMetric('');
    handleAnalytics('Change View', name);
  };

  const isCustomViewEnabledForTab =
    isShowCustomViews && isShowCustomViewPage[tab];

  return (
    <div className={`${s.panel} ${isModal ? 'modal' : ''}`}>
      <div className={s.panel_filters}>
        {isCustomViewEnabledForTab && (
          <CustomViewsDropdown
            tab={tab}
            pinnedView={pinnedView}
            setPinnedView={setPinnedView}
            onSelectView={onChangeView}
            openCreateNewViewModal={handleOpenModal}
          />
        )}

        {filtersList.map((name) => {
          const filter = filters[name];
          const Handler = Handlers[filter.type] || DropdownNormal;

          return (
            <Handler
              tab={tab}
              key={name}
              name={name}
              config={filter}
              onChange={(
                values: Filters.PersistValue[],
                withReset: boolean = false
              ) => {
                // unselect metrics bar when change forecast category filter
                if (name === FILTERS.FORECAST_CATEGORY_NAMES.key) {
                  selectMetric('');
                }

                // unselect metric if opp stage filter is changed while Booked is selected metric
                if (
                  name === FILTERS.OPPORTUNITY_STAGES.key &&
                  selectedMetric === 'Booked'
                ) {
                  selectMetric('');
                }

                filterPersist({
                  tab,
                  name,
                  values,
                  withReset,
                  emptyNotAllowed: filter.emptyNotAllowed,
                });
                handleAnalytics('Change Filter', filter.title, values);
                onChangeFilter(
                  tab,
                  name,
                  values,
                  withReset,
                  filter.emptyNotAllowed
                );
              }}
            />
          );
        })}

        <button
          className={s.panel_button}
          onClick={() => {
            clearFilters({ tab });
            handleAnalytics('Reset Filters');
            selectMetric('');
          }}
        >
          Reset filters
        </button>
      </div>

      <div className={s.saveBtnContainer}>
        {Boolean(
          !isReadOnlyUser && isShowCustomViews && isShowCustomViewPage?.[tab]
        ) && (
          <SaveViewDropdown
            canBeUpdated={canViewBeUpdated}
            openCreateNewViewModal={handleOpenModal}
            onUpdateCustomView={handleUpdateCustomView}
          />
        )}
      </div>

      <CreateNewViewModal
        isOpen={isModalOpen}
        filterProfilesNames={filterProfilesNames}
        canCreateSharedView={userRole === USER_ROLES.SALES_OPERATIONS}
        onClose={handleCloseModal}
        onSubmit={handleSubmitNewView}
      />

      <BuConfirmationPopup
        cancelText="Cancel"
        confirmText="Confirm"
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => updateCustomView()}
      >
        <div className={s.confirmationModalContent}>
          You are updating a shared view. Changes made will impact users who
          have access to this view
        </div>
      </BuConfirmationPopup>
    </div>
  );
};

const stateToProps = (state: IReduxState, { tab }: OwnProps) => ({
  filters: getFiltersState(state, tab),
  isShowCustomViews: getShowCustomViews(state),
  isReadOnlyUser: isReadOnlyUser(state),
  selectedMetric: getSelectedItem(state),
});

const dispatchToProps = {
  clearFilters: actions.ui.filters.clearAll,
  filterPersist: actions.ui.filters.persist,
  selectMetric: forecastActions.selectItem,
};

export default connect(stateToProps, dispatchToProps)(OpenFiltersPanel);
