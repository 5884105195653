import * as R from 'ramda';

import { MetricObjectItemsTotals } from 'api/MetricObjectItems';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { highlightText as highlightTextStyle } from 'components/UI/common/TypedTable/styles';
import { IRowType } from 'components/settings/SalesProcess/types';
import { set } from 'lodash';
import { ColumnTypes } from './renderers';
import { CustomCellConfig } from './renderers/CustomCell';
import TotalCell from './renderers/custom/TotalCell';
import { getCellValue } from './renderers/custom/common';

export type IEditableRow = {
  isEditing: boolean;
  __backup: IRow | null;
} & IRow;

export type IAddableRow = {
  isNew: boolean;
} & IRow;

let newIdCounter = 0;
export const EditingRow = {
  useEditingRow: <T extends IRow>(row: T): IEditableRow & T => ({
    ...row,
    isEditing: false,
    __backup: null,
  }),

  startEditing: <T extends IEditableRow>(row: T): T => ({
    ...row,
    isEditing: true,
    __backup: R.clone(row),
  }),

  cancelEditing: <T extends IEditableRow>(row: T): T => ({
    ...row,
    ...row.__backup,
    isEditing: false,
    __backup: null,
  }),

  getOriginal: <T extends IEditableRow>(row: T): T => row.__backup as T,

  canEditOnlyOne: (row: IRow, rows: IRow[]) =>
    (rows as IRowType[]).some((item) => item.isEditing),

  mapStartEditingCallback:
    <T extends IEditableRow>(row: IRow) =>
    (item: T): T =>
      item.id === row.id ? EditingRow.startEditing(item) : item,

  mapCancelEditingCallback:
    <T extends IEditableRow>(row: IRow) =>
    (item: T): T =>
      item.id === row.id ? EditingRow.cancelEditing(item) : item,

  useAddingRow: <T extends IRow>(row: T): IAddableRow & T => ({
    ...row,
    isNew: false,
  }),

  createNewRow: <T extends IRow>(row: T): T & IAddableRow => ({
    ...row,
    id: row.id ? row.id : `**new-row-${newIdCounter++}`,
    isNew: true,
  }),

  filterCancelEditingNewRowsCallback:
    <T extends IAddableRow>(row: IRow) =>
    (item: T): boolean =>
      !(item.isNew && item.id === row.id),
};

/**
 * Expands/Collapse children rows
 *
 * @param source
 * @param level which level should be expanded/collapsed
 * @param expand
 * @param bottomTop does expands parents also
 */
export const setRowExpand = <T extends IRow>(
  source: T[],
  level: number,
  expand: boolean,
  bottomTop: boolean = false
): T[] =>
  level >= 0
    ? source.map((item) => ({
        ...item,
        isChildrenVisible:
          level === 0 || bottomTop ? expand : item.isChildrenVisible,
        children: item.children
          ? setRowExpand(item.children, level - 1, expand, bottomTop)
          : undefined,
      }))
    : source;

export const highlightText = (text: string, highlight: string) => {
  const expr = new RegExp(`(${highlight})`, 'gi');
  return text.split(expr).map((x, i) =>
    x.match(expr) ? (
      <mark key={i} className={highlightTextStyle}>
        {x}
      </mark>
    ) : (
      <span key={i}>{x}</span>
    )
  );
};

/**
 * Helper function to generate the columns that we want for the totals
 * row
 */
export const getTotalColumns = (columns: IColumn[], row: IRow) => {
  const totalColumns = columns
    .slice(1)
    .filter((column) => !column.hidden)
    .map((column) => ({ ...column, editable: false }))
    .map((column) => {
      const value = column.field && getCellValue({ row, column });
      if (value === undefined || value === null) {
        return {
          ...column,
          // Hack so it can display empty for the rows that are not money
          field: 'not existant label',
          type: ColumnTypes.CUSTOM,
          editable: false,
          config: {
            renderer: () => null,
          } as CustomCellConfig,
        };
      }

      return { ...column, editable: false };
    });

  const totalColumn: IColumn = {
    field: 'totalLabel',
    id: 'totalLabel',
    label: 'total',
    sort_order: SortOrder.ASCENDING,
    type: ColumnTypes.CUSTOM,
    config: {
      renderer: TotalCell,
    } as CustomCellConfig,
  };
  return [totalColumn].concat(totalColumns);
};

export const getTotalsRow = (
  totals: MetricObjectItemsTotals | undefined,
  isLoading: boolean,
  /**
   * For some cases the field name in the totals object is different
   * than the one we have in the columns (like opportunities)
   */
  totalFieldToColumnField: Record<string, string> = {}
): IRow => {
  if (isLoading || !totals) {
    return { id: 'total', isLoading: true };
  }

  const { totals: totalsFields, total_amount: totalAmount } = totals;

  const totalAsRow = totalsFields.reduce(
    (acc, { column: totalField, value }) => {
      const rowField = totalFieldToColumnField[totalField] || totalField;

      set(acc, rowField, value);
      return acc;
    },
    {
      amount: totalAmount,
    } as Partial<IRow>
  );

  return {
    id: 'total',
    ...totalAsRow,
    isLoading: false,
  };
};
