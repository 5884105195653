import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuFormulaTextField from 'components/UI/BuFormulaTextField';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import { MetricCreateUnitsField } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/FormulaMetricInputs/MetricCreateUnitsField';
import {
  inputsContainer,
  tooltipSize,
} from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/styles';
import { parseFormulaToMetricsIdArray } from 'components/dashboard/Metrics/Widget/helper';
import {
  AnalysisType,
  OPPORTUNITY,
} from 'components/dashboard/Metrics/constants';
import {
  ExclamationIcon,
  MetricCreateSubTitle,
} from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricFormula,
  BIMetricSimple,
  BIMetricSimplified,
  BIMetricsQueryFilter,
  UnitValue,
} from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';

interface Props {
  readonly metric: BIMetricFormula;
  onCompleteMetricInputs: (
    complete: boolean,
    synthetic: Partial<BIMetricFormula>
  ) => void;
}

export const FormulaMetricInputs: React.FC<Props> = ({
  metric,
  onCompleteMetricInputs,
}) => {
  const [newFormula, setNewFormula] = useState<string>(
    metric?.synthetic_metric || ''
  );
  const [newUnit, setNewUnit] = useState<UnitValue | undefined>(
    metric?.properties?.metricUnit
  );
  const [filters, setFilters] = useState<{
    complete: boolean;
    filters: BIMetricsQueryFilter[];
  }>({ complete: !!metric?.filters, filters: metric?.filters ?? [] });

  const simpleMetricsSimplified: BIMetricSimplified[] = useSelector(
    metricSelectors.getAllSimpleMetricsSimplified
  );
  const simpleMetricsTSSimplified: BIMetricSimplified[] = useSelector(
    metricSelectors.getAllSimpleHistoricalMetricsSimplified
  );
  const metricsWithTSStatus = useSelector(
    metricSelectors.getAllMetricsWithTSStatus
  );

  const metricsList = useSelector(metricSelectors.getAllMetricsWithTS);

  const conditionsColumnFields = useMemo(() => {
    if (
      metric.analysis_type === AnalysisType.LIVE ||
      metric.analysis_type === AnalysisType.HISTORICAL
    ) {
      const objectsSet = new Set<string>();
      const usedFormulaMetricIds = parseFormulaToMetricsIdArray(newFormula);
      const usedMetricsList = metricsList.filter((metric) =>
        usedFormulaMetricIds.includes(metric._id)
      );
      usedMetricsList.forEach((metricObject) => {
        if (metricObject) {
          objectsSet.add((metricObject as BIMetricSimple).object);
        }
      });
      return Array.from(objectsSet);
    } else {
      return [];
    }
  }, [metric.analysis_type, metricsList, newFormula]);

  const handleApplyFormula = (formula: string): void => {
    setNewFormula(formula);
  };

  const handleChangeUnits = (unitValue: UnitValue): void => {
    setNewUnit(unitValue);
  };

  // this function makes me thinking we have to improve it...
  // probably wasn't a good idea to move it here...
  const handleChangeConditions = (
    complete: boolean,
    filters: BIMetricsQueryFilter[]
  ): void => {
    setFilters({ complete, filters });
  };

  useEffect(() => {
    if (newFormula) {
      const synthetic: Partial<BIMetricFormula> = {
        synthetic_metric: newFormula,
        properties: {
          metricUnit: newUnit,
        },
        filters: filters.filters,
      };

      onCompleteMetricInputs(filters?.complete, synthetic);
    }
  }, [newFormula, newUnit, filters.filters]);

  return (
    <>
      <div className={inputsContainer}>
        <MetricCreateSubTitle>
          <span>Formula</span>
          <TooltipWrapper
            tooltip={
              <div className={tooltipSize}>
                <p>
                  Formula metrics are composed of other metrics using a formula
                  with math operators and regular numbers. Using aggregation
                  functions such as sum, average etc straight in the formula
                  field is not supported.
                </p>
                <p>
                  Below is an example of a valid formula:
                  <br />
                  (Total amount of opps won / Total amount of opps) * 100
                </p>
              </div>
            }
            position="right center"
          >
            <ExclamationIcon>
              <BuIcon
                name={BoostUpIcons.BadgeInfoOutline}
                color="var(--bu-gray-700)"
              />
            </ExclamationIcon>
          </TooltipWrapper>
        </MetricCreateSubTitle>

        <BuFormulaTextField
          metrics={
            metric.analysis_type === AnalysisType.HISTORICAL
              ? simpleMetricsTSSimplified
              : simpleMetricsSimplified
          }
          metricsStatus={metricsWithTSStatus}
          formulaToBeEdited={newFormula}
          onFormulaFinishEditing={handleApplyFormula}
        />
      </div>

      <div className={inputsContainer}>
        <MetricCreateSubTitle>Units</MetricCreateSubTitle>
        <MetricCreateUnitsField
          initialValue={newUnit}
          onChange={handleChangeUnits}
        />
      </div>

      <Conditions
        metric={metric}
        columnFields={conditionsColumnFields}
        onCompleteConditions={handleChangeConditions}
      />
    </>
  );
};
