import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';

import { getContext, OBJECT_TYPE_OPEN_MODE } from './AIChatContext.helper';
import {
  SendFeedbackMutation,
  useAIChatService,
} from '../hooks/useAIChatService';
import { ChatMessage, ChatContext as APIChatContext } from '../api/AIChat';

// Define page context types using discriminated unions for type safety
export type PageContext =
  | {
      object_type: 'deal';
      object_id: string;
      suggestedActions: string[];
    }
  | {
      object_type: 'call';
      object_id: string;
      suggestedActions: string[];
    }
  | {
      object_type: 'none';
      suggestedActions: string[];
    };

export type OpenMode = 'none' | 'page' | 'quickview';

export type Feedback = 'positive' | 'negative' | 'neutral';

interface AIChatContextType {
  /**
   * Controls whether the chat is active and initialized.
   * When false, it will clear the chat history and stop any active queries.
   * Use this when you want to completely disable the chat functionality.
   */
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;

  /**
   * Controls only the visibility of the chat UI.
   * When true, the chat panel is hidden but the chat state and history are preserved.
   * Use this when you want to temporarily hide the chat without losing context.
   */
  hideChat: boolean;
  setHideChat: (hide: boolean) => void;

  /**
   * Computed property that determines if the chat should be visible.
   * It's true when chat is enabled AND not hidden.
   */
  showChat: boolean;

  openMode: OpenMode;
  setOpenMode: (mode: OpenMode) => void;
  pageContext: PageContext;
  setPageContext: (context: PageContext) => void;
  messages: ChatMessage[];
  sendMessage: (content: string) => Promise<void>;
  clearMessages: () => void;
  isLoadingHistory: boolean;
  isSendingMessage: boolean;
  sendFeedback: SendFeedbackMutation;
  isSendingFeedback: boolean;
}

const defaultPageContext: PageContext = {
  object_type: 'none',
  suggestedActions: ['How can I help you today?'],
};

const AIChatContext = createContext<AIChatContextType | undefined>(undefined);

export const AIChatProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [hideChat, setHideChat] = useState<boolean>(false);
  const [openMode, setOpenMode] = useState<OpenMode>('none');
  const [pageContext, setInternalPageContext] =
    useState<PageContext>(defaultPageContext);
  const [initializingChat, setInitializingChat] = useState<boolean>(false);

  const showChat = enabled && !hideChat;

  const {
    chatHistory,
    isLoadingHistory,
    initializeChat,
    sendMessage: apiSendMessage,
    isSendingMessage,
    sendFeedback,
    isSendingFeedback,
  } = useAIChatService({
    chatEnabled: enabled && !initializingChat,
  });

  const isLoadingChat = isLoadingHistory || initializingChat;

  /*
   * This function is used to initialize before setting the page context
   * This is only needed until we have a persistent chat history
   * that can be shared between sessions / pages
   *
   */
  const setPageContext = useCallback(
    async (context: PageContext) => {
      setInitializingChat(true);
      setEnabled(true);
      setOpenMode(
        OBJECT_TYPE_OPEN_MODE[
          context.object_type as keyof typeof OBJECT_TYPE_OPEN_MODE
        ]
      );
      setInternalPageContext(context);
      await initializeChat();
      setInitializingChat(false);
    },
    [initializeChat]
  );

  const clearMessages = () => {
    initializeChat();
  };

  const sendMessage = async (content: string): Promise<void> => {
    const apiContext = getContext(pageContext);

    try {
      await apiSendMessage({ query: content, context: apiContext });
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  return (
    <AIChatContext.Provider
      value={{
        enabled,
        setEnabled,
        hideChat,
        setHideChat,
        showChat,
        openMode,
        setOpenMode,
        pageContext,
        setPageContext,
        messages: chatHistory,
        clearMessages,
        isLoadingHistory: isLoadingChat,
        isSendingMessage,
        sendMessage,
        sendFeedback,
        isSendingFeedback,
      }}
    >
      {children}
    </AIChatContext.Provider>
  );
};

export const useAIChat = (): AIChatContextType => {
  const context = useContext(AIChatContext);
  if (!context) {
    throw new Error('useAIChat must be used within an AIChatProvider');
  }
  return context;
};
